import {
  APPROVAL_API_HOST,
  FORMS_API_HOST,
} from '~/common/utils/api-hosts.js';

export default {
  forms_sections: {
    url: 'sections',
    baseURL: FORMS_API_HOST,
  },
  forms_fields: {
    url: 'fields',
    baseURL: FORMS_API_HOST,
  },
  forms_templates: {
    url: 'templates',
    baseURL: FORMS_API_HOST,
  },
  forms_approvals: {
    url: 'approvals',
    baseURL: APPROVAL_API_HOST,
    methods: {
      approval: req => ({
        url: 'approval-flow/hook/action',
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
    },
  },
  forms: {
    url: 'forms',
    baseURL: FORMS_API_HOST,
    methods: {
      get_graph: req => ({
        url: 'reports',
        data: req.body,
        method: 'POST',
        toast: false,
      }),
      get_custom_report: req => ({
        url: 'reports/export-pdf',
        data: req.body,
        method: 'POST',
      }),
      get_submission_report: req => ({
        url: 'forms/submissions/responses/xlsx',
        data: req.body,
        responseType: 'blob',
        method: 'POST',
      }),
      get_kanban_data: req => ({
        url: 'forms/kanban-view',
        data: req.body,
        method: 'POST',
        signal: req.signal,
      }),
      get_attachments: req => ({
        url: 'forms/attachments',
        data: req.body,
        method: 'POST',
        signal: req.signal,
        // responseType: 'blob',
      }),
      get_field_filters_list: req => ({
        url: `templates/${req.id}/fields-filters`,
        ...(req.query && { params: req.query }),
        method: 'GET',
      }),
    },
  },
};
