import { okta_config } from '../utils/constants';

export default {
  auth: {
    methods: {
      logout(req = {}) {
        return {
          url: 'logout',
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
        };
      },
      get_freshworks_token(req = {}) {
        return {
          method: 'POST',
          url: 'freshworks/sign-in',
          data: req.body || {},
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
        };
      },
      fetchAccessToken(req) {
        return {
          method: 'POST',
          url: 'access-code-auth/',
          data: req.body,
        };
      },
      fetchRefreshToken() {
        return {
          method: 'POST',
          url: 'token/lifetime/extend/',
          data: { redirect_uri: `${location.origin}/auth/callback` },
        };
      },
      resendCode(req) {
        return {
          method: 'POST',
          url: `users/${req.id}/resend-code/`,
          data: req.body,
        };
      },
      confirmEmail(req) {
        return {
          method: 'POST',
          url: `users/${req.uid}/verify-code/`,
          data: { code: req.otp },

        };
      },
      fetchWebFinger(req) {
        const params = {
          resource: `okta:acct:${req.email}`,
          requestContext: encodeURI(
            `${import.meta.env.VITE_APP_OKTA}/oauth2/default/v1/authorize?`
            + `client_id=${import.meta.env.VITE_APP_OKTA_CLIENT_ID}`
            + '&response_type=code'
            + `&scope=${okta_config.scopes.join('+')}`
            + `&state=${crypto.randomUUID().slice(0, 6)}`
            + `&redirect_uri=${okta_config.redirectUri}`,
          ),
        };

        return {
          method: 'get',
          url: `${import.meta.env.VITE_APP_OKTA}/.well-known/webfinger`,
          params,
          data: req.body,
        };
      },
      fetchLoggedInUserDetails: (req = {}) => ({ ...req, url: '/info' }),
      requestForgotPassword(payload) {
        return {
          method: 'POST',
          url: 'users/reset-password/',
          data: payload,
        };
      },
      confirmEnrollment(req) {
        return {
          method: 'POST',
          url: 'users/confirm-enrollment',
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
        };
      },
      signUp(req = {}) {
        return {
          method: 'POST',
          url: 'users/register/',
          data: req.body,
          ...(req.query && { params: req.query }),
        };
      },
      resetPassword(req) {
        return {
          method: 'POST',
          url: 'users/password/',
          data: req.data,
          ...(req.query && { params: { p: req.query } }),
        };
      },
    },
  },
};
