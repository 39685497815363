<script setup>
import { storeToRefs } from 'pinia';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const emit = defineEmits(['add-tracking']);

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { is_type_user } = common_store;
const { active_schedule, active_task } = storeToRefs(project_management_store);

const $t = inject('$t');
const $date = inject('$date');

const state = reactive({
  is_section_open: true,
});

const activity_trackings = computed(() => {
  return active_schedule.value?.trackings?.filter?.(activity_tracking => activity_tracking.activity === active_task.value.uid);
});
</script>

<template>
  <div v-if="activity_trackings.length" class="py-4">
    <div class="flex items-center gap-2">
      <div class="cursor-pointer flex items-center" @click="state.is_section_open = !state.is_section_open">
        <IconHawkChevronDown v-if="state.is_section_open" class="inline" />
        <IconHawkChevronRight v-else class="inline" />
        <span class="text-sm font-semibold text-gray-900 ml-1">
          {{ $t('Activity tracking') }}
        </span>
      </div>
      <div
        v-if="active_task.actions.track"
        class="w-5 h-5 bg-primary-50 hover:bg-primary-100 flex items-center justify-center rounded-full cursor-pointer"
        @click="emit('add-tracking')"
      >
        <IconHawkPlus class="w-3 h-3 text-primary-700" />
      </div>
    </div>
    <div v-if="state.is_section_open" class="mt-2 ml-4">
      <PmListComponent :items="activity_trackings" left-section-wrapper-classes="w-full">
        <template #left_section="{ item }">
          <div class="flex gap-3 w-full">
            <div class="h-8">
              <HawkMembers
                :members="item.created_by?.uid"
                size="sm"
                :has_tooltip="false"
              />
            </div>
            <div class="flex flex-col gap-[2px] w-full">
              <div class="flex items-center justify-between">
                <HawkMembers
                  :members="item.created_by?.uid"
                  size="sm"
                  type="only_name"
                  name_classes="text-sm font-semibold text-gray-900"
                />
                <div class="text-xs font-normal text-gray-600">
                  {{ $date(item.created_at, 'DD MMMM YYYY hh:mm a') }}
                </div>
              </div>
              <div class="flex items-center gap-3 text-xs font-normal text-gray-600">
                <div v-if="item.resource" class="flex items-center gap-2">
                  {{ $t('Resource') }}:
                  <template v-if="active_schedule.resources.find(resource => resource.uid === item.resource)">
                    <template v-if="active_schedule.resources.find(resource => resource.uid === item.resource).type === 'custom'">
                      <HawkBadge size="md" custom_classes="!p-[3px] !h-full bg-gray-100 text-gray-100">
                        <div class="flex items-center text-xs font-medium text-gray-700">
                          <div class="w-4 h-4 flex items-center justify-center bg-gray-100 text-xs font-medium text-gray-600 rounded-full border border-gray-600 ">
                            {{ active_schedule.resources.find(resource => resource.uid === item.resource).name.charAt(0) }}
                          </div>
                          <div class="mx-1.5">
                            {{ active_schedule.resources.find(resource => resource.uid === item.resource).name }}
                          </div>
                        </div>
                      </HawkBadge>
                    </template>
                    <template v-else-if="active_schedule.resources.find(resource => resource.uid === item.resource).type === 'member'">
                      <template v-if="is_type_user(active_schedule.resources.find(resource => resource.uid === item.resource).external_id)">
                        <HawkMembers
                          :members="active_schedule.resources.find(resource => resource.uid === item.resource).external_id"
                          :has_avatar="true"
                          type="badge"
                          size="xs"
                        />
                      </template>
                      <template v-else>
                        <HawkMembers
                          :members="active_schedule.resources.find(resource => resource.uid === item.resource).external_id"
                          :has_avatar="true"
                          type="badge"
                          size="xs"
                        />
                      </template>
                    </template>
                  </template>
                  <div v-else class="italic text-gray-400">
                    {{ $t('Deleted') }}
                  </div>
                </div>
                <template v-if="item.resource && ['per_item', 'per_hour'].includes(active_schedule.resources.find(resource => resource.uid === item.resource)?.cost_type) && item.units">
                  <span class="text-gray-300">•</span>
                  <div v-if="active_schedule.resources.find(resource => resource.uid === item.resource)?.cost_type === 'per_hour'" class="flex gap-2">
                    {{ $t('Duration') }}:
                    <div
                      class="font-medium text-gray-700"
                    >
                      {{ item.units }} {{ $t('hrs') }}
                    </div>
                  </div>
                  <div v-else-if="active_schedule.resources.find(resource => resource.uid === item.resource)?.cost_type === 'per_item'" class="flex gap-2">
                    {{ $t('Items') }}:
                    <div
                      class="font-medium text-gray-700"
                    >
                      {{ item.units }} {{ $t('pcs') }}
                    </div>
                  </div>
                </template>
                <template v-if="active_schedule.track_costs">
                  <span v-if="item.resource" class="text-gray-300">•</span>
                  <div class="flex gap-2">
                    {{ $t('Cost') }}:
                    <div class="font-medium text-gray-700">
                      {{ active_schedule?.currency?.symbol }}{{ new Intl.NumberFormat('en-US').format(item.cost) }}
                    </div>
                  </div>
                </template>
                <template v-if="item.work">
                  <span class="text-gray-300">•</span>
                  <div class="flex gap-2">
                    {{ $t('Work') }}:
                    <div class="font-medium text-gray-700">
                      {{ item.work }} {{ $t('units') }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </PmListComponent>
    </div>
  </div>
</template>
