<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import PmRenameModal from '~/project-management/components/menus/pm-rename-modal.vue';
import PmSchedulePreview from '~/project-management/components/pm-schedule-preview.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');
const $date = inject('$date');

const router = useRouter();
const auth_store = useAuthStore();
const dashboard_store = useDashboardStore();
const project_management_store = useProjectManagementStore();

const { set_schedules } = project_management_store;
const { schedules } = storeToRefs(project_management_store);
const { current_organization } = storeToRefs(auth_store);

const rename_modal = useModal({
  component: PmRenameModal,
  attrs: {
    onClose() {
      rename_modal.close();
    },
  },
});

const form$ = ref(null);

const state = reactive({
  is_loading: false,
  search: '',
  freeze_table: -1,
  preview: '',
});

const filtered_schedules = computed(() => {
  const searched_schedules = [];
  schedules.value.forEach((x) => {
    if (x.name.toLowerCase().includes(state.search.toLowerCase()))
      searched_schedules.push(x);
  });
  return searched_schedules;
});

const is_boarding = computed(() => !schedules.value.length && !filtered_schedules.value?.length);

watch(() => [current_organization.value?.uid, router?.currentRoute.value.params?.asset_id], async () => {
  if (!current_organization.value?.uid || state.is_loading)
    return;
  try {
    state.preview = '';
    state.is_loading = true;
    await dashboard_store.set_fusion_chart();
    await set_schedules({
      asset: router?.currentRoute.value.params?.asset_id,
    });
    state.preview = schedules.value[0]?.uid;
    state.is_loading = false;
  }
  catch (error) {
    logger.error(error);
  }
}, { immediate: true });

onMounted(() => {
  project_management_store.$reset();
});
</script>

<template>
  <div>
    <HawkPageHeader
      :title="`${$t('Project management')} - ${$t('Schedules')}`"
      :display_onboarding="{ for: 'project-management' }"
    />
    <Vueform ref="form$" size="sm" class="px-4 pb-5">
      <div class="flex w-full col-span-full" :class="[is_boarding ? 'justify-end' : 'justify-between']">
        <HawkSearchInput v-if="!is_boarding" v-model="state.search" :placeholder="$t('Search')" />
        <!-- TODO: test in Safari -->
        <ButtonElement
          name="new_schedule"
          @click="router.push({ name: 'new-schedule' })"
        >
          <IconHawkPlus class="w-5 h-5 text-white inline -mt-0.5 mr-2" />
          <span class="w-full">{{ $t('New Schedule') }}</span>
        </ButtonElement>
      </div>
    </Vueform>
    <HawkLoader v-if="state.is_loading" />
    <div v-else-if="is_boarding">
      <hawk-illustrations type="on-boarding" for="project-management" />
    </div>
    <div v-else-if="!filtered_schedules?.length">
      <hawk-illustrations type="no-results" />
    </div>
    <div v-else-if="filtered_schedules?.length" class="px-4 flex gap-3">
      <div class="border border-gray-200 w-1/4 min-w-[calc(25vw)] rounded-xl h-fit max-h-[calc(100vh-220px)] scrollbar">
        <div
          v-for="(schedule, index) in filtered_schedules"
          :key="schedule.uid"
          class="cursor-pointer px-4 pt-4"
          :class="{
            'bg-gray-100': state.preview === schedule.uid,
            'rounded-t-xl': index === 0,
            'rounded-b-xl pb-4': index === filtered_schedules.length - 1,
          }"
          @click="state.preview = schedule.uid"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2 text-sm font-medium text-gray-900">
              <span
                v-tippy="{
                  content: schedule.name.length > 20 ? schedule.name : '',
                  placement: 'top',
                }"
                class="max-w-[15vw] truncate"
              >
                {{ schedule.name }}
              </span>
              <IconHawkEdit
                v-if="!schedule.is_published"
                v-tippy="{
                  content: $t('Unpublished schedule'),
                  placement: 'top',
                }"
                class="w-3 h-3"
              />
            </div>
            <div>
              <IconHawkTrophyFilled class="inline mr-1 text-warning-400" />
              <span class="text-sm font-semibold text-gray-900">
                {{ schedule.scheduled_milestones || 0 }}/{{ schedule.total_milestones || 0 }}
              </span>
            </div>
          </div>
          <div class="flex items-center justify-between mt-1">
            <div class="text-xs font-medium text-gray-700">
              {{ Math.round((schedule.progress || 0) * 100) }}% <span class="mx-1">•</span> <span
                :class="dayjs().isAfter(dayjs(schedule.planned_start ?? schedule.start)) && schedule.progress === 0 ? 'text-error-700' : ''"
              >
                {{ $date(schedule.planned_start ?? schedule.start, 'DD MMM YYYY') }}
              </span> -> <span
                :class="dayjs().isAfter(dayjs(schedule.planned_finish ?? schedule.finish)) && schedule.progress !== 1 ? 'text-error-700' : ''"
              >
                {{ $date(schedule.planned_finish ?? schedule.finish, 'DD MMM YYYY') }}
              </span>
            </div>
            <HawkBadge v-if="schedule.progress < schedule.percent_schedule_complete" color="yellow">
              {{ $t("Delayed") }}
            </HawkBadge>
            <HawkBadge v-else-if="schedule.progress >= schedule.percent_schedule_complete" color="blue">
              {{ $t("On track") }}
            </HawkBadge>
          </div>
          <hr v-if="index !== filtered_schedules.length - 1" class="mt-4 -mx-4">
        </div>
      </div>
      <PmSchedulePreview v-if="state.preview" :schedule="schedules.find(schedule => schedule.uid === state.preview)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep() {
  th {
    @apply text-xs font-medium text-gray-600;
  }
}

:deep() .no_pagination :last-child  tr:last-child {
  th:first-child,td:first-child{
    border-bottom-left-radius: 8px;
  }
  th:last-child,td:last-child {
    border-bottom-right-radius: 8px;
  }
}
</style>
