<script setup>
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  isPublished: {
    type: Boolean,
    required: true,
  },
});

const $t = inject('$t');

const router = useRouter();

const auth_store = useAuthStore();
const project_management_store = useProjectManagementStore();

const { active_schedule, is_fullscreen, schedule_dirtiness } = storeToRefs(project_management_store);

function onReload() {
  schedule_dirtiness.value = false;
  if (!props.isPublished && active_schedule.value?.members?.[auth_store.logged_in_user_details.user_id] !== 'write') {
    router.push({ name: 'project-management' });
    return;
  }
  router.go();
}
</script>

<template>
  <HawkModalContainer
    :options="{
      teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body',
      clickToClose: false,
      escToClose: false,
    }"
  >
    <HawkModalHeader :hide_close="true">
      <template #title>
        {{ $t('Please reload the page') }}
      </template>
    </HawkModalHeader>
    <HawkModalContent>
      <div v-if="props.isPublished" class="text-sm font-medium text-gray-700">
        {{ $t('This schedule has been published.') }}
        {{ $t('Please reload the page to see the updated schedule.') }}
      </div>
      <div v-else class="text-sm font-medium text-gray-700">
        {{ $t('This schedule has been unpublished.') }}
        <template v-if="active_schedule?.members?.[auth_store.logged_in_user_details.user_id] !== 'write'">
          {{ $t('You will no longer be able to see the schedule as you are not an admin.') }}
        </template>
        <template v-else>
          {{ $t('Please reload the page to see the updated schedule.') }}
        </template>
      </div>
    </HawkModalContent>
    <HawkModalFooter>
      <template #right>
        <div class="flex justify-end">
          <HawkButton
            color="primary"
            @click="onReload"
          >
            {{ $t('Reload') }}
          </HawkButton>
        </div>
      </template>
    </HawkModalFooter>
  </HawkModalContainer>
</template>
