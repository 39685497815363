<script>
export default {
  name: 'TextElement',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        inputContainer: '',
        input: '',
      },
    };
  },
};
</script>

<template>
  <component :is="elementLayout" ref="container">
    <template #element>
      <div :class="classes.inputContainer">
        <ElementAddon v-if="hasAddonBefore" type="before">
          <slot name="addon-before">
            <component :is="fieldSlots['addon-before']" :el$="el$" />
          </slot>
        </ElementAddon>
        <ElementAddon v-if="hasAddonAfter" type="after">
          <slot name="addon-after">
            <component :is="fieldSlots['addon-after']" :el$="el$" />
          </slot>
        </ElementAddon>
        <ElementLabelFloating v-if="hasFloating && !empty" :visible="!empty" />
        <ElementLoader v-if="isLoading" />
        <input
          :id="fieldId"
          v-bind="{
            ...attrs,
            ...aria,
          }"
          ref="input"
          :value="model"
          :type="inputType"
          :name="name"
          :class="classes.input"
          :placeholder="Placeholder"
          :autocomplete="autocomplete"
          :disabled="isDisabled"
          :readonly="readonly"
          @keydown="handleKeydown"
          @keyup="handleKeyup"
          @keypress="handleKeypress"
          @input="handleInput"
          @select="handleInput"
          @blur="handleBlur"
          @paste="e => el$.update(e.clipboardData?.getData('text')?.split('\n')?.join(', ').replace(/[\n\r]/g, '').replace(/\t|\s+/g, ' ').trim(), e.preventDefault())"
          v-on="{ wheel: inputType === 'number' ? (e) => { e.preventDefault() } : null }"
        >
      </div>
    </template>
    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </component>
</template>
