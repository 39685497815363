import {
  ANNOTATIONS_API_HOST,
  PLANS_API_HOST,
} from '~/common/utils/api-hosts.js';

export default {
  plans: {
    url: 'drawings',
    baseURL: PLANS_API_HOST,
  },
  annotations_service: {
    url: '',
    baseURL: ANNOTATIONS_API_HOST,
  },
};
