import IconHawkCriticalPriorityFlag from '~icons/hawk/critical-priority-flag.svg';
import IconHawkHighPriorityFlag from '~icons/hawk/high-priority-flag.svg';
import IconHawkLowPriorityFlag from '~icons/hawk/low-priority-flag.svg';
import IconHawkMediumPriorityFlag from '~icons/hawk/medium-priority-flag.svg';
import { countBy, filter, flatten, groupBy, kebabCase, keyBy, omit, orderBy, pick, sortBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { useAuthStore as authStore } from '~/auth/stores/auth.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTasksStore } from '~/tasks/store/tasks.store';

function isStringified(str) {
  try {
    return JSON.parse(str);
  }
  catch (err) {
    return str;
  }
}

function getFilenameFromUrl(src) {
  try {
    const url = new URL(src);
    let filename = url.searchParams.get('f');
    const key = url.searchParams.get('k');
    if (filename)
      filename = decodeURI(filename);
    else if (key)
      filename = decodeURI(key);
    const extension = filename?.split('.')?.pop?.();
    if (extension)
      return filename;
    else
      return `${filename}.jpg`;
  }
  catch (error) {
    logger.error(error);
    return 'Unnamed.jpg';
  }
}

export function useThermStore(id = 'therm') {
  return defineStore(id, {
    state: () => ({
      expand_left_sidebar: true,
      expand_right_sidebar: true,
      polygon: null,
      map: null,
      inactive_feature_types: [100],
      selected_features: [],
      raw_images: [],
      raw_images_map: null,
      last_selected_project: null,
      projects_meta_map: {},
      projects_details_map: {},
      projects_features_map: {},
      projects_elements_map: {},
      container: null,
      filters: {},
      ftg: null,
      search_key: '',
      show_request_mbtiles_popup: {
        resolved_all_requests: false,
        requested_reports: {},
      },
      map_layers: {
        cad: false,
        visual: false,
        thermal: true,
      },
      unique_projects_list: [],
      loader_enabled: false,
      defect_table_instance: null,
      status_configs: [],
      priority_configs: [
        {
          uid: 'critical',
          name: 'Critical',
          icon: IconHawkCriticalPriorityFlag,
          color: 'error',
        },
        {
          uid: 'high',
          name: 'High',
          icon: IconHawkHighPriorityFlag,
          color: 'warning',
        },
        {
          uid: 'medium',
          name: 'Medium',
          icon: IconHawkMediumPriorityFlag,
          color: 'success',
        },
        {
          uid: 'low',
          name: 'Low',
          icon: IconHawkLowPriorityFlag,
          color: 'gray',
        },
      ],
      show_defect_status_icons: true,
      gallery_view_state: {
        is_active: false,
        active_attachment: null,
        project_features_attachment_map: {},
        filtered_attachment_features: [],
      },
      report_defects: [],
    }),
    getters: {
      status_map: state => keyBy(state.status_configs, 'uid'),
      priority_map: state => keyBy(state.priority_configs, 'uid'),
      active_group(state) {
        if (this.container?.groups) {
          return (
            Object.values(state.container?.groups).find(item => item.is_active)
            || null
          );
        }

        return null;
      },
      active_projects(state) {
        if (this.active_group) {
          return (
            Object.values(this.active_group.projects).filter(
              item => item.is_active,
            ) || null
          );
        }
        return null;
      },
      active_projects_map() {
        return keyBy(this.active_projects, 'uid');
      },
      all_features: state => (options = {}) => {
        const features = [];
        if (state.active_group?.projects) {
          Object.values(state.active_group?.projects || {}).forEach((project) => {
            const filtered_project_features = state.get_project_features(project, options);
            features.push(...filtered_project_features);
          });
        }
        return features;
      },
      features() {
        return this.filtered_defects_therm_lite(this.all_features());
      },
      features_count: state => (remove_filter_inactive_feature_types = false) => {
        return countBy(
          state.filtered_defects_therm_lite(state.all_features({ remove_filter_inactive_feature_types })),
          f => f.properties.featureTypeId,
        );
      },
      inactive_feature_types_map(state) {
        return keyBy(state.inactive_feature_types, a => a);
      },
      groups_by_scan_dates(state) {
        return (
          orderBy(
            Object.values(state?.container?.groups),
            group => group?.properties?.date || group?.name,
            ['desc'],
          ) || {}
        );
      },
      feature_types(state) {
        const flattendArray = flatten(state.ftg?.map(item => item.featureTypes));
        return keyBy(flattendArray, 'id');
      },
      tasks_hash() {
        const tasks_store = useTasksStore('therm_tasks_store');
        const hash = {};
        tasks_store.tasks().forEach((task) => {
          if (this.features_hash[task?.properties?.issue_uid])
            hash[task?.properties?.issue_uid] = task;
        });
        return hash;
      },
      features_hash() {
        return keyBy(this.all_features(), item => item.properties.uid);
      },
      filtered_features_hash() {
        return keyBy(this.filtered_defects_therm_lite(this.all_features()), item => item.properties.uid);
      },
      use_tasks() {
        return this.container?.useTasks;
      },
      forms_list_hash() {
        const forms_store = useFormsStore('therm_forms_store');
        return groupBy(forms_store.forms, item => item?.target_element?.uid);
      },
      tasks_list_hash() {
        const tasks_store = useTasksStore('therm_tasks_store');
        return groupBy(tasks_store.tasks(), (item) => {
          const issue_uid = item?.properties?.issue_uid;
          const target_element_uid = item?.target_element?.uid;
          if (this.features_hash[issue_uid])
            return issue_uid;
          else if (this.features_hash[target_element_uid])
            return target_element_uid;
          else
            return null;
        });
      },
      formatted_project_features_attachment_map(state) {
        const { project_features_attachment_map } = state.gallery_view_state;
        const formatAttachments = (attachments, feature) => {
          return attachments.map(attachment => ({
            ...attachment,
            uid: attachment.uid || crypto.randomUUID(),
            project_uid: feature.project_uid,
            feature_uid: feature.feature_uid,
            created_by: attachment?.owner?.uid,
            file_name: attachment?.file_name || getFilenameFromUrl(attachment?.src),
            url: attachment?.url || attachment?.src,
            thumbnail_small: attachment?.thumbnail_small || attachment?.src,
            is_raw_image: !attachment?.uid,
          }));
        };
        return Object.entries(project_features_attachment_map).reduce((project_map, [project_uid, features_map]) => {
          const project_features = Object.entries(features_map).reduce((features_map, [feature_uid, feature]) => {
            const all_feature_attachments = formatAttachments([...(feature.user_attachments || []), ...(feature.raw_images || [])], feature);
            features_map[feature_uid] = {
              ...feature,
              all_feature_attachments,
              attachments_count: all_feature_attachments.length,
            };
            return features_map;
          }, {});
          project_map[project_uid] = project_features;
          return project_map;
        }, {});
      },
      active_attachments_list(state) {
        return (options = {}) => {
          const feature_attachments = Object.entries(state.formatted_project_features_attachment_map).reduce((acc, [project_uid, features_map]) => {
            if (this.active_group?.projects?.[project_uid]?.is_active) {
              const attachment_features = this.get_filtered_attachments(features_map, options);
              acc.push(...attachment_features);
            }
            return acc;
          }, []);
          return feature_attachments;
        };
      },
      restore_view_config(state) {
        if (state.container) {
          return {
            key: `therm_map_${state.container?.uid}_previous_view`,
            name: 'Therm',
          };
        }
        return null;
      },
    },
    actions: {
      reset_store() {
        this.container = null;
        this.selected_features = [];
        this.last_selected_project = null;
        this.map = null;
        this.inactive_feature_types = [100];
        this.ftg = null;
        this.map_layers = {
          cad: false,
          visual: false,
          thermal: true,
        };
        this.unique_projects_list = [];
        this.polygon = '';
        this.filters = {};
      },
      set_map_layer(value) {
        this.map_layers = value;
      },
      set_raw_images(value) {
        this.raw_images = value;
      },
      set_raw_images_map(value) {
        this.raw_images_map = value;
      },
      set_expand_left_sidebar(value) {
        this.expand_left_sidebar = value;
      },
      set_expand_right_sidebar(value) {
        this.expand_right_sidebar = value;
      },
      set_search_key(value) {
        this.search_key = value;
      },
      set_filters(filters) {
        this.filters = filters;
      },
      set_selected_features(value) {
        this.selected_features = value;
        this.selected_features = [...(this.selected_features || [])];
      },
      duedate_filter(task) {
        if (this.filters.due_date_start && this.filters.due_date_end) {
          const date = task?.due_date;
          if (!date)
            return false;
          let min = null;
          let max = null;
          if (this.filters.due_date_start)
            min = new Date(this.filters.due_date_start);

          if (this.filters.due_date_end)
            max = new Date(this.filters.due_date_end);
          const due_date = new Date(date);
          const check_min = min ? due_date >= min : true;
          const check_max = max ? due_date <= max : true;
          return check_min && check_max;
        }
        return true;
      },
      assignees_filter(task) {
        const assignee_list = [
          ...(this.filters.assignees || []),
          ...(this.filters.assignees_teams || []),
        ];
        if (assignee_list.length === 0)
          return true;
        return assignee_list.some(assignee =>
          task?.assignees.includes(assignee),
        );
      },
      check_task_filters(task) {
        const owner_filter = (task) => {
          if (this.filters.owner?.length)
            return task && this.filters.owner.includes(task?.owner.uid);
          return true;
        };
        const array_filter = (task, key) => {
          if (this.filters[key]?.length)
            return task && this.filters[key].includes(task[key]);
          return true;
        };
        const boolean_archive = (task, key) => {
          if (this.filters[key])
            return task && this.filters[key] === task[key];
          return true;
        };

        const array_filters = ['priority', 'category', 'status'];
        const boolean_filters = ['bookmark', 'archive'];
        return (
          array_filters.reduce((acc, curr) => acc && array_filter(task, curr), true)
          && boolean_filters.reduce((acc, curr) => acc && boolean_archive(task, curr), true)
          && owner_filter(task)
          && this.assignees_filter(task)
          && this.duedate_filter(task)
        );
      },
      check_feature_filters(feature) {
        const check_temperature_diff = (properties) => {
          if ((this.filters?.temperature_difference[0] || 0) === 0 && (this.filters?.temperature_difference[1] || 100) === 100)
            return true;
          const value = properties.temperature_difference;
          return (
            value >= (this.filters?.temperature_difference[0] || 0)
            && value <= (this.filters?.temperature_difference[1] || 100)
          );
        };
        const check_feature_types = (properties) => {
          if (this.filters?.include_feature_types)
            return (this.filters?.include_feature_types?.includes('all') || this.filters?.include_feature_types?.includes(properties.featureTypeId));
          return true;
        };
        return check_temperature_diff(feature.properties) && check_feature_types(feature.properties);
      },
      filtered_defects(features, type = 'defects') {
        if (Object.keys(this.filters).length === 0 || (Object.keys(this.filters).every(filter => ['temperature_difference', 'temp_diff_elements'].includes(filter) && this.filters.temperature_difference[0] === 0 && this.filters.temperature_difference[1] === 100)))
          return features;

        const features_hash = keyBy(features, item => item.properties.uid);
        const tasks_hash = {};
        const tasks_store = useTasksStore('therm_tasks_store');

        tasks_store.tasks().forEach((task) => {
          if (features_hash[task?.properties?.issue_uid])
            tasks_hash[task?.properties?.issue_uid] = task;
        });

        if (type === 'tasks')
          return filter(features || [], task => this.check_task_filters(task));

        return filter(features || [], (feature) => {
          const task = tasks_hash[feature.properties.uid];
          return (((!task && this.filters.ignore_task_filters) || this.check_task_filters(task)) && this.check_feature_filters(feature));
        });
      },
      filtered_defects_therm_lite(features, type = 'defects') {
        if (Object.keys(this.filters).length === 0 || (Object.keys(this.filters).every(filter => ['temperature_difference', 'temp_diff_elements'].includes(filter) && this.filters.temperature_difference[0] === 0 && this.filters.temperature_difference[1] === 100)))
          return features;

        const features_hash = keyBy(features, item => item.properties.uid);
        const tasks_hash = {};
        const tasks_store = useTasksStore('therm_tasks_store');

        tasks_store.tasks().forEach((task) => {
          if (features_hash[task?.properties?.issue_uid])
            tasks_hash[task?.properties?.issue_uid] = task;
        });

        if (type === 'tasks')
          return filter(features || [], task => !this.filters?.filter?.not_associated_with_defects || this.check_task_filters(task));

        return filter(features || [], (feature) => {
          let tasks = [];
          const task = tasks_hash?.[feature.properties.uid];
          if (!this.use_tasks)
            tasks = this.tasks_list_hash?.[feature.properties.uid] || [];
          else if (task)
            tasks = [task];
          const task_condition_passed = !this.filters?.filter?.associated_with_defects || tasks.some(task => (task && this.check_task_filters(task)));
          let feature_condition_passed = this.check_feature_filters(feature);

          if (this.filters?.defect_status?.length)
            feature_condition_passed = feature_condition_passed && this.filters.defect_status.includes(feature.properties.status);
          if (this.filters?.defect_priority?.length)
            feature_condition_passed = feature_condition_passed && this.filters.defect_priority.includes(feature.properties.priority);

          return (task_condition_passed && feature_condition_passed);
        });
      },
      get_project_features(project, options) {
        const features = [];
        if (project.is_active && this.projects_features_map[project.uid]) {
          Object.keys(this.projects_features_map[project.uid] || {}).forEach((key) => {
            if (options?.remove_filter_inactive_feature_types || !this.inactive_feature_types_map[key]) {
              const filtered = this.projects_features_map[project.uid]?.[key];
              features.push(...filtered);
            }
          });
        }
        return features;
      },
      async get_container_data(container_uid) {
        const response = await this.$services.therm.getAll({
          attribute: `views/${container_uid}`,
          query: {
            hierarchy: true,
            projects: true,
            complete: true,
          },
          toast: !this.$router.currentRoute.value?.query?.metadata,
        });
        let container = response.data;
        if (container?.groups?.length) {
        // filter group and project based on # of projects and published/disabled projects.
          container.groups = container.groups.reduce((groups, group) => {
            group.projects = (group.projects || []).reduce(
              (projects, project) => {
                if (project.published !== false)
                  projects.push(project);
                return projects;
              },
              [],
            );
            if (group.projects.length)
              groups.push(group);
            return groups;
          }, []);
        }

        if (container?.groups?.length)
        // get unique project names and set in state
          container = this.parse_container_data({ container });
        return container;
      },
      async set_container(options = {}) {
        let container;
        if (options.container) {
          this.container = options.container;
          container = options.container;
        }
        else if (!this.container) {
          container = await this.get_container_data(options.uid);
        }
        this.container = container;
        const unique_projects = [];
        Object.values(container.groups).forEach((group) => {
          Object.values(group.projects).forEach((project) => {
            if (
              !unique_projects.includes(project.name)
              && project.published !== false
            ) {
              unique_projects.push(project.name);
            }
          });
        });
        this.unique_projects_list = sortBy(unique_projects);
        if (options.initial_load) {
          const group_from_query = this.$router?.currentRoute.value.query?.group;
          let first_group;
          if (group_from_query) {
            first_group = container?.groups[group_from_query];
          }
          else {
            first_group = orderBy(
              Object.values(container?.groups),
              group => group.properties.date || group.name,
              ['desc'],
            )?.[0];
          }
          const first_project = orderBy(
            Object.values(first_group?.projects || {}),
            project => project.name,
            ['asc'],
          )[0];
          this.set_active_group({ group: first_group });
          this.set_therm_active_project({ project: first_project, is_active: true });
        }

        return container;
      },
      set_active_group(options = {}) {
        const container = this.container;
        if (container.groups[this.active_group?.uid])
          container.groups[this.active_group.uid].is_active = false;
        container.groups[options.group.uid].is_active = true;
        this.container = container;
      },
      set_inactive_features(value) {
        this.inactive_feature_types = value;
        this.update_map_features();
      },
      collect_project_bounds(turf) {
        const map_bounds = [];

        Object.values(this.container.groups).forEach((group) => {
          Object.values(group.projects).forEach((project) => {
            if (
              this.active_projects_map[project.uid]?.ortho_enabled
              && ((this.projects_meta_map[project.uid]?.bounds)
                || (project?.properties?.bounds))
            ) {
              map_bounds.push(
                turf.bboxPolygon(
                  project.properties?.bounds
                    ? isStringified(project.properties?.bounds)
                    : this.projects_meta_map[project.uid]?.bounds,
                ),
              );
            }
          });
        });

        return map_bounds;
      },
      async set_polygon() {
        try {
          const turf = (await import('@turf/turf'));
          const fc = {
            features: this.features,
            type: 'FeatureCollection',
          };

          let featuresBox;
          if (fc.features?.length) {
            if (
              fc.features?.length === 1
              && fc.features[0]?.geometry?.type === 'Point'
            ) {
              featuresBox = turf.buffer(fc, 1, { units: 'kilometers' })
                ?.features[0];
              logger.log(featuresBox);
            }
            else {
              featuresBox = turf.bboxPolygon(turf.bbox(fc));
            }
          }

          const map_bounds = this.collect_project_bounds(turf);

          let queryBoundary;
          if (featuresBox || map_bounds.length) {
            const featureCollection = {
              type: 'FeatureCollection',
              features: [
                ...(featuresBox ? [featuresBox] : []),
                ...map_bounds,
              ],
            };

            queryBoundary = turf.bboxPolygon(turf.bbox(featureCollection));
          }
          let polygon = null;
          if (queryBoundary)
            polygon = btoa(JSON.stringify(queryBoundary.geometry));

          this.polygon = polygon;
        }
        catch (err) {
          logger.log(err);
        }
      },
      async set_therm_active_project(options) {
        try {
          const container = { ...this.container };
          let project = container.groups[options.project.group]?.projects[options.project.uid];
          if (project) {
            project.is_active = options.is_active;
            // Shift all these to a single function
            project = await this.set_project_meta({ project });
            if (this.map)
              await this.set_project_ortho({ project });
            project = await this.set_project_features({ project });

            project = await this.set_project_elements({ project });

            project = await this.set_project_details({ project });
            if (this.map && !options.in_toggling_all_projects_context)
              await this.fly_to_project({ project });
            container.groups[options.project.group].projects[
              options.project.uid
            ] = project;

            this.container = container;
            if (!options.in_toggling_all_projects_context) {
              await this.fetch_therm_tasks();
              this.update_map_features();
              if (options.is_active)
                this.last_selected_project = project;
            }
            await this.set_polygon();
            return project;
          }
        }
        catch (err) {
          logger.log(err);
        }
      },
      async set_project_meta(options) {
        try {
          let meta = this.projects_meta_map[options.project.uid];
          // Check if tile_url present and meta is not fetched
          if (meta || !options.project?.reports?.orthotiles?.tile_url) {
            if (options?.project?.is_active) {
              this.show_request_mbtiles_popup = {
                ...this.show_request_mbtiles_popup,
              };
            }
            return options.project;
          }

          const response = await this.$services.therm.getAll({
            url: options.project.reports.orthotiles.tile_url,
          });

          if (response.data.filesize === 0) {
            this.show_request_mbtiles_popup = {
              resolved_all_requests: false,
              requested_reports: {
                ...(this.show_request_mbtiles_popup?.requested_reports || {}),
                [options.project.uid]: response.data.id,
              },
            };
          }
          meta = response.data;
          if (meta?.center)
            meta.location = meta.center.slice(0, 2);

          this.projects_meta_map[options.project.uid] = meta;
          this.show_request_mbtiles_popup.resolved_all_requests = true;
          return options.project;
        }
        catch (err) {
          logger.log(err);
        }
      },
      async set_project_ortho(options) {
        try {
          if (options?.project?.reports?.orthotiles?.tile_url) {
            this.set_sources_and_layers({
              id: options.project.uid,
              layer_visible:
              options.project.is_active && this.map_layers.thermal,
              tile_url: options?.project?.reports?.orthotiles?.tile_url,
            });
          }

          if (options?.project?.reports?.orthoTiles_visual?.tile_url) {
            this.set_sources_and_layers({
              id: `${options.project.uid}-visual`,
              layer_visible: options?.project?.is_active && this.map_layers.visual,
              tile_url: options?.project?.reports?.orthoTiles_visual?.tile_url,
            });
          }

          if (options?.project?.reports?.orthoTiles_cad?.tile_url) {
            this.set_sources_and_layers({
              id: `${options.project.uid}-cad`,
              layer_visible: options.project.is_active && this.map_layers.cad,
              tile_url: options?.project?.reports?.orthoTiles_cad?.tile_url,
            });
          }
        }
        catch (err) {
          logger.log(err);
        }
      },
      async set_project_features(options) {
        try {
          let features = this.projects_features_map[options.project.uid];
          if ((options.project?.vectors && !features) || options.forceUpdate) {
            const response = await this.$services.common.getAll({
              url: options.project.vectors,
              query: { tables: false, parse_raw_images: true, ...(options.query || {}) },
            });
            features = response.data.features;

            features = features.map((f) => {
              f.properties.featureTypeId
              = f.properties.class_id || f.properties.featureTypeId;

              return f;
            });

            this.projects_features_map[options.project.uid] = groupBy(
              features,
              f => f.properties.featureTypeId,
            );

            this.set_projects_attachments(options.project.uid, features);
          }

          return options.project;
        }
        catch (err) {
          logger.log(err);
          return options.project;
        }
      },
      async set_project_elements(options) {
        if (!options.project?.is_active)
          return options.project;

        if (!this.projects_elements_map[options.project.uid]?.elements) {
          const response = await this.$services.therm.get_project_elements({
            project_id: options.project.uid,
          });
          const elements = keyBy(response.data, key => key);
          if (this.projects_details_map[options.project.uid]?.element?.uid) {
            elements[
              this.projects_details_map[options.project.uid]?.element?.uid
            ] = this.projects_details_map[options.project.uid]?.element?.uid;
          }
          this.projects_elements_map[options.project.uid] = elements;
        }

        return options.project;
      },
      async set_project_details(options) {
        try {
          if (!this.projects_details_map[options.project.uid]?.project_details) {
            const response = await this.$services.terra.get_terra_project_details(
              {
                group_id: options.project.group,
                project_id: options.project.uid,
              },
            );
            this.projects_details_map[options.project.uid] = response.data;
          }
          return options.project;
        }
        catch (err) {
          logger.log(err);
        }
      },
      async fly_to_project(options) {
        const project
        = this.container?.groups[options.project.group]?.projects[
          options.project.uid
        ];

        if (!this.projects_meta_map[options.project.uid]?.location && !project.properties?.location) {
          await this.fly_to_first_feature({ project });
        }

        else {
          this.fly_to({
            location: project.properties?.location
              ? isStringified(project.properties?.location)
              : this.projects_meta_map[options.project.uid]?.location,
          });
        }
      },
      async fly_to_first_feature(options) {
        if (options.is_fly !== false && options.project.is_active) {
          const features = Object.values(this.projects_features_map[options.project.uid] || {});
          if (features?.length) {
            const turf = (await import('@turf/turf'));
            const centroid = turf.centroid(features[0][0]);
            this.fly_to({ location: centroid.geometry.coordinates });
          }
        }
      },
      fly_to(options) {
        if (options.location && this.map) {
          this.map.flyTo({
            center: options.location,
            zoom: options.zoom || 15,
            speed: options.speed || 4,
          });
        }
      },
      update_map_features(options) {
        try {
          const features = options?.features || this.features;
          if (this.map?.getSource('all_features_source')) {
            this.map.getSource('all_features_source').setData({
              type: 'FeatureCollection',
              features,
            });
          }
        }
        catch (error) {
          logger.error(error);
        }
      },
      async fetch_therm_tasks() {
        try {
          const tasks_store = useTasksStore('therm_tasks_store');
          if (this.active_projects?.length) {
            const encoded = btoa(
              `projectUid=${this.active_projects
                .map(item => item.uid)
                .join(',')}`,
            );
            const response = await this.$services.tasks.post({
              attribute: 'filters',
              body: {
                filters: {
                  organization: authStore().current_organization?.uid,
                  properties: encoded,
                  pageSize: Number.MAX_SAFE_INTEGER,
                },
              },
            });
            const tasks = response.data.tasks;
            const results = keyBy(tasks, 'uid');

            tasks_store.tasks_map = results;
          }
          else {
            tasks_store.set_tasks([]);
          }
        }
        catch (err) {
          logger.log(err);
        }
      },
      update_features_in_container(options = {}) {
        const container = this.container;
        if (options.features) {
          const total = options.features.length;
          for (let i = 0; i < total; i++) {
            const feature = options.features[i];

            const project_id
              = feature.properties.project || feature.properties.projectUid;

            const features = this.projects_features_map[project_id]?.[feature.properties.featureTypeId] || [];

            const featureIndex = features.findIndex(
              item => item.properties.uid === feature.properties.uid,
            );

            if (featureIndex !== -1) {
              if (options.properties_to_update) {
                feature.properties = {
                  ...features[featureIndex]?.properties,
                  ...pick(feature.properties, options.properties_to_update),
                };
              }

              features[featureIndex] = feature;
            }
            else { features.push(feature); }

            this.projects_features_map[project_id][feature.properties.featureTypeId] = features;
          }

          this.container = container;
        }
      },
      async get_raw_images(url) {
        const result = await this.$services.therm.getAll({ url });
        return result;
      },
      async create_therm_tasks(payload) {
        // Call bulk elements for selected features
        // Update elements in features
        // Update features description and group from feature types by referencing class id
        // Now use create task api and send the above payload
        // It’ll return response.data.issues
        // Update these issues back to therm using above update_therm api
        // Refetch tickets and refresh on the map
        try {
          let features = [];
          // 1st Step
          const elements = await this.$services.therm.post({
            url: 'element/bulkElementCreate',
            body: {
              uids: payload.issues.map(item => item.properties.uid),
            },
          });
          // 2nd and 3rd Step
          features = payload.issues.map((feature, index) => {
            if (elements.data[feature.properties.uid]) {
              payload.issues[index].properties.element
                = elements.data[feature.properties.uid];

              feature.properties.element = elements.data[feature.properties.uid];
            }
            const feature_type
              = this.feature_types[
                feature.properties.featureTypeId || feature.properties.class_id
              ];
            const group_details = this.container.groups[this.active_group?.uid];
            const project_details = this.projects_details_map[feature.properties.projectUid];

            if (feature_type) {
              feature.properties.issue_description
                = feature_type.name ?? feature_type.class_name;
              feature.properties.issue_group = feature_type.group;
            }
            feature.properties.project_details = {
              groupName: project_details.group.name,
              projectName: project_details.name,
              ...(group_details?.properties?.date && {
                groupProperties: {
                  date: group_details.properties.date,
                },
              }),
            };
            payload.issues[index] = omit(payload.issues[index], [
              'include',
              'action',
              'templateUid',
            ]);
            return feature;
          });

          this.update_features_in_container({
            features: payload.issues,
          });
          // 4th step
          const response = await this.$services.tasks.post({
            attribute: 'therm',
            body: {
              ...payload,
              issues: features,
            },
          });
          // 5th Step
          await this.$services.therm.patch({
            url: 'elements/features',
            body: {
              geojson: {
                type: 'FeatureCollection',
                features: response.data.issues,
              },
            },
          });
          await this.fetch_therm_tasks();
          // 6th Step
          if (this.$router.currentRoute.value.name !== 'therm-defects')
            this.update_map_features();
          const task_uid = response.data?.data[0]?.uid;
          if (task_uid) {
            const tasks_store = useTasksStore('therm_tasks_store');
            const extra_properties = { method: 'Defect', count: response.data?.data?.length, type: 'Task' };
            tasks_store.task_track_events('Created', extra_properties, task_uid);
          }
          if (response.data?.data?.length === 1 && task_uid) {
            this.$router.push({
              query: {
                task: btoa(JSON.stringify({
                  id: task_uid,
                  store_key: 'therm_tasks_store',
                })),
              },
            });
          }
          return response;
        }
        catch (err) {
          logger.log(err);
        }
      },
      set_sources_and_layers(options) {
        if (this.map?.getLayer('polygon_feature_layer')) {
          if (!this.map.getSource(options.id)) {
            this.map.addSource(options.id, {
              type: 'raster',
              tiles: [`${options.tile_url}/{z}/{x}/{y}.png`],
              tileSize: 256,
            });
          }

          // If project layer is not added

          if (!this.map.getLayer(options.id)) {
            this.map.addLayer(
              {
                id: options.id,
                type: 'raster',
                source: options.id,
              },
              'polygon_feature_layer',
            );
          }

          if (this.map.getLayer(options.id)) {
            this.map.setLayoutProperty(
              options.id,
              'visibility',
              options.layer_visible ? 'visible' : 'none',
            );
          }
        }
      },
      parse_container_data(options = {}) {
        try {
          let project_name_hash = {};
          options.container.groups = keyBy(
            options.container.groups.map((group) => {
              project_name_hash = {};
              group.projects.forEach((item) => {
                if (!project_name_hash[item.name])
                  project_name_hash[item.name] = item;
              });
              return {
                ...group,
                is_active: false,
                projects: keyBy(
                  group.projects.map(project => ({
                    ...project,
                    vectors: project.allFeatures || project.vectors,
                    is_active: false,
                  })),
                  'uid',
                ),
                project_name_hash,
              };
            }),
            'uid',
          );
          return options.container;
        }
        catch (err) {
          logger.log(err);
        }
      },
      async set_ftg_and_update_features_styles(options = { update_features_styles: true }) {
        if (!this.ftg) {
          if (options.ftg) {
            this.ftg = options.ftg;
          }
          else {
            const response = await this.$services.therm.getAll({
              attribute: `viewer/view/${options.uid}/config`,
              query: {
                hierarchy: true,
              },
            });
            this.ftg = response.data;
          }
        }

        if (options.update_features_styles)
          this.update_features_styles(options);
      },
      update_features_styles(options = {}) {
        const property = 'featureTypeId';
        try {
          let stops = this.ftg.reduce((arr, item) => {
            arr.push(
              ...item.featureTypes.map(f => [f.id, f.properties.color || '#222']),
            );
            return arr;
          }, []);
          stops = stops.sort((a, b) => a[0] - b[0]);

          if (!stops.length)
            return;

          const stops_config = options.styles ?? {
            property,
            stops,
            default: 'black',
          };

          if (this.map.getLayer('linestring_feature_layer')) {
            this.map.setPaintProperty(
              'linestring_feature_layer',
              'line-color',
              stops_config,
            );
          }

          if (this.map.getLayer('polygon_feature_layer')) {
            this.map.setPaintProperty(
              'polygon_feature_layer',
              'fill-color',
              stops_config,
            );
          }

          if (this.map.getLayer('point_feature_layer')) {
            this.map.setPaintProperty(
              'point_feature_layer',
              'circle-color',
              stops_config,
            );
          }
        }
        catch (err) {
          logger.log(err);
        }
      },
      async get_status_configs(container_uid) {
        try {
          const response = await this.$services.therm.get({
            attribute: `views/view/${container_uid}/issues/status-configs`,
          });
          this.status_configs = response.data.map(status => ({
            ...status,
            icon_name: kebabCase(status.name),
          }));
          logger.log('Status config', this.status_configs);
        }
        catch (error) {
          logger.log(error);
        }
      },
      async updateIssue(payload, options = {}) {
        try {
          const issue_data = this.features_hash[payload.uid];
          const project_uid = issue_data.properties.projectUid;
          const feature_type_id = issue_data.properties.class_id || issue_data.properties.featureTypeId;
          const defect_type_changed = payload.class_id && (feature_type_id !== payload.class_id);

          let data = {};
          if (options?.skip_API) {
            data = payload;
          }
          else {
            const response = await this.$services.therm.patch({
              attribute: `features/view/${this.container.uid}/project/${project_uid}`,
              body: [payload],
            });
            data = response.data?.features?.[0]?.properties;
          }
          if (!data)
            return;

          if (defect_type_changed) {
            const features = this.projects_features_map[project_uid]?.[payload.class_id] || [];
            features.push({
              ...issue_data,
              properties: {
                ...issue_data.properties,
                ...payload,
                ...data,
                featureTypeId: payload.class_id,
              },
            });
            this.projects_features_map[project_uid] = {
              ...this.projects_features_map[project_uid],
              [payload.class_id]: features,
            };
          }
          if (this.projects_features_map[project_uid]?.[feature_type_id]) {
            this.projects_features_map[project_uid][feature_type_id] = this.projects_features_map[project_uid]?.[feature_type_id].reduce((acc, issue) => {
              if (issue.properties.uid === data.uid && defect_type_changed)
                return acc;
              if (issue.properties.uid === data.uid)
                issue.properties = { ...issue.properties, ...data };
              acc.push(issue);
              return acc;
            }, []);
          }

          // update attachment map
          const feature_uid = data.uid;
          const { project_features_attachment_map } = this.gallery_view_state;
          const project_attachment_details = project_features_attachment_map?.[project_uid];
          const updated_feature = {
            feature_uid,
            project_uid,
            user_attachments: data.user_attachments,
          };
          project_features_attachment_map[project_uid] = {
            ...(project_attachment_details || {}),
            [feature_uid]: {
              ...(project_attachment_details?.[feature_uid] || {}),
              ...updated_feature,
            },
          };
          this.set_gallery_state('project_features_attachment_map', project_features_attachment_map);
          return { status: 'success', data: payload, error: null };
        }
        catch (error) {
          logger.log(error);
          return { status: 'failed', data: null, error };
        }
      },
      async updateUseTasks(payload) {
        try {
          const { data } = await this.$services.therm.post({
            attribute: `qc/view/${this.container.uid}/config-use-tasks`,
            body: payload,
          });
          this.container.useTasks = data.useTasks;
        }
        catch (error) {
          logger.log(error);
        }
      },
      async bulkUpdateFeatures(defects, form_payload) {
        const projects_defects_map = defects.reduce((acc, defect) => {
          const project_uid = defect.properties.project || defect.properties.projectUid;
          if (!acc[project_uid])
            acc[project_uid] = [];
          acc[project_uid].push({
            uid: defect.properties.uid,
            ...form_payload,
          });
          return acc;
        }, {});
        const promises = [];
        Object.keys(projects_defects_map).forEach((project_uid) => {
          promises.push(
            this.$services.therm.patch({
              attribute: `features/view/${this.container.uid}/project/${project_uid}`,
              body: projects_defects_map[project_uid],
            }),
          );
        });
        const responses = await Promise.all(promises);
        const features = [];
        responses.forEach((response) => {
          features.push(...response.data.features.map((f) => {
            f.properties = {
              ...(this.features_hash[f.properties.uid]?.properties || {}),
              ...f.properties,
            };
            return f;
          }));
        });
        this.update_features_in_container({ features });
      },

      async set_projects_attachments(project_uid, features) {
        try {
          let { project_features_attachment_map } = this.gallery_view_state;

          project_features_attachment_map = {
            ...project_features_attachment_map,
            [project_uid]: features.reduce((acc, f) => {
              const feature_uid = f.properties.uid;
              const all_attachments = [...(f?.properties?.user_attachments || []), ...(f?.properties?.raw_images || [])];
              if (all_attachments.length) {
                acc[feature_uid] = {
                  feature_uid,
                  project_uid,
                  ...pick(f.properties, ['user_attachments', 'raw_images']),
                };
              }
              return acc;
            }, {}),
          };
          this.gallery_view_state.project_features_attachment_map = project_features_attachment_map;
        }
        catch (error) {
          logger.error(error);
        }
      },
      set_gallery_state(key, value) {
        this.gallery_view_state[key] = value;
      },
      get_filtered_attachments(features_map) {
        return Object.values(features_map).filter(({ feature_uid }) => !!this.filtered_features_hash[feature_uid]);
      },
    },
  })();
}
