<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useElementSize } from '@vueuse/core';

import { debounce } from 'lodash-es';
import { onBeforeMount } from 'vue';

import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  chat_store: {
    type: Object,
    required: true,
  },
  asset_id: {
    type: String,
  },
  item_uid: {
    type: String,
  },
  header_height: {
    type: Number,
    default: 0,
  },
  module: {
    type: String,
    default: 'dms',
  },
  custom_message_properties: {
    type: Object,
  },
  filter_comments: {
    type: Function,
    default: comments => comments,
  },
  disabled_controls: {
    type: Array,
    default: () => [],
  },
  last_viewed_at: {
    type: Date,
  },
  can_comment: {
    type: Boolean,
    default: true,
  },
  validate_comment: {
    type: Function,
  },
});

const emit = defineEmits(['mouseenter', 'mouseleave']);

const chat_store = props.chat_store;
const auth_store = useAuthStore();

const user = auth_store?.logged_in_user_details?.user_id;

const comment_input = ref(null);

const users_typing = ref([]);

const { height: comment_input_height } = useElementSize(comment_input);

const activity_block_height = computed(() => {
  const location_offset = 100;
  return `${window.innerHeight - (comment_input_height.value + props.header_height + location_offset)}`;
});

const new_message_listener = ref(null);
const start_typing_listener = ref(null);
const stop_typing_listener = ref(null);

onMounted(async () => {
  new_message_listener.value = chat_store.chat_channel.on('message.new', scrollToBottom);
  start_typing_listener.value = chat_store.chat_channel.on('typing.start', setTypingUser);
  stop_typing_listener.value = chat_store.chat_channel.on('typing.stop', setTypingUser);
});

onBeforeMount(() => {
  new_message_listener.value?.unsubscribe();
  start_typing_listener.value?.unsubscribe();
  stop_typing_listener.value?.unsubscribe();
});

// USER TYPING
const sendTypingEvent = debounce(async () => {
  await chat_store.chat_channel.keystroke();
  await new Promise(_resolve => setTimeout(2000));
  await chat_store.chat_channel.stopTyping();
}, 200);

function setTypingUser(e) {
  if (e.user.id !== user) {
    if (e.type === 'typing.start' && !users_typing.value.includes(e.user.id))
      users_typing.value.push(e.user.id);
    else if (e.type === 'typing.stop')
      users_typing.value = users_typing.value.filter(uid => uid !== e.user.id);
  }
}

// SCROLL AND HEIGHT HANDLERS
function scrollToBottom(timeout = 20) {
  setTimeout(() => {
    const element = document.getElementById(`chat-block`);
    if (element)
      element.scrollTo({ top: element.scrollHeight + element.getBoundingClientRect().y, behavior: 'smooth' });
  }, timeout);
}

function onInput() {
  sendTypingEvent();
}
</script>

<template>
  <div
    v-if="filter_comments(chat_store.comments).length" id="comment-block"
    class="!overflow-y-scroll overflow-x-hidden scrollbar"
    :style="{ height: `${activity_block_height}px` }"
  >
    <div
      v-for="comment in filter_comments(chat_store.comments)"
      :key="comment.id"
      class="cursor-pointer"
      @mouseenter="emit('mouseenter', comment)"
      @mouseleave="emit('mouseleave', null)"
    >
      <document-comment
        :last_viewed_at="last_viewed_at"
        :chat_store="chat_store"
        :comment="comment"
        :item_uid="item_uid"
        :disabled_controls="disabled_controls"
        :asset_id="props.asset_id"
        :validate_comment="props.validate_comment"
        :module="props.module"
      />
    </div>
  </div>
  <div
    v-else
    class="w-full flex items-center justify-center"
    :style="{ height: `${activity_block_height}px` }"
  >
    <HawkIllustrations variant="mini" type="no-data" for="comments" />
  </div>
  <div v-if="can_comment" class="absolute z-998 bottom-1 bg-white left-6 right-6">
    <ChatCommentInput
      id="chat-comment-settings"
      ref="comment_input"
      :chat_store="chat_store"
      :user="user"
      :asset_id="props.asset_id"
      :comment_input_config="{
        autofocus: true,
        disabled_controls,
        close_on_disable: true,
      }"
      :custom_message_properties="custom_message_properties"
      :module="props.module"
      :validate_comment="props.validate_comment"
      :attachment_config="{ meta: { service: props.module, id: props.item_uid } }"
      @input="onInput"
    />
    <div class="text-gray-600 text-sm italic flex items-center invisible mt-0.5" :class="users_typing.length ? '!visible' : ''">
      <p v-for="uid in users_typing" :key="uid">
        <HawkMembers :members="uid" :has_avatar="false" />
      </p>
      <p class="ml-1">
        {{ $t('typing') }} ...
      </p>
    </div>
  </div>
</template>
