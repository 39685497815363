import { merge } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';

export const useSheetStore = defineStore('sheet', {
  state: () => ({
    sheet: null,
    selected_version_obj: null,
    sheet_privacy_status: null,
    is_compare_view: false,
  }),
  getters: {},
  actions: {
    async fetch_sheet() {
      try {
        const { data } = await this.$services.plans.get({
          id: this.$router.currentRoute.value.params.sheet_id,
          url: 'drawings/sheets',
        });
        this.sheet = data[0];
        this.selected_version_obj = data[0].current_version;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    update_active_sheet(uid, sheets_map) {
      this.sheet = sheets_map[uid];
    },
    update_selected_sheet_version(sheet_version_obj) {
      this.selected_version_obj = sheet_version_obj;
    },
    async update_sheet_privacy(privacy_status) {
      const body = {
        sheet: {
          uid: this.$router.currentRoute.value.params.sheet_id,
          private: privacy_status,
        },
      };

      try {
        const { data } = await this.$services.plans.patch({
          id: this.$router.currentRoute.value.params.drawing_id,
          attribute: 'sheets',
          body,
        });
        this.sheet.private = data.sheet.private;
        if (privacy_status)
          this.$track_event('private_sheet', { count: 1 });
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    set_compare_view(value) {
      this.is_compare_view = value;
    },
    async patch_calibration_to_sheet(calibration) {
      try {
        const body = {
          sheet: {
            uid: this.$router.currentRoute.value.params.sheet_id,
            properties: {
              ...this.sheet.properties,
              ...calibration,
            },
          },
        };

        const { data } = await this.$services.plans.patch({
          id: this.$router.currentRoute.value.params.drawing_id,
          attribute: 'sheets',
          body,
        });

        this.sheet.properties = data.sheet.properties;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async fetch_selected_sheet_annotations(element_uids) {
      try {
        const annotation_data = await Promise.all(element_uids.map(async element_uid => await this.$services.annotations_service.getAll({
          query: {
            element: element_uid,
          },
        }).then(({ data }) => ({ [element_uid]: data.annotations }))));

        return merge({}, ...annotation_data);
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSheetStore, import.meta.hot));
