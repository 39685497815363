<script setup>
// --------------------------------- Imports -------------------------------- //
import { storeToRefs } from 'pinia';
import useEmitter from '~/common/composables/useEmitter';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// --------------------------------- Injects -------------------------------- //
const $t = inject('$t');
const project_management_store = useProjectManagementStore();
const emitter = useEmitter();

// ----------------------- Variables - Pinia - consts ----------------------- //
const { set_all_tasks_open_state } = project_management_store;

// --------------------- Variables - Pinia - storeToRefs -------------------- //
const { $g, flags, active_task, active_tab, active_view, active_schedule, comment_priority_status, hidden_focus_input, is_fullscreen, is_schedule_editable, is_schedule_dynamic, is_mini_loading, tippy_target } = storeToRefs(project_management_store);

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  is_expanded: true,
  is_menu_visible: false,
  is_comments_slide_over_visible: false,
  is_attachments_slide_over_visible: false,
});

// --------------------------- Computed properties -------------------------- //
const is_group_by_length_zero = computed(() => active_view.value.data.group_by.length !== 0);

const tabs_items = computed(() => [
  { uid: 'gantt-chart', label: $t('Schedule') },
  ...(is_fullscreen.value
    ? []
    : [
        {
          uid: 'resources',
          label: $t('Resources'),
          disabled: !active_schedule.value.track_resources || active_task.value,
          disabled_tooltip: !active_schedule.value.track_resources ? $t('Enable resources tracking to view this') : '',
        },
        {
          uid: 'settings',
          label: $t('Settings'),
          disabled: active_task.value,
        },
      ]
  ),
]);

const are_filters_present = computed(() => {
  if (!active_view.value)
    return false;
  const filters = active_view.value.data.filters;

  for (const key of Object.keys(filters)) {
    if (
      ((['critical', 'not_critical', 'activity', 'milestone'].includes(key)) && filters[key])
      || (key === 'progress' && JSON.stringify(filters.progress) !== JSON.stringify([0, 100]))
      || (key === 'date_range' && isDateRangeChanged(filters))
      || (key === 'progress_updates' && filters.progress_updates?.length)
      || (!['progress', 'priorities', 'date_range'].includes(key) && filters[key]?.length)
    ) {
      return true;
    }
  }
  return false;
});

// -------------------------------- Functions ------------------------------- //
function isDateRangeChanged(filters) {
  const date_range = $g.value?.getState();
  return (
    JSON.stringify(filters.date_range) !== JSON.stringify([null, null])
    && JSON.stringify(filters.date_range) !== JSON.stringify([date_range?.min_date, date_range?.max_date])
  );
}

function toggleExpandCollapse(bool) {
  set_all_tasks_open_state(bool);
  state.is_expanded = bool;
}

function toggleCollapsed() {
  toggleExpandCollapse(!state.is_expanded);
}

function onTodayClicked() {
  $g.value.showDate(new Date());
}

function onTabClick(event) {
  if (active_tab.value === 'gantt-chart')
    emitter.emit('hide_resources_editor');
  active_tab.value = event.uid;
  if (event.uid === 'gantt-chart')
    window.gantt.$setLayout();
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div class="flex justify-between py-2 px-4">
    <div class="flex items-center">
      <HawkTabs
        :tabs="tabs_items"
        :active_item="active_tab"
        :space="4"
        @tab-click="onTabClick"
      />
      <HawkLoader v-if="is_mini_loading" container_class="ml-3 -mt-3" :height="5" :width="5" />
    </div>
    <div v-show="active_tab === 'gantt-chart'" class="flex items-center">
      <input ref="hidden_focus_input" type="text" class="h-0 w-0">
      <PmSearch
        v-if="flags.is_search_visible"
        class="ml-3"
        @expand-all="() => toggleExpandCollapse(true)"
        @close-search="flags.is_search_visible = false"
      />
      <HawkButton
        v-else
        v-tippy="{
          content: $t('Search'),
          placement: 'bottom',
          appendTo: tippy_target,
        }"
        icon
        type="text"
        class="ml-3"
        @click="flags.is_search_visible = true"
      >
        <IconHawkSearchMd class="w-4 h-4" />
      </HawkButton>
      <div class="relative">
        <HawkButton
          v-if="!is_schedule_dynamic"
          id="main-filter-button"
          v-tippy="{
            content: $t('Filters'),
            placement: 'bottom',
            appendTo: tippy_target,
          }"
          icon
          class="ml-1"
          type="text"
          :disabled="is_group_by_length_zero"
          :class="{ 'cursor-not-allowed': is_group_by_length_zero }"
          @click="state.is_menu_visible = !state.is_menu_visible"
        >
          <IconHawkFilterFunnelRed v-if="are_filters_present" class="w-4 h-4" />
          <IconHawkFilterFunnelOne v-else class="w-4 h-4" />
        </HawkButton>
        <PmFilterMenu
          v-if="state.is_menu_visible"
          :key="active_view.data.filters"
          @click.stop
          @close="state.is_menu_visible = false"
        />
      </div>
      <PmQuickFilterMenu
        v-if="!is_schedule_dynamic"
      />
      <PmViewMenu v-if="!is_schedule_editable" class="mx-1" />
      <div class="flex items-center">
        <HawkButton
          v-if="!is_schedule_dynamic"
          v-tippy="{
            content: $t('All attachments'),
            placement: 'bottom',
            appendTo: tippy_target,
          }"
          icon
          type="text"
          @click="state.is_attachments_slide_over_visible = true"
        >
          <IconHawkAttachmentOne class="w-4 h-4" />
        </HawkButton>
        <HawkButton
          v-if="!is_schedule_dynamic"
          v-tippy="{
            content: $t('All comments'),
            placement: 'bottom',
            appendTo: tippy_target,
          }"
          icon
          type="text"
          @click="state.is_comments_slide_over_visible = true"
        >
          <IconHawkMessageDotsCircleRedDot v-if="comment_priority_status === 'unread_mentions'" class="w-4 h-4" />
          <IconHawkMessageDotsCircleGrayDot v-else-if="comment_priority_status === 'unread_comments'" class="w-4 h-4" />
          <IconHawkMessageDotsCircle v-else-if="comment_priority_status === 'all_comments'" class="w-4 h-4" />
        </HawkButton>
        <HawkButton
          v-tippy="{
            content: $t('Go to today'),
            placement: 'bottom',
            appendTo: tippy_target,
          }"
          icon
          type="text"
          @click="onTodayClicked"
        >
          <IconHawkCalendarCheckOne class="w-4 h-4" />
        </HawkButton>
        <HawkButton
          v-tippy="{
            content: `${state.is_expanded ? $t('Collapse all') : $t('Expand all')}`,
            placement: 'bottom',
            appendTo: tippy_target,
          }"
          icon
          type="text"
          @click="toggleCollapsed"
        >
          <template v-if="state.is_expanded">
            <IconHawkMinimizeOne class="w-4 h-4" />
          </template>
          <template v-else>
            <IconHawkMaximizeOne class="w-4 h-4" />
          </template>
        </HawkButton>
        <PmZoom class="ml-3" />
        <PmSettingsMenu
          class="ml-3"
        />
      </div>
    </div>
  </div>
  <PmAttachmentsSlideOver
    :active="state.is_attachments_slide_over_visible"
    @close="state.is_attachments_slide_over_visible = false"
  />
  <PmCommentsSlideOver
    :active="state.is_comments_slide_over_visible"
    @close="state.is_comments_slide_over_visible = false"
  />
</template>
