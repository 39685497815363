import ArrowDown from '~icons/hawk/arrow-down?raw';
import ArrowLeft from '~icons/hawk/arrow-left?raw';
import ArrowRight from '~icons/hawk/arrow-right?raw';
import ArrowUp from '~icons/hawk/arrow-up?raw';
import PmCompleted from '~icons/hawk/pm-completed?raw';
import { storeToRefs } from 'pinia';
import { computed, inject, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import useEmitter from '~/common/composables/useEmitter';
import { changeIconDimensions } from '~/common/utils/common.utils';
import PmResourceCalendarModal from '~/project-management/components/pm-resource-calendar-modal.vue';
import { useTaskLayers } from '~/project-management/composables/pm-task-layers.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

export function useLayouts() {
  const $t = inject('$t');

  const IconArrowUp = changeIconDimensions(ArrowUp, 14, 14);
  const IconArrowDown = changeIconDimensions(ArrowDown, 14, 14);
  const IconArrowLeft = changeIconDimensions(ArrowLeft, 14, 14);
  const IconArrowRight = changeIconDimensions(ArrowRight, 14, 14);
  const IconPmCompleted = changeIconDimensions(PmCompleted, 16, 16);

  const emitter = useEmitter();
  const project_management_store = useProjectManagementStore();

  const { setupTaskLayers } = useTaskLayers();
  const { modify_config, set_view_dirtiness } = project_management_store;
  const { $g, active_schedule, active_view, hidden_focus_input, resource_mode, is_resources_panel_loading, is_schedule_editable } = storeToRefs(project_management_store);
  const layout = computed(() => active_view.value.data.layout);

  const resource_calendar_modal = useModal({
    component: PmResourceCalendarModal,
    attrs: {
      onClose: () => {
        resource_calendar_modal.close();
      },
    },
  });

  window.handleResourceClick = function (resource_id) {
    const resource_element = document.querySelector(`[resource-id='${resource_id}']`);
    const resource_string = resource_element.getAttribute('data-resource');
    const resource = JSON.parse(resource_string);
    const calendar = active_schedule.value.calendars.find(calendar => calendar.id === resource.uid) || active_schedule.value.calendars[0];

    const selected_weekdays = [];
    let working_hours = [];

    for (const day in calendar.days) {
      if (calendar.days[day].is_working) {
        selected_weekdays.push(day);
        if (!working_hours.length) {
          const wh = calendar.days[day].working_hours;
          for (const working_hour of wh) {
            const str = working_hour.replaceAll(':00', '');
            const split_numbers = str.split('-');
            const num_start = Number.parseInt(split_numbers[0], 10);
            const num_end = Number.parseInt(split_numbers[1], 10) || 24;
            const range = Array.from({ length: num_end - num_start }, (_, i) => i + num_start);
            working_hours = [...working_hours, ...range];
          }
        }
      }
    }
    resource_calendar_modal.patchOptions({
      attrs: {
        resource,
        initialFormData: {
          working_hours,
          selected_weekdays,
        },
      },
    });
    resource_calendar_modal.open();
  };

  // function getResourceAssignments(resourceId) {
  //   let assignments;
  //   const store = $g.value.getDatastore($g.value.config.resource_store);
  //   const resource = store.getItem(resourceId);

  //   if (resource.$level === 0) {
  //     assignments = [];
  //     store.getChildren(resourceId).forEach((childId) => {
  //       assignments = assignments.concat($g.value.getResourceAssignments(childId));
  //     });
  //   }
  //   else if (resource.$level === 1) {
  //     assignments = $g.value.getResourceAssignments(resourceId);
  //   }
  //   else {
  //     assignments = $g.value.getResourceAssignments(resource.$resource_id, resource.$task_id);
  //   }
  //   return assignments;
  // }

  function getTaskCounts(resource) {
    const { completed_tasks_count, total_tasks_count } = active_schedule.value.resource_assignments.reduce((counts, resource_assignment) => {
      if (
        active_schedule.value.activities[resource_assignment.activity]?.progress === 1
        && resource_assignment.resource === resource.uid
      ) {
        counts.completed_tasks_count++;
      }

      if (resource_assignment.resource === resource.uid)
        counts.total_tasks_count++;

      return counts;
    }, { completed_tasks_count: 0, total_tasks_count: 0 });

    return { completed_tasks_count, total_tasks_count };
  }

  const resource_config = {
    columns: [
      {
        name: 'name',
        label: $t('Name'),
        tree: true,
        template(resource) {
          const additional_classes = ['assigned', 'unassigned'].includes(resource.id) ? 'text-gray-900 font-semibold' : '';
          const { completed_tasks_count, total_tasks_count } = getTaskCounts(resource);
          // ${resources_overloaded.value[resource.uid] ? `<span class="text-error-500 scale-x-[-1] resource-overloaded">${IconMeterMedium}</span>` : ''}
          return `
            <span class="w-full flex items-center gap-3 ${additional_classes}">
              ${resource.text}
              ${completed_tasks_count === total_tasks_count && total_tasks_count ? `<span class="text-success-500 resource-tasks-completed">${IconPmCompleted}</span>` : ''}
            </span>
          `;

          // <span class="flex items-center cursor-pointer" resource-id='${resource.id}' data-resource='${JSON.stringify(resource)}' onclick="handleResourceClick(this.getAttribute('resource-id'))">
          // ${IconCalendar}
          // </span>
        },
      },
      {
        name: 'tasks',
        label: $t('Tasks'),
        template(resource) {
          const { completed_tasks_count, total_tasks_count } = getTaskCounts(resource);

          if (!total_tasks_count)
            return '<div class="ml-2.5">-</div>';
          return `
            <div class="ml-2.5">
              ${completed_tasks_count}/${total_tasks_count}
            </div>
          `;
        },
      },
      {
        name: 'progress',
        label: $t('Progress'),
        template(resource) {
          const { completed_tasks_count, total_tasks_count } = getTaskCounts(resource);

          if (!total_tasks_count)
            return '<div class="ml-2.5">-</div>';
          return `
            <div class="ml-2.5">
              ${Math.round((completed_tasks_count / total_tasks_count) * 100)}%
            </div>
          `;
        },
      },
      // {
      //   name: 'workload',
      //   label: 'Workload',
      //   template(resource) {
      //     let totalDuration = 0;
      //     if (resource.$level === 2) {
      //       const assignment = $g.value.getResourceAssignments(resource.$resource_id, resource.$task_id)[0];
      //       totalDuration = resource.duration * assignment.value;
      //     }
      //     else {
      //       const assignments = getResourceAssignments(resource.id);
      //       assignments.forEach((assignment) => {
      //         const task = $g.value.getTask(assignment.task_id);
      //         totalDuration += Number(assignment.value) * task.duration;
      //       });
      //     }

      //     return `${totalDuration || 0}h`;
      //   },
      // },
    ],
  };

  function updateLayoutConfiguration(button_axis) {
    const layout_configs = {
      0: { grid: true, chart: true, resource_grid: false, resource_chart: false },
      1: { grid: false, chart: true, resource_grid: false, resource_chart: false },
      2: { grid: true, chart: false, resource_grid: false, resource_chart: false },
      3: { grid: true, chart: true, resource_grid: true, resource_chart: true },
      4: { grid: false, chart: true, resource_grid: true, resource_chart: true },
      5: { grid: true, chart: false, resource_grid: true, resource_chart: true },
    };
    const current_layout = calculateCurrentLayout(button_axis);
    modify_config({ key: 'layout', value: layout_configs[current_layout] });
    set_view_dirtiness(true);
    setLayout();
  }

  function calculateCurrentLayout(button_axis) {
    if (button_axis === 'HLayout')
      return calculateHLayout();
    else if (button_axis === 'VLayout')
      return calculateVLayout();
  }

  function calculateHLayout() {
    let result = -1;

    if (layout.value.resource_grid) {
      if (layout.value.grid && layout.value.chart)
        result = 4;
      else if (layout.value.grid && !layout.value.chart)
        result = 3;
      else if (layout.value.chart)
        result = 5;
    }
    else if (layout.value.grid && layout.value.chart) {
      result = 1;
    }
    else if (layout.value.grid && !layout.value.chart) {
      result = 0;
    }
    else if (layout.value.chart) {
      result = 2;
    }
    return result;
  }

  function calculateVLayout() {
    if (layout.value.grid && layout.value.chart)
      return layout.value.resource_grid ? 0 : 3;
    else if (layout.value.grid)
      return layout.value.resource_grid ? 2 : 5;
    else if (layout.value.chart)
      return layout.value.resource_grid ? 1 : 4;
  }

  function attachHLayoutButtons() {
    let extras = '';
    let icon = null;
    let selector = '';
    let button_text = '';
    if (layout.value?.grid && layout.value?.chart) {
      extras = 'left-0 rounded-r h-9';
      icon = IconArrowLeft;
      selector = '.gantt_layout_cell.gantt_resizer.gantt_resizer_x';
    }
    else if (layout.value?.grid) {
      extras = 'right-0 rounded-l h-16';
      icon = IconArrowLeft;
      button_text = `<span class="-rotate-90 text-center mb-2 mt-[14px]">${$t('Chart')}</span>`;
      selector = '.gantt_grid';
    }
    else {
      extras = 'left-0 rounded-r h-14';
      icon = IconArrowRight;
      button_text = `<span class="-rotate-90 text-center mb-2 mt-[10px]">${$t('Grid')}</span>`;
      selector = '.gantt_task';
    }

    const new_div = document.createElement('div');
    new_div.className = `pm-expand-button z-2 flex flex-col items-center justify-center absolute top-1/2 -translate-y-1/2 w-5 border border-primary-200 bg-primary-100 hover:border-primary-600 hover:bg-primary-600 hover:text-white z-1 cursor-pointer ${extras}`;
    new_div.innerHTML = `<div class="flex flex-col justify-center items-center text-xs font-medium">${icon}${button_text}</div>`;
    new_div.onclick = (e) => {
      emitter.emit('hide_resources_editor');
      $g.value?.ext?.inlineEditors?.hide?.();

      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      setTimeout(() => {
        hidden_focus_input.value.focus();
        updateLayoutConfiguration('HLayout');
      }, 10);
    };

    const el = document.querySelector(selector);
    if (el)
      el.appendChild(new_div);
  }

  function attachVLayoutButtons() {
    const el = document.querySelector('.gantt_layout_x');
    if (el) {
      let extras = '';
      let icon = null;
      let button_text = '';
      if (layout.value?.resource_grid && layout.value?.resource_chart) {
        extras = '-translate-x-1/2 -translate-y-[20px] h-5';
        icon = IconArrowDown;
      }
      else {
        extras = `${layout.value?.grid && !layout.value?.chart ? '-translate-y-[24px]' : '-translate-y-[22px]'} -translate-x-1/2 h-6`;
        icon = IconArrowUp;
        button_text = `&nbsp;&nbsp;${$t('Workload')}`;
      }

      const new_div = document.createElement('div');
      new_div.className = `pm-expand-button z-2 flex flex-col items-center justify-center absolute left-1/2 min-w-9 border border-primary-200 bg-primary-100 hover:border-primary-600 hover:bg-primary-600 hover:text-white z-1 cursor-pointer rounded-t ${extras}`;
      new_div.innerHTML = `<div class="flex justify-center items-center text-xs font-medium px-2">${icon}${button_text}</div>`;
      new_div.onclick = (e) => {
        emitter.emit('hide_resources_editor');
        $g.value?.ext?.inlineEditors?.hide?.();

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        setTimeout(() => {
          hidden_focus_input.value.focus();
          updateLayoutConfiguration('VLayout');
        }, 10);
      };
      el.appendChild(new_div);
    }
  }

  function removeLayoutButtons() {
    const elements = document.querySelectorAll('.pm-expand-button');
    if (!elements?.length)
      return;
    for (const element of elements)
      element.remove();
  }

  function attachLayoutButtons() {
    removeLayoutButtons();
    attachHLayoutButtons();
    if (active_schedule.value.track_resources)
      attachVLayoutButtons();
  }

  function updateResourceMode() {
    const hours_input_element = document.getElementById('hours-radio');
    const tasks_input_element = document.getElementById('tasks-radio');

    if (hours_input_element && tasks_input_element) {
      if (resource_mode.value === 'hours') {
        hours_input_element.setAttribute('checked', true);
        hours_input_element.parentNode.classList.toggle('active', true);
        tasks_input_element.removeAttribute('checked');
      }
      else if (resource_mode.value === 'tasks') {
        tasks_input_element.setAttribute('checked', true);
        tasks_input_element.parentNode.classList.toggle('active', true);
        hours_input_element.removeAttribute('checked');
      }
    }
  }

  const hours_labels = {
    0: $t('Hours per year'),
    1: $t('Hours per quarter'),
    2: $t('Hours per month'),
    3: $t('Hours per week'),
    4: $t('Hours per day'),
  };

  const tasks_labels = {
    0: $t('Tasks per year'),
    1: $t('Tasks per quarter'),
    2: $t('Tasks per month'),
    3: $t('Tasks per week'),
    4: $t('Tasks per day'),
  };

  gantt.$setLayout = setLayout;
  function setLayout() {
    removeLayoutButtons();
    const hours_label = hours_labels[active_view.value.data.zoom_level ?? 4];

    const tasks_label = tasks_labels[active_view.value.data.zoom_level ?? 4];

    const layout_grid_chart = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            { resizer: true, width: 1 },
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    const layout_grid = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    const layout_chart = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    const layout_grid_chart_resources = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            { resizer: true, width: 1 },
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
        },
        // { resizer: true, width: 1 },
        {
          height: 35,
          cols: [
            {
              html: `
              <div id="resources-header" class="h-full ml-4 flex items-center text-xs font-semibold text-gray-900">
                ${$t('Resources workload')}
              </div>
              `,
              group: 'grids',
            },
            { resizer: true, width: 1 },
            {
              html: `
                <div class="flex">
                  <div class="flex items-center mr-4">
                    <input value="hours" id="hours-radio" type="radio" name="resource-mode">
                    <label for="hours-radio" class="ms-2 text-xs font-medium text-gray-900 cursor-pointer">${hours_label}</label>
                  </div>
                  <div class="flex items-center">
                    <input value="tasks" id="tasks-radio" type="radio" name="resource-mode">
                    <label for="tasks-radio" class="ms-2 text-xs font-medium text-gray-900 cursor-pointer">${tasks_label}</label>
                  </div>
                </div>`,
              css: 'resource-controls',
            },
          ],
        },
        {
          config: resource_config,
          cols: [
            { view: 'resourceGrid', group: 'grids', width: 435, scrollY: 'resourceVScroll' },
            { resizer: true, width: 1 },
            { view: 'resourceTimeline', scrollX: 'scrollHor', scrollY: 'resourceVScroll' },
            { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' },
          ],
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    const layout_grid_resources = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
        },
        // { resizer: true, width: 1 },
        {
          height: 35,
          cols: [
            { html: '', group: 'grids' },
            { resizer: true, width: 1 },
            {
              html: `
                <div class="flex">
                  <div class="flex items-center mr-4">
                    <input value="hours" id="hours-radio" type="radio" name="resource-mode">
                    <label for="hours-radio" class="ms-2 text-xs font-medium text-gray-900 cursor-pointer">${hours_label}</label>
                  </div>
                  <div class="flex items-center">
                    <input value="tasks" id="tasks-radio" type="radio" name="resource-mode">
                    <label for="tasks-radio" class="ms-2 text-xs font-medium text-gray-900 cursor-pointer">${tasks_label}</label>
                  </div>
                </div>`,
              css: 'resource-controls',
            },
          ],
        },
        {
          config: resource_config,
          cols: [
            { view: 'resourceGrid', group: 'grids', width: 435, scrollY: 'resourceVScroll' },
            { resizer: true, width: 1 },
            { view: 'resourceTimeline', scrollX: 'scrollHor', scrollY: 'resourceVScroll' },
            { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' },
          ],
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    const layout_chart_resources = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
        },
        // { resizer: true, width: 1 },
        {
          height: 35,
          cols: [
            { html: '', group: 'grids' },
            { resizer: true, width: 1 },
            {
              html: `
                <div class="flex">
                  <div class="flex items-center mr-4">
                    <input value="hours" id="hours-radio" type="radio" name="resource-mode">
                    <label for="hours-radio" class="ms-2 text-xs font-medium text-gray-900 cursor-pointer">${hours_label}</label>
                  </div>
                  <div class="flex items-center">
                    <input value="tasks" id="tasks-radio" type="radio" name="resource-mode">
                    <label for="tasks-radio" class="ms-2 text-xs font-medium text-gray-900 cursor-pointer">${tasks_label}</label>
                  </div>
                </div>`,
              css: 'resource-controls',
            },
          ],
        },
        {
          config: resource_config,
          cols: [
            { view: 'resourceGrid', group: 'grids', width: 435, scrollY: 'resourceVScroll' },
            { resizer: true, width: 1 },
            { view: 'resourceTimeline', scrollX: 'scrollHor', scrollY: 'resourceVScroll' },
            { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' },
          ],
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    const layout_mapping = {
      0: layout_grid_chart,
      1: layout_chart,
      2: layout_grid,
      3: layout_grid_chart_resources,
      4: layout_chart_resources,
      5: layout_grid_resources,
    };

    let active_layout = 0;
    if (layout.value.grid && layout.value.chart && layout.value.resource_grid && layout.value.resource_chart && active_schedule.value.track_resources)
      active_layout = 3;

    else if (!layout.value.grid && layout.value.chart && layout.value.resource_grid && layout.value.resource_chart && active_schedule.value.track_resources)
      active_layout = 4;

    else if (layout.value.grid && !layout.value.chart && layout.value.resource_grid && layout.value.resource_chart && active_schedule.value.track_resources)
      active_layout = 5;

    else if (layout.value.grid && layout.value.chart)
      active_layout = 0;

    else if (!layout.value.grid && layout.value.chart)
      active_layout = 1;

    else if (layout.value.grid && !layout.value.chart)
      active_layout = 2;

    $g.value.config.layout = layout_mapping[active_layout];

    // const el = document.getElementById(active_instance_id.value);
    // $g.value.init(el);

    // NOTE: The below 2 lines were initially present in the setTimeout which includes setSizes. It was added to fix some issue which was occurring because of keyboard navigation (unable to recollect the exact issue). But the problem with these two lines being there is that the 'Enter' key was not activating the inline editors when we move to the GRID only mode. Ramil on the email (Subject: Issue with toggling keyboard navigation while switching layouts) has suggested that the right way to do this is to have the keyboard_navigation configs changed before the resetLayout. While this works because we forgot the earlier issue, we need to keep an eye on the below lines.
    $g.value.config.keyboard_navigation = ![1, 4].includes(active_layout);
    $g.value.config.keyboard_navigation_cells = ![1, 4].includes(active_layout);

    $g.value.resetLayout();
    setupTaskLayers();

    gantt.$setupColumns();
    attachLayoutButtons();

    const grid = $g.value.$ui.getView('grid');
    if (grid) {
      grid.attachEvent('onBeforeColumnDragStart', (column) => {
        const disabled_columns = ['wbs', 'select-columns'];
        if (is_schedule_editable.value)
          disabled_columns.push('id');
        return !disabled_columns.includes(column.draggedColumn.name);
      });
      grid.attachEvent('onAfterColumnReorder', () => {
        if (is_schedule_editable.value)
          return;
        set_view_dirtiness(true);
      });
    }
    // TODO: check if this is needed.
    // Old note: Start with a grid view, and click the horizontal layout button, the chart doesn't show up
    // if ([0, 3].includes(active_layout))
    // $g.value.config.grid_width = window.innerWidth / 2;

    setTimeout(() => {
      $g.value.setSizes();
    }, 100);

    updateResourceMode();
  }

  watch(is_resources_panel_loading, (newVal) => {
    const el = document.getElementById('resources-header');
    if (!el)
      return;

    if (newVal) {
      if (!document.getElementById('resources-header-loading')) {
        const div = document.createElement('div');
        div.setAttribute('id', 'resources-header-loading');
        div.textContent = 'Loading...';
        el.appendChild(div);
      }
    }
    else {
      const div = document.getElementById('resources-header-loading');
      if (div)
        el.removeChild(div);
    }
  });

  return {
    setLayout,
  };
}
