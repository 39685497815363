/* eslint-disable no-control-regex */
import { cloneDeep, findKey, keyBy, omit, some } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';

import slugify from 'slugify';
import { useAuthStore } from '~/auth/stores/auth.store';
import { import_fusioncharts } from '~/common/utils/package.utils.js';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store';
import { useDashboardTasksStore } from '~/dashboard/store/dashboard-tasks.store';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store';
import { useDashboardThermStore } from '~/dashboard/store/dashboard-therm.store';

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    scope: 'global',
    widget_configuration: null,
    widget_name: null,
    widget_asset: null,
    is_form_valid: false,
    reports_map: {},
    current_dashboard: null,
    is_editing_widget: false,
    is_editing_dashboard: false,
    widget_uid: null,
    dashboard_loading: false,
    print_map: {},
    new_print_map: {},
    fusion_chart_state: {},
    pivot_table_instance: null,
    selected_date_range: {},
    is_slug_mode: false,
  }),
  getters: {
    reports: (state) => {
      return Object.values(state.reports_map);
    },
    is_mobile_view: (state) => {
      return state.scope === 'micro_app';
    },
  },
  actions: {
    set_selected_date_range(range = {}) {
      this.selected_date_range = range;
    },
    minW(chart) {
      if (['map-view', 'single_form_view', 'transmittal_detail_view'].includes(chart)) {
        return 12;
      }

      else if (['container_views', 'map_view', 'tasks_list', 'forms_list', 'activity_history', 'activity_history_table', 'documents_list', 'transmittal_list'].includes(chart)) {
        return 6;
      }

      else if (
        [
          'forms_list_with_tabs',
          'pivot table',
          'form_responses',
          'activities_work_combined_table',
          'activities_work_table',
          'activities_table',
          'workprogress_terra_chart',
          'form_responses',
          'projects_list',
        ].includes(chart)) {
        return 8;
      }

      else if (['spi', 'cpi', 'activities_metrics', 'number_chart', 'metric', 'progress_card',
      ].includes(chart)) {
        return 3;
      }

      else {
        return 4;
      }
    },

    minH(chart) {
      if (
        ['spi', 'cpi', 'activities_metrics', 'number_chart', 'metric', 'progress_card', 'feature_type_table'].includes(chart)
      )
        return 5;

      if (chart === 'ma-view')
        return 20;

      if (chart === 'single_form_view' || chart === 'transmittal_detail_view')
        return 25;

      return 15;
    },

    set_scope(scope) {
      this.scope = scope;
    },
    async set_dashboards(query) {
      this.reports_map = {};
      try {
        const { data } = await this.$services.dashboard.getAll({
          query,
        });
        if (data.report?.length)
          this.reports_map = keyBy(data.report, 'uid');
      }
      catch (err) {
        logger.log(err);
      }
    },

    set_active_dashboard(id) {
      if (id)
        this.current_dashboard = this.reports_map[id];

      else this.current_dashboard = this.reports.find(o => o.default) || this.reports[0] || {};
    },
    async get_field_values() {
      try {
        const current_route = this.$router.currentRoute.value;

        const { data } = await this.$services.fieldvalues.getAll({
          query: {
            asset: current_route.params.asset_id,
          },
        });

        return data;
      }
      catch (e) {
        logger.log(e);
        return null;
      }
    },
    get_pivot_table_properies(widget_data) {
      const pivot_config = this.pivot_table_instance?.getReport({
        withGlobals: true,
      });
      pivot_config.active_chart = this.pivot_table_instance?.active_chart;
      if (pivot_config)
        delete pivot_config.dataSource;

      return { ...(widget_data?.properties || {}), pivot_config };
    },
    set_widget_configuration(data) {
      if (Object.keys(data || {}).length)
        this.widget_configuration = { ...data };
      else
        this.widget_configuration = null;
    },
    async delete_dashboards(query) {
      const all_reports = cloneDeep(this.reports_map);
      this.reports_map = omit(all_reports, query.map(q => q.uid));
      try {
        await this.$services.dashboard.delete({
          body: {
            reports: [
              ...query,
            ],
          },
        });
      }
      catch (err) {
        logger.log({ err });
        this.reports_map = { ...all_reports };
      }
    },
    async update_dashboard(payload, $t) {
      if (!payload.uid) {
        this.create_dashboard(payload);
      }
      else {
        try {
          const { data } = await this.$services.dashboard.patch({
            id: payload.uid,
            body: payload,
          });
          if (data?.report?.[0]) {
            this.reports_map[data.report[0].uid] = data.report[0];
            this.current_dashboard = data.report[0];
            return data.report[0];
          }
        }
        catch (err) {
          this.$toast({ title: $t('Saving Dashboard Failed'), text: $t('Unable to save the dashboard. Please try again later'), type: 'error' });
          logger.error(err);
        }
      }
    },
    async create_dashboard(payload) {
      try {
        const route = this.$router.currentRoute.value;
        const query = {

          ...(this.scope !== 'organization'
            ? {
                asset: route.params.asset_id,
              }
            : {}),
          ...(this.scope === 'schedule'
            ? {
                references: [
                  {
                    resource_id: route.params.schedule_id,
                    resource_type: 'project_management',
                  },
                ],
              }
            : {}),
          ...(this.scope === 'forms'
            ? {
                references: [
                  {
                    resource_id: route.params.template_uid,
                    resource_type: 'forms',
                  },
                ],
              }
            : {}),
        };
        const { data } = await this.$services.dashboard.post({
          body: { ...payload, ...query },
        });

        this.reset_print_map();

        if (data?.report?.[0]) {
          this.reports_map[data.report[0].uid] = data.report[0];
          this.current_dashboard = { ...data.report[0] };
          this.reports_map = { ...this.reports_map };
          return data.report[0];
        }
        return data;
      }
      catch (err) {
        logger.error(err);
        this.dashboard_loading = false;
      }
    },
    async create_report_dashboard(config) {
      const authStore = useAuthStore();

      this.create_widget_uid();
      const payload = this.parse_configuration(config);

      const body = {
        name: payload.name,
        report_data: {
          [this.widget_uid]: {
            x: 0,
            y: 0,
            w: 12,
            h: 25,
            i: 0,
            source: payload?.properties?.source,
            type: payload.chart,
            uid: this.widget_uid,
            data: payload,
          },
        },
        default: false,
        organization: authStore.current_organization?.uid,
        asset: this.widget_asset,
        references: [{
          // resource_id: route.params.template_uid,
          resource_type: 'inventory',
        }],
        public: true,
        standard: true,
      };

      const { data } = await this.$services.dashboard.post({ body });
      this.reset_print_map();
      if (data?.report?.[0]) {
        this.reports_map[data.report[0].uid] = data.report[0];
        this.current_dashboard = { ...data.report[0] };
        this.reports_map = { ...this.reports_map };
        return data.report[0];
      }
    },

    unparse_configuration(configuration) {
      if (!Object.keys(configuration || {}).length)
        return null;
      const dashboard_tasks_store = useDashboardTasksStore();
      const dashboard_forms_store = useDashboardFormsStore();
      const dashboard_terra_store = useDashboardTerraStore();
      const dashboard_therm_store = useDashboardThermStore();
      const dashboard_schedule_store = useDashboardScheduleStore();
      const dashboard_custom_store = useDashboardCustomStore();

      let widget_data = {};
      if (configuration.data?.properties?.source?.type === 'tasks')
        widget_data = dashboard_tasks_store.unparse_tasks_form_to_server_format(configuration.data);

      else if (configuration.data?.properties?.source?.type === 'forms')
        widget_data = dashboard_forms_store.unparse_forms_form_to_server_format(configuration.data);

      else if (configuration.data?.properties?.source?.type === 'terra')
        widget_data = dashboard_terra_store.unparse_terra_form_to_server_format(configuration.data);

      else if (configuration.data?.properties?.source?.type === 'therm')
        widget_data = dashboard_therm_store.unparse_therm_form_to_server_format(configuration.data);

      else if (configuration.data?.properties?.source?.type === 'project management')
        widget_data = dashboard_schedule_store.unparse_schedule_form_to_server_format(configuration.data);

      else if (configuration.data?.properties?.source?.type === 'custom')
        widget_data = dashboard_custom_store.unparse_custom_form_to_server_format(configuration.data);

      else
        return configuration;

      return {
        ...configuration,
        data: widget_data,
        module: configuration?.source?.type,
        type: widget_data?.type,
      };
    },
    parse_configuration(configuration) {
      if (!Object.keys(configuration || {}).length)
        return null;

      const dashboard_tasks_store = useDashboardTasksStore();
      const dashboard_forms_store = useDashboardFormsStore();
      const dashboard_terra_store = useDashboardTerraStore();
      const dashboard_therm_store = useDashboardThermStore();
      const dashboard_schedule_store = useDashboardScheduleStore();
      const dashboard_custom_store = useDashboardCustomStore();

      configuration = {
        ...configuration,
        asset_id: this.widget_asset,
      };

      let widget_data = {};
      if (configuration.module === 'tasks') {
        widget_data = dashboard_tasks_store.parse_tasks_form_to_server_format(configuration);
      }
      else if (configuration.module === 'forms') {
        widget_data = dashboard_forms_store.parse_forms_form_to_server_format(configuration);
      }
      else if (configuration.module === 'terra') {
        widget_data = dashboard_terra_store.parse_terra_form_to_server_format(configuration);
      }
      else if (configuration.module === 'therm') {
        widget_data = dashboard_therm_store.parse_therm_form_to_server_format(configuration);
      }
      else if (configuration.module === 'schedule') {
        widget_data = dashboard_schedule_store.parse_schedule_form_to_server_format(configuration);
      }
      else if (configuration.module === 'inventory') {
        const config_copy = { ...configuration };
        delete config_copy.columns_widths;
        delete config_copy.columns_order;
        widget_data = {
          ...config_copy,
          chart: configuration.type,
          name: this.widget_name || 'Untitled',
          properties: {
            ...configuration.properties,
            name: this.widget_name || 'Untitled',
            ...(Object.keys(configuration.columns_widths || {}).length && { columns_widths: configuration.columns_widths }),
            ...(configuration.columns_order?.length && { columns_order: configuration.columns_order }),
          },
        };
      }
      else if (configuration.module === 'custom') {
        widget_data = dashboard_custom_store.parse_custom_form_to_server_format(configuration);
      }
      else {
        widget_data = configuration;
        widget_data.chart = configuration.type;
        widget_data.name = this.widget_name || 'Untitled';

        if (['asset_pivot_table', 'stock_tracking', 'transactions_pivot'].includes(configuration.type))
          widget_data.properties = this.get_pivot_table_properies(widget_data);

        return widget_data;
      }

      return { ...widget_data, properties: { ...widget_data.properties, name: this.widget_name || 'Untitled' } };
    },
    set_form_valid(val) {
      this.is_form_valid = !!val;
    },

    async add_widget(config) {
      const widget_uid = this.widget_uid;
      const payload = this.parse_configuration(config);

      const index = this.get_index();
      const report_data = this.current_dashboard?.report_data || {};
      if (index) {
        const body = {
          x: 0,
          y:
            (Object.keys(report_data).length && Object.keys(report_data).length * 15)
            || 15,
          w: this.minW(payload.chart) || 4,
          h: this.minH(payload.chart) || 15,
          i: index,
          source: payload?.properties?.source,
          type: payload.chart,
          uid: widget_uid,
          data: payload,
        };

        this.current_dashboard.report_data = { ...report_data, [widget_uid]: body };

        this.current_dashboard = { ...this.current_dashboard };

        setTimeout(() => {
          const el = document.querySelectorAll(`[data-id='${widget_uid}']`)?.[0];
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
          }
        }, 1000);
      }
    },
    update_widget(config, toast = true, $t) {
      const payload = this.parse_configuration(config);
      const widget = this.current_dashboard.report_data[this.widget_uid];
      const body = {
        x: widget.x,
        y: widget.y,
        w: widget.w,
        h: widget.h,
        i: widget.i,
        source: payload?.properties?.source,
        type: payload.chart,
        uid: this.widget_uid,
        data: payload,
      };
      this.current_dashboard.report_data[this.widget_uid] = body;

      this.current_dashboard = { ...this.current_dashboard };
      if (toast) {
        this.$toast({
          title: $t('Widget Saved'),
          text: $t('The widget has been successfully created and saved on the dashboard.'),
          type: 'success',
        });
      }
    },
    async update_terra_map_widget(uid, map_data, $t) {
      const widget = { ...this.current_dashboard.report_data[uid] };
      widget.data.properties.data = {
        ...widget.data.properties.data,
        ...map_data,
      };
      this.current_dashboard.report_data[uid] = { ...widget };

      try {
        await this.$services.dashboard.patch({
          id: this.current_dashboard.uid,
          body: this.current_dashboard,
        });
        this.$toast({
          title: $t('Widget Saved'),
          text: $t('The widget has been successfully created and saved on the dashboard'),
          type: 'success',
        });
      }
      catch (err) {
        logger.log(err);
        this.$toast({
          title: $t('Widget Creation Failed'),
          text: $t('We couldn\'t save the widget. Please double-check your input and try again'),
          type: 'success',
        });
      }
    },
    update_widget_table_widths(uid, columns_widths) {
      const widget = { ...this.current_dashboard.report_data[uid] };
      widget.data.properties = {
        ...widget.data.properties,
        ...{
          columns_widths,
        },
      };
      this.current_dashboard.report_data[uid] = { ...widget };
    },
    update_table_default_columns(uid, default_columns = []) {
      const widget = { ...this.current_dashboard.report_data[uid] };
      widget.data.properties = {
        ...widget.data.properties,
        ...{
          default_columns,
        },
      };
      this.current_dashboard.report_data[uid] = { ...widget };
    },
    async delete_widget(config) {
      const current_reports = cloneDeep(this.current_dashboard.report_data);
      const delete_key = findKey(current_reports, report => report.uid === config.uid);

      delete current_reports[delete_key];
      this.current_dashboard.report_data = { ...current_reports };
      this.current_dashboard = { ...this.current_dashboard };
      try {
        await this.$services.dashboard.patch({
          id: this.current_dashboard.uid,
          body: this.current_dashboard,
        });

        const print_cpy = { ...this.print_map };
        delete print_cpy[config.uid];
        this.print_map = { ...print_cpy };
      }
      catch (err) {
        logger.log({ err });
      }
    },
    get_index() {
      let increment = 1;
      const report_data = this.current_dashboard?.report_data || {};
      let unique = (Object.keys(report_data).length || 0) + increment;
      let not_unique = some(report_data, ['i', unique]);
      while (not_unique) {
        increment += 1;
        unique = (Object.keys(report_data).length || 0) + increment;
        not_unique = some(report_data, ['i', unique]);
      }
      return unique;
    },
    format_table_columns_widths(columns_widths) {
      if (!Object.values(columns_widths || {}).length)

        return {};

      const data = {};

      Object.values(columns_widths || {}).forEach((col) => {
        if (Object.values(col.children || {}).length) {
          Object.values(col.children).forEach((sub_col) => {
            data[sub_col.id] = Number.parseInt(sub_col.size);
          });
        }
        else {
          data[col.id] = Number.parseInt(col.size);
        }
      });

      return data;
    },
    set_table_column_widths(widget_id = null, columns_widths = {}) {
      const formatted_widths = this.format_table_columns_widths(columns_widths);

      if (
        this.is_editing_dashboard
        && widget_id !== 'preview'
      ) { this.update_widget_table_widths(widget_id, formatted_widths); }

      else if (widget_id === 'preview') {
        const obj = {
          ...this.widget_configuration,
          columns_widths: formatted_widths,
        };
        this.set_widget_configuration(obj);
      }
    },
    set_table_column_order(columns) {
      const obj = {
        ...this.widget_configuration,
        columns_order: columns,
      };
      this.set_widget_configuration(obj);
    },
    set_edit_widget_data(widget_data = null) {
      if (!widget_data)
        return;

      this.set_is_editing_widget(true);
      this.set_widget_uid(widget_data.uid);
      this.set_widget_asset(widget_data.data.asset_id);
      this.set_widget_configuration(widget_data.data);
      this.set_widget_name(widget_data.data.name);
    },
    set_is_editing_widget(bool) {
      this.is_editing_widget = bool;
    },
    set_widget_uid(uid = null) {
      this.widget_uid = uid;
    },
    create_widget_uid() {
      this.widget_uid = crypto.randomUUID();
    },
    set_dashboard_loading(boolean) {
      this.dashboard_loading = boolean;
    },
    set_widget_name(value) {
      this.widget_name = value;
    },
    set_widget_asset(value) {
      this.widget_asset = value;
    },
    set_default_values() {
      this.set_widget_configuration(null);
      this.set_widget_name(null);
      this.set_widget_asset(null);
      this.set_is_editing_widget(false);
      this.set_widget_uid(null);
    },
    update_print_map(id, payload) {
      this.print_map[id] = payload;
    },
    update_new_print_map(name, payload) {
      if (!name)
        return;
      slugify.extend({ '/': '-', '(': '', ')': '', '*': '', '&': '-' });

      const widget_slug = slugify(name.replaceAll(/[^\x00-\x7F]/g, '').replaceAll('.', '-'), { lower: true });
      this.new_print_map[widget_slug] = payload;
    },
    reset_print_map() {
      this.print_map = {};
      this.new_print_map = {};
    },
    set_is_editing_dashboard(value) {
      this.is_editing_dashboard = value;
    },
    set_slug_mode(value) {
      this.is_slug_mode = value;
    },
    async set_fusion_chart() {
      this.fusion_chart_state = await import_fusioncharts();
    },
    async dashboard_custom_print(meta_data) {
      try {
        return await this.$services.dashboard.custom_print({
          body: {
            dashboard_json: {
              meta_data,
              print_data: this.new_print_map,
            },
            docx_template: this.current_dashboard.docx_template || null,
          },
        });
      }
      catch (e) {
        logger.log(e);
      }
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardStore, import.meta.hot));
