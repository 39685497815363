<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { uniq } from 'lodash-es';
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';

import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

const props = defineProps({
  selected_forms: {
    type: Array,
    default: () => [],
  },
  forms_store: { type: Object },
  selected_features_without_forms: {
    type: Array,
    default: () => [],
  },
  create_forms_callback: {
    type: Function,
  },
  template_uid: {
    type: String,
  },
});

const emit = defineEmits(['bulkSubmit']);

const FormDetailBulkUpdatePopup = defineAsyncComponent({
  loader: () => import('~/forms/components/form-detail-bulk-update/form-detail-bulk-update-popup.vue'),
  loadingComponent: HawkLoader,
});

// Different logic in v2  for parent forms
const selected_forms = props.selected_forms;
const parent_forms = computed(() => uniq(props.selected_forms.map(form => (form.template?.uid || form.template)) || []));
const approval_forms = computed(() => props.selected_forms.filter(form => form.is_approval_pending || form.is_pending_for_action));
const non_submitted_forms = computed(() => props.selected_forms.filter(form => form.status.name !== 'Submitted') || []);
const is_same_step = computed(() => non_submitted_forms.value.filter(form => form.status?.index !== props.selected_forms?.[0]?.status?.index).length === 0);
const is_visible = computed(() => parent_forms?.value?.length <= 1 && ((non_submitted_forms?.value?.length > 0 && approval_forms?.value?.length === 0) || props.selected_features_without_forms.length));
const form_count = computed(() => (non_submitted_forms.value.length + props.selected_features_without_forms.length));
// form detail bulk update popup
const { open: openFormBulkUpdatePopup, close: closeFormBulkUpdatePopup } = useModal({
  component: FormDetailBulkUpdatePopup,
  attrs: {
    forms: non_submitted_forms.value,
    template: parent_forms.value[0] || props.template_uid,
    forms_store: props.forms_store,
    selected_features_without_forms: props.selected_features_without_forms,
    create_forms_callback: props.create_forms_callback,
    form_count: form_count.value,
    onClose() {
      closeFormBulkUpdatePopup();
    },
    onBulkSubmit(forms) {
      emit('bulkSubmit', forms);
    },
  },
});
</script>

<template>
  <form-detail-bulk-update-cta-button v-if="form_count > 100 && !(approval_forms.length && is_same_step)" disabled class="bg-warning-25 border border-warning-200 rounded-lg px-6 py-4">
    <template #title>
      {{ $t('Bulk fill forms') }}
    </template>
    <template #subtitle>
      {{ $t('Can not bulk fill more than 100 forms') }}
    </template>
    <template #cta_title>
      {{ $t('Fill') }} {{ form_count }}  {{ $t('forms') }}
    </template>
  </form-detail-bulk-update-cta-button>
  <form-detail-bulk-update-cta-button v-else-if="is_visible && is_same_step" :disabled="false" class="bg-primary-25 border border-primary-200 rounded-lg px-6 py-4" @click="openFormBulkUpdatePopup">
    <template #title>
      {{ $t('Bulk fill') }} {{ form_count }} {{ $t('forms') }}
    </template>
    <template #subtitle>
      {{ $t('Fill multiple forms at the same time') }}
    </template>
    <template #info>
      <div v-if="selected_forms.length !== non_submitted_forms.length" class="text-xs text-gray-600 mt">
        <span class="font-semibold">{{ $t('Note') }}</span>: {{ $t('Ignoring') }} {{ selected_forms.length - non_submitted_forms.length }} {{ $t('already submitted forms') }}
      </div>
    </template>
    <template #cta_title>
      {{ $t('Fill') }} {{ form_count }}  {{ $t('forms') }}
    </template>
  </form-detail-bulk-update-cta-button>
  <form-detail-bulk-update-cta-button v-else-if="approval_forms.length && is_same_step" disabled class="bg-warning-25 border border-warning-200 rounded-lg px-6 py-4">
    <template #title>
      {{ $t('Bulk fill forms') }}
    </template>
    <template #subtitle>
      {{ approval_forms.length }} /{{ selected_forms.length }}  {{ $t('forms are pending for review. Deselect them in order to proceed') }}
    </template>
    <template #cta_title>
      {{ $t('Fill') }} {{ form_count }}  {{ $t('forms') }}
    </template>
  </form-detail-bulk-update-cta-button>
  <form-detail-bulk-update-cta-button v-else-if="(is_visible || approval_forms.length) && !is_same_step" disabled class="bg-warning-25 border border-warning-200 rounded-lg px-6 py-4">
    <template #title>
      {{ $t('Bulk fill forms') }}
    </template>
    <template #subtitle>
      {{ $t('Forms must belong to the same step to bulk fill') }}
    </template>
    <template #cta_title>
      {{ $t('Fill') }}  {{ $t('forms') }}
    </template>
  </form-detail-bulk-update-cta-button>
  <form-detail-bulk-update-cta-button v-else-if="!non_submitted_forms.length" hide-cta class="bg-success-25 border border-success-200 rounded-lg px-6 py-4">
    <template #status_icon>
      <HawkFeaturedIcon theme="light-circle" size="md" color="success" class="mr-4">
        <IconHawkCheck />
      </HawkFeaturedIcon>
    </template>
    <template #subtitle>
      <div class="text-sm text-gray-900">
        {{ $t('All') }} {{ selected_forms.length - non_submitted_forms.length }} {{ $t('forms') }} {{ $t('have been submitted.') }}
      </div>
    </template>
  </form-detail-bulk-update-cta-button>
</template>
