<script setup>
import { useRoute } from 'vue-router';
import { customAlphabet } from 'nanoid';
import dayjs from 'dayjs';
import { useForm } from '~/forms/composables/form-list-configuration';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormsStore } from '~/forms/store/forms.store';

const props = defineProps({
  on_submit: {
    type: Function,
    default: null,
  },
  on_cancel: {
    type: Function,
    required: false,
    default: () => ({}),
  },
  fields: {
    type: Array,
    default: () => ['Form Name', 'Template', 'Assignees', 'Checkbox', 'Start Date', 'Due Date', 'Tags', 'Category'],
  },
  modal_options: {
    type: Object,
    default: () => ({}),
  },
  event_properties: {
    type: Object,
    default: () => ({}),
  },
  form_data: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);

const nanoid = customAlphabet(
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890',
  11,
);
const $track_event = inject('$track_event');

const is_loading = ref(false);
const filter_form_template_store = useFormTemplatesStore('form-filter-all-templates');
const form_store = useFormsStore();
const common_store = useCommonStore();
const auth_store = useAuthStore();
const form_template_store = useFormTemplatesStore();
const route = useRoute();
const { formatLoadedData } = useForm();
const forms$ = ref(null);
const form = ref(null);

const get_template_uid = computed(() => route.params.template_uid || props.form_data?.template_uid);
const get_category_uid = computed(() => form_store.form_template.find(template => template.uid === (get_template_uid.value || form.value.template_uid))?.category);
async function formMounted(form$) {
  form$.elements$.name.input.focus();
  if (get_template_uid.value)
    await form$.load({ template_uid: get_template_uid.value, category: get_category_uid.value ?? null });
  if (!form_store.form_template.length) {
    is_loading.value = true;
    await form_template();
    updateCategory();
    updateDueDate();
    is_loading.value = false;
  }
  if (props.form_data)
    await form$.load(props.form_data);
  if (props.fields.includes('Prefix feature name')) {
    form.value.prefix_feature_name = true;
    await form$.load(form.value);
  }
}

function updateCategory() {
  const category = filter_form_template_store.form_templates_map[get_template_uid?.value || form.value?.template_uid]?.category;
  form.value.category = category;
}
function updateDueDate() {
  const days_required = filter_form_template_store.form_templates_map[get_template_uid?.value || form.value?.template_uid]?.days_required;
  const due_date = days_required && dayjs().add(days_required, 'day').toDate();
  form.value.due_date = due_date;
}
function onTemplateChangeHandler(new_val, old_val, el$) {
  updateCategory();
  updateDueDate();
}
async function onSave() {
  const { name, category, tags, start_date, due_date, template_uid, assignees, prefix_feature_name } = forms$.value.data;
  const payload = {
    forms: {
      add: [{
        name, uid: nanoid(), prefix_feature_name, template: template_uid, category, created_at: new Date().toISOString(), start_date: start_date ? new Date(start_date).toISOString() : undefined, due_date: due_date ? new Date(due_date).toISOString() : undefined, tags, assignees: assignees.map(assignee => assignee.uid),
      }],
    },
  };
  await props.on_submit(payload);
}
async function form_template() {
  const query = {
    isChild: false,
    public: false,
    status: 'published',
    exclude_sections: true,
    asset_uid: !route?.params?.asset_id ? 'Organization' : route?.params?.asset_id,
  };
  await filter_form_template_store.set_form_templates({ query });
}
const template_list = computed(() => {
  if (route.params.template_uid)
    return [...filter_form_template_store.form_templates, ...[form_template_store?.form_templates_map[route.params.template_uid]]];
  else
    return filter_form_template_store.form_templates.sort((form1, form2) => form1?.name?.localeCompare(form2.name));
});

const has_template_access = computed(() => {
  return route.params.template_uid ? template_list.value.find(template => template?.uid === route.params.template_uid) : true;
});

const form_template_asset_list = computed(() => {
  const assetMap = template_list.value.filter(template => template?.can_create_forms)
    .reduce((final, form) => {
      if (!final[form.target_element.asset])
        final[form.target_element.asset] = [];
      final[form.target_element.asset].push(form);
      return final;
    }, {});
  const asset_template_list = Object.keys(assetMap)
    .map((asset_id) => {
      return {
        name: common_store?.get_asset(asset_id)?.name,
        children: assetMap[asset_id],
      };
    });
  const template_asset_list = asset_template_list
    .map((asset) => {
      return {
        asset_name: asset.name,
        label: asset.name || auth_store.current_organization?.name,
        items: asset.children.filter(form => form.name).map((form) => {
          return { name: form.name, uid: form.uid };
        }),
      };
    }).sort((a, b) => a.asset_name?.localeCompare?.(b.asset_name));
  return template_asset_list;
});

function setCreatedCategory(e) {
  forms$.value.elements$.category.load(e.uid);
}
function setCreatedTag(e) {
  forms$.value.elements$.tags.load([...forms$.value.data.tags, e.uid]);
}
</script>

<template>
  <hawk-modal-container :options="modal_options">
    <Vueform
      ref="forms$"
      v-model="form"
      sync size="sm" :display-errors="false"
      :presets="['']" :float-placeholders="false" class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="onSave" :columns="{
        default: { container: 9 },
        sm: { container: 9, label: 2 },
        md: { container: 9, label: 4, wrapper: 11 },
      }"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close'), on_cancel()">
          <template #title>
            {{ $t("New Form") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="!pb-0">
          <template v-for="field in fields">
            <TextElement
              v-if="field === 'Form Name'"
              :key="field"
              class="pb-4"
              autocomplete="off"
              :label="$t('Name')"
              name="name"
              :rules="`${['required']}`"
              :placeholder="`${$t('Enter form name')}`"
            />
            <!-- Templates -->
            <div v-if="field === 'Template'" :key="field" tabindex="-1">
              <SelectElement
                class="mb-4"
                name="template_uid"
                rules="required"
                :label="$t('template')"
                :loading="is_loading"
                :placeholder="has_template_access ? $t('Choose template') : '-'"
                track-by="name"
                label-prop="name"
                :info="has_template_access || is_loading ? null : 'Access to this template is not available'"
                value-prop="uid"
                :items="form_template_asset_list"
                :disabled="get_template_uid ? true : false"
                :clear-on-search="true"
                :close-on-select="true"
                :can-deselect="true"
                :groups="true"
                :hide-selected="false"
                :search="true"
                @change="onTemplateChangeHandler"
              >
                <template #option="{ option }">
                  {{ option.name }}
                </template>
                <template #tag="{ option }">
                  {{ option.name }}
                </template>
              </SelectElement>
            </div>
            <!-- Assignees -->
            <hawk-assignee-input
              v-if="field === 'Assignees'"
              :key="field"
              class="mb-6"
              :options="{
                name: 'assignees',
                label: $t('Assignees'),
                has_teams: true,
                placeholder: $t('Select assignees'),
              }"
              :truncate_tag_length="20"
              :multi="true"
            />
            <CheckboxElement
              v-if="field === 'Checkbox'"
              :key="field"
              class="my-8 text-[#004EEB] text-bold"
              :add-class="{
                input: 'hidden',
              }"
              name="advance_options"
            >
              <div class="flex items-center text-[#004EEB] font-semibold">
                <icon-hawk-chevron-right v-if="form && !form.advance_options" class="w-6 h-8 text-[#004EEB]" /><icon-hawk-chevron-down v-else class="w-6 h-8 text-[#004EEB]" />
                {{ $t('Advanced options') }}
              </div>
            </CheckboxElement>
            <!-- Start Date -->
            <DateTimeElement
              v-if="field === 'Start Date'"
              :key="forms$?.data?.due_date"
              :options="{
                format: 'dd/MM/yyyy',
                placeholder: $t('Select start date'),
                startTime: { hours: 0, minutes: 0 },
                maxDate: forms$?.data?.due_date ? new Date(forms$.data.due_date).toJSON().slice(0, 10) : null,
              }"
              :conditions="[
                ['advance_options', true],
              ]" class="mb-4" name="start_date"
              :label="$t('Start date')"
            />
            <!-- Due Date -->
            <DateTimeElement
              v-if="field === 'Due Date'"
              :key="forms$?.data?.start_date"
              :options="{
                format: 'dd/MM/yyyy',
                placeholder: $t('Select due date'),
                startTime: { hours: 23, minutes: 59 },
                minDate: forms$?.data?.start_date ? new Date(forms$.data.start_date) : null,
              }"
              :conditions="[
                ['advance_options', true],
              ]" class="mb-4" name="due_date"
              :label="$t('Due date')"
            />
            <!-- Tags -->
            <hawk-tags-input
              v-if="field === 'Tags'"
              :key="field"
              class="mb-4"
              :options="{
                name: 'tags',
                label: $t('tags'),
                placeholder: $t('Select tags'),
                conditions: [
                  ['advance_options', true],
                ],
                create: true,
              }"
              :setter_function="setCreatedTag"
            />
            <!-- Category -->
            <hawk-category-input
              v-if="field === 'Category'"
              :key="field"
              class="mb-4"
              :options="{
                name: 'category',
                label: $t('Category'),
                placeholder: $t('Select category'),
                create: true,
                from: 'Forms',
              }"
              :setter_function="setCreatedCategory"
              :conditions="[
                ['advance_options', true],
              ]"
            />
            <!-- Prefix Feature Name -->
            <CheckboxElement
              v-if="field === 'Prefix feature name'"
              :key="field"
              name="prefix_feature_name"
              class="mb-6"
              :conditions="[
                ['advance_options', true],
              ]"
              :default="true"
              :label="$t('Prefix feature name')"
            />
          </template>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button
                class="mr-4 font-bold"
                type="outlined"
                @click="$emit('close'), on_cancel()"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement v-if="has_template_access" button-class="w-full bg-blue-600" name="submit" :submits="true" data-dd-action-name="form-create">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
