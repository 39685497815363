import MaskPlugin from '@vueform/plugin-mask';
import { Validator } from '@vueform/vueform';
import de from '@vueform/vueform/locales/de';
import en from '@vueform/vueform/locales/en';
import es from '@vueform/vueform/locales/es';
import ja from '@vueform/vueform/locales/ja';
import pt from '@vueform/vueform/locales/pt';
import tailwind from '@vueform/vueform/themes/tailwind';

import HawkUploadFileItem from '~/common/components/atoms/hawk-upload-file-item.vue';
import CheckboxTristateElement from '~/common/components/vueform/CheckboxTristateElement.vue';
import CurrencyElement from '~/common/components/vueform/CurrencyElement.vue';
import DateTimeElement from '~/common/components/vueform/DateTimeElement.vue';
import DirectoryUpload from '~/common/components/vueform/DirectoryUpload.vue';
import DMSUploadElement from '~/common/components/vueform/DMSUploadElement.vue';
import FormFieldTreeElement from '~/common/components/vueform/FormFieldTreeElement.vue';
import MembersElement from '~/common/components/vueform/MembersElement.vue';
import QuantityElement from '~/common/components/vueform/QuantityElement.vue';
import SheetElement from '~/common/components/vueform/SheetElement.vue';

import SignatureElement from '~/common/components/vueform/SignatureElement.vue';
import VPickTime from '~/common/components/vueform/v-pick-time.vue';
import VueFormDragAndDrop from '~/common/components/vueform/vueform-drag-and-drop.vue';
import VueFormElementInfo from '~/common/components/vueform/vueform-element-info.vue';
import VueFormElementLabel from '~/common/components/vueform/vueform-element-label.vue';

import VueFormFileElement from '~/common/components/vueform/vueform-file-element.vue';
import VueFormListElement from '~/common/components/vueform/vueform-list-element.vue';
import VueFormMultiFileComponent from '~/common/components/vueform/vueform-multifile.vue';
import VueFormMultifileElement from '~/common/components/vueform/vueform-multifile-element.vue';
import VueformTextElement from '~/common/components/vueform/vueform-text-element.vue';
import VueFormTextarea from '~/common/components/vueform/vueform-textarea.vue';
import WysiwygEditorElement from '~/common/components/vueform/WysiwygEditorElement.vue';

import VueFormDragAndDropPlugin from '~/common/installers/vueform/vueform-drag-and-drop.js';
import VueFormFileElementPlugin from '~/common/installers/vueform/vueform-file-element.js';
import VueFormFilePreview from '~/common/installers/vueform/vueform-filepreview.js';
import { isFileExtensionAllowed } from '~/common/utils/common.utils';
// Custom Plugins
import VueFormInitialiser from '~/common/installers/vueform/vueform-initialiser.js';

import VueFormMultiFile from '~/common/installers/vueform/vueform-multifile.js';
import VueFormUploadLoader from '~/common/installers/vueform/vueform-upload-loader.js';

const no_blacklist_extensions = class extends Validator {
  get message() {
    return 'Unsupported file extension';
  }

  check(value) {
    if (Array.isArray(value))
      return value.every(this.checkFile);
    return this.checkFile(value);
  }

  checkFile(value) {
    if (value?.has_unsupported_extension)
      return false;
    else if (!(value instanceof File) || !value?.name)
      return true;
    return isFileExtensionAllowed(value.name, true);
  }
};

const ButtonElementClasses = {
  button: '!scale-100 !font-semibold !opacity-100',
  button_sm: '!h-9',
  button_primary: '!text-white !bg-primary-600 hover:!bg-primary-700 !border-primary-600 hover:!border-primary-700 focus:!outline-primary-100 focus:!ring-4 focus:!ring-primary-100 disabled:!bg-primary-200 aria-disabled:!bg-primary-200 disabled:!border-primary-200 aria-disabled:!border-primary-200',
  button_secondary: '!bg-white !border-gray-300 !text-gray-700 hover:!text-gray-800 hover:!bg-gray-50 focus:!outline-gray-100 focus:!ring-4 focus:!ring-gray-100 !shadow-sm disabled:!border-gray-200 aria-disabled:!border-gray-200 disabled:!text-gray-300 aria-disabled:!text-gray-300 disabled:hover:!bg-white aria-disabled:hover:!bg-white',
  button_danger: '!bg-red-600 !border-red-600 hover:!bg-red-700 hover:!border-red-700 focus:!outline-red-100 disabled:!bg-red-200 aria-disabled:!bg-red-200 disabled:!border-red-200 aria-disabled:!border-red-200',
};

const MultifileElementClasses = {
  button: 'inline-flex items-center border font-medium shadow-sm relative hawk-button rounded-lg focus:outline-none !border-gray-300 text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-800 focus:ring-4 focus:ring-gray-100 px-3 py-2 text-sm h-9 mr-4',
};
const TextelementAndTextareaClasses = {
  input: 'rounded-lg text-gray-900 placeholder:text-gray-500',
  inputContainer: 'rounded-lg',
  inputContainer_disabled: '!bg-gray-100',
  input_disabled: '!text-gray-400',
  input_success: 'text-gray-900 focus:text-gray-900 focus:outline-4 outline-primary-100 focus:outline-primary-100 border-0 focus:border-0',
  inputContainer_success: 'text-gray-900 focus:text-gray-900 focus:outline-4 outline-primary-100 focus:outline-primary-100 border border-gray-300 focus:border focus:!border-primary-600',
  inputContainer_focused: '!border-primary-600 focus:!border-primary-600',
  input_danger: 'text-gray-900 focus:text-gray-900 focus:!outline-4',
  inputContainer_danger: 'text-gray-900 !outline-red-100 focus:!outline-red-100 focus:text-gray-900 focus:outline-4 !border !border-red-600',
};
const RadioElementClasses = {
  input_default: 'checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500',
  input_disabled: 'checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500',
  input_danger: 'checked:!bg-red-50 checked:after:!bg-red-500 checked:!border-red-500',
  text: '!text-gray-700',
};
const RadioGroupElementClasses = {
  wrapper_unselected: 'focus:!border-primary-500',
  wrapper_selected: '!bg-primary-50 !border-primary-500 !text-black !border-1',
  input_default: 'checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500',
  input_disabled: 'checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500',
  input_danger: 'checked:!bg-red-50 checked:after:!bg-red-500 checked:!border-red-500',
};
const ToggleElementClasses = {
  toggle: {
    toggle_sm: '!w-8',
    toggleOff: '!border-gray-100 !bg-gray-100 hover:!bg-gray-200 hover:!border-gray-200',
    toggleOn: '!border-primary-600 !bg-primary-600 hover:!bg-primary-700 hover:!border-primary-700',
    toggleOnDisabled: '!bg-gray-200 !border-gray-200',
  },
};
const SelectElementClasses = {
  select: {
    caret: 'select-caret !h-4 !w-4 !bg-gray-600',
    container: '!min-h-[36px] focused:!rounded-lg focused:!border-primary-600',
    dropdown: '!rounded-lg',
    dropdownTop: 'mb-2 -mt-1',
    options: '!p-1 text-sm',
    option: '!min-h-[40px] !rounded-lg !px-2 hover:!bg-gray-50 hover:text-gray-700',
    optionSelected: '!bg-gray-100 hover:!bg-gray-50 !text-gray-700',
    optionSelectedPointed: '!bg-gray-100 hover:!bg-gray-50 !text-gray-700',
    optionSelectedDisabled: '!bg-gray-100 hover:!bg-gray-50 !text-gray-500',
    optionDisabled: '!bg-gray-100 hover:!bg-gray-50 !text-gray-500 hover:!text-gray-500',
    container_danger: '!border-red-600',
  },
};

const CheckboxgroupClasses = {
  wrapper_unselected: '!text-gray-700',
  wrapper_selected: '!bg-gray-100 !text-gray-900 ',
  wrapper: '!border-gray-300 font-semibold !border-1',
  input_default: 'checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500',
  input_disabled: 'checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500',
};

const TagsElementClasses = {
  select: {
    caret: 'select-caret !h-4 !w-4 !bg-gray-600',
    container: '!min-h-[36px] !rounded-lg focused:!rounded-b-lg !rounded-b-lg focused:!border-primary-500',
    dropdown: '!rounded-t-lg text-sm',
    tag: '!bg-transparent !text-gray-700 !font-normal !text-sm !border-gray-300 !leading-relaxed',
    tagRemove: 'text-gray-400 !p-0.5 !rounded hover:!bg-gray-100 !ml-1',
    options: '!p-1 text-sm',
    option: '!min-h-[40px] !rounded-lg !px-2 hover:!bg-gray-50 hover:text-gray-700',
    optionSelected: '!bg-gray-100 hover:!bg-gray-50 !text-gray-700',
    optionSelectedPointed: '!bg-gray-100 hover:!bg-gray-50 !text-gray-700',
    tagsSearchWrapper: '!ml-0',
  },
};

const DateElementClasses = {
  inputContainer: 'rounded-lg focused:!border-primary-600',
};

export default {
  classHelpers: true,
  theme: tailwind,
  locales: { en, es, ja, pt, de },
  rules: {
    no_blacklist_extensions,
  },
  elements: [
    VPickTime,
    DMSUploadElement,
    MembersElement,
    SignatureElement,
    QuantityElement,
    CurrencyElement,
    WysiwygEditorElement,
    DateTimeElement,
    FormFieldTreeElement,
    SheetElement,
    CheckboxTristateElement,
  ],
  presets: {
    default: {
      columns: {
        default: { container: 12, label: 12, wrapper: 12 },
        sm: { container: 12, label: 6, wrapper: 12 },
      },
      addClasses: {
        ElementError: { container: 'flex !text-red-700' },
        ButtonElement: ButtonElementClasses,
        MultifileElement: MultifileElementClasses,
        RadioElement: RadioElementClasses,
        RadiogroupRadio: RadioGroupElementClasses,
        CheckboxElement: RadioElementClasses,
        CheckboxgroupRadio: RadioElementClasses,
        ToggleElement: ToggleElementClasses,
        SelectElement: SelectElementClasses,
        MultiselectElement: SelectElementClasses,
        CheckboxgroupCheckbox: CheckboxgroupClasses,
        TagsElement: TagsElementClasses,
        TextElement: TextelementAndTextareaClasses,
        TextareaElement: TextelementAndTextareaClasses,
        DateElement: DateElementClasses,
      },
      removeClasses: {
        SelectElement: {
          select: {
            dropdownTop: ['!rounded-b-none'],
          },
        },
        MultifileElement: {
          button: 'inline-block transition form-border-width-btn focus:outline-zero',
          button_md: 'form-p-btn form-radius-btn form-text',
          button_enabled: 'form-bg-btn-secondary form-color-btn-secondary form-border-color-btn-secondary form-shadow-btn cursor-pointer transition-transform transform hover:scale-105 focus:form-ring',
        },
        ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm', container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm' },
      },
      templates: {
        ElementLabel: VueFormElementLabel,
        FilePreview: HawkUploadFileItem,
        MultifileElement: VueFormMultiFileComponent,
        ElementInfo: VueFormElementInfo,
        ListElement: VueFormListElement,
        TextElement: VueformTextElement,
      },
    },
    select_top_direction: {
      removeClasses: {
        SelectElement: {
          select: {
            dropdown: ['!-bottom-1', 'translate-y-full', '-mt-px'],
          },
        },
      },
    },
    hawk_file_element: {
      templates: {
        FileElement: VueFormFileElement,
        MultifileElement: VueFormMultifileElement,
        DragAndDrop: VueFormDragAndDrop,
      },
    },
    upload_directory: {
      templates: {
        MultifileElement: DirectoryUpload,
      },
    },
    text_area_without_enter: {
      templates: {
        TextareaElement: VueFormTextarea,
      },
    },
    disable_error_messages: {
      addClasses: { ElementError: { container: 'hidden' } },
    },
    // TODO: improve preset, remove outline for inputs
    supress_errors: {
      addClasses: { ElementError: { container: 'hidden' } },
      // removeClasses: {
      //   TextElement: {
      //     input: ['inputContainer_danger'],
      //   },
      // },
      // replaceClasses: {
      //   TextElement: {
      //     input_danger: 'border',
      //     inputContainer_danger: 'border',
      //   },
      // },
      overrideClasses: {
        TextElement: {
          input: {
            input_danger: 'static-display-disabled',
          },
        },
      },
    },
    static_display: {
      addClasses: {
        ElementLabel: {
          wrapper: '!text-gray-700 !font-medium',
        },
        ElementError: { container: '!text-red-700' },
        TextElement: {
          inputContainer: 'static-display',
          input: 'static-display-focused',
        },
        TagsElement: {
          select: {
            container: 'static-display',
            wrapper: 'static-display-focused',
            tags: 'no-padding',
            container_disabled: 'static-display-disabled',
          },
        },
        DateElement: {
          inputContainer: 'static-display ',
          input: 'static-display-focused',
        },
        SelectElement: {
          select: {
            container: 'static-display',
            wrapper: 'static-display-focused',
            container_disabled: 'static-display-disabled',
          },
        },
      },
      overrideClasses: {
        TagsElement: {
          select: {
            container_disabled: 'static-display-disabled',
          },
        },
        SelectElement: {
          select: {
            container_disabled: 'static-display-disabled',
          },
        },
        DateElement: {
          inputContainer_disabled: 'static-display-disabled',
        },
        EditorElement: {
          input_disabled: 'static-display-disabled',
        },
      },
    },
    repeatable_list: {
      removeClasses: {
        ListElement: {
          add: [
            'form-bg-primary',
            'form-border-color-primary',
            'form-color-on-primary',
            'form-border-width-btn',
            'hover:scale-105',
            'focus:form-ring',
          ],
          remove: ['form-bg-passive', 'rounded-full', '-translate-x-1/2', 'p-0.5', '-translate-y-1/2', 'w-4', 'h-4', 'left-0', 'list-group-hover:opacity-100', 'opacity-0', 'top-px', 'absolute'],
          removeIcon: ['mask-form-remove-light', 'form-bg-passive-color'],
        },
      },
      addClasses: {
        ListElement: {
          list: ['list_container'],
          add: ['text-gray-600', 'font-semibold', 'text-sm'],
          remove: ['h-5 w-5 ml-auto !mt-2'],
          removeIcon: ['delete-list-icon'],
        },
      },
    },
    repeatable_drag_icon: {
      removeClasses: {
        ListElement: {
          handleIcon: ['form-bg-passive-color', 'mask-bg', 'mask-form-sort-handle'],
          handle: ['opacity-0', '-translate-x-full'],
          remove: ['z-999'],
        },
      },
      addClasses: {
        ListElement: {
          add: ['!text-blue-600', 'font-semibold', 'text-sm', '!mt-6', 'hover:bg-blue-50'],
          handleIcon: ['drag-handle-icon', 'bg-gray-400'],
          handle: ['-translate-x-[80%]'],
        },
      },
    },
    focused_underline: {
      removeClasses: {
        SelectElement: {
          select: {
            container: 'focused:!border-primary-600',
            container_default: 'focused:form-ring',
            containerActive: 'form-focus',
          },
        },
        TagsElement: {
          select: {
            container: 'focused:!border-primary-600',
            container_default: 'focused:form-ring',
            containerActive: 'form-focus',
          },
        },
      },
      addClasses: {
        TextElement: {
          inputContainer: '!border-transparent vueform-input-bottom-underline-focused',
        },
        SelectElement: {
          select: {
            container: '!border-transparent !ring-0',
            containerActive: 'vueform-input-bottom-underline-focused',
          },
        },
        TagsElement: {
          select: {
            container: '!border-transparent !ring-0',
            containerActive: 'vueform-input-bottom-underline-focused',
          },
        },
      },
      overrideClasses: {
        TextElement: {
          inputContainer_focused: 'ring-0',
        },
      },
    },
    underline: {
      removeClasses: {
        SelectElement: {
          select: {
            container: 'focused:!border-primary-600',
            container_default: 'focused:form-ring',
            containerActive: 'form-focus',
          },
        },
        TagsElement: {
          select: {
            container: 'focused:!border-primary-600',
            container_default: 'focused:form-ring',
            containerActive: 'form-focus',
          },
        },
      },
      addClasses: {
        TextElement: {
          inputContainer: '!border-transparent vueform-input-bottom-underline',
        },
        SelectElement: {
          select: {
            container: '!border-transparent !ring-0 vueform-input-bottom-underline',
            containerActive: 'vueform-input-bottom-underline-focused',
          },
        },
        TagsElement: {
          select: {
            container: '!border-transparent !ring-0 vueform-input-bottom-underline',
            containerActive: 'vueform-input-bottom-underline-focused',
          },
        },
      },
      overrideClasses: {
        TextElement: {
          inputContainer_focused: 'ring-0 vueform-input-bottom-underline-focused',
        },
      },
    },
    xs_variant: {
      removeClasses: {
        SelectElement: {
          select: {
            option: '!min-h-[40px]',
          },
        },
        TagsElement: {
          select: {
            option: '!min-h-[40px]',
          },
        },
      },
      addClasses: {
        SelectElement: {
          select: {
            option: 'text-xs !min-h-[30px]',
          },
        },
        TagsElement: {
          select: {
            tagWrapper: 'text-xs',
            option: 'text-xs !min-h-[30px]',
          },
        },
      },
    },
  },
  usePresets: ['default'],
  plugins: [
    VueFormFilePreview,
    VueFormInitialiser,
    VueFormMultiFile,
    VueFormFileElementPlugin,
    VueFormUploadLoader,
    VueFormDragAndDropPlugin,
    MaskPlugin,
  ],
  addClasses: {
    ElementLabel: {
      container: 'text-gray-700 font-medium',
    },
    RadiogroupElement: {
      wrapper: 'text-gray-700',
    },
    RadiogroupRadio: {
      input: 'mb-2',
    },
  },
  floatPlaceholders: false,
};
