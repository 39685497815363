import { cloneDeep } from 'lodash-es';
/* eslint-disable no-undef */
import { defineStore } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import ApprovalIcon from '../assets/icons/approval.svg';
import ConditionalIcon from '../assets/icons/conditional.svg';
import EmailIcon from '../assets/icons/email.svg';
import EndBlockIcon from '../assets/icons/end-block.svg';
import FormStepIcon from '../assets/icons/form-step.svg';
import UpdateFeatureIcon from '../assets/icons/update-feature.svg';
import UploadFilesIcon from '../assets/icons/upload-files.svg';

export function useFormWorkflowStore(key) {
  const { $t } = useCommonImports();

  return defineStore(key || 'form-workflow', {
    state: () => ({
      graph: null,
      paper: null,
      panzoom: null,
      workflow: null,
      selected_node: null,
      new_node: null,
      blocks: [],
      add_btn_trigger: false,
      rule_id_to_open: null,
      selected_rule_node: null,
      tree: null,
      map_icons: {
        step: FormStepIcon,
        approvalFlow: ApprovalIcon,
        conditionalLogic: ConditionalIcon,
        sendEmail: EmailIcon,
        uploadFiles: UploadFilesIcon,
        updateFeature: UpdateFeatureIcon,
        end: EndBlockIcon,
      },
      addButtonTool: null,
      disable_outside_click: false,
      is_sidebar_form_dirty: false,
    }),
    getters: {
      getBlockByNodeId(state) {
        return state.blocks.reduce((acc, cur) => {
          acc[cur.properties.node_id] = cur;
          return acc;
        }, {});
      },
      getNodeIdByBlockId(state) {
        return state.blocks.reduce((acc, cur) => {
          acc[cur.uid] = cur.properties.node_id;
          return acc;
        }, {});
      },
      getBlockByUid(state) {
        return state.blocks.reduce((acc, cur) => {
          acc[cur.uid] = cur;
          return acc;
        }, {});
      },
      block_step(state, getters) {
        return (
          getters.getBlockByNodeId[state.selected_node?.model?.id]?.step ?? -1
        );
      },
      form_blocks(state) {
        return state.blocks.reduce((acc, cur) => {
          if (cur.type === 'step')
            acc.push(cur.step);
          return acc;
        }, []);
      },
      is_initial_block_saved(state) {
        if (state.blocks.length === 1) {
          const block = state.blocks[0];
          if (block?.properties?.step === 1) {
            const node = this.paper.findViewByModel(block?.properties?.node_id);
            const children = this.graph.getNeighbors(node.model, { outbound: true });
            return !!children?.length;
          }
          return true;
        }
        return true;
      },
    },
    actions: {
      getSteps(step_number) {
        const block = this.blocks.find(val => val.step === step_number);
        if (block) {
          let curr = block;
          const steps = [1];
          while (curr && !curr.initial_block) {
            if (!steps.includes(curr.step))
              steps.push(curr.step);
            curr = this.getBlockByUid[curr.source];
          }
          return steps;
        }
        return [step_number];
      },
      get_all_fields() {
        const fields = [];
        Object.values(useFormTemplateDetailStore().steps_with_sections || {}).forEach((step) => {
          if (this.form_blocks.includes(step.index)) {
            step.sections.forEach((section) => {
              if (section.type === 'default') {
                fields.push(
                  ...(
                    section.fields?.filter(
                      field => field.type !== 'group' && field.status === 'active',
                    ) || []
                  ).map((field) => {
                    return {
                      step: {
                        name: step.name,
                        index: step.index,
                      },
                      section_name: section.name,
                      section_id: section.uid,
                      section_type: section.type,
                      form_block_name: step.name,
                      form_block_id: `block_${step.index}`,
                      ...field,
                    };
                  }),
                );
              }
            });
          }
        });

        return fields;
      },
      get_fields(step_number, specific_fields = [], section_types = ['default']) {
        const fields = [];
        const steps = this.getSteps(step_number);
        Object.values(useFormTemplateDetailStore().steps_with_sections || {}).forEach((step) => {
          if (
            steps.includes(step.index)
            && (this.form_blocks.includes(step.index))
          ) {
            step.sections.forEach((section) => {
              if (section_types.includes(section.type)) {
                fields.push(
                  ...(
                    section.fields?.filter(
                      field =>
                        (specific_fields.includes(field.type)
                          || specific_fields.length === 0)
                          && field.type !== 'group'
                          && field.status === 'active',
                    ) || []
                  ).map((field) => {
                    return {
                      step: {
                        name: step.name,
                        index: step.index,
                      },
                      section_name: section.name,
                      section_id: section.uid,
                      section_type: section.type,
                      form_block_name: step.name,
                      form_block_id: `block_${step.index}`,
                      ...field,
                    };
                  }),
                );
              }
            });
          }
        });
        return fields;
      },
      get_document_generate_blocks() {
        const block = this.getBlockByNodeId[this.selected_node?.model?.id];
        let parent_id = null;
        if (block)
          parent_id = this.getBlockByUid[block.source]?.properties?.node_id;
        else
          parent_id = this.new_node.parent_id;

        let parent_block = this.getBlockByNodeId[parent_id];
        const document_generation_blocks = [];
        while (parent_block?.source) {
          if (parent_block.type === 'documentGeneration')
            document_generation_blocks.push(parent_block);
          parent_block = this.getBlockByUid[parent_block.source];
        }
        return document_generation_blocks.slice().reverse();
      },
      async deleteStep(step_index) {
        try {
          await this.$services.forms_templates.delete({
            body: {
              stepIndex: step_index,
            },
            attribute: `${this.workflow.form}/workflows/steps`,
            toast: false,
          });
          const template = useFormTemplateDetailStore().form_template_detail;
          delete template.steps[step_index];
          useFormTemplateDetailStore().form_template_detail = template;
        }
        catch (err) {
          logger.log('🚀 ~ file: form-workflow.store.js:115 ~ deleteStep ~ ̥:', err);
        }
      },
      async cloneStep({ payload }) {
        try {
          if (this.workflow.status === 'published')
            payload.source = await this.createVersion({ payload, action: 'clone-step' });

          const { data } = await this.$services.forms_templates.post(
            {
              attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks/duplicate`,
              body: payload,
            },
          );
          await this.updateSelectedNode({
            ...payload.properties,
            block_type: 'step',
            create: true,
          });
          this.selected_node.model.attr('body', {
            stroke: '#E1E2E7',
            strokeDasharray: '0,0',
            strokeWidth: 1,
          });
          await this.updateFormWorkflow({
            payload: {
              properties: {
                workflow_structure: this.graph.toJSON(),
              },
            },
          });
          this.selected_node.model.attr('body', {
            stroke: '#1676EE',
            strokeDasharray: '10,4',
            strokeWidth: 2,
          });
          this.new_node = null;
          this.blocks = [...this.blocks, data.block];
          return data.block;
        }
        catch (err) {
          this.$toast({ text: err?.data?.message || $t('Block cloning failed!'), type: 'error' });
          throw err;
        }
      },
      async createFormWorkflow(form_uid) {
        try {
          const { data } = await this.$services.forms_templates.post(
            {
              attribute: `${form_uid}/workflows`,
            },
          );

          this.workflow = data.workflow;

          this.$toast({
            title: $t('Workflow template created'),
            text: $t('The workflow template has been successfully activated.'),
            type: 'success',
          });
        }
        catch (err) {
          this.$toast({
            title: $t('Failed to create workflow template'),
            text: $t('Unable to create workflow template. Please try again.'),
            type: 'error',
          });
          throw err;
        }
      },
      async getFormWorkflow(options) {
        const { data } = await this.$services.forms_templates.getAll({
          attribute: `${options.form_id}/workflows/${options.workflow_id}`,
        });
        this.workflow = data.workflow;
      },
      async updateFormWorkflow(req) {
        const { data } = await this.$services.forms_templates.patch({
          attribute: `${this.workflow.form}/workflows/${this.workflow.uid}`,
          body: req.payload,
        });
        this.workflow = data.workflow;
      },
      async createVersion(options = {}) {
        const res = await this.$services.forms_templates.post({
          attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/duplicate`,
        });
        this.workflow = res.data.workflow;
        this.blocks = this.blocks.map((block) => {
          block.uid = res.data.uidMap[block.uid];
          block.source = res.data.uidMap[block.source];
          return block;
        });
        await useFormTemplateDetailStore().set_form_template({ id: this.workflow.form });
        if (options.action === 'create') {
          options.payload.source = res.data.uidMap[options.payload.source];
          if (options.payload.type === 'rollback') {
            const jump_to = options.payload.properties.jump_to;
            options.payload.properties.jump_to = jump_to ? res.data.uidMap[options.payload.properties.jump_to] : null;
          }

          await this.createBlock({ ...options });
        }
        else if (options.action === 'delete') {
          options.block_id = res.data.uidMap[options.block_id];
          await this.deleteBlock({ ...options });
        }
        else if (options.action === 'clone-step') {
          return res.data.uidMap[options.payload.source];
        }
      },
      async getAllBlocks() {
        const res = await this.$services.forms_templates.getAll({
          attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks`,
        });
        this.blocks = res.data.blocks;
      },
      async getAffectedMetrics({ block_uid }) {
        const res = await this.$services.forms_templates.getAll({
          attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks/${block_uid}/affected-forms`,
        });
        return res.data;
      },
      async createBlock({ payload }) {
        if (this.workflow.status === 'published') {
          await this.createVersion({ payload, action: 'create' });
        }
        else {
          const { data } = await this.$services.forms_templates.post(
            {
              attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks`,
              body: payload,
            },
          );
          this.blocks = [...this.blocks, data.block];
        }
      },
      async updateBlock({ payload, block_id }) {
        const { data } = await this.$services.forms_templates.patch({
          attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks/${block_id}`,
          body: payload,
        });
        this.blocks = [
          ...this.blocks.filter(block => block.uid !== block_id),
          data.block,
        ];
      },
      async deleteBlock({ payload, block_id }, fetch_all_blocks = true) {
        if (this.workflow.status === 'published') {
          await this.createVersion({
            payload,
            block_id,
            action: 'delete',
          });
        }
        else {
          await this.$services.forms_templates.delete(
            {
              attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks/${block_id}`,
              body: payload,
            },
            {
              type: 'success',
              text: 'Block deleted successfully!',
            },
          );
          if (fetch_all_blocks)
            this.getAllBlocks();
        }
      },
      async getSupportedBlocks({ block_id, query }) {
        const { data } = await this.$services.forms_templates.getAll({
          attribute: `${this.workflow.form}/workflows/${this.workflow.uid}/blocks/${block_id}/supported-blocks`,
          query,
        });
        return data.blocks;
      },
      /* has block and cell model params to delete a specific nodes else default deletes selected node */
      async deleteNode({ block = null, cell_model = null, payload = {} }) {
        const deleteNodesFromGraph = (node, graph) => {
          const children = graph.getSuccessors(node);
          children.forEach(child => child.remove());
          node.remove();
        };
        /* by default delete node tries to delete selected node */
        let node = this.selected_node?.model;
        if (cell_model?.model)
          node = cell_model.model;

        const parent_label_block = this.paper.model.getNeighbors(
          this.paper.findViewByModel(node.id).model,
          {
            inbound: true,
          },
        )[0];
        const concurrent_node
        = this.paper.model.getNeighbors(
          this.paper.findViewByModel(parent_label_block.id).model,
          {
            outbound: true,
          },
        ).length > 1;
        /* to delete a block associated with it */
        if (block?.uid) {
          try {
          /* using this node to add plus tool */
            if (!concurrent_node) {
              await this.addNode({
                block: { type: 'add' },
                link: {
                  source: parent_label_block.id,
                },
              });
            }
            deleteNodesFromGraph(node, this.graph);
            await this.deleteBlock({
              payload,
              block_id: block.uid,
            });
            await this.updateFormWorkflow({
              payload: {
                properties: {
                  workflow_structure: this.graph.toJSON(),
                },
              },
            });

            this.add_btn_trigger = true;
            this.refreshGraphLayout();
          }
          catch (err) {
            logger.log(err);
            this.loadFormWorkflowJson();
          }
        }
        else {
          /* checks if delete is happens for new node */
          this.new_node = null;
          this.loadFormWorkflowJson();
          deleteNodesFromGraph(node, this.graph);
          this.add_btn_trigger = true;
          this.refreshGraphLayout();
        }

        /* checks if delete node is selected node to set it null */
        if (node.id === this.selected_node?.model?.id)
          this.selected_node = null;
      },
      /* has payload and block params to update<=(contains block_id in data) or create node */
      async saveAndUpdateNode({ payload, data }) {
        const block_type = data.block_id
          ? this.getBlockByUid[data.block_id].type
          : payload.type;
        try {
          /* data contains block_id when we update block */
          if (data.block_id) {
            await this.updateBlock({
              payload,
              block_id: data.block_id,
            });
          }
          else {
            await this.createBlock({ payload });
            this.new_node = null;
          }
          /* updates the selected node details on graph */
          await this.updateSelectedNode({
            ...payload.properties,
            block_type,
            create: !data.block_id || !this.is_initial_block_saved,
          });

          await this.updateFormWorkflow({
            payload: {
              properties: {
                workflow_structure: this.graph.toJSON(),
              },
            },
          });
          this.loadFormWorkflowJson();
          this.selected_node = null;
        }
        catch (error) {
          if (block_type === 'end')
            this.loadFormWorkflowJson();
          logger.log(`Failed to ${data.block_id ? 'update' : 'save'} block`, error);
          throw error;
        }
      },
      /* called to load graph through JSON */
      loadFormWorkflowJson() {
        this.graph.fromJSON(this.workflow.properties.workflow_structure);
        /* After loaded from JSON trigger to set tool for add button tool */
        this.add_btn_trigger = true;
        this.refreshGraphLayout();
      },
      /* called during saving or updating node */
      async updateSelectedNode(
        { name, description, conditional_logic, configure_buttons, block_type, create },
      ) {
        /* updating the selected node on the graph using attr */
        if (name)
          this.selected_node.model.attr('label', { text: name });
        if (description)
          this.selected_node.model.attr('description', { text: description });
        if (create && block_type === 'step') {
          const label_rect = await this.addNode({
            block: { type: 'label', name: 'Submitted' },
            link: { source: this.selected_node.model.id },
            addButtonTool: this.addButtonTool,
          });
          await this.addNode({
            block: { type: 'add' },
            link: { source: label_rect.id },
            addButtonTool: this.addButtonTool,
          });
        }
        if (create && block_type === 'documentGeneration') {
          const label_rect = await this.addNode({
            block: { type: 'label', name: 'Done' },
            link: { source: this.selected_node.model.id },
            addButtonTool: this.addButtonTool,
          });
          await this.addNode({
            block: { type: 'add' },
            link: { source: label_rect.id },
            addButtonTool: this.addButtonTool,
          });
        }

        if (block_type === 'approvalFlow') {
          const neighbors = this.graph.getNeighbors(this.selected_node.model, { outbound: true });
          const approval_label_model = this.paper.findViewByModel(neighbors[0]);
          const reject_label_model = this.paper.findViewByModel(neighbors[1]);
          if (configure_buttons.is_enabled) {
            approval_label_model.model.attr('label', { text: configure_buttons.approve });
            reject_label_model.model.attr('label', { text: configure_buttons.reject });
          }
          else {
            approval_label_model.model.attr('label', { text: 'Approved' });
            reject_label_model.model.attr('label', { text: 'Rejected' });
          }
        }

        /* for conditional block */
        if (
          Object.values(conditional_logic || {}).length
          && block_type === 'conditionalLogic'
        ) {
          const rule_by_uid = cloneDeep(Object.values(conditional_logic).reduce(
            (acc, rule, ind) => {
              acc[rule.uid] = { ...rule, index_key: ind + 1 };
              return acc;
            },
            {},
          ));
          let fetch_all_blocks = false;
          const neighbors = this.graph
            .getNeighbors(this.selected_node.model, { outbound: true });
          for (let index = 0; index < neighbors.length; index++) {
            const label_node = neighbors[index];
            logger.log('label', label_node);
            const label_node_model = this.paper.findViewByModel(label_node);
            logger.log('🚀 ~ file: form-workflow.store.js:385 ~ .forEach ~ label_node_model:', label_node_model);
            if (label_node_model.model.get('type').includes('LinkLabelNode')) {
              const rule_id = label_node?.attributes?.attrs?.rule_id?.text;

              if (rule_id && rule_by_uid[rule_id]) {
                logger.log('🚀 ~ file: form-workflow.store.js:385 ~ .forEach ~ label_node_model:', rule_by_uid, rule_id);
                /* updating existing rule name */
                label_node_model.model.attr('label', {
                  text: `${rule_by_uid[rule_id].name}`,
                });
                label_node.set({
                  siblingRank: rule_by_uid[rule_id].index_key,
                });
                /* deleting once rule is updated */
                delete rule_by_uid[rule_id];
              }
              else {
                /* deleting block associated to rule as it is not present in current rules */
                const children = this.graph.getSuccessors(label_node);

                children.forEach(child => child.remove());
                const immediate_nodes = children.reduce((acc, curr) => {
                  const block = this.getBlockByNodeId[curr?.id];
                  if (block?.properties?.rule_id)
                    acc.push(block);
                  return acc;
                }, []);

                label_node.remove();
                fetch_all_blocks = fetch_all_blocks || (immediate_nodes.length > 0);

                if (immediate_nodes.length) {
                  await Promise.all(immediate_nodes.map(immediate => (this.deleteBlock({
                    block_id: immediate.uid,
                    payload: {},
                  }, false))));
                }
              }
            }
          }
          if (fetch_all_blocks)
            this.getAllBlocks();

          /* Adding rules as it they were not present in links */
          for (const rule_id in rule_by_uid) {
            await new Promise(resolve => setTimeout(() => resolve(), 500));

            const label_rect = await this.addNode({
              block: {
                type: 'label',
                siblingRank: rule_by_uid[rule_id].index_key,
                name: `${rule_by_uid[rule_id].name}`,
              },
              link: {
                source: this.selected_node?.model?.id,
                rule_id,
              },
            });
            await this.addNode({
              block: { type: 'add' },
              link: { source: label_rect.id },
            });
          }
        }
      },
      refreshGraphLayout() {
        const tree = new joint.layout.TreeLayout({
          graph: this.graph,
          parentGap: 50,
          siblingGap: 50,
          direction: 'B',
        });

        tree.layout();
        this.paper.fitToContent({
          allowNewOrigin: 'any',
          contentArea: tree.getLayoutBBox(),
        });
        this.tree = tree;
      },
      /* used for adding links to node */
      addLinks({ source, target, rule_id }) {
        const cell = new joint.shapes.workflow.Link({
          source: { id: source },
          target: { id: target },
          rule_id,
        });

        return cell;
      },
      /* used for adding node to graph */
      async addNode({ block, link, addButtonTool = null }) {
        let rect = null;
        if (block.type === 'start') {
          rect = new joint.shapes.workflow.StartNode();
        }
        else if (block.type === 'end') {
          rect = new joint.shapes.workflow.EndNode();
        }
        else if (block.type === 'label') {
          let fill_color = '#464646';
          if (['Submitted', 'Approved'].includes(block.name))
            fill_color = '#039855';
          if (block.name === 'Rejected')
            fill_color = '#D92D20';

          rect = new joint.shapes.workflow.LinkLabelNode({
            attrs: {
              body: {
                fill: fill_color,
              },
              label: {
                text: block.name,
              },
              rule_id: { text: link.rule_id },
            },
            siblingRank: block.siblingRank,
          });
        }
        else if (block.type === 'add') {
          rect = new joint.shapes.workflow.AddButtonNode();
        }
        else {
          rect = new joint.shapes.workflow.Node({
            attrs: {
              label: {
                text: block.name,
              },
              description: {
                text: block.description,
              },
              iconImage: {
                xlinkHref: block.image_v2 ? block.image_v2 : `${import.meta.env.VITE_APP_API_HOST}/forms/v1/resource/static/icons/workflow/form-block-v2.svg`,
              },
            },
          });
        }
        if (link) {
          const link_element = await this.addLinks({
            source: link.source,
            target: rect.id,
            rule_id: link.rule_id,
          });
          this.graph.addCells([rect, link_element]);
        }
        else { this.graph.addCells([rect]); }
        this.refreshGraphLayout();
        if (block.type === 'add' && addButtonTool)
          addButtonTool(rect, { add: true });
        return rect;
      },
    },
  })();
}
