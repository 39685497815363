import { TASKS_API_HOST } from '../utils/api-hosts';

export default {
  common: {
    methods: {
      get_activities(req = {}) {
        return {
          url: `activities/${req.module}/${req.resource_type}/${req.resource_uid
          }/`,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          data: req.body,
          baseURL: TASKS_API_HOST,
        };
      },
      get_stream_chat_user_token(req = {}) {
        return {
          url: `stream/user-token/chat`,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          toast: false,
        };
      },
      get_activities_feed(req = {}) {
        return {
          url: `activities/feeds/${req.feed_id}`,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          baseURL: TASKS_API_HOST,
        };
      },
      get_audit_logs(req = {}) {
        return {
          url: 'activities/logs/',
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          signal: req.signal,
          baseURL: TASKS_API_HOST,
        };
      },
      get_notifications(req = {}) {
        return {
          url: `activities/notifications/${req.user}/`,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          baseURL: TASKS_API_HOST,
        };
      },
      clear_notifications(req = {}) {
        return {
          url: `activities/notifications/${req.user}/`,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          data: req.body,
          method: 'POST',
          baseURL: TASKS_API_HOST,
        };
      },
    },
  },
};
