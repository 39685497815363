<script setup>
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useIntegrationsStore } from '~/common/stores/integrations.store';
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);
const form = ref({
  sync: 'All folder items',
  account: null,
  site: null,
});
const state = reactive({
  destinations: [],
  sharepoint_sites: [],
  destination: null,
  is_destinations_loading: false,
});
const $t = inject('$t');
const $services = inject('$services');
const form$ = ref(null);
const document_store = useDocumentStore();
const integration_store = useIntegrationsStore();
const sharepoint_accounts = computed(() => integration_store.integrations_map.SHAREPOINT?.map(item => ({ label: item.account.name, value: item._id, item, disabled: new Date(item.expires_in) <= new Date() })));
const sharepoint_site_destinations = computed(() => state.destinations || []);

async function handleSave() {
  try {
    await document_store.crud_documents(
      {
        request: {
          id: props.item.uid,
          attribute: 'integrations',
          body: {
            integrations: [{
              id: form.value.account.value,
              type: 'Sharepoint',
              filters: {},
              metadata: {
                site_id: form.value.site.id,
                destination: form.value.destination,
                site_name: form.value.site.hostname,
                account_name: form.value.account.label,
              },
            }],
          },
        },

      },
    );

    emit('close');
  }
  catch (err) {
    logger.log(err);
  }
}
async function fetchDestinations() {
  try {
    state.is_destinations_loading = true;
    const response = await $services.integrations.getAll({
      url: '/sharepoint/fileAndFolders',
      query: {
        site: form.value.site.id,
        filter_by: 'Folder',
        integration_id: form.value.account.value,
      },
    });
    state.destinations = response.data.children;
    state.is_destinations_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ fetchDestination ~ error:', error);
    state.is_destinations_loading = false;
  }
}
async function fetchSites() {
  try {
    state.is_sites_loading = true;
    const response = await $services.integrations.getAll({
      url: '/sharepoint/sites',
      query: {
        integration_id: form.value.account.value,
      },
    });
    state.sharepoint_sites = response.data;
    state.is_sites_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ fetchSites ~ error:', error);
    state.is_sites_loading = false;
  }
}
function addAccount() {
  try {
    integration_store.authorize_integration({
      integration_type: 'SHAREPOINT',
    });
  }
  catch (err) {
    logger.log(err);
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      sync
      :columns="{ lg: { container: 12, label: 4, wrapper: 12 } }"
      :should_validate_on_mount="false" :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              <div class="flex flex-col justify-start">
                {{ $t('Integrate with Sharepoint') }}
                <p class="text-xs text-gray-600 font-normal">
                  {{ $t('Sync folder items to a destination in Sharepoint.') }}
                </p>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content class="grid gap-4 min-h-[200px]" :is_scroll="false">
          <SelectElement
            :label="$t('Account')"
            name="account"
            :native="false"
            :object="true"
            :placeholder="$t('Choose an account')"
            :can-deselect="false"
            :can-clear="false"
            rules="required"
            :items="sharepoint_accounts"
            @select="fetchSites"
          >
            <template #before-list>
              <HawkButton
                type="link"
                class="mx-3 my-1"
                @click="addAccount"
              >
                <IconHawkPlus /> {{ $t('Add account') }}
              </HawkButton>
            </template>
            <template #option="{ option }">
              <span class="text-sm text-gray-900 font-medium">
                <HawkBadge v-if="option.disabled" color="red" size="sm">
                  {{ $t('Expired') }}
                </HawkBadge>
                {{ option.label }} <span class="text-gray-600 font-normal">({{ option.item.account.email }})</span>
              </span>
            </template>
          </SelectElement>
          <SelectElement
            :conditions="[['account', '!=', null]]"
            :label="$t('Site')"
            name="site"
            :object="true"
            label-prop="hostname"
            value-prop="id"
            :native="false"
            :placeholder="$t('Choose a site')"
            :can-deselect="false"
            :can-clear="false"
            :loading="state.is_sites_loading"
            rules="required"
            :items="state.sharepoint_sites"
            @select="fetchDestinations"
          />
          <HawkTreeSelect
            :key="sharepoint_site_destinations?.length"
            :conditions="[['site', '!=', null]]"
            :options="{
              name: 'destination',
              label: $t('Location'),
              native: false,
              search: true,
            }"
            :data="sharepoint_site_destinations"
            data_type="object"
            :multi_select="false"
            :show_expanded_search="true"
            :loading="state.is_destinations_loading"
            label_key="name"
            rules="required"
            value_key="id"
            select_type="LEAF_PRIORITY"
            select_view="SELECT"
            @update-form="state.destination = $event?.[0]"
            @input-value="form.destination = $event?.id"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-submit-button :form$="form$">
                {{ $t('Integrate') }}
              </hawk-submit-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
