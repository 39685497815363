<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { isEqual } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import CoreActivitiesItem from '~/common/components/organisms/hawk-activities/core-activities-item.vue';
import DocumentActivitiesItem from '~/common/components/organisms/hawk-activities/document-activities/document-activities-item.vue';
import FormsActivitiesItem from '~/common/components/organisms/hawk-activities/forms-activities-item.vue';
import InventoryActivitiesItem from '~/common/components/organisms/hawk-activities/inventory-activities-item.vue';
import ProjectManagementActivitiesItem from '~/common/components/organisms/hawk-activities/project-management-activities-item.vue';
import SystemModelActivitiesItem from '~/common/components/organisms/hawk-activities/system-model-activities-item.vue';
import TaskActivitiesItem from '~/common/components/organisms/hawk-activities/task-activities/task-activities-item.vue';
import { useResourceHelper } from '~/common/utils/common.utils.js';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  module: {
    type: [String, Array],
    default: '',
  },
  resource_uid: {
    type: String,
  },
  resource_type: {
    type: String,
  },
  is_timeline: {
    default: false,
    type: Boolean,
  },
  is_notifications: {
    default: false,
    type: Boolean,
  },
  timeline_icon: {
    default: 'avatar',
    type: String,
    validator(value) {
      return ['avatar', 'dot'].includes(value);
    },
  },
  can_initialize: {
    default: true,
    type: Boolean,
  },
  container_class: {
    default: 'max-h-[400px] mb-8 overflow-auto',
    type: String,
  },
  query: {
    type: Object,
  },
  lazy_load: {
    type: Boolean,
    default: false,
  },

});
const emit = defineEmits('close');
// --------------------------------- Imports -------------------------------- //
const route = useRoute();
const router = useRouter();
const $services = inject('$services');

const { getResourceName } = useResourceHelper();
const auth_store = useAuthStore();

const COMPONENTS_MAP = {
  'ticket': TaskActivitiesItem,
  'file': DocumentActivitiesItem,
  'transmittal_document': DocumentActivitiesItem,
  'transmittal': DocumentActivitiesItem,
  'folder': DocumentActivitiesItem,
  'project_management': ProjectManagementActivitiesItem,
  'form_template': FormsActivitiesItem,
  'form': FormsActivitiesItem,
  'inventory': InventoryActivitiesItem,
  'system-model': SystemModelActivitiesItem,
  'core': CoreActivitiesItem,
  'user': CoreActivitiesItem,
  'team': CoreActivitiesItem,
  'role': CoreActivitiesItem,
  'asset': CoreActivitiesItem,
};

const state = reactive({
  is_loading: false,
  activities: [],
  total_count: 0,
});
const list_instance = ref();
const current_page_number = ref(1);

const has_activities = computed(() => state.activities.length);
const user_id = computed(() => auth_store?.logged_in_user_details?.user_id);

function getItemUid(meta, module_name) {
  let uid;
  switch (module_name) {
    case 'asset':
      uid = meta.asset.uid;
      break;
    case 'ticket':
      uid = meta.task.uid;
      break;
    case 'form_template':
      uid = meta?.template?.uid;
      break;
    case 'form':
      uid = meta?.form?.uid;
      break;
    case 'file':
      uid = meta?.file?.uid;
      break;
    case 'folder':
      uid = meta?.folder?.uid;
      break;
    case 'project_management':
      uid = meta?.schedule?.uid;
      break;
    case 'team':
      uid = meta?.team?.uid;
      break;
    case 'role':
      uid = meta?.role?.uid;
      break;
    case 'user':
      uid = meta?.user;
      break;
    case 'transmittal_document':
    case 'transmittal':
      uid = meta?.transmittal?.uid;
      break;

  // 'transmittal_document': DocumentActivitiesItem,
  // 'inventory': InventoryActivitiesItem,
  // 'system-model': SystemModelActivitiesItem,
  // 'core': CoreActivitiesItem,
  }
  return uid;
}
function openDetails(item) {
  const item_uid = getItemUid(item.meta, item.resource_name?.toLowerCase());
  if (!item_uid)
    return;
  switch (item.resource_name?.toLowerCase()) {
    case 'asset':
      router.push({ name: 'asset-dashboard', params: { asset_id: item_uid } });
      break;

    case 'ticket':
      router.replace({
        ...route,
        query: {
          ...route.query,
          task: btoa(JSON.stringify({ id: item_uid, store_key: '' })),
        },
      });
      break;

    case 'team':
      // check for the asset
      router.push({
        name: route.params.asset_id ? 'asset-settings-edit-team' : 'account-settings-edit-team',
        params: { id: item_uid },
      });
      break;

    case 'role':
      router.push({
        name: route.params.asset_id ? 'asset-settings-edit-role' : 'account-settings-edit-role',
        params: { id: item_uid },
      });
      break;

    case 'user':
      // check asset is comeing i the response
      router.push({
        name: route.params.asset_id ? 'asset-settings-users-details' : 'account-settings-users-details',
        params: { user_id: item_uid },
      });
      break;

    case 'file':

      router.push({
        name: 'files-documents-all-files',
        params: { asset_id: item.asset },
        query: { folder: item.parent || 'null', document: item_uid },
      });
      break;

    case 'folder':
      router.push({
        name: 'files-documents',
        params: { asset_id: item.asset },
        query: { folder: item_uid },
      });
      break;
    case 'form':
      router.replace({
        ...route,
        params: {
          ...route.params,
          asset_id: item.asset,
        },
        query: {
          ...route.query,
          form: btoa(JSON.stringify({ form_uid: item_uid, store_key: '' })),
        },
      });
      break;

    case 'form_template':
      router.push({
        name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-overview',
        params: {
          template_uid: item_uid,
          asset_id: item.asset,
        },
      });

      break;

    case 'instance':
      router.push({
        name: 'sm-template-details',
        params: {
          asset_id: item.asset,
          template_id: item.template_uid,
        },
        query: {
          component: item.component_uid,
          instance: item_uid,
        },
      });
      break;

      // Check later
    case 'comment':
      router.replace({
        ...route,
        query: {
          ...route.query,
          task: btoa(JSON.stringify({ id: item.task_uid, comment_uid: item_uid })),
        },
      });
      break;
    case 'transmittal_document':
    case 'transmittal':
      router.push({
        name: 'files-transmittals-detail',
        params: { asset_id: item.asset, transmittal_uid: item_uid },
      });
      break;
    default:
      break;
  }
  emit('close');
}
async function getData(clear = false) {
  state.is_loading = true;
  if (props.is_notifications) {
    await getNotification(clear);
  }
  else {
    try {
      const { data, headers } = await $services.common.get_activities({
        module: props.module, // DMS // FORM // tasks
        resource_type: props.resource_type, // file/folder // form_template/form // ticket

        resource_uid: props.resource_uid,
        query: {
          ...props.query,
          ...(props.lazy_load ? { page_number: current_page_number.value } : { page_size: Number.MAX_SAFE_INTEGER }),
          sort: '-createdAt',
        },
      });
      state.total_count = headers['x-total-count'];
      if (props.is_timeline)
        state.activities = data;
      else state.activities = [...(state.activities || []), ...data];
    }
    catch (e) {
      logger.log({ e });
    }
  }

  state.is_loading = false;
}

async function getNotification(clear) {
  try {
    if (clear)
      state.activities = [];
    const { data, headers } = await $services.common.get_notifications({
      user: user_id.value,
      query: {
        type: 'notification',
        sort: '-createdAt',
        page_number: current_page_number.value,
        page_size: 10,
        // unread: true,
      },
    });
    state.total_count = headers['x-total-count'];
    state.activities = [...(state.activities || []), ...data];
  }
  catch (e) {
    logger.log({ e });
  }
}

async function getList() {
  if (props.lazy_load && state.total_count > state.activities?.length) {
    current_page_number.value++;
    await getData();
  }

  else { list_instance.value?.allItemsLoaded(true); }
}
function getUser(actor) {
  return actor.split(':')[1];
}

onMounted(() => {
  if (props.can_initialize)
    getData();
});

watch(() => props.query, (new_val, old_val) => {
  if (!old_val || (!isEqual(new_val, old_val))) {
    state.activities = [];
    getData();
  }
}, { deep: true });

defineExpose({ getData, has_activities });
</script>

<template>
  <div>
    <slot name="top-header" />
    <div v-if="state.is_loading && !props.lazy_load" class="h-[400px] grid place-content-center">
      <HawkLoader />
    </div>

    <div v-else-if="has_activities">
      <hawk-timeline v-if="is_timeline" :details="state.activities">
        <template #icon="detail">
          <slot name="template-icon">
            <div class="h-10 w-10 rounded-full bg-white flex items-center justify-center absolute" />
            <HawkMembers v-if="props.timeline_icon === 'avatar'" :members="getUser(detail.data.actor)" class="z-10" />
            <IconHawkCheck
              v-if="props.timeline_icon === 'dot'"
              class="h-6 w-6 text-primary-600"
            />
          </slot>
        </template>
        <template #description="{ data } = detail">
          <div class="flex space-between text-xm text-grey-600 flex-wrap">
            <p>
              <span v-if="data?.meta?.external" class="mr-2 text-sm font-medium text-gray-700">
                {{ getUser(data.actor) }}
              </span>
              <HawkMembers v-else :members="getUser(data.actor)" class="mr-2" type="label" :has_avatar="false" />
            </p>
            <p>
              {{ $date(data.created_at, 'DATETIME_LONG_WITH_LONG_DAY_AND_MONTH') }}
            </p>
          </div>

          <component :is="COMPONENTS_MAP[data.resource_name?.toLowerCase()]" :activity="data" />
        </template>
      </hawk-timeline>
      <div v-else>
        <HawkInfiniteList :list="state.activities" :load_more="getList" :container_class="props.container_class" @list-instance="(instance) => list_instance = instance">
          <template #item="{ item }">
            <div class="border-b border-gray-200 py-3 cursor-pointer" @click="props.is_notifications ? openDetails(item) : null">
              <div class="flex justify-between text-sm mb-1" :class="item.unread ? 'font-semibold text-gray-900' : 'text-gray-600'">
                <div :class="{ 'font-medium': !item.unread }" class="flex items-center gap-2">
                  {{ getResourceName(item) }}
                  <div v-if="item.unread" class="bg-primary-500 h-[6px] w-[6px] rounded-full flex-shrink-0" />
                </div>
                <div>{{ $date(item.created_at, 'L_DATETIME_MED') }}</div>
              </div>
              <div class="flex items-start">
                <hawk-members :members="getUser(item.actor)" type="badge" size="sm" class="mr-2" />

                <component :is="COMPONENTS_MAP[item.resource_name?.toLowerCase()]" :activity="item" class="w-[60%] text-gray-600" :show_form_name="false" />
              </div>
            </div>
          </template>
        </HawkInfiniteList>
      </div>
    </div>
    <div v-else-if="state.is_loading && props.lazy_load" class="h-[400px] grid place-content-center">
      <HawkLoader />
    </div>
    <HawkIllustrations v-else-if="!has_activities" type="no-data" for="generic" :has_fixed_height="false" class="h-[400px] grid place-content-center" />
  </div>
</template>
