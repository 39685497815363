<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useFeatureActions } from '~/terra/utils/feature-actions.composable.js';

import ToolButton from './tool-button.vue';
import ToolRenderer from './tool-renderer.vue';

const props = defineProps({
  availableWidth: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['openCreationPopup']);

const { $t, $toast, auth_store, route } = useCommonImports();
const { tasks_forms, moveSelectedFeatures, deleteSelectedFeatures, handleChangeFeatureType } = useFeatureActions();

const $form_create_permission = inject('$form_create_permission');

const terra_store = useTerraStore();
const task_store = useTasksStore('terra_task_store');
const forms_store = useFormsStore('terra_form_store');

const more_tool = {
  uid: 'more',
  label: $t('More'),
  icon: IconHawkDotsHorizontal,
};
const active_tool = ref('');

const toolbar_width = computed(() => {
  const width = props.availableWidth;
  return `maxWidth: ${width}px`;
});

const selection_tools = computed(() => {
  return [
    {
      uid: 'select_all',
      label: $t('Select all'),
      on_click: () => {
        terra_store.selected_features = terra_store.visible_features_on_map;
      },
    },
    {
      uid: 'invert_selection',
      label: $t('Invert selection'),
      on_click: () => {
        const selected_features_uids = terra_store.selected_features.map(feature => feature.properties.uid);

        terra_store.selected_features = terra_store.visible_features_on_map.filter(feature => !selected_features_uids.includes(feature.properties.uid));
      },
    },
  ];
});

const action_tools = computed(() => {
  return [
    {
      uid: 'copy',
      label: $t('Copy'),
      icon: IconHawkCopyOne,
      action: () => {
        const { copy } = useClipboard();
        copy(terra_store.selected_features.map(row => row.properties.name || '').join('\n'));
        $toast({
          title: $t('Successfully copied!'),
          text: $t('Feature names are copied to your clipboard.'),
          type: 'success',
          position: 'top-right',
        });
      },
    },
    {
      uid: 'class',
      label: $t('Class'),
      icon: IconHawkGridOne,
      disabled: !terra_store.check_permission('modify_feature_properties'),
      disabled_reason: $t('You don\'t have permissions to perform this action.'),
    },
    {
      uid: 'tasks',
      label: $t('Tasks'),
      icon: IconHawkFileCheckThree,
      disabled: tasks_forms.value.task?.count > 100 && terra_store.selected_features.length > 100,
      disabled_reason: $t('You can only select up to 100 tasks at once'),
      sub_tools: [
        {
          uid: 'create-tasks',
          label: $t('Create tasks'),
          event: 'create-tasks',
          disabled: terra_store.selected_features.length > 100 || !auth_store.check_permission('create_tasks', route.params.asset_id),
          tooltip: terra_store.selected_features.length > 100 ? $t('Can not create more than 100 tasks at once') : null,
          on_click: () => {
            emits('openCreationPopup', 'task');
          },
        },
      ],
    },
    {
      uid: 'forms',
      label: $t('Forms'),
      icon: IconHawkClipboardPlus,
      disabled: tasks_forms.value.form?.count > 100 && terra_store.selected_features.length > 100,
      disabled_reason: $t('You can only select up to 100 forms at once'),
      sub_tools: [
        {
          uid: 'create-forms',
          label: $t('Create forms'),
          event: 'create-forms',
          disabled: terra_store.selected_features.length > 100 || $form_create_permission?.value?.state === 'disabled',
          tooltip: terra_store.selected_features.length > 100 ? $t('Can not create more than 100 forms at once') : $form_create_permission?.value?.tooltip,
          on_click: () => {
            emits('openCreationPopup', 'form');
          },
        },
      ],
    },
    {
      uid: 'edit-geometry',
      tooltip: $t('Edit geometry'),
      icon: IconHawkPenToolOne,
      disabled: terra_store.gallery_view_state.is_active || !terra_store.check_permission('modify_features'),
      disabled_reason: terra_store.gallery_view_state.is_active ? $t('You can not edit geometry in gallery view') : $t('You don\'t have permissions to perform this action.'),
      action: () => {
        const event = new KeyboardEvent('keydown', {
          key: 'E',
          code: 'KeyE',
          shiftKey: true,
          bubbles: true,
          cancelable: true,
        });
        document.dispatchEvent(event);
      },
    },
    {
      uid: 'move',
      tooltip: $t('Move'),
      disabled: !terra_store.check_permission('modify_features'),
      disabled_reason: $t('You don\'t have permissions to perform this action.'),
      icon: IconHawkMove,
      action: () => {
        moveSelectedFeatures();
      },
    },
    {
      uid: 'delete',
      tooltip: $t('Delete'),
      disabled: !terra_store.check_permission('modify_features'),
      disabled_reason: $t('You don\'t have permissions to perform this action.'),
      icon: IconHawkTrashThree,
      custom_icon_class: '!text-error-400',
      event: 'delete',
      action: () => {
        deleteSelectedFeatures();
      },
    },
  ];
});

const toolbar_tools = computed(() => {
  const TOOL_BASE_WIDTH = 90;
  const TOOL_ICON_ONLY_WIDTH = 40;
  const MORE_BUTTON_WIDTH = 70;

  const availableWidth = Number.parseInt(props.availableWidth) - 250; // Subtract padding and selection counter width
  let currentWidth = 0;
  const visible = [];
  const overflow = [];

  action_tools.value.forEach((tool) => {
    const toolWidth = tool.label ? TOOL_BASE_WIDTH : TOOL_ICON_ONLY_WIDTH;

    if (currentWidth + toolWidth + (overflow.length ? MORE_BUTTON_WIDTH : 0) <= availableWidth) {
      visible.push(tool);
      currentWidth += toolWidth;
    }
    else {
      overflow.push(tool);
    }
  });

  return {
    visibleTools: visible,
    overflowTools: overflow,
  };
});
watch(() => terra_store.is_in_edit_geometry, (val) => {
  if (val)
    active_tool.value = 'edit-geometry';
  else
    active_tool.value = '';
});
</script>

<template>
  <div class="rounded-md bg-white" :style="toolbar_width">
    <div class="flex items-center p-2 h-auto">
      <div>
        <HawkMenu position="fixed" additional_trigger_classes="!ring-0 !border-0" :items="selection_tools">
          <template #trigger="{ open }">
            <div class="flex items-center border-2 border-gray-200 rounded-lg">
              <div class="text-xs font-medium text-gray-600 py-1.5 px-3" :class="{ 'bg-gray-100': open }">
                {{ terra_store.selected_features.length }} {{ $t('selected') }}
              </div>
              <div class="cursor-pointer border-l-2 border-gray-200 py-1.5 px-2" @click="terra_store.selected_features = []">
                <IconHawkXClose class="w-4 h-4" />
              </div>
            </div>
          </template>
        </HawkMenu>
      </div>
      <div v-for="tool in toolbar_tools.visibleTools" :key="tool.uid" class="ml-2">
        <ToolRenderer
          :tool="tool"
          :active-tool="active_tool"
          :task-store="task_store"
          :forms-store="forms_store"
          :tasks-forms="tasks_forms"
          @change-feature-type="handleChangeFeatureType"
        />
      </div>
      <template v-if="toolbar_tools.overflowTools.length">
        <div class="w-[1px] border bg-slate-600 h-5 mx-2" />
        <div class="ml-auto">
          <HawkMenu
            position="fixed"
            additional_trigger_classes="!ring-0 !border-0"
            additional_dropdown_classes="min-w-[200px]"
          >
            <template #trigger="{ open }">
              <ToolButton
                :tool="more_tool"
                :active-tool="open ? more_tool.uid : ''"
              />
            </template>
            <template #content>
              <div class="p-1">
                <div
                  v-for="tool in toolbar_tools.overflowTools"
                  :key="tool.uid"
                >
                  <ToolRenderer
                    :is-menu-child="true"
                    :tool="tool"
                    :active-tool="active_tool"
                    :task-store="task_store"
                    :forms-store="forms_store"
                    :tasks-forms="tasks_forms"
                    @change-feature-type="handleChangeFeatureType"
                  />
                </div>
              </div>
            </template>
          </HawkMenu>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
