<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { pick, uniqBy } from 'lodash-es';

import { useModal } from 'vue-final-modal';
import DocumentBrowsePopup from '~/dms/components/atoms/document-browse-popup.vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => {},
  },
  fields: {
    type: Array,
    default: () => [],
  },
  fieldvalues_map: {
    type: Object,
    default: () => {},
  },
  selectDmsFiles: {
    type: Boolean,
    default: false,
  },
  vueForm: {
    type: Object,
    default: null,
  },
});

const $t = inject('$t');
const components = ref([]);
const dms_selected_files = ref([]);

const document_browse_modal = useModal({
  component: DocumentBrowsePopup,
});

onMounted(() => {
  createComponentList();
});

function getComponentOptions(type) {
  let options = {};

  switch (type) {
    case 'labels':
    case 'checkbox':
      options = {
        component: 'CheckboxgroupElement',
        additional_options: fields_value => ({ items: fields_value }),
      };
      break;
    case 'radio':
      options = {
        component: 'RadiogroupElement',
        additional_options: fields_value => ({ items: fields_value }),
      };
      break;
    case 'text':
    case 'email':
    case 'url':
    case 'number':
      options = {
        component: 'TextElement',
        additional_options: (_, properties, type) => ({
          inputType: type,
          ...(properties?.currency || type === 'number'
            ? {
                attrs: { min: 0 },
                rules: 'nullable|integer|regex:/^[0-9]+$/',
                messages: {
                  integer: 'This field must be an integer.',
                  regex: 'This field must be an integer.',
                },
              }
            : {}),
          ...(type === 'email' ? { rules: 'nullable|email' } : {}),
          ...(type === 'url' ? { rules: 'nullable|url' } : {}),
        }),
      };
      break;
    case 'dropdown':
      options = {
        component: 'SelectElement',
        additional_options: (fields_value, properties) => ({
          items: fields_value,
          search: !properties.radio,
          native: false,
        }),
      };
      break;
    case 'date':
      options = {
        component: 'DateTimeElement',
        additional_options: () => ({
          options: {
            'format': 'yyyy-MM-dd',
            'model-type': 'yyyy-MM-dd',
            'placeholder': $t('Select date'),
          },
        }),
      };
      break;
    case 'file':
      options = {
        component: 'MultifileElement',
        extra_options: {
          selectDmsFiles: props.selectDmsFiles,
        },
        additional_options: () => ({
          presets: ['hawk_file_element'],
          drop: true,
          use_uppy: true,
          auto: false,
          options: {
            clickable_text: $t('Click to upload'),
            text: $t('or drag and drop'),
          },
          events: {
            mounted: el$ => filesMounted(el$, default_field_value),
          },
        }),
      };
      break;
  }
  return options;
}

function createComponentList() {
  const custom_fields = props.fields;
  components.value = custom_fields.map((element) => {
    const { type, uid, name, config, properties } = element;
    let fields_value = [];

    if (Array.isArray(config))
      fields_value = config.map(field => ({ value: field.uid, label: field.name }));

    const default_field_value = props.fieldvalues_map?.[uid]?.value;

    const baseComponent = {
      name: uid,
      label: sanitizer(name),
      default: default_field_value,
    };
    const type_check = type === 'dropdown' && properties?.radio ? 'radio' : type;
    const { component, additional_options, extra_options = {} } = getComponentOptions(type_check);
    const options = {
      ...baseComponent,
      ...additional_options(fields_value, properties, type_check),
    };

    return {
      component,
      options,
      ...extra_options,
    };
  });
}

function sanitizer(data) {
  const div_element = document.createElement('div');
  const text_node = document.createTextNode(data);
  div_element.appendChild(text_node);
  return div_element.innerHTML;
}

function filesMounted(el$, files) {
  if (files) {
    const format_files = files.map((file) => {
      const service = file?.service || {};
      service.url = file?.pre_signed_url;
      return { ...file, service };
    });
    el$.load(format_files);
  }
}

function appendValues(field_uid, files) {
  try {
    const { name } = props.options;
    const data = props.vueForm?.el$('column2')?.children$?.[name]?.children$?.[field_uid]?.data || [];
    const uniqByData = uniqBy([...data[field_uid], ...files], (item) => {
      if (item?.integration?.resource_uid) {
        return item.integration.resource_uid;
      }
      return item.uid;
    });
    props.vueForm?.el$('column2')?.children$?.[name]?.children$?.[field_uid]?.update?.(uniqByData);
  }
  catch (err) {
    logger.log(err);
  }
}

function openFileSelectionModal(field_uid) {
  document_browse_modal.patchOptions({
    attrs: {
      title: $t('Select files'),
      action_label: $t('Save'),
      on_submit: async ({ selected_documents }) => {
        try {
          dms_selected_files.value = selected_documents.map(file => ({
            uid: file.uid,
            integration: {
              type: 'dms',
              resource_type: file.type,
              resource_uid: file.uid,
            },
            service: file.service,
            name: file.name,
            file_name: file.name,
            file_size: file.file_size,
            mime_type: file.file_type,
            meta: file.meta,
            created_by: file.created_by.uid,
            created_at: file.created_at,
            ...(pick(file, ['thumbnail_small', 'thumbnail_xsmall', 'thumbnail_large', 'thumbnail_small_size', 'thumbnail_xsmall_size', 'thumbnail_large_size'])),
          }));
          appendValues(field_uid, dms_selected_files.value);
        }
        catch (er) {
          logger.error(er);
        }
        finally {
          document_browse_modal.close();
        }
      },
      onClose() {
        document_browse_modal.close();
      },
    },
  });
  document_browse_modal.open();
}
</script>

<template>
  <ObjectElement
    v-bind="options"
  >
    <template v-for="component in components" :key="component.name">
      <component
        :is="component.component"
        v-bind="component.options"
        v-on="component?.events || {}"
      />

      <div v-if="component?.selectDmsFiles" class="grid grid-cols-12 gap-4 col-span-12">
        <div class="col-span-4" />
        <div class="col-span-8">
          <div class="flex items-center my-1">
            <hr class="flex-1 border-t border-gray-200">
            <span class="text-gray-500 font-medium">{{ $t('or') }}</span>
            <hr class="flex-1 border-t border-gray-200">
          </div>

          <div class="space-y-2 mb-2">
            <div class="text-sm font-semibold text-blue-700 cursor-pointer" @click="openFileSelectionModal(component.options.name)">
              {{ $t('Choose from DMS') }}
            </div>
            <p class="text-gray-600 text-xs">
              <span class="font-medium">{{ $t('Note') }}:</span> {{ $t('Only accessible to the users permitted in the DMS') }}.
            </p>
          </div>
        </div>
      </div>
    </template>
  </ObjectElement>
</template>
