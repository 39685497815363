import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

export function useTaskLayers() {
  const project_management_store = useProjectManagementStore();
  const { $g, active_view, is_schedule_editable } = storeToRefs(project_management_store);

  const layer_ids = ref([]);

  function addElement(config) {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.className = config.css || '';
    div.style.left = config.left;
    div.style.width = config.width;
    div.style.height = config.height;
    div.style.lineHeight = config.height;
    div.style.top = config.top;
    if (config.html)
      div.innerHTML = config.html;
    if (config.wrapper)
      config.wrapper.appendChild(div);
    return div;
  }

  function setupTaskLayers() {
    $g.value.templates.drag_date = $g.value.date.date_to_str($g.value.config.drag_date);

    // drag highlight area
    const layer_highlight = $g.value.addTaskLayer({
      renderer: function highlight_area(task) {
        const sizes = $g.value.getTaskPosition(task, new Date(task.start_date), new Date(task.end_date));
        const wrapper = document.createElement('div');

        addElement({
          css: 'drag_move_vertical',
          left: `${sizes.left}px`,
          top: 0,
          width: `${sizes.width}px`,
          height: `${$g.value.getVisibleTaskCount() * $g.value.config.row_height}px`,
          wrapper,
        });

        addElement({
          css: 'drag_move_horizontal',
          left: 0,
          top: `${sizes.top}px`,
          width: `${100}%`,
          height: `${$g.value.config.row_height - 1}px`,
          wrapper,
        });

        return wrapper;
      },
      filter(task) {
        return $g.value.config.show_drag_vertical && task.id === $g.value.getState().drag_id;
      },
    });

    // drag dates
    const layer_drag_dates = $g.value.addTaskLayer({
      renderer: function show_dates(task) {
        const sizes = $g.value.getTaskPosition(task, new Date(task.start_date), new Date(task.end_date));
        const wrapper = document.createElement('div');

        addElement({
          css: 'drag_move_start drag_date',
          left: `${sizes.left - $g.value.config.drag_label_width}px`,
          top: `${sizes.top}px`,
          width: `${$g.value.config.drag_label_width}px`,
          height: `${$g.value.config.row_height - 1}px`,
          html: $g.value.templates.drag_date(new Date(task.start_date)),
          wrapper,
        });

        addElement({
          css: 'drag_move_end drag_date',
          left: `${sizes.left + sizes.width + 200}px`,
          top: `${sizes.top}px`,
          width: `${$g.value.config.drag_label_width}px`,
          height: `${$g.value.config.row_height - 1}px`,
          html: $g.value.templates.drag_date(new Date(task.end_date)),
          wrapper,
        });

        return wrapper;
      },
      filter(task) {
        return $g.value.config.show_drag_dates && task.id === $g.value.getState().drag_id;
      },
    });

    // float
    const layer_float = $g.value.addTaskLayer({
      renderer: {
        render: (task) => {
          if (!active_view.value.data.feature_visibility.float || task.type !== $g.value.config.types.task)
            return false;

          const state = $g.value.getState().drag_mode;
          if (state === 'resize' || state === 'move')
            return false;

          if (is_schedule_editable.value)
            task.free_slack = $g.value.getFreeSlack(task);

          if (!task?.free_slack)
            return false;

          const slack_start = new Date(task.end_date);
          const slack_end = $g.value.calculateEndDate(
            slack_start,
            task.free_slack,
          );
          const sizes = $g.value.getTaskPosition(task, slack_start, slack_end);
          const el = document.createElement('div');

          el.className = 'slack';
          el.style.left = `${sizes.left}px`;
          el.style.top = `${sizes.top + 13}px`;
          el.style.width = `${sizes.width}px`;
          el.style.height = `${sizes.height}px`;

          return el;
        },
      },
    });

    // baseline
    const baseline_start = 'bl_start';
    const baseline_end = 'bl_finish';

    const layer_baseline = $g.value.addTaskLayer({
      renderer: {
        render: (task) => {
          if (!active_view.value.data.feature_visibility.baseline)
            return false;

          if (task[baseline_start] && task[baseline_end]) {
            const sizes = $g.value.getTaskPosition(
              task,
              dayjs(task[baseline_start]).startOf('day'),
              dayjs(task[baseline_end]).startOf('day'),
            );
            const el = document.createElement('div');
            el.className = task.type === $g.value.config.types.task ? 'baseline' : '';
            el.style.left = `${sizes.left}px`;
            el.style.width = `${sizes.width}px`;
            el.style.top = `${sizes.top + 25}px`;
            return el;
          }
          return false;
        },
        getRectangle: (task, _view) => {
          if (task[baseline_start] && task[baseline_end]) {
            return $g.value.getTaskPosition(
              task,
              task[baseline_start],
              task[baseline_end],
            );
          }

          return null;
        },
      },
    });

    layer_ids.value.push(
      layer_highlight,
      layer_drag_dates,
      layer_float,
      layer_baseline,
    );
  }

  return {
    layer_ids,
    setupTaskLayers,
  };
}
