import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FeaturesMovePopup from '~/terra/components/feature-details/features-move-popup.vue';
import ChangeFeatureTypePopup from '~/terra/components/feature-type-groups/change-feature-type-popup.vue';
import { useTerraStore } from '~/terra/store/terra.store';

export function useFeatureActions() {
  const { $t } = useCommonImports();
  const terra_store = useTerraStore();

  const tasks_forms = computed(() => {
    return Object.values(terra_store.selected_tasks_forms).reduce((acc, cur) => {
      if (acc[cur.properties.feature_type]) {
        acc[cur.properties.feature_type].data.push(JSON.parse(cur.properties.data));
        acc[cur.properties.feature_type].count = acc[cur.properties.feature_type].count + 1;
      }
      else {
        acc[cur.properties.feature_type] = {
          data: [JSON.parse(cur.properties.data)],
          count: 1,
        };
      }
      return acc;
    }, {});
  });

  function moveSelectedFeatures(used_for = null) {
    const uid = terra_store.selected_features[0]?.properties?.uid;
    const move_popup = useModal({
      component: FeaturesMovePopup,
      attrs: {
        onClose() {
          move_popup.close();
        },
        onMove() {
          if (used_for)
            terra_store.terra_track_events('Table select row action move', { uid });
          else
            terra_store.terra_track_events('Moved', { uid });
        },
      },
    });
    move_popup.open();
  }

  function deleteSelectedFeatures(used_for = null) {
    const is_bulk_select = terra_store.selected_features.length > 1;
    const delete_popup = useModal({
      component: HawkDeletePopup,
      attrs: {
        header: is_bulk_select ? $t('Delete features') : $t('Delete feature'),
        content: is_bulk_select ? $t('Are you sure you want to delete the features?') : $t('Are you sure you want to delete the feature?'),
        show_toast: false,
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          const uid = terra_store.selected_features[0]?.properties?.uid;
          await terra_store.delete_features();
          if (used_for)
            terra_store.terra_track_events('Table select row action delete', { count: terra_store.selected_features.length, uid });
          else
            terra_store.terra_track_events('Feature deleted', { count: terra_store.selected_features.length, uid });
          delete_popup.close();
        },
      },
    });
    delete_popup.open();
  }

  function handleChangeFeatureType(feature_type) {
    const change_feature_type_popup = useModal({
      component: ChangeFeatureTypePopup,
      attrs: {
        selectedFeatureTypeUid: feature_type.uid,
        onClose() {
          change_feature_type_popup.close();
        },
      },
    });
    change_feature_type_popup.open();
  }

  return {
    tasks_forms,
    deleteSelectedFeatures,
    moveSelectedFeatures,
    handleChangeFeatureType,
  };
}
