import { has, isObject, isString, keyBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { getPageNameByRouteName } from '~/common/utils/common.utils';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useTerraStore } from '~/terra/store/terra.store';
import { useFormFilterStore } from './form-filter-store';

export function getAssigneeDataFromPayload(payload, assignees) {
  const map = {};
  if (payload?.add) {
    assignees.forEach(assignee => map[assignee.uid] = assignee);
    payload.add.forEach(assignee => map[assignee.uid] = assignee);
  }
  else if (payload?.remove) {
    assignees.forEach(assignee => map[assignee.uid] = assignee);
    payload.remove.forEach(assignee => delete map[assignee]);
  }
  else {
    assignees.forEach(assignee => map[assignee.uid] = assignee);
  }
  return Object.values(map).filter(data => data.access);
}

export function constructPayloadMap(forms) {
  const payload_map = {};
  if (Array.isArray(forms)) {
    forms.forEach((payload_form) => {
      if (payload_form?.uid && typeof payload_form.uid === 'string')
        payload_map[payload_form.uid] = payload_form;
    });
  }
  return payload_map;
}

export function useFormsStore(key) {
  const { $t } = useCommonImports();

  return defineStore(key || 'forms', {
    state: () => ({
      store_key: key,
      forms_map: {},
      templates: [],
      total_form_count: 0,
      asset_id: null,
      form_template: [],
    }),
    getters: {
      forms: (state) => {
        return Object.values(state?.forms_map);
      },
      status_values(state) {
        const statuses = [
          {
            name: 'Open',
            label: 'Open',
            value: 'open',
          },
          {
            name: 'Draft',
            label: 'Draft',
            value: 'draft',
          },
          {
            name: 'Submitted',
            label: 'Submitted',
            value: 'submitted',
          },
        ];
        if (state?.templates?.length === 1) {
          const template = state.templates[0];
          const step_data = Object.values(template.steps).map(step => ({
            name: step.name,
            label: step.name,
            value: step.index,
            is_step: true,
          }));
          return template.workflow
            ? [...step_data, {
                name: 'Submitted',
                label: 'Submitted',
                value: 'submitted',
              }]
            : statuses;
        }
        return statuses;
      },
      get_parent_form: state => (uid) => {
        return state.parent_form_map[uid];
      },
    },
    actions: {
      async reset() {
        this.forms_map = {};
        this.parent_forms = [];
        this.parent_form_map = {};
        this.form_template = [];
      },
      async set_forms(req, append = false) {
        try {
          if (
            ((has(req?.query, 'reference_name') && !req.query.reference_name)
              && (req?.query?.element && isString(req.query.element)))
              || (!has(req?.query, 'reference_name') && req?.query?.element && isString(req.query.element))
          ) {
            this.tasks_map = {};
            this.total_task_count = 0;
            // set data to null or empty
            return;
          }

          if (isObject(req?.query?.element) && req?.query?.element?.uid)
            req.query.element = req.query.element.uid;
          else if (req?.query?.element && isString(req.query.element))
            delete req.query.element;

          const { data, headers } = await this.$services.forms[req.action || 'getAll'](
            {
              query: {
                page_size: 100000000,
                ...req?.query || {},
                ...useFormFilterStore(key).filter_payload,
                ...(useFormFilterStore(key).search_key && { q: useFormFilterStore(key).search_key }),
                asset_uid: req.query?.asset_uid || this.$router.currentRoute.value.params.asset_id,
              },
              body: {
                ...req.body,
              },
              attribute: req?.attribute,
            },
          );

          const forms = keyBy(data.form || data.forms, 'uid');

          if (append)
            this.forms_map = { ...this.forms_map, ...forms };
          else
            this.forms_map = forms;

          if (Array.isArray(data?.templates))
            this.templates = data?.templates;
          this.total_form_count = headers['x-total-count'];
          return data;
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async create_form(req, return_form_data = false, options = {}) {
        try {
          if (req.body.forms.add.length > 100) {
            this.$toast({ text: $t('Can not create more than 100 forms at once'), type: 'error' });
            return;
          }
          const { data } = await this.$services.forms.post({ body: req.body });
          if (data.forms.errors.length > 0) {
            this.$toast({ text: $t('Form creation failed!'), type: 'error' });
            return;
          }
          const new_form = data.forms.added[0];
          if (new_form)
            this.forms_map = { ...keyBy(data.forms.added, 'uid'), ...this.forms_map };
          if (!options.disable_toast)
            this.$toast({ text: $t('Form created successfully'), type: 'success' });
          if (!req.do_not_open_form_details && new_form)
            this.$router.push({ ...this.$router.currentRoute, query: { form: btoa(JSON.stringify({ form_uid: new_form.uid, store_key: key, ...(req.router_query_param || {}) })) } });
          const properties = this.get_properties(req.body?.forms?.add?.[0]);
          if (new_form)
            this.forms_track_events('Created', new_form.uid, { properties, count: data.forms.added.length, ...(options.properties || {}) });
          if (return_form_data)
            return data;
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: $t('Form creation failed!'), type: 'error' });
          return error;
        }
      },
      async update_forms(req, type = null) {
        try {
          if (req.body.forms.update.length > 100) {
            this.$toast({ text: $t('Can not update more than 100 forms at once'), type: 'error' });
            return;
          }
          const { data } = await this.$services.forms.post({
            body: {
              ...req.body,
            },
          });
          if (type === 'bulk' && data?.forms?.updated) {
            data.forms.updated.forEach((updated_form) => {
              const form = this.forms_map[updated_form.uid];
              if (form) {
                form.due_date = updated_form.due_date;
                form.category = updated_form.category;
                form.assignees = updated_form.assignees;
                form.properties = updated_form.properties;
                this.forms_map[updated_form.uid] = { ...form };
              }
            });
          }
          if (!req.disable_toast)
            this.$toast({ text: $t('Form Updated Successfully'), type: 'success' });
        }
        catch (error) {
          logger.log(error);
          this.$toast({ text: $t('Form Updation Failed!'), type: 'error' });
          return error;
        }
      },
      async delete_form(uid) {
        try {
          const { data } = await this.$services.forms.post({ body: { forms: { remove: [uid] } }, toast: { message: $t('Form Instance deleted successfully') } });
          const type = this.forms_map[uid]?.properties?.integration?.type;
          if (['INTG_101', 'INTG_201'].includes(type)) {
            const store = type === 'INTG_101' ? useTerraStore() : useInventoryStore();
            await store.syncApiForInspectionForms(this.forms_map[uid], { event: 'FORMS_DELETED', status: 'deleted' });
          }
          this.forms_track_events('Deleted', uid, { mode: 'Single' });
          data.forms.removed.forEach(uid => delete this.forms_map[uid]);
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Form failed to delete!', type: 'error' });
          return error;
        }
      },
      async remove_forms(uids = []) {
        try {
          if (uids.length > 100) {
            this.$toast({ text: $t('Can not delete more than 100 forms'), type: 'error' });
            return;
          }

          const payload = uids.map(uid => (uid));
          const { data } = await this.$services.forms.post({ body: { forms: { remove: payload } }, toast: { message: 'Form Instance deleted successfully' } });
          const sync_api_payload = data.forms.removed.reduce((acc, uid) => {
            const integration_type = this.forms_map[uid]?.properties?.integration?.type;
            if (!acc[integration_type])
              acc[integration_type] = [];

            acc[integration_type].push({
              event: 'FORMS_DELETED',
              ...(integration_type === 'INTG_101'
                ? {
                    feature: this.forms_map[uid].properties.integration?.feature?.uid,
                    field: this.forms_map[uid].properties.integration?.field?.uid,
                  }
                : {
                    adjustment: this.forms_map[uid].properties.integration?.transaction?.uid,
                    item: this.forms_map[uid].properties.integration?.item?.uid,
                    form_template_uid: this.forms_map[uid]?.template?.uid || this.forms_map[uid]?.template,
                  }),
              form: {
                uid,
                status: 'deleted',
              },
            });

            this.forms_track_events('Deleted', uid, { mode: uids.length > 1 ? 'Bulk' : 'Single' });
            delete this.forms_map[uid];
            return acc;
          }, {});
          if (sync_api_payload?.INTG_101?.length) {
            const terra_store = useTerraStore();
            await terra_store.syncApiForInspectionForms(null, { payload: sync_api_payload.INTG_101 });
          }
          if (sync_api_payload?.INTG_201?.length) {
            const inventory_store = useInventoryStore();
            await inventory_store.syncApiForInspectionForms(null, { payload: sync_api_payload.INTG_201 });
          }
          this.forms_map = { ...this.forms_map };
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async get_templates(req) {
        try {
          const { data } = await this.$services.forms_templates.getAll(
            {
              query: {
                ...req?.query || {},
                asset_uid: this.$router.currentRoute.value.params.asset_id || null,
                page_size: 100000000,

              },
              body: {
                ...req.body,
              },
            },
          );
          this.form_template = data?.templates;
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async archiveForms(uids, successCallbackFn) {
        try {
          const { data } = await this.$services.forms.post({
            body: { forms: { archive: uids } },
          });
          this.forms_track_events('Archived', uids[0], { mode: uids.length > 1 ? 'Bulk' : 'Single', count: uids.length });

          data.forms.archived?.forEach(uid => delete this.forms_map[uid]);
          successCallbackFn?.(data);
        }
        catch (error) {
          this.$toast({ text: 'Form/s failed to archive!', type: 'error', position: 'bottom-right' });
          throw error;
        }
      },
      async unarchiveForms(uids, successCallbackFn) {
        try {
          const { data } = await this.$services.forms.post({
            body: { forms: { unarchive: uids } },
          });

          data.forms.unarchived?.forEach(uid => delete this.forms_map[uid]);
          successCallbackFn?.(data);
        }
        catch (error) {
          this.$toast({ text: 'Form/s failed to unarchive!', type: 'error', position: 'bottom-right' });
          throw error;
        }
      },
      async validateForms(form_uids) {
        try {
          const { data } = await this.$services.forms.post({
            body: {
              attribute: 'validate',
              forms: form_uids,
            },
          });
          return data.forms;
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async bulkSubmitForms(submission_payload) {
        const response = await this.$services.forms.post(
          {
            body: submission_payload,
          },
        );

        const forms_to_update = response.data.forms.updated.reduce((acc, form) => {
          acc[form.uid] = form;
          return acc;
        }, {});
        Object.assign(this.forms_map, forms_to_update);

        const form_uids = submission_payload.forms.update.map(form => form.uid);
        const form_validation_response = await this.validateForms(form_uids);

        form_validation_response.removed?.forEach((form) => {
          delete this.forms_map[form.uid];
        });

        return response;
      },
      get_properties(form) {
        const properties = [];
        if (form.name)
          properties.push('Name');
        if (form.category)
          properties.push('Category');
        if (form.tags?.length)
          properties.push('Tags');
        if (form.due_date)
          properties.push('Due date');
        if (form.start_date)
          properties.push('Start date');
        if (Object.keys(form.members || {}).length || Object.keys(form.teams || {}).length)
          properties.push('Assignees');
        return properties;
      },
      forms_track_events(event_name, form_uid, properties = {}, include_properties = ['associated_with', 'integration']) {
        const route = this.$router.currentRoute.value;
        const default_properties = { view: getPageNameByRouteName(route.name), uid: form_uid };
        const form_detail = this.forms_map[form_uid];
        if (form_detail) {
          if (include_properties.includes('associated_with'))
            default_properties.associated_with = `${form_detail?.target_element?.type?.toUpperCase()}`;
          if (include_properties.includes('integration'))
            default_properties.integration = form_detail?.properties?.integration?.type || null;
        }
        this.$track_event(event_name, {
          ...default_properties,
          ...properties,
          module: 'Forms',
        });
      },
    },
  })();
}
