<script setup>
import { computed, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import FormFilter from '~/forms/components/form-filter.vue';
import { useFormsStore } from '~/forms/store/forms.store';
import TaskFilters from '~/tasks/components/organisms/tasks-filters.vue';
import { useTasksStore } from '~/tasks/store/tasks.store';
import LabelSelectorPopup from '~/terra/components/settings/label-selector-popup.vue';
import { useTerraStore } from '../store/terra.store';

const emit = defineEmits(['saveView', 'setSymbols', 'toggleLabels']);
const $t = inject('$t');

const terra_store = useTerraStore();
const task_store = useTasksStore('terra_task_store');
const forms_store = useFormsStore('terra_form_store');

const task_filter_popup = useModal({
  component: TaskFilters,
  attrs: {
    filter_type: 'terra',
    onClose() {
      task_filter_popup.close();
    },
  },
});
const form_filter_popup = useModal({
  component: FormFilter,
  attrs: {
    filter_type: 'terra',
    onClose() {
      form_filter_popup.close();
    },
  },
});
const label_selector_popup = useModal({
  component: LabelSelectorPopup,
  attrs: {
    onClose() {
      label_selector_popup.close();
    },
    onUpdateLabels() {
      emit('toggleLabels');
      label_selector_popup.close();
    },
  },
});

const settings = computed(() => {
  return ({
    ...terra_store.settings,
    tasks_cluster: terra_store.tasks_cluster,
    forms_cluster: terra_store.forms_cluster,
  });
});

function openTasksFiltersPopup() {
  task_filter_popup.patchOptions({
    attrs: {
      fields: ['Group by', 'Status', 'Priority', 'Category', 'Assignees', 'Created By', 'Due Date', 'Starred', 'Archived'],
      filters: terra_store.tasks_cluster.filters,
      task_store,
      onFilter(val) {
        logger.log(val);
        terra_store.tasks_cluster.filters = val;
        emit('setSymbols');
        emit('saveView');
        task_filter_popup.close();
      },
    },
  });
  task_filter_popup.open();
}

function openFormFiltersPopup() {
  form_filter_popup.patchOptions({
    attrs: {
      fields: ['Template', 'Status', 'Category', 'Assignees', 'Created By', 'Due Date', 'Rolled Back'],
      filters: terra_store.forms_cluster.filters,
      forms_store,
      onFilter(val) {
        terra_store.forms_cluster.filters = { ...val, status: val?.submission_status || [] };
        emit('setSymbols');
        emit('saveView');
        form_filter_popup.close();
      },
    },
  });
  form_filter_popup.open();
}

function updateSettings(key, value) {
  const new_settings = settings.value;
  new_settings[key] = value;
  terra_store.settings = new_settings;
  if (key === 'filter_task_form_with_target_element')
    terra_store.terra_track_events('Settings - Filter for components');
  else if (key === 'display_labels')
    terra_store.terra_track_events('Settings - Show label');

  emit('saveView');
}
function toggleActive(key) {
  terra_store[key].active = !terra_store[key].active;
  const status = terra_store[key].active ? 'enabled' : 'hidden';
  if (key === 'tasks_cluster')
    terra_store.terra_track_events(`Tasks ${status}`);
  else if (key === 'forms_cluster')
    terra_store.terra_track_events(`Forms ${status}`);
  emit('setSymbols');
  emit('saveView');
}
</script>

<template>
  <div class="p-4">
    <div class="mb-6">
      <p class="font-semibold">
        {{ $t('Settings') }}
      </p>
    </div>
    <div class="flex cursor-pointer items-center py-1.5 rounded-lg justify-between hover:bg-gray-50">
      <div class="flex items-center">
        <HawkCheckbox id="labels" :model-value="settings.display_labels" @input="updateSettings('display_labels', !settings.display_labels);emit('toggleLabels')">
          {{ $t('Show labels') }}
        </HawkCheckbox>
      </div>
      <div class="cursor-pointer" @click="label_selector_popup.open()">
        <IconHawkSettingsOne class="w-[16px] h-[16px] text-gray-500" />
      </div>
    </div>
    <div class="flex cursor-pointer items-center py-1.5 rounded-lg justify-between hover:bg-gray-50">
      <HawkCheckbox id="fly" :model-value="settings.fly" @input="updateSettings('fly', !settings.fly)">
        {{ $t('Fly to bounds') }}
      </HawkCheckbox>
    </div>

    <div class="flex cursor-pointer items-center py-1.5 rounded-lg justify-between hover:bg-gray-50">
      <HawkCheckbox id="fly" :model-value="settings.show_counts" @input="updateSettings('show_counts', !settings.show_counts)">
        {{ $t('Show counts') }}
      </HawkCheckbox>
    </div>

    <hr class="border-b border-gray-200 my-2.5">

    <div class="flex cursor-pointer items-center py-1.5 rounded-lg justify-between hover:bg-gray-50">
      <div class="flex items-center ">
        <HawkCheckbox id="tasks" :model-value="settings.tasks_cluster.active" @input="toggleActive('tasks_cluster')">
          {{ $t('Tasks') }}
        </HawkCheckbox>
      </div>
      <div class="cursor-pointer" @click="openTasksFiltersPopup">
        <IconHawkSettingsOne class="w-[16px] h-[16px] text-gray-500" />
      </div>
    </div>
    <div class="flex cursor-pointer items-center py-1.5 rounded-lg justify-between hover:bg-gray-50">
      <div class="flex items-center">
        <HawkCheckbox id="forms" :model-value="settings.forms_cluster.active" @input="toggleActive('forms_cluster')">
          {{ $t('Forms') }}
        </HawkCheckbox>
      </div>
      <div class="cursor-pointer" @click="openFormFiltersPopup">
        <IconHawkSettingsOne class="w-[16px] h-[16px] text-gray-500" />
      </div>
    </div>
    <div class="flex cursor-pointer items-center py-1.5 rounded-lg justify-between hover:bg-gray-50">
      <HawkCheckbox id="filter_task_form_with_target_element" :model-value="settings.filter_task_form_with_target_element" @input="updateSettings('filter_task_form_with_target_element', !settings.filter_task_form_with_target_element); emit('setSymbols')">
        {{ $t('Filter for components') }}
      </HawkCheckbox>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.appearance-auto {
  appearance: auto !important;
}
</style>
