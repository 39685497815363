<script setup>
import { keys, pickBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();

const { $g, active_view, active_schedule, is_schedule_editable, tippy_target } = storeToRefs(project_management_store);

const critical_path = computed(() => active_view.value.data?.feature_visibility?.critical_path);

const check_items = computed(() => {
  return {
    activity_name: $t('Activity name'),
    percentage_complete: $t('% Complete'),
    links: $t('Links'),
    critical_path: $t('Critical path'),
    float: $t('Float'),
    today_line: $t('Today line'),
    overdue: $t('Overdue'),
    ...(is_schedule_editable.value
      ? {}
      : {
          data_date_line: $t('Data date line'),
        }),
    ...(!is_schedule_editable.value
      && active_schedule.value?.baseline
      ? {
          baseline: $t('Baseline'),
        }
      : {}),
    ...(active_schedule.value.deadline
      ? {
          deadline_line: $t('Deadline line'),
        }
      : {}),
  };
});

const default_checked_options = computed(
  () => keys(pickBy(active_view.value.data.feature_visibility)),
);

function onCheckboxGroupChange(value) {
  keys(active_view.value.data.feature_visibility || {}).forEach(key =>
    active_view.value.data.feature_visibility[key] = value.includes(key),
  );
  // shows the critical path in the chart
  $g.value.config.highlight_critical_path = critical_path.value;
  $g.value.render();
}
</script>

<template>
  <hawk-menu additional_trigger_classes="ring-gray-100">
    <template #trigger>
      <HawkButton
        v-tippy="{
          content: $t('Display settings'),
          placement: 'bottom',
          appendTo: tippy_target,
        }"
        icon
        type="text"
      >
        <IconHawkSettingsOne class="w-4 h-4" />
      </HawkButton>
    </template>
    <template #content>
      <div class="px-4 py-3 w-60">
        <template v-if="!is_schedule_editable">
          <div class="mb-2 text-sm font-semibold text-gray-900">
            {{ $t("Table settings") }}
          </div>
          <PmTimeRangeMenu />
          <PmLevelMenu v-if="active_view.data.group_by.length === 0" />
          <PmGroupByMenu class="mb-4" />
        </template>
        <div class="mb-1 text-sm font-semibold text-gray-900">
          {{ $t("Gantt settings") }}
        </div>
        <Vueform size="sm">
          <CheckboxgroupElement
            :items="check_items"
            :default="default_checked_options"
            :add-classes="{
              CheckboxgroupCheckbox: {
                container: 'my-2',
              },
            }"
            name="displayoptionscheckboxgroup"
            @change="onCheckboxGroupChange"
          />
        </Vueform>
      </div>
    </template>
  </hawk-menu>
</template>
