import { httpClient } from '~/common/middleware/axios.middleware';
import { $toast } from '~/common/utils/common.utils';

function handleErrors(error) {
  if (error.code === 'ERR_CANCELED') {
    logger.error(error);
  }

  else if (error?.response?.status === 404) {
    $toast({ text: 'Resource not found!', type: 'error', title: 'OOPS' });
  }

  else if (error?.response?.status === 403) {
    $toast({
      title: 'Error',
      text: 'You don\'t have the permission to perform this action. Please contact your admin for more information.',
      type: 'error',
    });
  }

  return error?.response || error;
}

export async function apiWrapper(options) {
  try {
    const response = await httpClient({ ...options });
    if (options.toast)
      $toast(options.toast);

    return response;
  }
  catch (error) {
    if (options.toast !== false)
      throw handleErrors(error);
    else throw error;
  }
}
