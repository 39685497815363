<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';
import { useThermStore } from '~/therm/store/therm.store';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const group_by = ref({ uid: 'status', label: $t('Status') });

const menu_items = computed(() => ([
  { uid: 'status', label: $t('Status') },
  { uid: 'severity', label: $t('Severity') },
  { uid: 'priority', label: $t('Priority') },
]));

const defect_status_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Defects by status'),
    id: 'defect_status_container',
    hide_download: true,
  };
});
const defect_status_graph_data = computed(() => {
  const status_data_map = therm_store.status_configs.reduce((acc, curr) => {
    acc[curr.uid] = 0;
    return acc;
  }, {});
  therm_store.report_defects.forEach((feature) => {
    const status = feature.status;
    if (status)
      status_data_map[status]++;
  });
  const data = [];
  for (const status_uid in status_data_map) {
    const status = therm_store.status_map[status_uid];
    data.push({
      label: status.name,
      value: status_data_map[status_uid],
      color: status.color,
    });
  }
  return { data, description: false, type: 'status' };
});

const severity_levels_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Defects by severity'),
    id: 'severity_levels_container',
    hide_download: true,
  };
});
const defect_severity_graph_data = computed(() => {
  const severity = {
    low: { label: 'Severity 1', value: 0, color: '#1570EF' },
    medium: { label: 'Severity 2', value: 0, color: '#53B1FD' },
    high: { label: 'Severity 3', value: 0, color: '#1849A9' },
  };
  therm_store.report_defects.forEach((feature) => {
    severity[feature.severity].value++;
  });
  const data = [];
  for (const severity_uid in severity) {
    data.push({
      uid: severity_uid,
      ...severity[severity_uid],
    });
  }
  return { data, description: true, type: 'severity' };
});

const defect_priority_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Defects by Priority'),
    id: 'defect_priority_container',
    hide_download: true,
  };
});

const defect_priority_graph_data = computed(() => {
  const priority_data_map = therm_store.priority_configs.reduce((acc, curr) => {
    acc[curr.uid] = 0;
    return acc;
  }, {});
  therm_store.report_defects.forEach((feature) => {
    const priority = feature.priority;
    if (priority)
      priority_data_map[priority]++;
  });
  const data = [];
  const color_map = {
    gray: '#5A6772',
    success: '#2D9A59',
    warning: '#FF7A29',
    error: '#FF5C4C',
  };
  for (const priority_uid in priority_data_map) {
    const priority = therm_store.priority_map[priority_uid];
    data.push({
      label: priority.name,
      value: priority_data_map[priority_uid],
      color: color_map[priority.color],
    });
  }
  return { data, description: false, type: 'defect' };
});

const graph_details = computed(() => {
  if (group_by.value.uid === 'status')
    return defect_status_graph_details.value;
  else if (group_by.value.uid === 'severity')
    return severity_levels_graph_details.value;
  else
    return defect_priority_graph_details.value;
});

const graph_data = computed(() => {
  if (group_by.value.uid === 'status')
    return defect_status_graph_data.value;
  else if (group_by.value.uid === 'severity')
    return defect_severity_graph_data.value;
  else
    return defect_priority_graph_data.value;
});
</script>

<template>
  <div class="border rounded-xl h-[540px] p-4">
    <ThermReportGraph
      :key="group_by.uid"
      :data="graph_data"
      :graph_details="graph_details"
      :has_borders="false"
      height="460"
    >
      <template #header-right>
        <HawkMenu
          :items="menu_items.map(item => ({ ...item, on_click: () => (group_by = item) }))"
          :has_bordered_trigger="false"
          additional_trigger_classes="!ring-0 p-0 -mt-1"
          additional_dropdown_classes="max-h-60 scrollbar"
          position="fixed"
        >
          <template #trigger="{ open }">
            <hawk-button type="outlined" size="xs" class="text-sm font-semibold !px-2 !py-0 focus:!ring-0">
              {{ group_by.label }}
              <IconHawkChevronDown
                class="text-white transition-transform"
                :class="{ 'rotate-180': open }"
              />
            </hawk-button>
          </template>
        </HawkMenu>
      </template>
    </ThermReportGraph>
  </div>
</template>
