import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { cloneDeep } from 'lodash-es';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

export function useFamConstants(translate) {
  dayjs.extend(quarterOfYear);
  const $t = translate || (text => text);

  const DEFAULT_COLUMNS = [
    { field: 'form_name' },
    { field: 'category' },
    { field: 'assignees' },
    { field: 'due_date' },
    { field: 'status' },
  ];
  const DEFAULT_DISPLAY_FILTERS = [
    { field: 'status' },
    { field: 'category' },
    { field: 'tags' },
    { field: 'assignees' },
    { field: 'due_date' },
  ];
  const DATE_FILTER_OPTIONS = {
    between: [
      { value: 'custom_range', label: `${$t('Custom range')}` },
      { value: 'last_7_days', label: `${$t('Last 7 days')}` },
      { value: 'next_7_days', label: `${$t('Next 7 days')}` },
      { value: 'last_30_days', label: `${$t('Last 30 days')}` },
      { value: 'next_30_days', label: `${$t('Next 30 days')}` },
      { value: 'week_to_date', label: `${$t('Week to date')}` },
      { value: 'this_week', label: `${$t('This week')}` },
      { value: 'last_week', label: `${$t('Last week')}` },
      { value: 'month_to_date', label: `${$t('Month to date')}` },
      { value: 'this_month', label: `${$t('This month')}` },
      { value: 'last_month', label: `${$t('Last month')}` },
      { value: 'quarter_to_date', label: `${$t('Quarter to date')}` },
      { value: 'this_quarter', label: `${$t('This quarter')}` },
      { value: 'last_quarter', label: `${$t('Last quarter')}` },
      { value: 'year_to_date', label: `${$t('Year to date')}` },
      { value: 'this_year', label: `${$t('This year')}` },
      { value: 'last_year', label: `${$t('Last year')}` },
    ],
    isNotBetween: [
      { value: 'custom_range', label: `${$t('Custom range')}` },
      { value: 'last_7_days', label: `${$t('Last 7 days')}` },
      { value: 'next_7_days', label: `${$t('Next 7 days')}` },
      { value: 'last_30_days', label: `${$t('Last 30 days')}` },
      { value: 'next_30_days', label: `${$t('Next 30 days')}` },
      { value: 'week_to_date', label: `${$t('Week to date')}` },
      { value: 'this_week', label: `${$t('This week')}` },
      { value: 'last_week', label: `${$t('Last week')}` },
      { value: 'month_to_date', label: `${$t('Month to date')}` },
      { value: 'this_month', label: `${$t('This month')}` },
      { value: 'last_month', label: `${$t('Last month')}` },
      { value: 'quarter_to_date', label: `${$t('Quarter to date')}` },
      { value: 'this_quarter', label: `${$t('This quarter')}` },
      { value: 'last_quarter', label: `${$t('Last quarter')}` },
      { value: 'year_to_date', label: `${$t('Year to date')}` },
      { value: 'this_year', label: `${$t('This year')}` },
      { value: 'last_year', label: `${$t('Last year')}` },
    ],
    isEqualTo: [
      { value: 'exact_date', label: `${$t('Exact date')}` },
      { value: 'today', label: `${$t('Today')}` },
      { value: 'yesterday', label: `${$t('Yesterday')}` },
      { value: 'last_7_days', label: `${$t('Last 7 days')}` },
      { value: 'next_7_days', label: `${$t('Next 7 days')}` },
      { value: 'last_30_days', label: `${$t('Last 30 days')}` },
      { value: 'next_30_days', label: `${$t('Next 30 days')}` },
      { value: 'week_to_date', label: `${$t('Week to date')}` },
      { value: 'this_week', label: `${$t('This week')}` },
      { value: 'last_week', label: `${$t('Last week')}` },
      { value: 'month_to_date', label: `${$t('Month to date')}` },
      { value: 'this_month', label: `${$t('This month')}` },
      { value: 'last_month', label: `${$t('Last month')}` },
      { value: 'quarter_to_date', label: `${$t('Quarter to date')}` },
      { value: 'this_quarter', label: `${$t('This quarter')}` },
      { value: 'last_quarter', label: `${$t('Last quarter')}` },
      { value: 'year_to_date', label: `${$t('Year to date')}` },
      { value: 'this_year', label: `${$t('This year')}` },
      { value: 'last_year', label: `${$t('Last year')}` },
    ],
    isNotEqualTo: [
      { value: 'exact_date', label: `${$t('Exact date')}` },
      { value: 'today', label: `${$t('Today')}` },
      { value: 'yesterday', label: `${$t('Yesterday')}` },
      { value: 'last_7_days', label: `${$t('Last 7 days')}` },
      { value: 'next_7_days', label: `${$t('Next 7 days')}` },
      { value: 'last_30_days', label: `${$t('Last 30 days')}` },
      { value: 'next_30_days', label: `${$t('Next 30 days')}` },
      { value: 'week_to_date', label: `${$t('Week to date')}` },
      { value: 'this_week', label: `${$t('This week')}` },
      { value: 'last_week', label: `${$t('Last week')}` },
      { value: 'month_to_date', label: `${$t('Month to date')}` },
      { value: 'this_month', label: `${$t('This month')}` },
      { value: 'last_month', label: `${$t('Last month')}` },
      { value: 'quarter_to_date', label: `${$t('Quarter to date')}` },
      { value: 'this_quarter', label: `${$t('This quarter')}` },
      { value: 'last_quarter', label: `${$t('Last quarter')}` },
      { value: 'year_to_date', label: `${$t('Year to date')}` },
      { value: 'this_year', label: `${$t('This year')}` },
      { value: 'last_year', label: `${$t('Last year')}` },
    ],
    isAfter: [
      { value: 'exact_date', label: `${$t('Exact date')}` },
      { value: 'today', label: `${$t('Today')}` },
      { value: 'after_start_of_this_week', label: `${$t('Start of this week')}` },
      { value: 'after_start_of_this_month', label: `${$t('Start of this month')}` },
      { value: 'after_start_of_this_quarter', label: `${$t('Start of this quarter')}` },
      { value: 'after_start_of_this_year', label: `${$t('Start of this year')}` },
    ],
    isBefore: [
      { value: 'exact_date', label: `${$t('Exact date')}` },
      { value: 'today', label: `${$t('Today')}` },
      { value: 'before_start_of_this_week', label: `${$t('Start of this week')}` },
      { value: 'before_start_of_this_month', label: `${$t('Start of this month')}` },
      { value: 'before_start_of_this_quarter', label: `${$t('Start of this quarter')}` },
      { value: 'before_start_of_this_year', label: `${$t('Start of this year')}` },
    ],
  };

  const ACCOUNT_SETTING_DATE_FILTERS = [
    {
      prefix: '15m',
      label: `${$t('Past 15 minutes')}`,
      value: 'past_15_minutes',
      get_value: () => getFormattedDate('past_15_minutes'),
    },
    {
      prefix: '30m',
      label: `${$t('Past 30 minutes')}`,
      value: 'past_30_minutes',
      get_value: () => getFormattedDate('past_30_minutes'),
    },
    {
      prefix: '1hr',
      label: `${$t('Past 1 hour')}`,
      value: 'past_1_hour',
      get_value: () => getFormattedDate('past_1_hour'),
    },
    {
      prefix: '1d',
      label: `${$t('Past 1 day')}`,
      value: 'past_1_day',
      get_value: () => getFormattedDate('past_1_day'),
    },
    {
      prefix: '1w',
      label: `${$t('Past 1 week')}`,
      value: 'past_1_week',
      get_value: () => getFormattedDate('past_1_week'),
    },
    {
      prefix: '2w',
      label: `${$t('Past 2 weeks')}`,
      value: 'past_2_weeks',
      get_value: () => getFormattedDate('past_2_weeks'),
    },
    {
      prefix: '1M',
      label: `${$t('Past 1 month')}`,
      value: 'last_30_days',
      get_value: () => getFormattedDate('last_30_days'),
    },
    {
      prefix: 'C',
      label: `${$t('Custom')}`,
      value: 'custom',
    },
  ];

  const DASHBOARD_GLOBAL_DATE_FILTERS = [
    {
      prefix: 'A',
      label: `${$t('All time')}`,
      value: 'all_time',
    },
    {
      prefix: '1d',
      label: `${$t('Past 1 day')}`,
      value: 'past_1_day',
      get_value: () => getFormattedDate('past_1_day'),
    },
    {
      prefix: 'T',
      label: `${$t('Today')}`,
      value: 'today',
      get_value: () => getFormattedDate('today'),
    },
    {
      prefix: 'Y',
      label: `${$t('Yesterday')}`,
      value: 'yesterday',
      get_value: () => getFormattedDate('yesterday'),
    },
    {
      prefix: '7d',
      label: `${$t('Last 7 days')}`,
      value: 'last_7_days',
      get_value: () => getFormattedDate('last_7_days'),
    },
    {
      prefix: '14d',
      label: `${$t('Last 14 days')}`,
      value: 'past_2_weeks',
      get_value: () => getFormattedDate('past_2_weeks'),
    },
    {
      prefix: '30d',
      label: `${$t('Past 30 days')}`,
      value: 'last_30_days',
      get_value: () => getFormattedDate('last_30_days'),
    },
    {
      prefix: '1w',
      label: `${$t('This week')}`,
      value: 'this_week',
      get_value: () => getFormattedDate('this_week'),
    },
    {
      prefix: '1m',
      label: `${$t('This month')}`,
      value: 'this_month',
      get_value: () => getFormattedDate('this_month'),
    },
    {
      prefix: '1q',
      label: `${$t('This quarter')}`,
      value: 'this_quarter',
      get_value: () => getFormattedDate('this_quarter'),
    },
    {
      prefix: '1y',
      label: `${$t('This year')}`,
      value: 'this_year',
      get_value: () => getFormattedDate('this_year'),
    },
    {
      prefix: 'C',
      label: `${$t('Custom')}`,
      value: 'custom',
    },
  ];

  function getFormattedDate(value) {
    let dates = [];
    switch (value) {
      case 'past_15_minutes':
        dates = [
          dayjs().subtract(15, 'minute'),
          dayjs(),
        ];
        break;
      case 'past_30_minutes':
        dates = [
          dayjs().subtract(30, 'minute'),
          dayjs(),
        ];
        break;
      case 'past_1_hour':
        dates = [
          dayjs().subtract(1, 'hour'),
          dayjs(),
        ];
        break;
      case 'past_1_day':
        dates = [
          dayjs().subtract(1, 'day'),
          dayjs(),
        ];
        break;
      case 'past_1_week':
        dates = [
          dayjs().subtract(1, 'week'),
          dayjs(),
        ];
        break;
      case 'past_2_weeks':
        dates = [
          dayjs().subtract(2, 'week'),
          dayjs(),
        ];
        break;
      case 'today':
        dates = [
          dayjs().startOf('day'),
          dayjs().endOf('day'),
        ];
        break;
      case 'yesterday':
        dates = [
          dayjs().subtract(1, 'day').startOf('day'),
          dayjs().subtract(1, 'day').endOf('day'),
        ];
        break;
      case 'last_7_days':
        dates = [
          dayjs().subtract(7, 'day').startOf('day'),
          dayjs().endOf('day'),
        ];
        break;
      case 'last_14_days':
        dates = [
          dayjs().subtract(14, 'day').startOf('day'),
          dayjs().endOf('day'),
        ];
        break;
      case 'next_7_days':
        dates = [
          dayjs().startOf('day'),
          dayjs().add(7, 'day').endOf('day'),
        ];
        break;
      case 'last_30_days':
        dates = [
          dayjs().subtract(30, 'day').startOf('day'),
          dayjs().endOf('day'),
        ];
        break;
      case 'next_30_days':
        dates = [
          dayjs().startOf('day'),
          dayjs().add(30, 'day').endOf('day'),
        ];
        break;
      case 'week_to_date':
        dates = [
          dayjs().startOf('week'),
          dayjs().endOf('day'),
        ];
        break;
      case 'this_week':
        dates = [
          dayjs().startOf('week').startOf('day'),
          dayjs().endOf('week'),
        ];
        break;
      case 'last_week':
        dates = [
          dayjs().subtract(1, 'week').startOf('week'),
          dayjs().subtract(1, 'week').endOf('week'),
        ];
        break;
      case 'month_to_date':
        dates = [
          dayjs().startOf('month'),
          dayjs().endOf('day'),
        ];
        break;
      case 'this_month':
        dates = [
          dayjs().startOf('month'),
          dayjs().endOf('month'),
        ];
        break;
      case 'last_month':
        dates = [
          dayjs().subtract(1, 'month').startOf('month'),
          dayjs().subtract(1, 'month').endOf('month'),
        ];
        break;
      case 'year_to_date':
        dates = [
          dayjs().startOf('year'),
          dayjs().endOf('day'),
        ];
        break;
      case 'this_year':
        dates = [
          dayjs().startOf('year'),
          dayjs().endOf('year'),
        ];
        break;
      case 'last_year':
        dates = [
          dayjs().subtract(1, 'year').startOf('year'),
          dayjs().subtract(1, 'year').endOf('year'),
        ];
        break;
      case 'quarter_to_date':
        dates = [
          dayjs().startOf('quarter'),
          dayjs().endOf('day'),
        ];
        break;
      case 'this_quarter':
        dates = [
          dayjs().startOf('quarter'),
          dayjs().endOf('quarter'),
        ];
        break;
      case 'last_quarter':
        dates = [
          dayjs().subtract(1, 'quarter').startOf('quarter'),
          dayjs().subtract(1, 'quarter').endOf('quarter'),
        ];
        break;
      case 'after_start_of_this_week':
      case 'before_start_of_this_week':
        dates = dayjs().startOf('week');
        break;
      case 'after_start_of_this_month':
      case 'before_start_of_this_month':
        dates = dayjs().startOf('month');
        break;
      case 'after_start_of_this_quarter':
      case 'before_start_of_this_quarter':
        dates = dayjs().startOf('quarter');
        break;
      case 'after_start_of_this_year':
      case 'before_start_of_this_year':
        dates = dayjs().startOf('year');
        break;
      case 'all_time':
        dates = [null, null];
        break;
      case 'dashboard_active_selected_range':
        dates = useDashboardStore()?.selected_date_range?.active_range || null;
        break;
      default: dates = null;
    }
    return dates;
  }

  function parseRulesDateData(rules) {
    const updated_rules = cloneDeep(rules);
    return updated_rules.map((rule) => {
      if (rule.operator_option)
        rule.value = getFormattedDate(rule.operator_option) || rule.value;
      return rule;
    });
  }

  return {
    DEFAULT_COLUMNS,
    DEFAULT_DISPLAY_FILTERS,
    DATE_FILTER_OPTIONS,
    ACCOUNT_SETTING_DATE_FILTERS,
    DASHBOARD_GLOBAL_DATE_FILTERS,
    getFormattedDate,
    parseRulesDateData,
  };
}
