<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { uniq } from 'lodash-es';
import { onBeforeMount, onMounted } from 'vue';

import { useRoute } from 'vue-router';
import useEmitter from '~/common/composables/useEmitter';
import { useChatStore } from '~/common/stores/chat.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useDocumentActions } from '~/dms/composables/document-actions.composable';
import DocumentCommentsList from './document-comments-list.vue';

const props = defineProps({
  document: {
    type: Object,
    default: null,
  },
  version: {
    type: String,
    default: null,
  },
  document_viewer: {
    type: Object,
    default: null,
  },
  can_comment: {
    type: Boolean,
    default: true,
  },
  members_with_access: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['update_version_from_viewer']);
const chat_store = useChatStore('plans-comment');
const route = useRoute();
const annotation_manager = props.document_viewer.getAnnotationManager();
const emitter = useEmitter();
const $t = inject('$t');
const common_store = useCommonStore();
const document_actions = useDocumentActions({ source: 'comments' });

function focus_annotation(comment) {
  const annotations = annotation_manager.getAnnotationsList();
  if (!comment) {
    annotation_manager.showAnnotations(annotations);
  }
  else if (comment?.document_annotation_uid) {
    annotation_manager.hideAnnotations(annotations.filter(annot => annot.Id !== comment.document_annotation_uid));
    const annotation = annotation_manager.getAnnotationById(comment.document_annotation_uid);
    if (annotation) {
      annotation_manager.jumpToAnnotation(annotation, { isSmoothScroll: true });
    }
  }
}

const selected_annotation = ref(undefined);
const document = computed(() => props.document);
onMounted(() => {
  emitter.on('single_annotation_selected_from_viewer', (annotation) => {
    selected_annotation.value = annotation?.Id;
  });
});

onBeforeMount(() => {
  emitter.off('single_annotation_selected_from_viewer');
});

function validateComment({ mentions, share_and_comment }) {
  const members = document.value.members.map(member => member.uid);
  const hierarchy_members = props?.members_with_access || document.value.hierarchy_members.map(member => member.user_uid);
  const existing_members = uniq([].concat(members, hierarchy_members));
  const remaining_members = mentions.filter(mention => !existing_members.includes(mention.uid));
  const remaining_members_names = remaining_members.map(mention => common_store.get_user_or_team_name(mention.uid));
  if (remaining_members.length) {
    const invalid_mentions = { members: remaining_members_names.join(', '), can_share: document.value.actions.can_share };
    if (share_and_comment)
      return document_actions.openShareModal({ item: document.value, prefilled_users: remaining_members.map(member => ({ uid: member.uid, access: 'read' })) });
    return invalid_mentions;
  }
}
</script>

<template>
  <div>
    <div class="z-1 absolute right-4 top-20 flex flex-col bg-white rounded-md shadow-md w-[400px] h-[88vh] p-4">
      <div class="flex justify-between items-center">
        <div v-if="!selected_annotation" class="text-gray-600 font-medium">
          {{ $t('All comments') }}
        </div>
        <div v-else class="flex justify-between items-center text-primary-700 cursor-pointer" @click="emitter.emit('focusAnnotation', { annotation_id: null })">
          <icon-hawk-arrow-left class="mr-1" />
          {{ $t('Go back') }}
        </div>
        <hawk-select v-if="document && document.versions" :options="document.versions.map(v => ({ label: v.version, id: v.uid }))" :default_index="document.versions.findIndex(version => version?.uid === props.version)" @input="version => version && version !== props.version ? emit('update_version_from_viewer', version.id) : null" />
      </div>
      <div v-if="selected_annotation" class="flex border p-3 items-center border-gray-600 rounded-lg shadow-xs text-gray-500 text-xs my-2">
        <icon-hawk-hexagon-one class="text-orange-500 w-4 h-4 mr-2" />
        {{ $t('Comments related to selected annotation') }}
      </div>
      <DocumentCommentsList
        v-if="chat_store.chat_channel"
        :key="selected_annotation"
        :chat_store="chat_store"
        :asset_id="route.params.asset_id"
        :item_uid="document.uid"
        :header_height="100"
        :last_viewed_at="document.last_viewed_at"
        :can_comment="can_comment"
        :disabled_controls="['settings', 'attach', 'like', 'pin']"
        :filter_comments="selected_annotation ? (comments) => comments.filter(comment => comment.document_annotation_uid === selected_annotation) : undefined"
        module="documents"
        :custom_message_properties="{ document_annotation_uid: selected_annotation }"
        :validate_comment="validateComment"
        @mouseenter="comment_id => focus_annotation(comment_id)"
        @mouseleave="focus_annotation()"
      />
    </div>
  </div>
</template>
