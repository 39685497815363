<script setup>
import { capitalize, isEqual, lowerCase } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useTagsStore } from '~/common/stores/tags.store';
import DashboardActivitiesListModal from '~/dashboard/components/dashboard-activities-list-modal.vue';
import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const tags_store = useTagsStore();

const { common_store } = useCommonImports();

const dashboard_schedule_store = useDashboardScheduleStore();

const $services = inject('$services');
const graph_data = ref(null);
const loading = ref(false);
const payload = ref(null);

const activities_list_modal = useModal({
  component: DashboardActivitiesListModal,
});

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  let type;
  switch (props.data?.data?.type) {
    case 'project_progress':
      type = props.data?.data?.chart_type === 'donut' ? 'doughnut2d' : 'angulargauge';
      break;
    case 'project_duration':
      type = 'angulargauge';
      break;
    case 'planned_vs_actual':
      type = props.data?.data.chart_type === 'vertical_graph' ? 'stackedcolumn2d' : 'stackedbar2d';
      break;
    case 'progress_history':
      type = 'stackedcolumn2d';
      break;
    case 's_curve':
      type = props.data.data?.dual_axis ? 'mscombidy2d' : 'mscombi2d';
      break;
    case 'earned_value_analysis':
      type = 'msspline';
      break;

    case 'vertical_graph':
      type = 'stackedcolumn2d';
      break;
    case 'horizontal_bar':
      type = 'stackedbar2d';
      break;
    case 'donut':
      type = 'doughnut2d';
      break;
    case 'activities_breakdown':
    case 'activities_chart':
      switch (props.data?.data?.chart_type) {
        case 'vertical_graph':
          type = 'scrollstackedcolumn2d';
          break;
        case 'horizontal_bar':
          type = 'stackedbar2d';
          break;
        case 'donut':
          type = 'doughnut2d';
          break;
      }
      break;
    case 'cost_work_history':
      switch (props.data?.data?.chart_type) {
        case 'bar':
          if (payload.value?.data?.cumulative)
            type = payload.value?.data?.dual_axis ? 'stackedcolumn2dlinedy' : 'stackedcolumn2dline';
          else
            type = 'stackedcolumn2d';
          break;
        case 'line':
          type = 'msspline';
          break;
      }
  }
  if (chart_config.value?.chart_display_mode === 'Unstacked' && type === 'stackedcolumn2d')
    return 'mscolumn2d';
  if (chart_config.value?.chart_display_mode === 'Unstacked' && type === 'stackedbar2d')
    return 'msbar2d';
  return type;
});

function x_axis_name() {
  if (props.data?.data?.chart_config?.x_label) {
    return props.data?.data?.chart_config?.x_label;
  }

  else
    if (payload.value?.x?.label) {
      let text = payload.value.x.label;
      if (text.includes('activity_code_values_map.'))
        text = text.replace('activity_code_values_map.', 'Activity code: ');

      return capitalize((text));
    }
    else { return ''; }
}

const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    chart: {
      xAxisName: x_axis_name(),
      yAxisName: props.data?.data?.chart_config?.y_label || capitalize(lowerCase(payload.value?.y?.label || '')),
      // plotToolText: toolTipText(),
      numbersuffix: '',
      theme: 'fusion',
      labelDisplay: 'Auto',
      useEllipsesWhenOverflow: '0',
      yAxisValuesStep: '2',
      gaugeOuterRadius: 150,
      connectNullData: 1,
      drawAnchors: 0,
      subCaption: props.data?.data?.chart_config?.description
        || props.data.data.subCaption || '',

    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.type,
    ...graph_data.value,
  },
  events: {
    dataPlotClick: (e) => {
      window.getActivities = (query) => {
        const popup_query = JSON.parse(atob(query));
        activities_list_modal.patchOptions({
          attrs: {
            id: props.id,
            name: `${e?.data?.id} - ${props.data?.data?.name} `,
            payload: props.data,
            popup_query,
            onClose() {
              activities_list_modal.close();
            },
          },
        });
        activities_list_modal.open();
      };
    },
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_schedule_store.parse_schedule_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.project_management.get_graph({ body: payload.value });
    graph_data.value = updateChartData(data?.data);
    loading.value = false;
  }
  catch (err) {
    logger.log({ err });
    loading.value = false;
  }
}

function updateChartData(chartData) {
  if (!chartData?.meta_data) {
    return chartData; // Return unchanged if structure is incorrect
  }

  const metadata = chartData.meta_data;
  const resourceMap = new Map();

  // Dynamically process all possible metadata keys
  ['resources', 'category', 'tags', 'priority'].forEach((key) => {
    const metaValue = metadata[key];
    if (Array.isArray(metaValue)) {
      metaValue.forEach((item) => {
        if (typeof item === 'string') {
          // Handle category and tags where values are direct UIDs
          resourceMap.set(item, fetchDataValues(item, key));
        }
        else if (item?.uid) {
          // Handle resources, etc.
          if (item.external_id) {
            resourceMap.set(item.uid, fetchDataValues(item.external_id, item.type || null));
          }
          else if (item.name) {
            resourceMap.set(item.uid, item.name);
          }
        }
      });
    }
  });

  if (chartData.data) {
    chartData.data.forEach((item) => {
      if (item.label && resourceMap.has(item.label)) {
        item.label = resourceMap.get(item.label); // Replace with fetched value
      }
    });
  }

  if (chartData.dataset) {
    chartData.dataset.forEach((series) => {
      if (resourceMap.has(series.seriesname)) {
        series.seriesname = resourceMap.get(series.seriesname);
      }
    });
  }

  if (chartData.categories) {
    chartData.categories.forEach((categoryGroup) => {
      categoryGroup.category.forEach((category) => {
        if (resourceMap.has(category.label)) {
          category.label = resourceMap.get(category.label);
        }
      });
    });
  }

  return chartData; // Return the updated object
}

function fetchDataValues(id, type) {
  if (type === 'member') {
    const user = common_store.users.find(a => a.uid === id);
    return `${user.first_name || ''} ${user.last_name || ''}` || user.email;
  }
  else if (type === 'category') {
    return common_store.get_category(id)?.name;
  }
  else if (type === 'tags') {
    return tags_store.get_tag(id)?.name;
  }
  else if (type === 'priority') {
    const priority_values = {
      1: 'Critical',
      2: 'High',
      3: 'Medium',
      4: 'Low',
      5: 'Not set',
    };
    return priority_values[id];
  }
}
if (props.id === 'preview') {
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        getReports();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

if (props.id !== 'preview') {
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        getReports();
      }, 250);
    }
  }, { immediate: true, deep: true });
}
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />
    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config" :group_key="payload?.group?.label" />
    <div v-if="props.data?.data?.type === 'earned_value_analysis'" class="flex justify-evenly h-[50px]">
      <div class="flex flex-col">
        <div class="text-xs font-medium text-gray-600">
          {{ $t('Schedule Performance Index (SPI)') }}
        </div>
        <div
          class="text-lg font-semibold" :class="graph_data?.meta_data?.spi >= 1 ? 'text-success-700' : 'text-error-700'"
        >
          {{ graph_data?.meta_data?.spi }}
        </div>
      </div>
      <div class="flex flex-col">
        <div class="text-xs font-medium text-gray-600">
          {{ $t('Schedule variance') }}
        </div>
        <div
          class="text-lg font-semibold"
        >
          {{ graph_data?.meta_data?.schedule_variance }}
        </div>
      </div>
      <div class="flex flex-col">
        <div class="text-xs font-medium text-gray-600">
          {{ $t('Cost Performance Index (CPI)') }}
        </div>
        <div class="text-lg font-semibold" :class="graph_data?.meta_data?.spi >= 1 ? 'text-success-700' : 'text-error-700'">
          {{ graph_data?.meta_data?.cpi }}
        </div>
      </div>
      <div class="flex flex-col">
        <div class="text-xs font-medium text-gray-600">
          {{ $t('Cost variance') }}
        </div>
        <div
          class="text-lg font-semibold"
        >
          {{ graph_data?.meta_data?.cost_variance }}
        </div>
      </div>
    </div>
  </div>
</template>
