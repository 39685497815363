<script setup>
import TaskComment from '~/tasks/pages/task-details-new/task-comments.vue/comment/task-comment.vue';
import TaskActivity from '~/tasks/pages/task-details-new/task-comments.vue/activity/task-activity.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  task_uid: {
    type: String,
    required: true,
  },
  asset_id: {
    type: String,
    default: '',
  },
  show_activities: {
    type: Boolean,
    required: true,
  },
});

const comment_refs = ref({});
const active_comment_id = ref(null);

function handleActiveEditor(id, val) {
  if (val) {
    if (active_comment_id.value && active_comment_id.value !== id)
      comment_refs.value[active_comment_id.value].closeReply();
    active_comment_id.value = id;
  }
  else {
    active_comment_id.value = null;
  }
}
function hasItems(key) {
  if (!props.show_activities) {
    const items = props.data[key].filter(item => !item.verb);
    return items.length;
  }
  return true;
}
defineExpose({ active_comment_id });
</script>

<template>
  <div>
    <div v-for="(value, key, index) in props.data" :key="index" :class="hasItems(key) ? 'p-3' : ''">
      <div v-if="hasItems(key)" class="flex width-full items-center">
        <div class="border-t my-8 w-1/2" :class="{ 'border-red-600': key === 'true' }" />
        <p class="mx-2 text-sm whitespace-nowrap" :class="{ 'text-red-600': key === 'true' }">
          {{ key === 'true' ? 'Unread' : $date(key, 'DATE_LONG_WITH_WEEKDAY') }}
        </p>
        <div class="border-t my-8 w-1/2" :class="{ 'border-red-600': key === 'true' }" />
      </div>
      <div v-for="item in value" :key="item.uid" class="w-full">
        <TaskActivity v-if="item.verb" v-show="show_activities" :activity="item" />
        <TaskComment
          v-else
          :ref="el => comment_refs[item.id] = el"
          :comment="item"
          :task_uid="task_uid"
          :asset_id="props.asset_id"
          @activeEditor="handleActiveEditor"
        />
      </div>
    </div>
  </div>
</template>
