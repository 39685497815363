<!-- eslint-disable vue/prop-name-casing -->
<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default_index: {
      required: false,
      default: 0,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    listbox_classes: {
      type: Array,
      default: () => [],
    },
    is_multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selected: this.is_multiple ? this.default_index.map(index => this.options?.[index]) : this.options?.[this.default_index],
    };
  },
  computed: {
    listbox_classes_str() {
      return this.listbox_classes.join(' ');
    },
    are_all_options_selected() {
      return this.selected.length === this.options.length;
    },
  },
  watch: {
    selected: {
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<template>
  <Listbox v-model="selected" :multiple="is_multiple" as="div">
    <div class="relative font-semibold w-full">
      <ListboxButton
        class="cursor-pointer relative w-full rounded-lg bg-white text-left focus:outline-none sm:text-sm"
        :class="{ 'shadow-sm border border-gray-300 focus:border-gray-500 focus:ring-1 focus:ring-gray-500': bordered }"
      >
        <slot name="trigger">
          <span v-if="is_multiple" class="block">
            <span v-for="(item, index) in selected" :key="index">
              <span>{{ item.label }}</span>
              <span v-if="index < selected.length - 1">, </span>
            </span>
          </span>
          <span v-else class="block truncate">{{ selected?.label ?? 'None' }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <IconHawkChevronDown class="ml-auto text-gray-400 min-w-5" />
          </span>
        </slot>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="min-w-max ring-current/5 absolute z-10 top-full max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 focus:outline-none sm:text-sm scrollbar"
        >
          <div v-if="are_all_options_selected">
            <slot name="no_options_found">
              <div class="py-2 pl-3 pr-9 text-sm font-semibold text-gray-600">
                No options to display
              </div>
            </slot>
          </div>
          <template v-else>
            <ListboxOption
              v-for="option in options"
              :key="option.id"
              v-slot="{ active, selection, selected: option_selected }"
              as="div"
              :value="option"
            >
              <slot name="option" :option="option" :active="active" :selection="selection" :option_selected="option_selected">
                <div
                  v-if="!option_selected"
                  class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900"
                  :class="{ 'bg-gray-100': active }"
                >
                  <span class="block truncate" :class="[selection ? 'font-semibold' : 'font-normal']">
                    {{ option.label }}
                  </span>
                </div>
              </slot>
            </ListboxOption>
          </template>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
