<script setup>
import { useElementSize } from '@vueuse/core';
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FeatureActionsToolbar from '~/terra/components/feature-details/actions-toolbar/feature-actions-toolbar.vue';
import FeatureDetails from '~/terra/components/feature-details/feature-details.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  mapStyle: {
    type: String,
    default: '',
  },
  sidebarState: {
    type: Object,
    required: true,
  },
  taskMarkerActive: {
    type: Boolean,
    default: false,
  },
  formMarkerActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'handleMapStyleChange',
  'filterGlDrawConvertedFeatures',
  'openCreationPopup',
  'updateTableActive',
]);

const { $t, auth_store } = useCommonImports();

const terra_store = useTerraStore();

// Container ref and size tracking
const footer_container = ref(null);
const { width: containerWidth } = useElementSize(footer_container);

// Calculate available width for the toolbar
const available_toolbar_width = computed(() => {
  // Start with the container width
  let availableWidth = containerWidth.value;

  // Subtract space for the map style toggle (if present)
  if (props.mapStyle) {
    availableWidth -= 60; // Width of the satellite/street image + margins
  }

  // Subtract space for the feature details (if present)
  if (terra_store.selected_features?.length >= 1 && !props.taskMarkerActive && !props.formMarkerActive) {
    // Approximate width of feature details component
    availableWidth -= 360;
  }

  // Subtract spacing between elements
  availableWidth -= 16; // space-x-4 class adds 16px of spacing

  return Math.max(availableWidth, 200); // Ensure a minimum width
});
const show_feature_components = computed(() => {
  return Boolean(
    terra_store.selected_features?.length >= 1
    && !props.taskMarkerActive
    && !props.formMarkerActive,
  );
});

// Methods
function handleMapStyleChange() {
  emit('handleMapStyleChange');
}

function filterGlDrawConvertedFeatures(payload) {
  emit('filterGlDrawConvertedFeatures', payload);
}

function openCreationPopup(type) {
  emit('openCreationPopup', type);
}

function updateTableActive() {
  emit('updateTableActive', true);
  terra_store.table_state.should_sort_selected_features = true;
}
</script>

<template>
  <div>
    <div
      ref="footer_container"
      :style="`${
        Boolean(sidebarState.active_menu.length)
          ? `width:calc(100vw - 400px);`
          : `width:calc(100vw - 100px);`
      }`"
      class="fixed bottom-3 flex justify-between items-end space-x-4 w-auto"
    >
      <div
        v-if="mapStyle"
        v-tippy="!mapStyle.includes('satellite') ? $t('Satellite') : $t('Street')"
        @click="handleMapStyleChange"
      >
        <img
          v-if="!mapStyle.includes('satellite')"
          src="../assets/satellite.jpg" alt="Satellite" class="cursor-pointer !rounded-lg border-2 border-white"
        >
        <img
          v-else
          src="../assets/street.jpg" alt="Street" class="cursor-pointer !rounded-lg border-2 border-white"
        >
      </div>
      <template v-if="auth_store.check_split('feature_actions_improvements') && show_feature_components">
        <FeatureActionsToolbar
          class="mb-0.5"
          :available-width="available_toolbar_width"
          @open-creation-popup="openCreationPopup"
        />
        <div class="w-[352px] z-[-100] h-1 bg-transparent" />
      </template>
    </div>
    <FeatureDetails
      v-if="show_feature_components"
      class="fixed bottom-3 right-3"
      @filter-gl-draw-converted-features="filterGlDrawConvertedFeatures"
      @is-table-active="updateTableActive"
      @open-creation-popup="openCreationPopup"
    />
  </div>
</template>
