import { CORE_API_HOST, PM_API_HOST, TERRA_API_HOST } from '../utils/api-hosts';

export default {
  features: {
    url: 'features',
    baseURL: TERRA_API_HOST,
    methods: {
      associate_workflow: req => ({
        url: `features/container/${req.container_id}/workflow/${req.id}`,
        data: req.body,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      reset_workflow_progress: req => ({
        url: `container/${req.container_id}/workflow-progress-reset/`,
        data: req.body,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req?.toast,
      }),
      update_workflow_progress: req => ({
        url: `features/container/${req.container_id}/workflow-progress`,
        data: req.body,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      get_reference_vectors: req => ({
        url: `features/asset/${req.asset_id}/reference-vectors/`,
        method: 'POST',
        data: req.body,
      }),
      get_feature_properties: req => ({
        url: 'features/properties',
        method: 'POST',
        data: req.body,
      }),
      get_feature_properties_operations: req => ({
        url: 'features/properties/operations',
        method: 'POST',
        data: req.body,
      }),
    },
  },
  feature_types: {
    url: 'feature-types',
    baseURL: TERRA_API_HOST,
    methods: {
      move: req => ({
        url: 'feature-types/move',
        data: req.body,
        method: 'post',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  feature_type_groups: {
    url: 'feature-type-groups',
    baseURL: TERRA_API_HOST,
    methods: {
      assign_to_view: req => ({
        url: `container-views/${req.id}/ftg/add/`,
        method: 'post',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  terra: {
    url: '',
    baseURL: TERRA_API_HOST,
    methods: {
      generate_mb_tiles: req => ({
        url: `containers/${req.container_id}/generate-mbtiles`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'post',
        baseURL: CORE_API_HOST,
      }),
      get_projects: req => ({
        url: `list-projects`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'post',
        baseURL: CORE_API_HOST,
      }),
      get_progress_updates_metrics: req => ({
        url: `container/${req.container_id}/metrics/progress-updates`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      get_range_analytics_progress_history_metrics: req => ({
        url: `container/${req.container_id}/metrics/progress-history`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'post',
      }),
      get_container_schedules: req => ({
        url: `/schedules/${req.schedule_id}/containers/${req.container_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: PM_API_HOST,
      }),
      get_terra_project_details: req => ({
        url: `groups/${req.group_id}/projects/${req.project_id}/`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req.toast,
        method: 'get',
        baseURL: CORE_API_HOST,
      }),
      getExtraPropsData: (req = {}) => ({
        url: `csv/container/${req.container_id}/generate-csv`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body || {},
        method: req.method || 'get',
      }),
      getExtraPropsDataSave: (req = {}) => ({
        url: `csv/container/${req.container_id}/import-csv`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'post',
        data: req.body,
      }),
      share: req => ({
        url: req.url,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: req.method,
        baseURL: CORE_API_HOST,
      }),
    },
  },

  terra_view_service: {
    url: 'container-views',
    baseURL: TERRA_API_HOST,
    methods: {
      get_elements: req => ({
        url: 'elements/feature/',
        method: 'post',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      export_progress_data: (req = {}) => ({
        url: 'progress-data',
        method: 'POST',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      get_graph: req => ({
        url: 'reports',
        data: req.body,
        method: 'POST',
      }),
      get_activities_table: req => ({
        url: 'reports/new',
        data: req.body,
        method: 'POST',
      }),
    },
  },
  terra_workflow: {
    url: 'workflows',
    baseURL: TERRA_API_HOST,
    methods: {},
  },
  terra_workflow_service: {
    url: 'workflows',
    baseURL: TERRA_API_HOST,
    methods: {},
  },
};
