import { CORE_API_HOST, INTEGRATIONS_API_HOST, STORAGE_API_HOST } from '../utils/api-hosts';
import { generateAssetScopeRequest } from './services.helper';

export default {
  integrations: {
    url: '/details',
    methods: {
      authorize_integration: req => ({
        url: `/auth/${req.integration_type}/authorize`,
        method: 'GET',
      }),
    },
    baseURL: INTEGRATIONS_API_HOST,
  },
  search: {
    url: 'search',
    baseURL: CORE_API_HOST,
  },
  notifications: {
    url: 'notifications',
    baseURL: CORE_API_HOST,
  },
  custom_views: {
    url: 'views',
    baseURL: CORE_API_HOST,
  },
  organizations: {
    url: 'organizations',
    baseURL: CORE_API_HOST,
    methods: {
      invite_users: req => ({
        url: `organizations/${req.organization_id}/invite_users`,
        method: 'POST',
        data: req.body,
      }),
      generate_upload_url: req => ({
        url: 'upload',
        method: 'POST',
        data: req.body,
        baseURL: STORAGE_API_HOST,
      }),
      setup_sample_project: req => ({
        url: 'setup-sample-project',
        method: 'POST',
        data: req.body,
      }),
    },
  },

  assets: {
    url: 'assets',
    baseURL: CORE_API_HOST,
    methods: {
      get_asset_reports: req => ({
        url: `assets/${req.id}/reports`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  fields: {
    url: 'fields',
    baseURL: CORE_API_HOST,
  },
  fieldvalues: {
    url: 'fieldvalues',
    baseURL: CORE_API_HOST,
  },
  containers: {
    baseURL: CORE_API_HOST,
    methods: {
      get: (req = {}) => ({
        url: `assets/${req.asset_id}/containers`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      post: (req = {}) => ({
        url: `assets/${req.asset_id}/containers`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'POST',
      }),
      patch: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'PATCH',
      }),
      delete: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'DELETE',
      }),
    },
  },
  groups: {
    baseURL: CORE_API_HOST,
    methods: {
      get: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      post: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'POST',
      }),
      patch: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups/${req.group_id}`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'PATCH',
      }),
      delete: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups/${req.group_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'DELETE',
      }),
    },
  },
  projects: {
    baseURL: CORE_API_HOST,
    methods: {
      get: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups/${req.group_id}/projects`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      post: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups/${req.group_id}/projects`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'POST',
      }),
      patch: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups/${req.group_id}/projects/${req.project_id}`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'PATCH',
      }),
      delete: (req = {}) => ({
        url: `assets/${req.asset_id}/containers/${req.container_id}/groups/${req.group_id}/projects/${req.project_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'DELETE',
      }),
      get_project_report_download_url: req => ({
        url: `projects/${req.project_id}/reports/${req.report_id}/download`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  users: {
    url: 'users',
    baseURL: CORE_API_HOST,
    methods: {
      get_solarlabs_token: req => ({
        url: 'auth/solarlabs/get-token',
        method: 'POST',
        data: req?.body || {},
        toast: req.toast,
      }),
      get_eanalytix_token: req => ({
        url: 'auth/eanalytix/get-token',
        method: 'POST',
        data: req?.body || {},
        toast: req.toast,
      }),
      get_invites: req => ({
        url: `organizations/${req.organization_id}/invitations`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      resend_invite: req => ({
        url: `organizations/${req.organization_id}/resend_emails/`,
        method: 'POST',
        data: req.body,
        ...(req.headers && { headers: req.headers }),
      }),
      revoke_invite: req => ({
        url: `organizations/${req.organization_id}/cancel_invites/`,
        method: 'POST',
        data: req.body,
        ...(req.headers && { headers: req.headers }),
      }),
      change_password: req => ({
        url: `users/${req.id}/credentials/change_password`,
        method: 'PUT',
        data: req.body,
      }),
      notifications: req => ({
        url: `users/${req.id}/notifications`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      generate_presigned_url: req => ({
        url: 'upload',
        data: req.body,
        method: 'POST',
        baseURL: STORAGE_API_HOST,
      }),
      save_profile_picture: req => ({
        url: 'me/display-picture',
        data: req.body,
        method: 'POST',
      }),
      get_activities: req => ({
        url: `activities/feeds/user:${req.id}/`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      update_activity: req => ({
        url: 'activities/feeds',
        method: 'PATCH',
        data: req.body,
      }),
      get_activities_count: req => ({
        url: `activities/feeds/user:${req.user_id}/unread?rule=$feed&type=notification`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  teams: {
    url: 'teams',
    baseURL: CORE_API_HOST,
    methods: {
      duplicate: req => ({
        url: `teams/${req.id}/duplicate`,
        method: 'POST',
        data: req.body,
      }),
    },
  },
  roles: {
    url: 'roles',
    baseURL: CORE_API_HOST,
    methods: {
      duplicate: req => ({
        url: `roles/${req.id}/duplicate`,
        method: 'POST',
        data: req.body,
      }),
    },
  },
  categories: {
    url: 'categories',
    baseURL: CORE_API_HOST,
  },
  permissions: {
    url: 'permissions',
    baseURL: CORE_API_HOST,
  },
  tags_service: {
    url: 'tags',
    baseURL: CORE_API_HOST,
  },
  // Account settings v2
  users_v2: {
    url: 'users',
    baseURL: CORE_API_HOST,
    methods: {
      get: req => generateAssetScopeRequest(req, `users/${req.id}`, 'get'),
      post: req => generateAssetScopeRequest(req, 'users', 'post'),
      patch: req => generateAssetScopeRequest(req, `users/${req.id}`, 'patch'),
      asset_admins_patch: req => generateAssetScopeRequest(req, 'users/asset-admins', 'patch'),
      global_admins_patch: req => generateAssetScopeRequest(req, 'users/global-admins', 'patch', CORE_API_HOST),
    },
  },
  teams_v2: {
    url: 'teams',
    baseURL: CORE_API_HOST,
    methods: {
      get: req => generateAssetScopeRequest(req, `teams/${req.id}`, 'get'),
      post: req => generateAssetScopeRequest(req, 'teams', 'post'),
      patch: req => generateAssetScopeRequest(req, `teams/${req.id}`, 'patch'),
    },
  },
  roles_v2: {
    url: 'roles',
    baseURL: CORE_API_HOST,
    methods: {
      get: req => generateAssetScopeRequest(req, `roles/${req.id}`, 'get'),
      post: req => generateAssetScopeRequest(req, 'roles', 'post'),
      patch: req => generateAssetScopeRequest(req, `roles/${req.id}`, 'patch'),
    },
  },
  permissions_v2: {
    url: 'permissions',
    baseURL: CORE_API_HOST,
    methods: {
      permissions_summary: req => generateAssetScopeRequest(req, `users/${req.id}/permission-summary`, 'get'),
    },
  },
};
