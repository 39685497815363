<script setup>
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useAuthStore } from '~/auth/stores/auth.store';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

const auth_store = useAuthStore();

const state = reactive({
  is_maintenance_banner_close_manually: false,
});

const upcoming_maintenance_data = {
  is_maintenance_upcoming: false,
  // March 16, 2025 from 11:30 am - 3:30 pm IST
  start_time: new Date('2025-03-16T11:30:00+05:30'),
  end_time: new Date('2025-03-16T15:30:00+05:30'),
};

const is_maintenance_banner_visible = computed(() => {
  if (upcoming_maintenance_data.is_maintenance_upcoming && !(state.is_maintenance_banner_close_manually || sessionStorage.getItem('is_maintenance_banner_closed'))) {
    const current_time = dayjs();
    return current_time.isBefore(upcoming_maintenance_data.start_time);
  }
  return false;
});

const maintenanceInfo = computed(() => {
  return {
    date: dayjs(upcoming_maintenance_data.start_time).format('MMMM D, YYYY'),
    startTime: dayjs(upcoming_maintenance_data.start_time).format('h:mma'),
    endTime: dayjs(upcoming_maintenance_data.end_time).format('h:mma'),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
});

function onCloseMaintenanceBanner() {
  state.is_maintenance_banner_close_manually = true;
  sessionStorage.setItem('is_maintenance_banner_closed', true);
}
</script>

<template>
  <div v-if="auth_store.logged_in_user_id">
    <transition
      leave-active-class="transition ease-out duration-1000"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="is_maintenance_banner_visible" class="bg-yellow-400 flex items-center justify-center text-sm p-3">
        <span>
          {{ $t('Please note that TaskMapper will be offline for planned maintenance on') }}
          <span>{{ maintenanceInfo.date }}</span> {{ $t('from') }}
          <span>{{ maintenanceInfo.startTime }}</span> {{ $t('to') }}
          <span>{{ maintenanceInfo.endTime }}</span>
          <span> ({{ maintenanceInfo.timezone }})</span>
        </span>
        <icon-hawk-x-close class="absolute top-0 right-0 mr-3 mt-3 cursor-pointer" @click="onCloseMaintenanceBanner" />
      </div>
    </transition>
    <HawkNavbar />
    <main class="text-gray-700">
      <router-view />
    </main>
  </div>
  <div v-else class="flex items-center justify-center h-screen flex-col">
    <div class="flex items-center">
      <img src="../../../assets/logos/taskmapper-full.svg" alt="TaskMapper" width="180">
    </div>
    <div class="m-8">
      <HawkLoader container_class="m-0 w-10 h-10" />
    </div>
  </div>
</template>
