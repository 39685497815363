import { cloneDeep, flatten, uniq } from 'lodash-es';

export function useFeatureCluster() {
  const handleStylingPoints = ({ map }) => {
    map.on('click', 'feature_clusters_point', (e) => {
      const clusterId = e?.features?.[0]?.properties?.cluster_id;
      if (clusterId) {
        map.setPaintProperty('feature_clusters_point', 'circle-color', '#344054');
        map.setPaintProperty('feature_clusters_point', 'circle-radius', 20);

        map.setPaintProperty('feature_clusters_point', 'circle-color', ['case', ['==', ['get', 'cluster_id'], clusterId], '#1570EF', '#344054']);
      };
    });

    map.on('click', 'feature_unclusters_point', (e) => {
      const point_id = e.features?.[0]?.properties?.point_id;
      if (point_id) {
        map.setPaintProperty('feature_unclusters_point', 'circle-color', ['case', ['==', ['get', 'point_id'], point_id], '#1570EF', '#344054']);
      };
    });

    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point, { layers: ['feature_clusters_point'] });
      if (features.length === 0)
        map.setPaintProperty('feature_clusters_point', 'circle-color', '#344054');

      const unclustered_features = map.queryRenderedFeatures(e.point, { layers: ['feature_unclusters_point'] });
      if (unclustered_features.length === 0)
        map.setPaintProperty('feature_unclusters_point', 'circle-color', '#344054');
    });
  };

  const fetchClusterLeaves = async (store, cluster) => {
    const cluster_id = cluster.properties.cluster_id;

    return new Promise((resolve, reject) => {
      store.map.getSource('feature_count_points').getClusterLeaves(cluster_id, 1000, 0, (err, leaves) => {
        if (err)
          return reject(new Error('Error fetching cluster leaves:', err));

        resolve(leaves);
      });
    });
  };

  const handleLayerClickEvents = async (e, store, layer_id, click_event_layers) => {
    const features = store.map.queryRenderedFeatures(e.point, {
      layers: click_event_layers,
    });
    let feature_uids = [];
    if (features.length > 0) {
      const feature = features[0];
      if (feature?.properties?.totalFeaturesCount !== undefined) {
        try {
          const leaves = await fetchClusterLeaves(store, feature);
          feature_uids = uniq(flatten(leaves.map(f => f.properties.feature_uids)));
        }
        catch (error) {
          feature_uids = [];
          logger.error(error);
        }
      }
      else if (feature.layer.id === layer_id) {
        feature_uids = uniq(JSON.parse(feature.properties.feature_uids));
      }
      else {
        feature_uids = [];
      }
    }
    store.selected_features = feature_uids.map(uid => cloneDeep(store.features_hash[uid]));
  };

  const handleClickEvents = async (store) => {
    const click_event_layers = ['feature_clusters_point', 'feature_unclusters_point'];
    click_event_layers.forEach((layer_id) => {
      store.map.on('click', layer_id, (e) => {
        handleLayerClickEvents(e, store, layer_id, click_event_layers);
      });
    });
  };

  const addFeatureClusters = async (store, features) => {
    const show_cluster = store.settings.show_counts;
    const cluster_source = 'feature_count_points';
    const cluster_layers = ['feature_clusters_point', 'feature_clusters_count', 'feature_unclusters_point', 'feature_unclusters_count'];

    if (!store.map)
      return;

    // Remove source and layers
    cluster_layers.forEach((layer) => {
      if (store.map?.getLayer(layer))
        store.map.removeLayer(layer);
    });
    if (store.map?.getSource(cluster_source))
      store.map.removeSource(cluster_source);

    if (!show_cluster)
      return;

    const turf = (await import('@turf/turf'));

    const features_list = features.reduce((acc, f) => {
      const centroid = turf.centroid(f);
      acc.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: centroid?.geometry?.coordinates,
        },
        feature_uid: f.properties.uid,
      });
      return acc;
    }, []);

    const unique_geometries = {};
    features_list.forEach((feature) => {
      const coords = JSON.stringify(feature.geometry.coordinates); // Serialize coordinates to identify same geometry
      if (!unique_geometries[coords]) {
        unique_geometries[coords] = [];
      }
      unique_geometries[coords].push(feature);
    });

    const data = [];

    Object.keys(unique_geometries).forEach((key) => {
      const features = unique_geometries[key];
      data.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: features[0]?.geometry?.coordinates,
        },
        properties: {
          count: features.length,
          feature_uids: features.map(f => f.feature_uid),
          point_id: crypto.randomUUID(),
        },
      });
    });

    store.map.addSource(cluster_source, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: data,
      },
      cluster: true,
      clusterProperties: {
        totalFeaturesCount: ['+', ['get', 'count']],
      },
    });

    store.map.addLayer({
      id: 'feature_clusters_point',
      type: 'circle',
      source: cluster_source,
      filter: ['has', 'point_count'],
      paint: {
        'circle-opacity': 0.8,
        'circle-color': '#344054',
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20, // Base radius for small clusters
          100, // Radius for medium clusters
          30, // Radius for large clusters
          750, // Radius for very large clusters
          40, // Maximum radius for the largest clusters
        ],
      },
    });
    store.map.addLayer({
      id: 'feature_clusters_count',
      type: 'symbol',
      source: cluster_source,
      layout: {
        'text-field': '{totalFeaturesCount}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], // Use bold fonts
        'text-size': 12,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });

    // For displaying unclustered data
    store.map.addLayer({
      id: 'feature_unclusters_point',
      type: 'circle',
      source: cluster_source,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-opacity': 0.8,
        'circle-color': '#344054',
        'circle-radius': 15,
      },
    });
    store.map.addLayer({
      id: 'feature_unclusters_count',
      type: 'symbol',
      source: cluster_source,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': ['get', 'count'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });

    handleStylingPoints(store);
    handleClickEvents(store);
  };

  return {
    addFeatureClusters,
  };
}
