<script setup>
const props = defineProps({
  tool: {
    type: Object,
    required: true,
  },
  activeTool: {
    type: String,
    default: null,
  },
  isMenuChild: {
    type: Boolean,
    default: false,
  },
});

function getLabel(tool) {
  if (props.isMenuChild)
    return tool.label || tool.tooltip;
  return tool.label;
}
</script>

<template>
  <div
    :key="activeTool"
    class="cursor-pointer flex items-center gap-2 p-2 hover:bg-gray-100 rounded-lg"
    :class="{ '!bg-gray-100': activeTool === tool.uid, 'w-full !gap-4': isMenuChild, 'opacity-50 pointer-events-none': tool.disabled }"
    @click="tool?.action"
  >
    <div>
      <Component :is="tool.icon" class="text-gray-500" :class="[isMenuChild ? 'w-5 h-5' : 'w-4 h-4', tool.custom_icon_class]" />
    </div>
    <div v-if="getLabel(tool)" class="text-xs font-medium text-gray-600" :class="{ '!text-sm': isMenuChild }">
      {{ getLabel(tool) }}
    </div>
  </div>
</template>
