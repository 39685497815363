<script setup>
import AttachmentOne from '~icons/hawk/attachment-one?raw';
import MessageDotsCircle from '~icons/hawk/message-dots-circle?raw';
import MessageDotsCircleGrayDot from '~icons/hawk/message-dots-circle-gray-dot?raw';
import MessageDotsCircleRedDot from '~icons/hawk/message-dots-circle-red-dot?raw';
import { cloneDeep, isEqual, uniq } from 'lodash-es';
import { nanoid } from 'nanoid';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useMembers } from '~/common/composables/members.js';
import useEmitter from '~/common/composables/useEmitter';
import { usePusherStore } from '~/common/stores/pusher.store.js';
import { changeIconDimensions, highlightElement, waitForElement } from '~/common/utils/common.utils';
import PmMentionsToast from '~/project-management/components/pm-mentions-toast.vue';
import PmReloadModal from '~/project-management/components/pm-reload-modal.vue';
import { useColumnTemplates } from '~/project-management/composables/pm-column-templates.composable';
import { useEvents } from '~/project-management/composables/pm-events.composable';
import { useGrouping } from '~/project-management/composables/pm-grouping.composable.js';
import { useI18n } from '~/project-management/composables/pm-i18n.composable';
import { useInlineEditors } from '~/project-management/composables/pm-inline-editors.composable';
import { useLayouts } from '~/project-management/composables/pm-layouts.composable';
import { useResources } from '~/project-management/composables/pm-resources.composable';
import { useSearch } from '~/project-management/composables/pm-search.composable';
import { useTaskLayers } from '~/project-management/composables/pm-task-layers.composable';
import { useZoom } from '~/project-management/composables/pm-zoom.composable';
import { getDefaultView } from '~/project-management/constants/pm-default-view';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');
const $services = inject('$services');

const router = useRouter();
const toastification = useToast();

const auth_store = useAuthStore();
const pusher_store = usePusherStore();
const project_management_store = useProjectManagementStore();
const emitter = useEmitter();

const { groupBy } = useGrouping();
const { setupLocale } = useI18n();
const { setLayout } = useLayouts();
const { setupZoomLevels } = useZoom();
const { getUserDetails } = useMembers();
const { setupResources } = useResources();
const { layer_ids, setupTaskLayers } = useTaskLayers();
const { getColumnTemplate, setupTemplates } = useColumnTemplates();
const { setupInlineEditors, setupInlineEditorEvents } = useInlineEditors();
const { setupGanttEvents, setupGanttFunctions } = useEvents();
const { current_match_index, search_results } = useSearch();
const { set_gantt_active_calendar, set_schedule_dirtiness, set_active_view, set_active_task_uid, update_activity, set_pm_attachments, set_references, bulk_fetch_activities } = project_management_store;

const {
  $g,
  views,
  flags,
  active_tab,
  active_task,
  active_view,
  triggered_by,
  is_fullscreen,
  is_pm_loading,
  active_schedule,
  filtered_task_ids,
  active_instance_id,
  pm_loading_message,
  active_schedule_data,
  is_schedule_editable,
  schedule_pusher_channel,
  comment_priority_status,
} = storeToRefs(project_management_store);

const IconAttachmentOne = changeIconDimensions(AttachmentOne, 16, 16);
const IconMessageDotsCircle = changeIconDimensions(MessageDotsCircle, 16, 16);
const IconMessageDotsCircleRedDot = changeIconDimensions(MessageDotsCircleRedDot, 16, 16);
const IconMessageDotsCircleGrayDot = changeIconDimensions(MessageDotsCircleGrayDot, 16, 16);

const reload_modal = useModal({
  component: PmReloadModal,
});

// computed
const columns = computed(() => active_view.value.data.columns);
const critical_path = computed(() => active_view.value.data?.feature_visibility?.critical_path);

const tippy_guy = ref(null);

// lifecycle methods
onMounted(() => {
  pm_loading_message.value = `${$t('Loading')}...`;
  is_pm_loading.value = true;
  active_instance_id.value = `pm-gantt-${nanoid(7)}`;
  $g.value = Gantt.getGanttInstance();
  window.gantt.$g = $g.value;
  const interval = setInterval(() => {
    if (active_view.value?.uid) {
      clearInterval(interval);
      setupGantt();
    }
  }, 500);
  const pm_pusher_interval = setInterval(() => {
    if (!schedule_pusher_channel.value)
      return;

    clearInterval(pm_pusher_interval);
    schedule_pusher_channel.value.bind('ACTIVITIES_UPDATED', async (payload) => {
      logger.log('🆘 ~ ACTIVITIES_UPDATED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      let activities_to_update = [];
      payload.data.forEach((activity_uid) => {
        activities_to_update.push(activity_uid);
        $g.value.eachParent((task) => {
          activities_to_update.push(task.uid);
        }, active_schedule.value.activities[activity_uid].id);
      });
      activities_to_update = uniq(activities_to_update);
      const activities = await bulk_fetch_activities(activities_to_update);
      let have_attachments_changed = false;
      activities.forEach((activity) => {
        const old_activity = cloneDeep(active_schedule.value.activities[activity.uid]);
        update_activity(activity, true);
        const new_activity = cloneDeep(active_schedule.value.activities[activity.uid]);

        if (!isEqual(old_activity.attachments, new_activity.attachments)) {
          have_attachments_changed = true;
        }
      });
      if (have_attachments_changed)
        set_pm_attachments();
      if (active_task.value?.uid)
        set_references($t);
      flags.value.activities_updated_count++;
    });
    schedule_pusher_channel.value.bind('SCHEDULE_UPDATED', async (payload) => {
      logger.log('🆘 ~ SCHEDULE_UPDATED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      const { data } = await $services.project_management.get({
        url: '/schedules',
        id: active_schedule.value.uid,
        query: { select: 'schedules,calendars' },
      });
      active_schedule.value = {
        ...active_schedule.value,
        ...data.data[0],
      };
    });
    schedule_pusher_channel.value.bind('MEMBER_UPDATED', (payload) => {
      logger.log('🆘 ~ MEMBER_UPDATED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      active_schedule.value.members = payload.data[0].members;
    });
    schedule_pusher_channel.value.bind('RESOURCES_ADDED', (payload) => {
      logger.log('🆘 ~ RESOURCES_ADDED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      active_schedule.value.resources = [payload.data[0], ...active_schedule.value.resources];
    });
    schedule_pusher_channel.value.bind('RESOURCES_UPDATED', (payload) => {
      logger.log('🆘 ~ RESOURCES_UPDATED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      active_schedule.value.resources = active_schedule.value.resources.map((resource) => {
        if (resource.uid === payload.data[0].uid) {
          return payload.data[0];
        }
        return resource;
      });
      flags.value.activities_updated_count++;
      $g.value.render();
    });
    schedule_pusher_channel.value.bind('RESOURCES_DELETED', (payload) => {
      logger.log('🆘 ~ RESOURCES_DELETED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      active_schedule.value.resources = active_schedule.value.resources.filter(resource => resource.uid !== payload.data[0].resource);
      if (active_tab.value === 'gantt-chart') {
        $g.value.render();
        flags.value.activities_updated_count++;
      }
    });
    schedule_pusher_channel.value.bind('UNPUBLISHED', (payload) => {
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      reload_modal.patchOptions({
        attrs: {
          isPublished: false,
        },
      });
      reload_modal.open();
    });
    schedule_pusher_channel.value.bind('PUBLISHED', (payload) => {
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      reload_modal.patchOptions({
        attrs: {
          isPublished: true,
        },
      });
      reload_modal.open();
    });
    schedule_pusher_channel.value.bind('TRACKING_ADDED', (payload) => {
      logger.log('🆘 ~ TRACKING_ADDED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      active_schedule.value.trackings.push(payload.data[0]);
    });
    schedule_pusher_channel.value.bind('TRACKING_DELETED', (payload) => {
      logger.log('🆘 ~ TRACKING_DELETED:', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      const removed_tracking_uids = payload.data.map(tracking => tracking.uid);
      active_schedule.value.trackings = active_schedule.value.trackings.filter((tracking) => {
        if (removed_tracking_uids.includes(tracking.uid))
          return false;
        return true;
      });
    });
    schedule_pusher_channel.value.bind('RESOURCE_ASSIGNMENTS_UPDATED', (payload) => {
      logger.log('🆘 ~ RESOURCE_ASSIGNMENTS_UPDATED', payload);
      if (payload.originator === auth_store?.logged_in_user_details?.user_id)
        return;
      payload.data.forEach((pusher_resource_assignment) => {
        if (pusher_resource_assignment.active) {
          const index = active_schedule.value.resource_assignments.findIndex(
            store_resource_assignment => (store_resource_assignment.activity === pusher_resource_assignment.activity && store_resource_assignment.resource === pusher_resource_assignment.resource),
          );

          if (index !== -1) {
            active_schedule.value.resource_assignments[index] = pusher_resource_assignment;
          }
          else {
            active_schedule.value.resource_assignments.push(pusher_resource_assignment);
          }
        }
        else {
          active_schedule.value.resource_assignments = active_schedule.value.resource_assignments.filter(store_resource_assignment => !(store_resource_assignment.activity === pusher_resource_assignment.activity && store_resource_assignment.resource === pusher_resource_assignment.resource));
        }
        const activity_resources = active_schedule.value.resource_assignments
          .filter(resource_assignment => resource_assignment.activity === pusher_resource_assignment.activity)
          .map(resource_assignment => resource_assignment.resource);
        update_activity({ uid: pusher_resource_assignment.activity, resources: activity_resources });
      });
    });
    schedule_pusher_channel.value.bind('SCHEDULE_NEW_COMMENT', (payload) => {
      logger.log('🆘 ~ SCHEDULE_NEW_COMMENT', payload);
      const activity_has_comment = true;
      const activity = active_schedule.value.activities[payload.data[0].activity_uid];
      activity.comments.has_unread_comments = activity_has_comment && !payload.data[0].presence_users.includes(auth_store?.logged_in_user_details?.user_id);
      activity.comments.has_unread_mentions = activity.comments.has_unread_comments && payload.data[0].mentioned_users.includes(auth_store?.logged_in_user_details?.user_id);
      update_activity({
        uid: payload.data[0].activity_uid,
        comments: {
          ...activity.comments,
        },
      }, false, ['comments']);
    });
  }, 500);
  const user_feed_pusher_interval = setInterval(() => {
    if (!pusher_store.user_feed_channel)
      return;

    clearInterval(user_feed_pusher_interval);
    pusher_store.user_feed_channel.bind('NEW_COMMENT', (payload) => {
      logger.log('🆘 ~ NEW_COMMENT', payload);
      toastification(
        {
          component: PmMentionsToast,
          props: {
            activityName: payload.activity.name,
            userName: getUserDetails(payload.comment_by).members_details[0].name,
            commentBy: payload.comment_by,
            commentText: payload.comment_text,
            translate: (key) => {
              return $t(key);
            },
          },
          listeners: {
            click: async () => {
              set_active_task_uid(payload.activity.uid);
              triggered_by.value = 'mentions-toast';
              await handleCommentScroll();
            },
          },
        },
        {
          timeout: 5000,
        },
      );
    }, 500);
  });
});

onUnmounted(() => {
  emitter.emit('hide_resources_editor');
  $g.value?.destructor?.();
  $g.value = null;
  window.gantt.$g = $g.value;
  project_management_store.$reset();
});

// methods
function initializePlugins() {
  $g.value.plugins({
    // undo: true,
    marker: true,
    tooltip: true,
    critical_path: true,
    auto_scheduling: true,
    keyboard_navigation: true,
  });
}

function setupGanttConfig() {
  $g.value.config.select_task = false;
  // enables sorting in the table
  $g.value.config.sort = !is_schedule_editable.value;
  // matches dates in ISO format. example: "2022-07-21T08:37:23.823Z"
  $g.value.config.date_format = '%Y-%m-%d %T %H:%i%Z';
  // enables the possibility to reorder grid columns by drag and drop
  // NOTE: This has been turned off due to an issue (Email: Issue with preventing reordering of the columns). Bring this back once DHTMLX fixes the issue and you've upgraded the version of the gantt.
  $g.value.config.reorder_grid_columns = false;
  // instructs Gantt to automatically extend the time scale in order to fit all displayed tasks
  gantt.config.fit_tasks = true;
  // enables automatic adjusting of the grid's columns to the grid's width
  $g.value.config.autofit = true;
  // sets the default height for rows of the table
  $g.value.config.row_height = 30;
  // opens all branches on load
  $g.value.config.open_tree_initially = true;
  // tells the resource timeline to render elements and call templates for non-allocated cells
  $g.value.config.resource_render_empty_cells = true;
  // sets the height of the time scale and the header of the grid
  $g.value.config.scale_height = 40;
  // enables showing tasks that are not between $g.value.config.start_date and $g.value.config.end_date in the Gantt chart
  $g.value.config.show_tasks_outside_timescale = false;
  // enables showing error alerts in case of unexpected behavior
  $g.value.config.show_errors = false;
  // enables auto scheduling
  $g.value.config.auto_scheduling = is_schedule_editable.value;
  // enables the auto scheduling mode, in which tasks will always be rescheduled to the earliest possible date
  $g.value.config.auto_scheduling_strict = is_schedule_editable.value;
  // defines whether gantt will do autoscheduling on data loading/parsing
  $g.value.config.auto_scheduling_initial = is_schedule_editable.value;
  // sets the way the scheduling algorithms process completed tasks
  $g.value.config.auto_scheduling_use_progress = is_schedule_editable.value;
  // defines whether the whole project will be moved
  $g.value.config.auto_scheduling_move_projects = is_schedule_editable.value;
  // allows or forbids creating links from parent tasks (projects) to their children
  $g.value.config.auto_scheduling_descendant_links = false;
  // defines whether tasks should inherit the constraint type from their parent project
  $g.value.config.auto_scheduling_project_constraint = is_schedule_editable.value;
  // activates the read-only mode for the Gantt chart
  $g.value.config.readonly = false;
  // adds an empty row into the end of the list of tasks to simplify tasks editing via keyboard
  $g.value.config.placeholder_task = false;
  // enables keyboard navigation in gantt
  $g.value.config.keyboard_navigation = true;
  // enables keyboard navigation by cells
  $g.value.config.keyboard_navigation_cells = true;
  // enables drag and drop of items of the project type
  $g.value.config.drag_project = true;
  // shows/hides markers
  $g.value.config.show_markers = true;
  // shows the critical path in the chart
  $g.value.config.highlight_critical_path = critical_path.value;
  // sets the minimum duration (in milliseconds) that can be set for a task during resizing.
  $g.value.config.min_duration = 24 * 60 * 60 * 1000; // (1 day)
  // adjusts the width of columns inside a scrollable grid
  $g.value.config.grid_elastic_columns = 'min_width';
  // preserve the initial grid's width while resizing columns within
  $g.value.config.keep_grid_width = true;
  // enables calculating the duration of tasks in working time instead of calendar time
  $g.value.config.work_time = true;
  // enables adjusting the task's start and end dates to the work time (while dragging)
  $g.value.config.correct_work_time = true;
  // sets the duration unit
  $g.value.config.duration_unit = 'day';
  // sets the number of '$g.value.config.duration_unit' units that will correspond to one unit of the 'duration' data property.
  $g.value.config.duration_step = 1;
  // defines whether gantt will perform a deep copy of data objects passed into the gantt.parse() method
  $g.value.config.deepcopy_on_parse = false;
  // enables rounding the task's start and end dates to the nearest scale marks
  $g.value.config.round_dnd_dates = false;
  // sets the minimum step (in minutes) for the task's time values
  $g.value.config.time_step = 60 * 24;
  // stores the types of links dependencies
  $g.value.config.links.start_to_start = 'SS';
  $g.value.config.links.start_to_finish = 'SF';
  $g.value.config.links.finish_to_start = 'FS';
  $g.value.config.links.finish_to_finish = 'FF';
  // defines whether to display the placeholder element inside the grid when it is empty
  $g.value.config.show_empty_state = true;
  // activity types
  $g.value.config.types = {
    wbs: 'WBS',
    task: 'TASK',
    project: 'PROJECT',
    virtual: 'VIRTUAL',
    milestone: 'MILESTONE',
    surrogate: 'SURROGATE',
  };

  // - - - - - PERFORMANCE CONFIG PROPERTIES [https://docs.dhtmlx.com/gantt/desktop__performance.html] - - - - -
  // enables/disables displaying column borders in the chart area [needs to be true if you want to display colors/highlights on the chart]
  $g.value.config.show_task_cells = true;
  // generates a background image for the timeline area instead of rendering actual columns' and rows' lines
  $g.value.config.static_background = true;
  // specifies that only visible part of the time scale is rendered on the screen
  $g.value.config.smart_scales = true;
  // reordering tasks within the same nesting level; it's set to marker for optimized performance
  // [https://docs.dhtmlx.com/gantt/api__gantt_order_branch_config.html]
  $g.value.config.order_branch = is_schedule_editable.value ? 'marker' : false;
  // reordering tasks within the whole gantt
  $g.value.config.order_branch_free = is_schedule_editable.value;
  // enables dynamic loading in the Gantt chart
  $g.value.config.branch_loading = true;

  // - - - - - CUSTOM CONFIG PROPERTIES - - - - -
  // highlight drag position (these are custom)
  $g.value.config.show_drag_vertical = true;
  $g.value.config.show_drag_dates = true;
  $g.value.config.drag_label_width = 70;
  $g.value.config.drag_date = '%M %d, %Y'; // e.g. Oct 20, 1983
  $g.value.templates.drag_date = null;
}

function getCustomFieldEditor(name, custom_type) {
  switch (custom_type) {
    case 'text':
      return { type: 'custom_text', map_to: name };
    case 'date':
      return { type: 'pm_date', map_to: name };
    default:
      return { type: 'number', map_to: name, min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER };
  }
}

function getCustomEditor({ name, custom_type }) {
  const allowed_columns = [
    'id',
    'text',
    'weight',
    'duration',
    'progress',
    'resources',
    'start_date',
    'planned_work',
    'predecessors',
    'constraint_date',
    'constraint_type',
  ];

  if (name.startsWith('custom_field_'))
    return getCustomFieldEditor(name, custom_type);

  if (!allowed_columns.includes(name))
    return null;

  const formatter = $g.value.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'day',
    short: true,
    hoursPerWeek: 56,
    labels: {
      day: {
        full: 'day',
        plural: 'days',
        short: 'd',
      },
      week: {
        full: 'week',
        plural: 'weeks',
        short: 'w',
      },
      month: {
        full: 'month',
        plural: 'months',
        short: 'm',
      },
      year: {
        full: 'year',
        plural: 'years',
        short: 'y',
      },
    },
  });
  const linksFormatter = $g.value.ext.formatters.linkFormatter({ durationFormatter: formatter });

  const customLinkFormatter = {
    format(link) {
      let formatted_value = `${link.source}`;
      if (link.type !== 'FS' || (link.lag && link.lag !== 0))
        formatted_value += ` ${link.type}`;
      if (link.lag && link.lag !== 0)
        formatted_value += `${link.lag > 0 ? '+' : ''}${link.lag}${$t('days')}`;
      return formatted_value;
    },
    parse(formatted_value) {
      formatted_value = formatted_value.trim();

      const pattern = /^(\S*)(?:\s.*)?$/;
      let result = formatted_value.replace(pattern, (match, p1) => {
        const new_value = $g.value.getTask(p1);
        return formatted_value.replace(p1, $g.value.getWBSCode(new_value));
      });
      result = result.replaceAll(' ', '');
      const link = linksFormatter.parse(result);
      return link;
    },
  };

  switch (name) {
    case 'id':
      return { type: 'pm_id_editor', map_to: name };
    case 'text':
      return { type: 'pm_text', map_to: name };
    case 'resources':
      return { type: name, map_to: name };
    case 'start_date':
    case 'constraint_date':
      return { type: 'pm_date', map_to: name };
    case 'duration':
      return { type: 'duration', map_to: 'duration', min: 0, max: 100, formatter };
    case 'planned_work':
      return { type: 'number', map_to: name, min: 0, max: Number.MAX_SAFE_INTEGER };
    case 'weight':
    case 'progress':
      return { type: 'number', map_to: name, min: 0, max: 100 };
    case 'predecessors':
      return { type: 'predecessor', map_to: 'auto', formatter: customLinkFormatter };
    case 'constraint_type':
      return {
        type: 'select',
        map_to: name,
        options: [
          { key: 'asap', label: gantt.locale.labels.asap },
          { key: 'alap', label: gantt.locale.labels.alap },
          { key: 'snet', label: gantt.locale.labels.snet },
          { key: 'snlt', label: gantt.locale.labels.snlt },
          { key: 'fnet', label: gantt.locale.labels.fnet },
          { key: 'fnlt', label: gantt.locale.labels.fnlt },
          { key: 'mso', label: gantt.locale.labels.mso },
          { key: 'mfo', label: gantt.locale.labels.mfo },
        ],
      };
  }
}

gantt.$setupColumns = setupColumns;
function setupColumns(columns_arr = null, override_size = false) {
  $g.value.config.columns = [
    ...(columns_arr || columns.value).filter((column) => {
      return !(
        (!active_schedule.value.track_resources && column.name === 'resources')
        || (!active_schedule.value.has_activity_weightages && column.name === 'weight')
      );
    }).map((column) => {
      const new_col = cloneDeep(column);
      const template = getColumnTemplate(column);

      document.querySelectorAll('.pm-wbs-header').forEach(el => el.remove());

      if (column.name === 'wbs') {
        let message_icon;
        if (comment_priority_status.value === 'unread_mentions')
          message_icon = IconMessageDotsCircleRedDot;
        else if (comment_priority_status.value === 'unread_comments')
          message_icon = IconMessageDotsCircleGrayDot;
        else if (comment_priority_status.value === 'all_comments')
          message_icon = IconMessageDotsCircle;
        new_col.label = `<span class="flex items-center pm-wbs-header">
                           <span class="flex items-center gap-x-1 ${is_schedule_editable.value ? 'ml-[22px]' : 'ml-1.5'} mr-2">
                             <span class="text-gray-500">${IconAttachmentOne}</span>
                             <span id="pm-wbs-header-message-icon" class="text-gray-500 cursor-pointer">${message_icon}</span>
                           </span>
                           ${$t('WBS')}
                         </span>`;
      }
      if (template)
        new_col.template = template;
      const editor = getCustomEditor(column);
      if (editor)
        new_col.editor = editor;
      if (override_size)
        new_col.width = column.min_width || 100;
      return new_col;
    }),
    {
      name: 'select-columns',
      label: '<div id="select-columns-button" class="gantt_add"></div>',
      template: getColumnTemplate({ name: 'select-columns' }),
      align: 'center',
      resize: false,
      sort: false,
      max_width: 40,
      min_width: 40,
    },
  ];
  let grid_width_temp = 0;
  if (active_view.value?.data?.grid_width) {
    grid_width_temp = active_view.value.data.grid_width;
  }
  else {
    grid_width_temp = $g.value.config.columns.reduce((acc, curr) => {
      acc += curr.width;
      return acc;
    }, 0);
  }
  $g.value.config.grid_width = grid_width_temp;
  setupInlineEditorEvents();
}

function setupEmptyStateElement() {
  $g.value.ext.emptyStateElement.isGanttEmpty = function () {
    return !filtered_task_ids.value.length;
  };
  $g.value.ext.emptyStateElement.isEnabled = function () {
    return !filtered_task_ids.value.length;
  };
  $g.value.ext.emptyStateElement.renderContent = function (container) {
    container.innerHTML = `
      <div class="bg-white fixed w-[calc(100vw-12px)] h-[calc(100vh-219px)] z-[11] flex justify-center pt-[200px]">
        ${$t('No activities match the selected filters.')}
      </div>
    `;
  };
}

function initializeGantt() {
  if (active_view.value.data.group_by.length) {
    groupBy(active_view.value.data.group_by);
  }
  else {
    set_gantt_active_calendar();
    $g.value.init(document.getElementById(active_instance_id.value));
    $g.value.parse(active_schedule_data.value);
    set_schedule_dirtiness(false);
  };
}

function onClickOutside() {
  $g.value?.ext?.inlineEditors?.hide?.();
}

function triggerInfoTippy() {
  if (!tippy_guy.value)
    return;

  tippy_guy.value.$el.$tippy.show();
  setTimeout(() => {
    tippy_guy.value.$el.$tippy.hide();
  }, 2000);
}

function setupGantt() {
  initializePlugins();
  setupLocale();
  setupTemplates(search_results, current_match_index);
  setupInlineEditors();
  setupGanttFunctions();
  setupGanttEvents(layer_ids, setupTaskLayers);
  setupZoomLevels();
  setupEmptyStateElement();
  setupColumns();
  setupGanttConfig();
  setupResources();
  initializeGantt();
  setLayout();
  triggerInfoTippy();

  if (router.currentRoute.value.query?.activity) {
    const activity_id = active_schedule.value.activities[router.currentRoute.value.query?.activity]?.id;
    if (filtered_task_ids.value.includes(activity_id)) {
      set_active_task_uid(router.currentRoute.value.query.activity);
    }
    else {
      router.push(
        {
          ...router.currentRoute.value,
          query: { },
        },
      );
    }
  }
}

window.gantt.$setWBSCommentIcon = setWBSCommentIcon;
async function setWBSCommentIcon() {
  await nextTick();
  const element = document.getElementById('pm-wbs-header-message-icon');
  if (element) {
    if (comment_priority_status.value === 'unread_mentions')
      element.innerHTML = IconMessageDotsCircleRedDot;
    else if (comment_priority_status.value === 'unread_comments')
      element.innerHTML = IconMessageDotsCircleGrayDot;
    else
      element.innerHTML = IconMessageDotsCircle;
  }
}

async function handleCommentScroll() {
  await waitForElement('#comments-done').then(async (element) => {
    setTimeout(() => {
      highlightElement(
        element,
        ['!-ml-3', '!pl-3', '!-mr-3', '!pr-5'],
        { behavior: 'smooth', block: 'end', inline: 'nearest' },
      );
    }, 250);
  });
}

watch(() => comment_priority_status.value, () => {
  setWBSCommentIcon();
}, { immediate: true });

watch(columns, (value) => {
  setupColumns(value);
  $g.value.render();
});

watch(() => [
  active_schedule.value?.track_resources,
  active_schedule.value?.track_costs,
  active_schedule.value?.has_activity_weightages,
], (newVal, oldVal) => {
  if (!isEqual(newVal, oldVal)) {
    views.value[0] = cloneDeep(getDefaultView($t, active_schedule.value));
    if (active_view.value.uid === '__default')
      set_active_view();

    setLayout();
    setupColumns();
    $g.value.render();
  }
});
</script>

<template>
  <div
    v-show="active_tab === 'gantt-chart'"
    :id="active_instance_id"
    v-click-outside="onClickOutside"
    :class="[
      is_fullscreen ? 'h-[calc(100vh-55px)]' : 'h-[calc(100vh-173px)]',
      is_schedule_editable ? 'pm-gantt--editable' : 'pm-gantt--readonly',
      active_view.data.feature_visibility.links ? '' : 'pm-gantt--no-links',
    ]"
    class="mx-auto pm-gantt"
    style="width: calc(100vw - 10px)"
  />
</template>

<style lang="scss">
  /*
  Changes done in the dhtmlxgantt_material.css file:
  - Replaced all instances of the color #d7d7d7 with var(--vf-gray-200)
  */
  @import "../styles/dhtmlxgantt_material.css";
  @import "../styles/pm-styles.scss";
</style>
