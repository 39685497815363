import {
  INVENTORY_API_HOST,
} from '~/common/utils/api-hosts.js';
import { generateAssetScopeRequest } from './services.helper';

export default {
  inventory: {
    url: '',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_items_master_list: req => ({
        url: 'items-master',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        toast: req?.toast,
        signal: req?.signal,
      }),
    },
  },
  inventory_unified_resources: {
    url: 'unified-resources',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_unified_resources: req => ({
        url: 'unified-resources',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
    },
  },
  inventory_items: {
    url: 'items',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_items_list_by_uids: req => ({
        url: 'items/item-lists',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      delete_item: req => ({ // use inventory.delete()
        url: `items/${req.id}`,
        method: 'DELETE',
      }),
      get_stock_units: req => ({
        url: 'stock-units',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        toast: req?.toast,
        signal: req?.signal,
      }),
      get_stock_units_details: req => ({
        url: 'stock-units/get-stock-unit',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      validate_stock_units: req => ({
        url: 'stock-units/validate-stock-units',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      validate_stock_units_v2: req => ({
        url: 'stock-units/stocks-validate',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      update_stock_unit: req => ({
        url: `stock-units/${req.id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
        toast: req?.toast,
        signal: req?.signal,
      }),
      stock_item_aggregate: req => ({
        url: `items/${req.id}/stocks-aggregates`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      update_stock_item_aggregate: req => ({
        url: `items/${req.id}/stocks-updates`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      get_stock_history: req => ({
        url: 'stock-histories',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        toast: req?.toast,
        signal: req?.signal,
      }),
      validate_serial_numbers: req => ({
        url: 'stock-units/validate-serial-numbers',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      register_items_in_asset: req => ({
        url: `assets/${req.asset_id}/items-registration`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        toast: req?.toast,
        method: 'POST',
      }),
      register_items_in_warehouse: req => ({
        url: `items/register/warehouses/${req.warehouse_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      get_item_procurement: req => generateAssetScopeRequest(req, `item/${req.id}/procurement`, 'GET', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
      update_item_ordered_qty: req => generateAssetScopeRequest(req, `update-ordered-qty`, 'POST', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
      update_item_procurement_qty: req => generateAssetScopeRequest(req, `update-item-procurements`, 'POST', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
    },
  },
  inventory_adjustments: {
    url: 'adjustments',
    baseURL: INVENTORY_API_HOST,
    methods: {
      send_emails: req => ({
        url: `adjustments/${req.id}/email-send`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      export_transaction: req => ({
        url: `adjustments/${req.id}/export-transaction`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'GET',
        toast: req?.toast,
      }),
    },
  },
  inventory_uom: {
    url: 'measurement-units',
    baseURL: INVENTORY_API_HOST,
  },
  inventory_workflows: {
    url: 'item-workflows',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_acl_workflows: (req) => {
        return {
          url: 'item-workflows/acl',
          ...(req?.query && { params: req.query }),
          ...(req?.headers && { headers: req.headers }),
          method: 'GET',
          toast: req?.toast,
        };
      },
      initialize_inventory: (req) => {
        return {
          url: 'initialize-inventory',
          ...(req?.query && { params: req.query }),
          ...(req?.headers && { headers: req.headers }),
          data: req?.body,
          method: 'POST',
          toast: req?.toast,
        };
      },
      generate_transaction_number: (req) => {
        return {
          url: 'generate-transaction-number',
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          toast: req?.toast,
        };
      },
      update_workflow: (req) => { // use inventory_workflows.patch()
        return {
          url: `item-workflows/${req.id}`,
          ...(req?.query && { params: req.query }),
          ...(req?.headers && { headers: req.headers }),
          data: req.body,
          method: 'PATCH',
          toast: req?.toast,
        };
      },
    },
  },
  inventory_categories: {
    url: 'categories',
    baseURL: INVENTORY_API_HOST,
  },
  inventory_item_types: {
    url: 'categories',
    baseURL: INVENTORY_API_HOST,
    methods: {
      update_item_type: req => ({ // inventory_item_types.patch
        url: `categories/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_item_type: req => ({ // inventory_item_types.delete
        url: `categories/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_warehouse_types: {
    url: 'warehouse-types',
    baseURL: INVENTORY_API_HOST,
    methods: {
      update_warehouse_type: req => ({ // inventory_warehouse_types.patch
        url: `warehouse-types/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_warehouse_type: req => ({ // inventory_warehouse_types.delete
        url: `warehouse-types/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_stock_statuses: {
    url: 'item-statuses',
    baseURL: INVENTORY_API_HOST,
    methods: {
      update_stock_status: req => ({ // inventory_stock_statuses.patch
        url: `item-statuses/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_stock_status: req => ({ // inventory_stock_statuses.delete
        url: `item-statuses/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_item_statuses: { // check this
    url: 'item-statuses',
    baseURL: INVENTORY_API_HOST,
  },
  inventory_vendors: {
    url: 'vendors',
    baseURL: INVENTORY_API_HOST,
    methods: {
      update_vendor: req => ({ // not require
        url: `vendors/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_vendor: req => ({ // not require
        url: `vendors/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_stock_in_other_warehouses: {
    url: 'procurement',
    baseURL: INVENTORY_API_HOST,
  },
  inventory_warehouses: {
    url: 'warehouses',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_procurement: req => ({
        url: `warehouses/${req.id}/procurement`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      set_procurement: req => ({
        url: `warehouses/${req.id}/procurement`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
      }),
      get_procurement_v2: req => generateAssetScopeRequest(req, `warehouse/${req.id}/procurement`, 'GET', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
      set_procurement_v2: req => generateAssetScopeRequest(req, `warehouse/${req.id}/procurement`, 'POST', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
      update_procurement_instances: req => generateAssetScopeRequest(req, `warehouse/${req.id}/procurements-association`, 'POST', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
      set_procurement_ordered_qty_v2: req => generateAssetScopeRequest(req, 'update-ordered-qty', 'POST', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
      get_stock_in_other_warehouses: req => ({
        url: `warehouses/${req.id}/items/${req.item_id}/warehouse-procurements`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req?.toast,
      }),
      get_asset_procurement: req => generateAssetScopeRequest(req, 'procurement', 'GET', INVENTORY_API_HOST, { prefix_url: '', asset_key: 'asset' }),
    },
  },
  inventory_metrics: {
    url: 'metrics',
    baseURL: INVENTORY_API_HOST,
  },
  inventory_custom_fields: {
    url: 'custom-fields',
    baseURL: INVENTORY_API_HOST,
  },
  inventory_reports: {
    url: 'reports',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_adjustment_lines: req => ({
        url: 'adjustment-lines',
        method: 'GET',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req?.toast,
      }),
      export_stock_units: req => ({
        url: 'export-stock-units',
        method: 'GET',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        extraOptions: req?.extraOptions,
        toast: req?.toast,
        signal: req.signal,
      }),
      export_transaction_report: req => ({
        url: 'export-transaction-report',
        method: 'GET',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        extraOptions: req?.extraOptions,
        toast: req?.toast,
        signal: req.signal,
      }),
      inventory_dynamic_report: req => ({
        url: 'dynamic-report',
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
      }),
    },
  },
  inventory_advance_shipments: {
    url: 'advance-shipments',
    baseURL: INVENTORY_API_HOST,
    methods: {
      get_shipment_procurement: req => ({
        url: 'warehouses/shipment-procurement',
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        toast: req?.toast,
      }),
      create_adjustment: req => ({
        url: `advance-shipments/${req.id}/create-adjustment`,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        toast: req?.toast,
      }),
    },
  },
};
