import axios from 'axios';
import QS from 'query-string';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { accessTokenCookie, clearAuthentication, currentOrganizationCookie } from '~/common/utils/common.utils';

let is_refreshing_token = false;

async function refreshToken() {
  const common_store = useCommonStore();
  is_refreshing_token = true;
  const res = await common_store.$services.auth.fetchRefreshToken();
  if (!res?.data?.access_token)
    return;
  accessTokenCookie('set', res?.data?.access_token);
  is_refreshing_token = false;
}

function requestInterceptor(config) {
  const common_store = useCommonStore();
  const auth_store = useAuthStore();

  const { url } = config;
  config.headers = config.headers || {};
  config.params = config.params || {};
  if (config.params.remove_slash && url[url?.length - 1] === '/')
    config.url = url.substring(0, url?.length - 1);
  if (!config.params.remove_slash && url[url?.length - 1] !== '/')
    config.url = `${url}/`;

  delete config.params?.remove_slash;

  const token = accessTokenCookie();
  if (token && !config.headers.authorization)
    config.headers.authorization = `jwt ${token}`;

  if (config.params.token || config.params.token === false) {
    delete config.headers?.authorization;
    delete config.params.token;
  }
  // Add organization query params to all the API requests
  if (!config.params.organization) {
    if (config.params.organization === false)
      delete config.params.organization;
    else
      config.params.organization = auth_store.current_organization?.uid || currentOrganizationCookie();
  }

  // Add asset query params to all the API requests
  if (!config.params.asset && !config.params.asset_uid) {
    if (config.params.asset === false) {
      delete config.params.asset;
      delete config.params.asset_uid;
    }
    else if (common_store?.active_asset?.uid) {
      config.params.asset = common_store.active_asset.uid;
      config.params.asset_uid = common_store.active_asset.uid;
    }
  }

  if (config.extraOptions) {
    if (config.extraOptions.responseType)
      config.responseType = config.extraOptions.responseType;
  }

  return config;
}

async function responseErrorHandler(error) {
  const originalRequest = error.config;

  if (error?.response?.status === 401 && (['OAUTH_10', 'OAUTH_11', 'OAUTH_22', 'OAUTH_31'].includes(error?.response.data?.code))) {
    logger.error(error.response);
    if (import.meta.env.VITE_WIDGET_NAME !== 'dashboard') {
      clearAuthentication();
      window.location.href = `${location.origin}/auth/sign-in`;
    }
    return Promise.reject(error);
  }

  if (error?.response?.data?.code === 'OAUTH_21' && !originalRequest._retry) {
    if (JSON.parse(localStorage.getItem('extended_session')) !== false) {
      originalRequest._retry = true;
      await refreshToken();
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!is_refreshing_token) {
            const token = accessTokenCookie();
            originalRequest.headers.authorization = `jwt ${token}`;
            clearInterval(interval);
            resolve(axios(originalRequest));
          }
        }, 1000);
      }).then(response => response);
    }
    else {
      logger.warn('Extended session is false');
      if (import.meta.env.VITE_WIDGET_NAME !== 'dashboard') {
        clearAuthentication();
        window.location.href = `${location.origin}/auth/sign-in`;
      }
    }
  }

  else {
    if (error?.response?.data?.message?.includes?.('E11000'))
      logger.error('E11000 detected');
    throw error;
  }
}

const httpClient = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_HOST}/core/v1/resource`,
  paramsSerializer: {
    serialize: params => QS.stringify(params, { arrayFormat: 'brackets' }),
  },
});

httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(response => response, responseErrorHandler);

export { axios, httpClient };
