<!-- eslint-disable import/order -->
<script setup>
import { defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

const route = useRoute();
const router = useRouter();

const { teleport_to = 'body' } = route.query.form_id ? { form_uid: route.query.form_id } : JSON.parse(atob(route.query.form));
const is_modal_open = ref(true);

function emptyRoute() {
  is_modal_open.value = false;
  const query = { ...route.query };
  delete query.form;
  delete query.form_id;
  delete query.organization;
  router.replace({ query });
}

const FormDetail = defineAsyncComponent({
  loader: () => import('~/forms/components/form-detail/form-detail.vue'),
  loadingComponent: HawkLoader,
});
</script>

<template>
  <hawk-modal-container v-model="is_modal_open" content_class="w-full h-screen !rounded-none" :options="{ teleportTo: teleport_to }" @close="emptyRoute()">
    <FormDetail @empty-route="emptyRoute" />
  </hawk-modal-container>
</template>
