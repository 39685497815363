import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { cloneDeep, isEqual, remove } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, inject, watch } from 'vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { getTranslationMap } from '~/project-management/utils/pm-helper.utils';

dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);

export function useGrouping() {
  const $t = inject('$t');
  const $toast = inject('$toast');

  const project_management_store = useProjectManagementStore();
  const { $g, active_view, active_instance_id, flags, groups_cache, is_recalculation_enabled, active_schedule, active_schedule_data } = storeToRefs(project_management_store);
  const { modify_config, reload_data } = project_management_store;

  const group_by = computed(() => active_view.value.data.group_by);

  function getIntervalsBetween(interval, from, to, format = 'YYYY-MM-DD') {
    if (!interval || !from || !to) {
      throw Object.assign(
        new Error('grouping failed'),
        { code: 16 },
      );
    }
    let start = dayjs(from).subtract(1, interval).startOf(interval);
    const end = dayjs(to).endOf(interval);
    const intervals = [];

    while (start < end) {
      const intervalEnd = dayjs(start)
        .endOf(interval)
        .add(1, 'second');

      let labelString = '';

      if (interval === 'day')
        labelString = `${$t('Day')} ${start.dayOfYear()} `;

      else if (interval === 'week')
        labelString = `${$t('Week')} ${start.week()} of ${start.year()} `;

      else if (interval === 'month')
        labelString = `${start.format('MMMM')} `;

      else if (interval === 'quarter')
        labelString = `${$t('Quarter')} ${start.quarter()} `;

      else if (interval === 'year')
        labelString = `${$t('Year')} ${start.year()} `;

      intervals.push({
        label: `${labelString}[${start.format(
          format,
        )} - ${intervalEnd.format(format)}]`,
        start: new Date(start.format(format)),
        end: new Date(intervalEnd.format(format)),
      });
      start = intervalEnd;
    }

    return intervals;
  }

  function groupByStatus(source_tasks, parent_task_id) {
    const tasks = cloneDeep(source_tasks);
    const return_array = [];
    const statuses = [
      'Pending',
      'In-Progress',
      'Resolved',
      'Closed',
      'Rejected',
    ];
    for (const status of statuses) {
      const wrapper_task_id = crypto.randomUUID();
      const wrapper_task = {
        id: wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: `Status: ${status}`,
        parent: parent_task_id,
        readonly: true,
      };
      const filtered_tasks = tasks.filter(
        task =>
          task.associated_task
          && task.type === $g.value.config.types.task
          && status === task.status,
      );
      if (filtered_tasks.length) {
        filtered_tasks.forEach((task) => {
          if (task.type !== $g.value.config.types.virtual)
            task.parent = wrapper_task_id;
        });
        filtered_tasks.unshift(wrapper_task);
        return_array.push({ filtered_tasks, wrapper_task_id });
      }
    }
    return return_array;
  }

  function groupByProgress(source_tasks, parent_task_id) {
    const tasks = cloneDeep(source_tasks);
    const return_array = [];
    const ranges = [
      [-0.1, 0.1],
      [0.1, 0.2],
      [0.2, 0.3],
      [0.3, 0.4],
      [0.4, 0.5],
      [0.5, 0.6],
      [0.6, 0.7],
      [0.7, 0.8],
      [0.8, 0.9],
      [0.9, 1.0],
    ];
    for (const range of ranges) {
      const wrapper_task_id = crypto.randomUUID();
      const wrapper_task = {
        id: wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: `${$t('Progress')}: ${Math.max(
          Math.round(range[0] * 100),
          0,
        )}% - ${Math.round(range[1] * 100)}%`,
        parent: parent_task_id,
        readonly: true,
      };
      const filtered_tasks = remove(
        tasks,
        // TODO: Check if the type check is required - It is removing milestones also
        task => task.type === $g.value.config.types.task
          && range[0] < task.progress
          && task.progress <= range[1],
      );
      if (filtered_tasks.length) {
        filtered_tasks.forEach((task) => {
          if (task.type !== $g.value.config.types.virtual)
            task.parent = wrapper_task_id;
        });
        filtered_tasks.unshift(wrapper_task);
        return_array.push({ filtered_tasks, wrapper_task_id });
      }
    }
    return return_array;
  }

  function groupByCriticality(source_tasks, parent_task_id) {
    const tasks = cloneDeep(source_tasks);
    const critical_wrapper_task_id = crypto.randomUUID();
    const non_critical_wrapper_task_id = crypto.randomUUID();
    const critical_tasks = [
      {
        id: critical_wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: $t('Critical'),
        parent: parent_task_id,
        readonly: true,
      },
    ];
    const non_critical_tasks = [
      {
        id: non_critical_wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: $t('Non-Critical'),
        parent: parent_task_id,
        readonly: true,
      },
    ];

    for (const task of tasks) {
      if (task.type === $g.value.config.types.virtual)
        continue;

      if (task.progress !== 1 && ($g.value.isCriticalTask(task) || (active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false))) {
        task.parent = critical_wrapper_task_id;
        critical_tasks.push(task);
      }
      else {
        task.parent = non_critical_wrapper_task_id;
        non_critical_tasks.push(task);
      }
    }

    const return_array = [];
    if (critical_tasks.length > 1) {
      return_array.push({
        filtered_tasks: critical_tasks,
        wrapper_task_id: critical_wrapper_task_id,
      });
    }
    if (non_critical_tasks.length > 1) {
      return_array.push({
        filtered_tasks: non_critical_tasks,
        wrapper_task_id: non_critical_wrapper_task_id,
      });
    }

    return return_array;
  }

  function groupByDate(datetype, source_tasks, parent_task_id, option) {
    const tasks = cloneDeep(source_tasks);
    const return_array = [];
    let min_date = dayjs().add(100, 'year').toISOString();
    let max_date = 0;

    for (const task of tasks) {
      if (dayjs(task[datetype]).isBefore(dayjs(min_date)))
        min_date = task[datetype];

      if (dayjs(task[datetype]).add(1, 'day').isAfter(max_date))
        max_date = task[datetype];
    }

    const ranges = getIntervalsBetween(option, min_date, max_date);

    for (const range of ranges) {
      const wrapper_task_id = crypto.randomUUID();
      const wrapper_task = {
        id: wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: range.label,
        parent: parent_task_id,
        readonly: true,
      };
      const filtered_tasks = tasks.filter(
        task => dayjs(range.start).isSameOrBefore(dayjs(task[datetype]))
          && dayjs(range.end).isAfter(dayjs(task[datetype])),
      );
      if (filtered_tasks.length) {
        filtered_tasks.forEach((task) => {
          if (task.type !== $g.value.config.types.virtual)
            task.parent = wrapper_task_id;
        });
        filtered_tasks.unshift(wrapper_task);
        return_array.push({ filtered_tasks, wrapper_task_id });
      }
    }
    return return_array;
  }

  function handleUndefinedTasks(tasks, property, label, parent_task_id) {
    const undefined_array = tasks.filter(task => task[property] === undefined);
    const other_tasks = tasks.filter(task => task[property] !== undefined);

    if (undefined_array.length) {
      const wrapper_task_id = crypto.randomUUID();
      const wrapper_task = {
        id: wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: `${label}: Not set`,
        parent: parent_task_id,
        readonly: true,
      };
      undefined_array.forEach((task) => {
        if (task.type === $g.value.config.types.task && task.type !== $g.value.config.types.virtual)
          task.parent = wrapper_task_id;
      });
      undefined_array.unshift(wrapper_task);
      return {
        undefined_array,
        other_tasks,
        wrapper_task_id,
      };
    }
    return { undefined_array: [], other_tasks, wrapper_task_id: null };
  }

  function findMinMaxValues(tasks, property) {
    let min_val = Number.MAX_SAFE_INTEGER;
    let max_val = 0;

    tasks.forEach((task) => {
      if (task[property] < min_val)
        min_val = task[property];
      if (task[property] > max_val)
        max_val = task[property];
    });
    return { min_val, max_val: Math.ceil(max_val) + 1 };
  }

  function createRanges(min_val, max_val, gap) {
    const ranges = [];
    for (let i = 0; ; i += gap) {
      if (i + gap > max_val) {
        ranges.push([i, max_val]);
        break;
      }
      else {
        ranges.push([i, i + gap]);
      }
    }
    return ranges;
  }

  function groupTasksByRange(tasks, ranges, property, label, parent_task_id) {
    const return_array = [];
    for (const range of ranges) {
      const wrapper_task_id = crypto.randomUUID();
      const wrapper_task = {
        id: wrapper_task_id,
        type: $g.value.config.types.virtual,
        text: `${label}: ${range[0]} - ${range[1]}`,
        parent: parent_task_id,
        readonly: true,
      };
      const filtered_tasks = remove(
        tasks,
        task => range[0] <= task[property] && task[property] < range[1],
      );
      if (filtered_tasks.length) {
        filtered_tasks.forEach((task) => {
          if (task.type !== $g.value.config.types.virtual)
            task.parent = wrapper_task_id;
        });
        filtered_tasks.unshift(wrapper_task);
        return_array.push({ filtered_tasks, wrapper_task_id });
      }
    }
    return return_array;
  }

  function groupByDurationOrTotalOrFreeSlack(property, source_tasks, parent_task_id, option) {
    const tasks = cloneDeep(source_tasks);
    const return_array = [];
    const label = getTranslationMap($t)[property];
    const gap = option === 'week' ? 7 : 1;
    const { undefined_array, other_tasks, wrapper_task_id } = handleUndefinedTasks(tasks, property, label, parent_task_id);
    if (undefined_array.length) {
      return_array.push({
        filtered_tasks: undefined_array,
        wrapper_task_id,
      });
    }
    if (other_tasks.length) {
      const { min_val, max_val } = findMinMaxValues(other_tasks, property);
      const ranges = createRanges(min_val, max_val, gap);
      const grouped_tasks = groupTasksByRange(other_tasks, ranges, property, label, parent_task_id);
      return_array.push(...grouped_tasks);
    }
    return return_array;
  }

  function getGroupingFunction(key) {
    const duration_type = 'duration';
    switch (key) {
      case 'wbs':
        return tasks => tasks;
      case 'status':
        return groupByStatus;
      case 'progress':
        return groupByProgress;
      case duration_type:
      case 'total_slack':
      case 'free_slack':
        return (tasks, parent_task_id = undefined, option = 'day') =>
          groupByDurationOrTotalOrFreeSlack(
            key,
            tasks,
            parent_task_id,
            option,
          );
      case 'critical':
        return groupByCriticality;
      // case 'resources':
      //   return groupByResources;
      case 'start_date':
      case 'end_date':
      case 'actual_start':
      case 'actual_finish':
      case 'planned_start':
      case 'planned_finish':
        return (tasks, parent_task_id = undefined, option = 'day') =>
          groupByDate(key, tasks, parent_task_id, option);
    }
  }

  function groupTasks(groups, tasks, parent = null) {
    const group = groups.shift();

    // redo the case when grouping is wbs
    if (group.option.key === 'wbs')
      return tasks;

    let result = [];

    try {
      tasks = tasks.filter(task => task.type === $g.value.config.types.task || task.type === $g.value.config.types.milestone);
      result = getGroupingFunction(group.option.key)(
        tasks,
        parent,
        group?.suboption?.key,
        $t,
      );
    }
    catch (error) {
      console.error(error);
      $toast({
        title: 'Grouping failed',
        text: 'One or more tasks don\'t support the property used for grouping',
        type: 'error',
        timeout: 4000,
      });
      modify_config({ key: 'group_by', value: [] });
      return null;
    }

    if (groups.length) {
      const filtered_result = [];
      for (const { filtered_tasks, wrapper_task_id } of result) {
        const filtered = groupTasks(
          cloneDeep(groups),
          filtered_tasks,
          wrapper_task_id,
        );
        filtered_result.push(...filtered_tasks, ...filtered);
      }
      return filtered_result;
    }
    else {
      return result.map(obj => obj.filtered_tasks).flat();
    }
  }

  function groupBy(groups) {
    const gantt_container = document.getElementById(active_instance_id.value);

    groups = groups.map((g) => {
      return {
        option: {
          key: g.option.uid,
          label: g.option.label,
        },
        ...(g.suboption
          ? {
              suboption: {
                key: g.suboption.uid,
                label: g.suboption.label,
              },
            }
          : {
              suboption: null,
            }),
      };
    });

    if (!groups?.length) {
      if (groups_cache.value.length) {
        groups_cache.value = [];
        reload_data(true);
        flags.value.resources_section_reload_count++;
      }
      return;
    }

    if (
      isEqual(groups, groups_cache.value)
      || groups.some(group => !group?.option?.key)
    ) {
      return;
    }

    is_recalculation_enabled.value = false;
    groups_cache.value = cloneDeep(groups);
    const copied_data = cloneDeep(active_schedule_data.value);

    const grouped_data = groupTasks(cloneDeep(groups), copied_data.data);

    if (!grouped_data)
      return;

    const new_data = {
      data: grouped_data,
      links: copied_data.links,
    };

    $g.value.clearAll();
    $g.value.init(gantt_container);
    $g.value.parse(new_data);
    flags.value.resources_section_reload_count++;
  }

  watch(group_by, (value) => {
    groupBy(value);
    window.gantt.$setupColumns();
  });

  return {
    getIntervalsBetween,
    groupByStatus,
    groupByProgress,
    groupByCriticality,
    groupByDate,
    groupByDurationOrTotalOrFreeSlack,
    getGroupingFunction,
    groupTasks,
    groupBy,
  };
}
