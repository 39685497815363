<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import DOMPurify from 'dompurify';
import { storeToRefs } from 'pinia';
import { inject } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { createUppyInstance } from '~/common/utils/uppy.util.js';

import 'vue3-emoji-picker/css';

const props = defineProps({
  type: {
    type: String,
    validator: value => ['live', 'static'].includes(value),
    default: 'live',
  },
  comment_text: {
    type: String,
    default: '',
  },
  disabled_controls: {
    type: Array,
    default: () => [],
    validator: value => value.every(item => ['pin', 'edit', 'reply', 'delete', 'attach', 'settings', 'like'].includes(item)),
  },
  reaction_count: {
    type: Number,
    default: 0,
  },
  liked_by_me: {
    type: Boolean,
    default: false,
  },
  liked_user_uids: {
    type: Array,
    default: () => [],
  },
  is_pinned: {
    type: Boolean,
    default: false,
  },
  files_to_attach: {
    type: Array,
    default: () => ([]),
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  asset_id: {
    type: String,
    default: '',
  },
  uppy_attachment_config: {
    type: Object,
    default: () => ({}),
  },
  focus_position: {
    default: true, // accepts 'start' | 'end' | 'all' | number | boolean | null (false)
  },
  close_on_disable: {
    type: Boolean,
  },
  append_mentions_to: {
    type: Element,
    default: null,
  },
  mentionItemsFilter: {
    type: Function,
    default: items => items,
  },
  validate_comment: {
    type: Function,
  },
});

const emit = defineEmits([
  'addAttachments',
  'close',
  'delete',
  'filesPaste',
  'initialized',
  'input',
  'pastedFilesUploadComplete',
  'pin',
  'removeAttachment',
  'reply',
  'send',
  'toggleReaction',
  'showMoreOptions',
]);

const $t = inject('$t');

const chat_store = useChatStore();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));
const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'));

const { logged_in_user_details } = useAuthStore();
const { scope_users } = useCommonStore();

const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];

const state = reactive({
  editor_instance_uuid: crypto.randomUUID(),
  editor_contents: props.comment_text,
  editor_methods: null,
  mentioned_users: [],
  is_editing: false,
  is_emoji_picker_visible: false,
  uppy: createUppyInstance({
    attachment_config: props.uppy_attachment_config,
    complete_cb: result => (emit('pastedFilesUploadComplete', result)),
  }),
  is_editor_focussed: false,
  is_editor_disabled: false,
  forceUpdate: false,
  share_and_comment: false,
});

const { active_editor_uuid } = storeToRefs(chat_store);

const is_new_comment = computed(() => !props.comment_text);

const placeholder_text = computed(() => {
  return props.type === 'static' ? $t('Type your reply') : $t('Type something to comment');
});

const is_editor_highlighted = computed(() => {
  return state.editor_instance_uuid === active_editor_uuid.value;
});

const is_editor_enabled = computed(() => {
  return props.type === 'live' || is_new_comment.value || state.is_editing;
});

const is_send_allowed = computed(() => {
  return !props.files_to_attach.some(file => file?.upload_pending)
    && (props.files_to_attach.length || state.editor_contents.replaceAll('<br>', '').replaceAll(/<p>\s*<\/p>/gi, '').trim().length)
    && !state.is_editor_disabled;
});

const all_controls_disabled = computed(() => {
  return ['pin', 'edit', 'reply', 'delete', 'settings'].every(item => props.disabled_controls.includes(item));
});

const invalid_mentions = computed(() => {
  return props.validate_comment ? props.validate_comment({ mentions: state.mentioned_users }) : false;
});

const mention_items = computed(() => {
  const result = props.mentionItemsFilter(scope_users(props.asset_id))
    .filter(user => user.uid !== logged_in_user_details.user_id)
    .map((user) => {
      const display_name = (user.first_name
        ? `${user.first_name} ${user.last_name || ''}`
        : user.email?.split?.('@')?.[0] ?? '').trim();
      return {
        id: user.uid,
        avatar: user?.display_picture ?? '',
        bg_color: getColorFromString(display_name),
        label: DOMPurify.sanitize(display_name, { ALLOWED_TAGS: [] }),
      };
    });
  return result;
});

watch(active_editor_uuid, (uuid) => {
  // steal focus
  if (!uuid && props.autofocus)
    active_editor_uuid.value = state.editor_instance_uuid;
});

watch(is_editor_highlighted, (newVal) => {
  if (newVal) {
    state.is_editor_disabled = false;
    state.share_and_comment = false;
  }
  else {
    state.is_editor_focussed = false;
    if (is_editor_enabled.value) {
      state.is_editor_disabled = true;
      if (props.close_on_disable)
        onCancelClicked();
    }
  }
});

function getColorFromString(string) {
  let total = 0;
  for (const char of string)
    total += char.charCodeAt(0);

  return color_set[total % color_set.length];
}

function triggerMentions() {
  state.editor_methods.openSuggestionList();
}

function onSelectEmoji(emoji) {
  if (emoji)
    state.editor_methods.insertContent(emoji.i);
  setTimeout(() => (state.is_emoji_picker_visible = false), 1);
}

function onInitialized(editor_methods) {
  state.editor_methods = editor_methods;
  emit('initialized', editor_methods);
}

function onInput(event) {
  state.mentioned_users = event.mentions;
  emit('input', event);
}

async function onSend() {
  const comment = {
    text: DOMPurify.sanitize(state.editor_contents),
    mentions: state.mentioned_users,
  };
  if (props.validate_comment) {
    await props.validate_comment({ mentions: comment.mentions, share_and_comment: state.share_and_comment });
  }
  is_send_allowed.value && emit('send', comment);
  if (state.is_editing) {
    state.is_editing = false;
    if (state.editor_instance_uuid === active_editor_uuid.value)
      active_editor_uuid.value = '';
  }
  state.mentioned_users = [];
  state.editor_contents = '';
}

function onEmojiPickerClicked() {
  state.is_emoji_picker_visible = !state.is_emoji_picker_visible;
}

function onFilesPaste(files) {
  try {
    state.uppy.addFiles(files);
    emit('filesPaste', files);
  }
  catch (error) {
    logger.info(`[DEBUG] hawk-comment-input.vue::210\n${error}`);
  }
}

function onCancelClicked() {
  state.editor_contents = props.comment_text;
  state.is_editing = false;
  if (state.editor_instance_uuid === active_editor_uuid.value)
    active_editor_uuid.value = '';
  emit('close');
  state.forceUpdate++;
}

function onFocus() {
  if (is_editor_enabled.value)
    active_editor_uuid.value = state.editor_instance_uuid;
  state.is_editor_focussed = true;
}

function onReplyClicked() {
  active_editor_uuid.value = state.editor_instance_uuid;
  emit('reply');
}

function onEditorClicked(event) {
  if (!is_editor_enabled.value) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
  }
}

onMounted(() => {
  if (props.autofocus)
    active_editor_uuid.value = state.editor_instance_uuid;
  if (is_editor_highlighted.value)
    state.is_editor_focussed = true;
});
</script>

<template>
  <div
    class="relative"
    :class="{ 'pointer-events-none': state.is_editor_disabled }"
  >
    <HawkAlertBanner v-if="is_editor_enabled && invalid_mentions" color="warning" class="mb-2">
      <template #icon>
        <IconHawkAlertTriangle class="w-8 h-8" />
      </template>
      <template #content>
        <div class="text-black font-semibold">
          {{ invalid_mentions?.members }} {{ $t("does not have access to the file") }}
        </div>
        <div v-if="invalid_mentions?.can_share">
          <hawk-checkbox v-model="state.share_and_comment" class="mt-1 text-black font-normal">
            {{ $t('Share file') }}
          </hawk-checkbox>
        </div>
      </template>
    </HawkAlertBanner>
    <div
      class="rounded-lg"
      :class="{
        'rounded-tl-none': type === 'static' && !state.is_editing && !is_new_comment,
        'border border-primary-600': is_editor_highlighted && is_editor_enabled && state.is_editor_focussed,
        'border border-gray-300': is_editor_enabled,
      }"
    >
      <HawkWysiwygEditorComponent
        :key="state.forceUpdate"
        v-model="state.editor_contents"
        :focus_position="focus_position"
        :autofocus="autofocus"
        :class="{
          'bg-gray-50 !pointer-events-none rounded-lg': state.is_editor_disabled,
          '!-ml-1': !is_editor_enabled,
        }"
        :editor_enabled="is_editor_enabled && !state.is_editor_disabled"
        :mention_items="mention_items"
        :placeholder_text="placeholder_text"
        :plugins="['mentions', 'paste-files']"
        :editor_classes="is_editor_enabled ? 'min-h-[44px] py-3 mb-1' : ''"
        :append_mentions_to="props.append_mentions_to"
        @click="onEditorClicked"
        @input="onInput"
        @focus="onFocus"
        @blur="state.is_editor_focussed = false"
        @ctrl-enter="onSend"
        @initialized="onInitialized"
        @files-paste="onFilesPaste"
      />
      <div v-if="files_to_attach.length" class="px-4 py-2 flex flex-wrap gap-5">
        <div v-for="file of files_to_attach" :key="file?.uuid" class="relative border p-2 border-gray-200 rounded-2xl w-fit flex items-center">
          <IconHawkLoadingCircle v-if="file?.upload_pending" class="animate-spin w-10 h-10 mr-4" />
          <hawk-icon-thumbnail v-else :width="10" :height="10" class="rounded-md mr-4" />
          <div v-tippy="file?.name || ''" class="text-sm">
            {{ $filters.truncate(file?.name || '', 15) }}
          </div>
          <div
            v-if="!file?.upload_pending"
            class="absolute -top-3 -right-3 p-1 cursor-pointer flex justify-center items-center w-fit rounded-full text-xs text-white bg-gray-900 hover:bg-gray-700"
            @click="$emit('removeAttachment', file?.data?.uuid ?? file.uuid)"
          >
            <IconHawkX class="w-4 h-4" />
          </div>
        </div>
      </div>
      <div>
        <slot name="more_options" />
      </div>
      <div
        v-if="is_editor_enabled"
        class="p-[6px] rounded-b-lg flex justify-between"
        :class="{
          'bg-gray-50': state.is_editor_disabled,
        }"
      >
        <div class="flex justify-center items-center">
          <div class="mr-2 h-7 w-7 rounded-lg cursor-pointer hover:bg-gray-100 flex justify-center items-center" @click="triggerMentions">
            <IconHawkAtSign class="w-4 h-4 text-gray-500" />
          </div>
          <div class="relative mr-2 h-7 w-7 rounded-lg cursor-pointer hover:bg-gray-100 flex justify-center items-center" @click="onEmojiPickerClicked">
            <IconHawkFaceSmile class="w-4 h-4 text-gray-500" />
            <EmojiPicker
              v-if="state.is_emoji_picker_visible"
              v-click-outside="() => (state.is_emoji_picker_visible = false)"
              :native="true"
              :hide-group-names="true"
              :disable-skin-tones="true"
              class="absolute left-0 bottom-8"
              @click.stop
              @select="onSelectEmoji"
            />
          </div>
          <div v-if="!(disabled_controls.includes('attach') || state.is_editing)" class="mr-2 h-7 w-7 rounded-lg cursor-pointer hover:bg-gray-100 flex justify-center items-center" @click="emit('addAttachments')">
            <IconHawkAttachmentOne class="w-4 h-4 text-gray-500" />
          </div>
          <div v-if="!disabled_controls.includes('settings')" class="mr-2 h-7 w-7 rounded-lg cursor-pointer hover:bg-gray-100 flex justify-center items-center" @click="emit('showMoreOptions')">
            <IconHawkSettingsOne class="w-4 h-4 text-gray-500" />
          </div>
        </div>
        <div class="flex justify-center items-center">
          <div
            v-if="type === 'static'"
            type="text"
            class="mr-2 p-1.5 rounded-lg text-xs cursor-pointer flex justify-center items-center hover:bg-gray-200"
            @click="onCancelClicked"
          >
            {{ $t('Cancel') }}
          </div>
          <div
            v-tippy="is_send_allowed ? $t('Ctrl+Enter to send') : ''"
            class="flex justify-center items-center w-[31px] h-7 rounded-lg text-white"
            :class="[is_send_allowed ? 'cursor-pointer bg-blue-600 hover:bg-blue-700' : 'bg-blue-200']"
            @click="onSend"
          >
            <IconHawkSendThree class="w-4 h-4" />
          </div>
        </div>
      </div>
      <div
        v-if="!all_controls_disabled && !is_new_comment && !state.is_editing && (type === 'static')"
        class="hidden group-hover:block absolute z-999 !-top-[30px] -right-0.5"
      >
        <div class="h-9 mt-1.5 mr-1.5 p-1 bg-white border border-gray-200 shadow-sm rounded flex justify-center items-center">
          <div
            v-if="!props.disabled_controls.includes('pin')"
            class="mr-1 cursor-pointer flex justify-center items-center hover:bg-gray-200 w-7 h-7 rounded-lg text-gray-600"
            @click="emit('pin')"
          >
            <IconHawkPinned v-if="is_pinned" class="text-warning-400" />
            <IconHawkPinTwo v-else />
          </div>
          <div
            v-if="!props.disabled_controls.includes('edit')"
            class="mr-1 cursor-pointer flex justify-center items-center hover:bg-gray-200 w-7 h-7 rounded-lg text-gray-600"
            @click="state.is_editing = true"
          >
            <IconHawkEditTwo class="text-gray-600" />
          </div>
          <div
            v-if="!props.disabled_controls.includes('reply')"
            class="mr-1 px-1 h-7 rounded-lg cursor-pointer hover:bg-gray-200 flex justify-center items-center text-gray-600"
            @click="onReplyClicked"
          >
            <IconHawkReverseLeft class="mr-2" />
            <span class="font-medium text-sm">{{ $t('Reply') }}</span>
          </div>
          <div
            v-if="!props.disabled_controls.includes('delete')"
            class="cursor-pointer flex justify-center items-center hover:bg-gray-200 w-7 h-7 rounded-lg text-gray-600"
            @click="emit('delete')"
          >
            <IconHawkTrashThree />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!is_new_comment && !state.is_editing && (type === 'static')" class="flex justify-between ml-2 mt-2">
      <HawkLikedUsersPopover
        v-if="!is_editor_enabled && !props.disabled_controls.includes('like') && !!liked_user_uids.length"
        :liked_user_uids="liked_user_uids"
        :liked_by_me="liked_by_me"
        :reaction_count="reaction_count"
        @liked="emit('toggleReaction')"
      />
      <div
        v-show="!is_editor_enabled && !props.disabled_controls.includes('like') && !liked_user_uids.length"
        class="h-7 min-w-[45px] rounded-xl cursor-pointer bg-gray-50 group-hover:bg-gray-100 flex justify-center items-center"
        @click="emit('toggleReaction')"
      >
        <icon-hawk-thumbs-up-filled v-if="liked_by_me" class="h-4 w-4 text-warning-400" />
        <icon-hawk-thumbs-up v-else class="h-4 w-4" :class="{ '!visible': reaction_count }" />
        <span v-if="reaction_count" class="ml-1 text-sm w-2">
          {{ reaction_count }}
        </span>
      </div>
      <slot name="right_footer" />
    </div>
  </div>
</template>
