import {
  CORE_API_HOST,
} from '~/common/utils/api-hosts.js';

export function generateAssetScopeRequest(req, endpoint, method, baseURL = CORE_API_HOST, options = {}) {
  const prefix_url = options?.prefix_url || '';
  const asset_key = options?.asset_key || 'assets';
  const asset_path = req.asset_id ? `${asset_key}/${req.asset_id}/` : '';
  const url = prefix_url + asset_path + endpoint;

  return {
    url,
    method,
    data: req.body,
    baseURL,
    ...(req.query && { params: req.query }),
    ...(req.headers && { headers: req.headers }),
    ...(req.extraOptions || {}),
    toast: req.toast,
    signal: req.signal,
  };
}
