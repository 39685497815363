<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import FormBulkUpdate from '~/forms/components/form-bulk-update.vue';
import { useExportPDF } from '~/forms/composables/form-detail-composable';
import { FORM_PERMISSIONS } from '~/forms/constants';

const props = defineProps({
  forms_store: {
    type: Object,
    required: true,
  },
  selected_forms: {
    type: Array,
    required: true,
  },
  options: {
    type: Object,
    default: () => {},
  },
  bulk_actions_config: {
    type: Object,
    default: () => ({
      prepend: false,
      include: ['bulk-update', 'export-as-pdf', 'delete'],
      additional_options: [],
    }),
  },
});
const emit = defineEmits(['deleteForms']);

const $t = inject('$t');
const $toast = inject('$toast');

const is_pdf_exporting = ref(false);

const route = useRoute();
const auth_store = useAuthStore();
const { formsPDFExportHandler } = useExportPDF();

function handleBulkFill() {
  const { open, close } = useModal({
    component: FormBulkUpdate,
    attrs: {
      forms_store: props.forms_store,
      onClose() {
        close();
      },
      selected_forms: props.selected_forms,
    },
  });
  open();
}
async function formBulkDeleteHandler() {
  try {
    const selected_forms_uids = props.selected_forms.map(form => form.uid);
    await props.forms_store.remove_forms(selected_forms_uids);
    emit('deleteForms');
  }
  catch (error) {
    logger.log(error);
  }
}
function deletePopup() {
  const form_text = props.selected_forms.length > 1 ? $t('forms') : $t('form');
  const content = `${$t('Are you sure you want to delete')} ${props.selected_forms.length} ${form_text} ? ${$t('This action cannot be undone.')}`;
  const { open: openDeletePopup, close: closeDeletePopup } = useModal({
    component: HawkDeletePopup,
    attrs: {
      header: $t('Delete Forms'),
      content,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          await formBulkDeleteHandler();
          closeDeletePopup();
        }
        catch (err) {
          logger.log(err);
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}
function exportCTAHandler() {
  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    is_pdf_exporting.value = true;
  });
}
const bulk_action_options = computed(() => {
  const options_to_include = props.bulk_actions_config?.include || ['bulk-update', 'export-as-pdf', 'delete'];
  const options = [
    { uid: 'bulk-update', label: $t('Bulk Update'), on_click: handleBulkFill, disabled: props.selected_forms.length > 100, tooltip: props.selected_forms.length > 100 ? $t('You can only select up to 100 forms at once') : '' },
    ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
      ? [
          { uid: 'delete', label: $t('Delete'), on_click: deletePopup, disabled: props.selected_forms.length > 100, tooltip: props.selected_forms.length > 100 ? $t('Can not delete more than 100 forms') : '' },
        ]
      : []),
    ...(auth_store.check_split('forms_export_pdf')
      ? [
          { uid: 'export-as-pdf', label: $t('Export PDF'), on_click: exportCTAHandler, disabled: props.selected_forms.length > 100, tooltip: props.selected_forms.length > 100 ? $t('Can not export more than 100 forms') : '' },
        ]
      : []
    ),
  ];
  const filtered_options = options.filter(option => options_to_include.includes(option.uid));
  return props.bulk_actions_config?.prepend
    ? [...(props.bulk_actions_config?.additional_options || []), ...filtered_options]
    : [...filtered_options, ...(props.bulk_actions_config?.additional_options || [])];
});
</script>

<template>
  <div class="flex items-center">
    <hawk-menu
      :items="bulk_action_options"
      :disabled="!selected_forms?.length || selected_forms.length > 100"
      v-bind="options"
      @open="show_dropdown = true"
      @close="() => {
        show_dropdown = false;
        $emit('close')
      }"
    >
      <template #trigger="{ open: openValue }">
        <slot name="trigger" :open="openValue">
          <hawk-button type="outlined">
            {{ $t('Actions') }}
            <IconHawkChevronDown
              class="text-white transition-transform"
              :class="{ 'rotate-180': show_dropdown }"
            />
          </hawk-button>
        </slot>
      </template>
    </hawk-menu>
  </div>
  <HawkExportToast
    v-if="is_pdf_exporting"
    v-bind="{
      ...(selected_forms.length > 1 ? {
        progress_text: $t('Exporting PDF'),
        completed_text: $t('Export request received'),
        completed_subtitle: $t('You will receive an email to download once the export is completed.'),
      } : {}),
    }"
    :submit="() => formsPDFExportHandler(selected_forms, route.params?.template_uid, () => is_pdf_exporting = false, props.forms_store)"
    @cancel="is_pdf_exporting = false"
    @close="is_pdf_exporting = false"
  />
</template>
