<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';

// other
import { groupBy, map, orderBy } from 'lodash-es';
import { sortData, sortRowsByColumn } from '~/common/utils/common.utils';

// composables
import { useDocumentPermissions } from '~/dms/composables/document-permissions.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useDocumentStore } from '~/dms/store/document.store';

// components
import DocumentBreadcrumbs from '~/dms/components/atoms/document-breadcrumbs.vue';
import DocumentThumbnail from '~/dms/components/atoms/document-thumbnail.vue';
import DocumentFolderForm from '~/dms/components/documents/forms/document-folder-form.vue';
import DocumentPlaceholderForm from '~/dms/components/documents/forms/document-placeholder-form.vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  action_label: {
    type: String,
    required: true,
  },
  is_move: {
    type: Boolean,
    required: false,
    default: false,
  },
  is_single_file: {
    type: Boolean,
    required: false,
    default: false,
  },
  move_data: {
    type: Array,
    required: false,
    default: () => [],
  },
  columns: {
    type: Array,
    required: false,
  },
  element: {
    type: Object,
    required: false,
    default: () => {},
  },
  on_submit: {
    type: Function,
    required: true,
  },
  move_item: {
    type: Object,
    required: false,
    default: () => {},
  },
  move_bulk_items: {
    type: Array,
    required: false,
    default: () => [],
  },
  update_table: {
    type: Function,
    required: true,
  },
  is_transmittal: {
    type: Boolean,
    default: false,
  },
});

defineEmits([
  'close',
  'targetElementUpdate',
]);

const $services = inject('$services');

const route = useRoute();

const document_permissions = useDocumentPermissions();

const auth = useAuthStore();
const common_store = useCommonStore();
const document_store = useDocumentStore();

const raw_folders = computed(() => document_store.folders_hierarchy);

// used to determine the starting point of folder hierarchy (in widget case data is different)
const folders = computed(() => {
  const folder_uids = props?.move_data?.map(item => item.uid) || [];

  return props.is_widget
    ? raw_folders.value.filter(item => folder_uids.includes(item.uid))
    : raw_folders.value;
});

const table_ref = ref(null);
const state = reactive({
  loading: false,
  is_initial_loading: false,
  breadcrumb_items: [],
  selected_item: {
    uid: 'files',
    type: 'files',
    label: 'Files',
  },
  groupedData: {},
  selected_items: [],
  active_item: {
    payload: {
      folder: null,
      asset: null,
    },
    children: folders.value,
    files: [],
  },
});

const is_internal = computed(() => document_store.is_internal);

// continually updated current list data shown in popup (active folder's folders/files)
const list_data = computed(() => {
  state.active_item.children.forEach(item => item.disable_table_checkbox = item.type === 'folder');
  if (props.is_move) {
    return sortData(state.active_item.children, 'name', 'asc', true);
  }

  else {
    const filtered_files = route.params.asset_id ? state.active_item?.files.filter(file => file.asset === route.params.asset_id) : state.active_item.files;
    return [
      ...sortData(state.active_item.children, 'name', 'asc', true),
      ...sortData(filtered_files, 'name', 'asc', true),
    ];
  }
});

const last_step = computed(() => {
  return !(list_data.value.length && list_data.value.length > 0);
});

const folder_tree = computed(() => {
  let final_tree = document_store.folders_hierarchy;
  if (final_tree?.length) {
    // asset scope
    if (route.params.asset_id) {
      final_tree = final_tree.filter(item => item.asset === route.params.asset_id);
      final_tree = sortData(final_tree, 'name', 'asc');
      return final_tree;
    }
    // org scope
    final_tree = map(groupBy(final_tree, 'asset'), (value, key) => (
      {
        name: key,
        uid: key,
        ancestors: [],
        type: key === 'null' ? 'organization' : 'asset',
        folders: sortData(value, 'name', 'asc'),
      }
    ));
    final_tree = orderBy(final_tree, [item => item.uid !== 'null' ? common_store.get_asset(item.uid)?.name.toLowerCase() : 'aaa'], 'asc');
    const orgIndex = final_tree.findIndex(i => i.name === 'null');
    if (orgIndex !== -1) {
      const modified = final_tree.splice(orgIndex, 1)[0];
      final_tree.unshift(modified);
    }
    return final_tree;
  }

  else {
    return final_tree;
  }
});

const local_columns = [
  {
    id: 'select',
    header: '',
    accessorKey: 'select',
    size: 1,
    customStyle: props.is_single_file ? { pointerEvents: 'none' } : {}
    ,
  },
  {
    header: 'Name',
    accessorKey: 'name',
    id: 'name',
    size: 400,
    cell: info => info.getValue(),
    columns: [],
    sortingFn: sortRowsByColumn,
  },
];

function isNotDestinationFolder(folder) {
  if (props.move_bulk_items?.length)
    return isNotBulkItem(folder);
  else
    return props.move_item?.uid !== folder.uid;
}

function filterAllowed(folders) {
  if (!props.is_move)
    return folders;

  const allowed_folders = [];
  folders.forEach((folder) => {
    if ((document_permissions.checkDocumentPermission({ permission: 'destination_folder', item: folder })) && isNotDestinationFolder(folder))
      allowed_folders.push(folder);
  });
  return allowed_folders;
}

function isNotBulkItem(folder) {
  return props.move_bulk_items.every(selected_item => selected_item.uid !== folder.uid);
}

async function getTargetElement() {
  const assetId = route.params.asset_id || state.selected_item.asset || (state.selected_item.type === 'asset' ? state.selected_item.uid : undefined);

  const target_element = await common_store.get_target_element(assetId);

  if (props.is_widget)
    emit('targetElementUpdate');

  return target_element;
}

const folder_modal = useModal({
  component: DocumentFolderForm,
});

function openFolderModal() {
  folder_modal.patchOptions({
    attrs: {
      on_submit: async (data) => {
        let payload = { ...data };
        const target_element = await getTargetElement();

        let folder_uid = state.selected_item?.uid || null;
        if (state.selected_item.type === undefined)
          folder_uid = state.selected_item.uid || null;

        payload = {
          ...payload,
          ...(is_internal.value ? { internal: true } : {}),
          location: null,
          ...(folder_uid !== 'files' && state.selected_item.type !== 'asset' && folder_uid !== 'null' ? { folder_uid } : { folder_uid: null }),
          organization: auth.current_organization?.uid,
          public: false,
          target_element,
        };

        const { data: { documents: { folders: { added: [response] } } } } = await $services.documents.post({ body: { folders: { add: [payload] } } });

        state.breadcrumb_items.push({
          label: response.name,
          uid: response.uid,
          disabled: true,
        });

        state.selected_item = response;

        await onCrumbClicked({
          item: response,
          path: state.breadcrumb_items,
        }, true);

        // reset sidebar
        await document_store.set_hierarchy();

        // reset table, but only when creating folder inside currently active dms folder
        if (document_store.active_folder_meta.uid === folder_uid)
          await props.update_table();
      },
      onClose() {
        folder_modal.close();
      },
    },
  });
  folder_modal.open();
}

function setChildrenFolders(files = []) {
  const asset_id = route.params.asset_id;
  state.active_item = {
    payload: {
      asset: null,
      folder: null,
    },
    children: filterAllowed(folders.value),
    files,
  };

  state.selected_item = asset_id
    ? { uid: asset_id, type: 'asset', label: common_store.assets_map[asset_id]?.name }
    : { uid: 'files', type: 'files', label: 'Files' };
}

async function onCrumbClicked(data, is_new_folder = false) {
  if (data.item.uid === data.path[data.path.length - 1].uid && !is_new_folder)
    return;

  state.is_initial_loading = true;
  const files = await getFiles({ item: data.item });
  const asset_id = route.params.asset_id;

  if (data.item.uid === 'files' || (asset_id && data.item.type === 'asset')) {
    setChildrenFolders(files);
    state.is_initial_loading = false;
    return;
  }
  const item_path = [];
  for (const item of data.path) {
    item_path.push(item);
    if (item.uid === data.item.uid)
      break;
  }
  let current_data = folder_tree.value;

  for (const item of item_path) {
    if ((asset_id && (item.uid === 'files' || item.type === 'asset'))
      || (!asset_id && item.uid === 'files')) {
      continue;
    }

    current_data = current_data.find(i => i.uid === item.uid)?.folders
    || current_data.find(i => i.uid === item.uid)?.children
    || [];
  }

  state.active_item = {
    payload: {
      folder: data.item.uid,
    },
    children: current_data,
    files,
  };

  state.selected_item = {
    ...data.item,
    scope: data.path[1].uid,
  };
  state.is_initial_loading = false;
}

async function getFiles({ item = null, is_root = false }) {
  const options = { children: true, service_object: true };
  if (item?.type === 'folder') {
    options.uid = item.uid;
  }

  else if (item?.type === 'asset' && !is_root) {
    options.asset = item.uid;
    options.is_root = true;
  }

  else if (item?.type === 'organization' && !is_root) {
    options.asset = false;
    options.is_root = true;
  }

  else {
    options.is_root = true;
  }

  const response = await $services.documents.getAll({
    query: {
      ...options,
      internal: is_internal.value,
      lite: true,
      ...(is_root ? { is_root } : {}),
    },
  });

  return response?.data?.files || [];
}

async function handleTreeItemClick(item, _, row) {
  if (item.type === 'file') {
    table_ref.value?.updateSelectedRows(row);
    return;
  }
  state.selected_item = item;

  // skip the request for files if move popup
  if (props.is_move) {
    state.active_item = {
      payload: {
        folder: item.uid,
        asset: item.asset,
      },
      children: item.children,
      files: [],
    };
    return;
  }

  state.is_initial_loading = true;
  const files = await getFiles({ item }) || [];
  state.active_item = {
    payload: {
      folder: item.uid,
      asset: item.asset,
    },
    children: item.children,
    files,
  };
  state.is_initial_loading = false;
}

function groupByFunction(data) {
  const group_by = 'asset';
  state.groupedData = data.reduce((group, row) => {
    const { original } = row;
    group[original[group_by]] = group[original[group_by]] ?? {
      show: false,
      rows: [],
    };
    group[original[group_by]].rows.push(row);
    return group;
  }, {});

  return state.groupedData;
}

async function onSubmit() {
  state.loading = true;
  if (props.is_move)
    await props.on_submit({ destination_uid: state.selected_item.uid === 'files' ? 'null' : state.selected_item.uid });

  else
    await props.on_submit({ selected_documents: state.selected_items });

  state.loading = false;
}

function updateBreadcrumbsPath(data) {
  state.breadcrumb_items = data;
}

function onRowSelect(items) {
  state.selected_items = [];
  if (items.length > 0)
    items.forEach(item => state.selected_items.push(item.original));
}

onBeforeMount(async () => {
  state.is_initial_loading = true;
  await document_store.set_hierarchy(route.params.asset_id);
  const files = await getFiles({ is_root: true });
  state.selected_items = [];
  state.active_item = {
    payload: {
      folder: null,
      asset: null,
    },
    children: filterAllowed(folders.value),
    files,
  };
  state.is_initial_loading = false;
});

onMounted(() => {
  if (route.params.asset_id)
    state.selected_item = { uid: route.params.asset_id, type: 'asset', label: common_store.assets_map[route.params.asset_id]?.name };
});

function formatTable(cell) {
  const { type, active_transmittal, uid } = cell?.row?.original || {};
  if (type === 'file') {
    if (active_transmittal?.uid && props.is_transmittal)
      return { backgroundColor: '#F2F4F7', pointerEvents: 'none' };

    if (props.is_single_file && state.selected_items.length === 1 && state.selected_items[0].uid !== uid)
      return { pointerEvents: 'none' };
  }
  return {};
}

const placeholder_modal = useModal({
  component: DocumentPlaceholderForm,
});

async function createPlaceholderFiles() {
  let payload = {};
  const target_element = await getTargetElement();

  let folder_uid = state.selected_item?.uid || null;
  if (state.selected_item.type === undefined)
    folder_uid = state.selected_item.uid || null;

  payload = {
    ...(is_internal.value ? { internal: true } : {}),
    location: null,
    ...(folder_uid !== 'files' && state.selected_item.type !== 'asset' && folder_uid !== 'null' ? { folder_uid } : { folder_uid: null }),
    organization: auth.current_organization?.uid,
    public: false,
    target_element,
  };

  placeholder_modal.patchOptions({
    attrs: {
      options: payload,
      onSave(files) {
        files.forEach((file) => {
          state.active_item.files.push(file);
        });
      },
      onClose() {
        placeholder_modal.close();
      },
    },
  });
  placeholder_modal.open();
}
</script>

<template>
  <hawk-modal-container content_class="max-h-[750px] rounded-lg !w-[700px]" @click.stop>
    <div class="col-span-12">
      <hawk-modal-header :is_move="true" @close="$emit('close')">
        <template #title>
          {{ title }}
        </template>
      </hawk-modal-header>
      <!-- Body -->
      <hawk-modal-content :is_scroll="false">
        <div v-if="state.is_initial_loading">
          <hawk-loader />
        </div>
        <div v-else>
          <div>
            <DocumentBreadcrumbs
              class="mb-4"
              :active_item="state.selected_item"
              :show_active_color="false"
              @on-click="onCrumbClicked"
              @breadcrumbs-computed="(data) => updateBreadcrumbsPath(data)"
            />
            <span v-if="state.selected_items.length">
              {{ `${state.selected_items.length} ${$t('document(s) selected')}` }}
            </span>
            <div v-if="last_step && is_move" class="rounded-lg border border-gray-300 bg-gray-100 w-full flex items-center justify-center p-16">
              <span class="text-gray-700">
                {{ $t("Move Here") }}
              </span>
            </div>
            <div v-else id="move_tree" class="rounded-lg border max-h-[450px] overflow-y-scroll scrollbar">
              <HawkTable
                ref="table_ref"
                is_gapless
                :data="list_data"
                :columns="is_move ? columns : local_columns"
                :is_loading="false"
                :group_by_function="state.selected_item.uid === 'files' ? groupByFunction : null"
                :sticky_group_label="true"
                :show_menu_header="false"
                grouping_row_classes="rounded-lg bg-gray-100 py-2 text-sm font-semibold text-gray-900"
                table_width="550px"
                arrows="right"
                :format-table="formatTable"
                @row-clicked="handleTreeItemClick"
                @select-row="onRowSelect"
              >
                <template #group-icon-close>
                  <IconHawkMinusCircle class="text-gray-600 h-4.5 w-4.5" />
                </template>
                <template #group-icon-open>
                  <IconHawkPlusCircle class="text-gray-600 h-4.5 w-4.5" />
                </template>
                <template #group-label="{ data }">
                  <hawk-organization-name
                    v-if="data.value === 'null'"
                    class="cursor-pointer"
                    organization_class="text-gray-900"
                    :uid="auth.current_organization?.uid"
                  />
                  <hawk-asset-name
                    class="cursor-pointer"
                    asset_class="text-gray-900"
                    :uid="data.value"
                  />
                </template>
                <template #name="{ data: { row: { original } } }">
                  <div v-if="original.type === 'folder'" class="w-full flex justify-between items-center">
                    <div class="flex items-center">
                      <div class="rounded-lg bg-gray-50 min-w-[40px] min-h-[40px] w-10 h-10 flex justify-center items-center group-hover:bg-gray-100 mr-3">
                        <IconHawkFolder class="text-xl text-gray-900" />
                      </div>
                      <span class="text-sm font-medium text-gray-900">{{ original.name }}</span>
                    </div>
                    <icon-ic-outline-chevron-right v-if="original.children?.length" class="text-xl font-normal text-gray-600" />
                  </div>
                  <div v-else class="w-full flex items-center justify-between">
                    <div class="w-full flex items-center">
                      <DocumentThumbnail
                        :file="original"
                        :is_browser="true"
                        class="mr-3"
                      />
                      <span class="text-sm font-medium text-gray-900">
                        {{ original.name }}
                      </span>
                    </div>
                    <IconHawkInfoCircle v-if="is_transmittal && original?.active_transmittal?.uid" v-tippy="$t('This document is currently in another transmittal')" class="pointer-events-auto" />
                  </div>
                </template>
                <template #noData>
                  <HawkIllustrations type="no-data" for="files" variant="mini" />
                </template>
              </HawkTable>
            </div>
            <hawk-button v-if="is_move" type="plain" class="mt-3 text-gray-600 font-semibold text-lg hover:text-gray-900" @click="openFolderModal">
              <IconHawkPlus class="text-gray-600 font-semibold text-lg hover:text-gray-900" />
              {{ $t('New Folder') }}
            </hawk-button>
            <hawk-button v-if="is_transmittal" type="plain" class="mt-3 text-gray-600 font-semibold text-lg hover:text-gray-900" @click="createPlaceholderFiles">
              <IconHawkPlus class="text-gray-600 font-semibold text-lg hover:text-gray-900" />
              {{ $t('New Document') }}
            </hawk-button>
          </div>
        </div>
      </hawk-modal-content>
      <hawk-modal-footer class="flex justify-between items-center">
        <template #right>
          <!-- Footer -->
          <div class="flex justify-end items-center">
            <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
              {{ $t('Cancel') }}
            </hawk-button>
            <hawk-button :disabled="is_move ? false : !state.selected_items.length" :loading="state.loading" @click="onSubmit">
              {{ action_label }}
            </hawk-button>
          </div>
        </template>
      </hawk-modal-footer>
    </div>
  </hawk-modal-container>
</template>
