<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { storeToRefs } from 'pinia';

import { useRoute } from 'vue-router';

// other
import { getFileExtension } from '~/common/utils/common.utils.js';
// composables
import { useDocumentViewer } from '~/plans/composables/useDocumentViewer';
// stores
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: false,
    default: 0,
  },
  should_open: {
    type: Boolean,
    required: false,
    default: false,
  },
  context_download: {
    type: Function,
    required: true,
  },
  is_browser: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['closeAttachment']);

const HawkFileIcon = defineAsyncComponent(() => import('~/common/components/atoms/hawk-file-icon.vue'));

const $t = inject('$t');
const $toast = inject('$toast');

const route = useRoute();

const { setup_core } = useDocumentViewer({});

const document_store = useDocumentStore();

const { is_internal } = storeToRefs(document_store);
const video_supported_format = ['avi', 'mkv', 'mov', 'mp4', 'webm', 'wmv', 'wtv'];
const local_index = ref(null);
const file_url = ref(null);
const attachment_obj = ref(null);
const type = computed(() => props.file.type);
const is_setting_up_document_viewer = ref(false);

if (!window?.Core)
  setup_core().then(() => is_setting_up_document_viewer.value = true);

function getSupportedAttachment(file) {
  if (file.is_placeholder)
    return false;

  const extension = getFileExtension(file.file_name || file.name);
  if (!window.Core)
    return true;

  return window.Core.SupportedFileFormats.CLIENT.includes(extension?.toLowerCase()) || video_supported_format.includes(extension?.toLowerCase());
}

function openPresignedURL(file) {
  if (getSupportedAttachment(file)) {
    attachment_obj.value = { file_name: file.file_name, url: file?.presigned_url };
    local_index.value = props.index;
  }
}

async function openAttachment() {
  if (props?.file?.presigned_url) {
    return openPresignedURL(props?.file);
  }
  else if (type.value !== 'folder') {
    file_url.value = await document_store.get_download_url({
      body: {
        files: [{
          uid: props.file.uid,
        }],
      },
      query: {
        read: true,
        ...(is_internal.value ? { internal: true } : {}),
        ...(
          route.meta.page === 'archive'
            ? { archive: true }
            : {}
        ),
      },
      attribute: 'url',
    });
  }
  if (getSupportedAttachment(props.file)) {
    if (file_url.value.data.urls.length) {
      attachment_obj.value = { file_name: props.file.file_name || props.file.name, url: file_url.value.data.urls[0].url };
      local_index.value = props.index;
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again later',
        type: 'error',
        position: 'bottom-right',
      });
    }
  }
  else {
    emit('closeAttachment');
    $toast({
      title: $t('Unsupported file'),
      text: $t('This file is not supported for viewing on the platform. Download to view it.'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

function onDownload() {
  props.context_download({ file: props.file });
}

watch(() => props.should_open, async () => {
  if (props.should_open === true)
    openAttachment();
});

const extra_viewer_options = ref({ no_tools: true, can_annotate: false, show_comments: false, viewer_container_class: '', version: props.file.defaultversion, has_unread_comments: props.file?.has_new_message });

const default_document_version = computed(() => props.file.defaultversion);

watch(default_document_version, () => {
  extra_viewer_options.value.version = default_document_version.value;
});

const viewer_options = computed(() => ({
  module: 'documents',
  element: props.file.element,
  element_uid: props.file.uid,
  enable_annotations: true,
  ...extra_viewer_options.value,
}));

function onShowAnnotation() {
  extra_viewer_options.value.no_tools = false;
  extra_viewer_options.value.viewer_container_class = 'w-[calc(100vw-400px)]';
  extra_viewer_options.value.has_unread_comments = false;
  extra_viewer_options.value.can_annotate = true;
  extra_viewer_options.value.show_comments = true;
}

function onHideAnnotation() {
  extra_viewer_options.value.no_tools = true;
  extra_viewer_options.value.viewer_container_class = '';
  extra_viewer_options.value.has_unread_comments = false;
  extra_viewer_options.value.can_annotate = false;
  extra_viewer_options.value.show_comments = false;
}

async function getVersionURL(version_uid) {
  const response = await document_store.get_download_url({
    body: {
      files: [{
        uid: props.file.uid,
        version_uid,
      }],
    },
    attribute: 'url',
  });
  const url = response.data.urls[0].url;
  return url;
}

async function updateVersionFromViewer(version_uid) {
  if (version_uid && attachment_obj.value) {
    const version = props.file.versions.find(version => version.uid === version_uid);
    if (version) {
      const url = await getVersionURL(version_uid);
      attachment_obj.value.file_name = version.file_name;
      attachment_obj.value.url = url;
      extra_viewer_options.value.version = version_uid;
    }
  }
}
</script>

<template>
  <div
    v-if="type === 'folder'"
    class="rounded-lg bg-gray-50 group-hover:bg-gray-100 min-w-[40px] min-h-[40px] w-10 h-10 flex justify-center items-center"
  >
    <IconHawkFolder class="h-[20px] w-[20px] text-gray-800" />
  </div>
  <div v-else>
    <div v-if="file.thumbnail_xsmall" :key="is_setting_up_document_viewer" class="relative w-10">
      <img
        alt="thumbnail"
        :src="file.thumbnail_xsmall"
        class="w-10 h-10 rounded-lg object-cover"
      >
      <div v-if="getSupportedAttachment(props.file) && !is_browser && route.name !== 'files-documents-trash'" class="absolute top-0  place-items-center hidden group-hover:grid" @click="openAttachment">
        <div class="h-10 w-10 bg-black rounded-lg bg-opacity-20" />
        <div class="text-white absolute">
          <IconHawkEye />
        </div>
      </div>
    </div>
    <div v-else-if="getSupportedAttachment(props.file) && !is_browser && route.name !== 'files-documents-trash'" class="w-10 h-10 min-w-[40px] min-h-[40px] bg-gray-100 rounded-lg group flex justify-center relative items-center">
      <div class="absolute top-0  place-items-center hidden group-hover:grid" @click="openAttachment">
        <div class="h-10 w-10 bg-black rounded-lg bg-opacity-20" />
        <div class="text-white absolute">
          <IconHawkEye />
        </div>
      </div>
      <HawkFileIcon :file-name="props.file.file_name || props.file.name" />
    </div>
    <div v-else-if="!file.is_placeholder" class="w-10 h-10 min-w-[40px] min-h-[40px] bg-gray-100 rounded-lg group flex justify-center relative items-center">
      <HawkFileIcon :file-name="props.file.file_name || props.file.name" />
    </div>
    <HawkAttachmentViewer
      :key="local_index + extra_viewer_options.version"
      :attachment="attachment_obj"
      :viewer_options="viewer_options"
      @download="onDownload"
      @close="local_index = null; attachment_obj = null; $emit('closeAttachment');onHideAnnotation()"
      @show_annotation="onShowAnnotation"
      @hide_annotation="onHideAnnotation"
    >
      <template #conversations="{ document_viewer }">
        <document-comments-sidebar v-if="extra_viewer_options.show_comments" :document="props.file" :members_with_access="file_url?.data?.urls?.[0]?.members_with_access" :version="extra_viewer_options.version" :document_viewer="document_viewer" @update_version_from_viewer="updateVersionFromViewer" />
      </template>
    </HawkAttachmentViewer>
  </div>
</template>
