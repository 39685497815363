<script setup>
const emit = defineEmits(['change']);
</script>

<template>
  <Vueform size="sm">
    <div class="col-span-12">
      <div class="text-sm font-normal text-gray-600 mb-6">
        {{ $t('Are you sure you want to delete? This action cannot be undone.') }}
      </div>
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="text-sm font-semibold text-gray-900">
            {{ $t('Remove tracking') }}
          </div>
          <div class="text-sm font-regular text-gray-600">
            {{ $t('Activity trackings of all the activities in the schedule associated to this/these resource(s) will be removed.') }}
          </div>
        </div>
        <ToggleElement @change="$event => emit('change', $event)" />
      </div>
    </div>
  </Vueform>
</template>
