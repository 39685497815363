import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { accessTokenCookie } from '~/common/utils/common.utils';
import { CNC_API_HOST, PM_API_HOST } from '../utils/api-hosts';

export function cnc_export_pdf_report(req = {}) {
  const { auth_store } = useCommonImports();
  return fetch(
    `${CNC_API_HOST}/reports/${req.report_id}/pdf/?organization=${auth_store.current_organization?.uid}`,
    {
      headers: {
        'Authorization': `jwt ${accessTokenCookie()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  );
}
export function pm_schedule_download_xlsx(req = {}) {
  const { auth_store } = useCommonImports();
  return fetch(
    `${PM_API_HOST}/schedules/${req.schedule_id}/download-p6-xlsx/?organization=${auth_store.current_organization?.uid}`,
    {
      headers: {
        'Authorization': `jwt ${accessTokenCookie()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: req.body,
    },
  );
}
