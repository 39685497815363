import { apiWrapper } from '~/common/utils/api-wrapper.util';
import BaseService from '~/common/utils/base-service.util';

const create_api_wrapper = (end_point, baseURL) => new BaseService(end_point, baseURL);

function createServicesMap(request) {
  const services_map = {};
  Object.keys(request).forEach((key) => {
    Object.entries(request[key]?.default || {}).forEach(([service_name, service]) => {
      const formatted_service_name = service_name.replace(' ', '_');
      // if (service.url)
      services_map[formatted_service_name] = create_api_wrapper(service.url, service.baseURL);
      if (service.methods) {
        if (!services_map[formatted_service_name])
          services_map[formatted_service_name] = {};
        Object.entries(service.methods).forEach(([method_name, method]) => {
          services_map[formatted_service_name][method_name] = async req => await apiWrapper({ baseURL: service.baseURL, ...method(req) });
        });
      }
    });
  });
  return services_map;
}

export const services = createServicesMap(import.meta.glob('~/common/services/api.service.js', { eager: true }));
