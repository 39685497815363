import {
  DMS_API_HOST,
} from '~/common/utils/api-hosts.js';

export default {
  documents: {
    url: 'documents',
    baseURL: DMS_API_HOST,
  },
  files: {
    url: 'files',
    baseURL: DMS_API_HOST,
  },
  folders: {
    url: 'folders',
    baseURL: DMS_API_HOST,
  },
  dms_transmittals: {
    url: 'transmittals',
    baseURL: DMS_API_HOST,
    methods: {
      export_pdf: req => ({
        url: 'transmittals/export-pdf',
        data: req.body,
        method: 'POST',
      }),
    },
  },
  dms_custom_fields: {
    url: 'custom-fields',
    baseURL: DMS_API_HOST,
  },
  dms_issue_purposes: {
    url: 'issue-purposes',
    baseURL: DMS_API_HOST,
  },
  dms_document_statuses: {
    url: 'document-statuses',
    baseURL: DMS_API_HOST,
  },
  dms_configuration: {
    url: 'configuration',
    baseURL: DMS_API_HOST,
  },
  dms_workflow_templates: {
    url: 'workflow-templates',
    baseURL: DMS_API_HOST,
  },
  dms: {
    url: '',
    baseURL: DMS_API_HOST,
    methods: {
      get_graph: req => ({
        url: 'reports',
        data: req.body,
        method: 'POST',
      }),
    },
  },
};
