<script setup>
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useMembers } from '~/common/composables/members.js';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  mode: {
    type: String,
    default: 'create',
  },
  existingMembers: {
    type: Array,
    default: () => [],
  },
  existingCustomResources: {
    type: Array,
    default: () => [],
  },
  prefillData: {
    type: Object,
    default: () => ({}),
  },
  save: {
    type: Function,
    required: false,
  },
});

const emit = defineEmits(['close', 'save', 'addAnother']);

const $t = inject('$t');

const route = useRoute();

const { getUserDetails } = useMembers();

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { active_schedule, is_fullscreen } = storeToRefs(project_management_store);
const { save_resources } = project_management_store;

const hot$ = ref(null);

const state = reactive({
  add_another: false,
  is_loading: false,
  is_translucent_loading: false,
  is_saving: false,
  assignee_key: 0,
  data: [],
  hands_on_table_instance: null,
  invalid_data: {
    is_table_empty: false,
    has_existing_names: false,
    has_duplicate_names: false,
    is_comment_cell_present: false,
  },
});

const taken_names = computed(() => {
  let taken_names_array = [
    ...props.existingMembers.map((item) => {
      const user = getUserDetails([item]);
      return user.members_details[0].name;
    }),
    ...props.existingCustomResources.map((item) => {
      return item;
    }),
  ];
  if (props.mode === 'edit') {
    taken_names_array = taken_names_array.filter((name) => {
      return !props.prefillData.some(item => item.name === name);
    });
  }
  return taken_names_array;
});

const member_details = computed(() => {
  return getUserDetails(common_store.scope_users(route.params.asset_id)).members_details;
});

const valid_member_details = computed(() => {
  return member_details.value.filter((member) => {
    return !props.existingMembers.includes(member.uid);
  });
});

const hot_columns = computed(() => {
  const columns = [
    {
      data: 'uid',
      header: 'UID',
    },
    {
      data: 'name',
      renderer: 'membersRenderer',
      validator: 'default-validator',
      type: 'autocomplete',
      source: props.mode === 'edit' ? [] : valid_member_details.value.map(member => member.name),
      config: {
        required: true,
      },
      header: $t('Name'),
      width: '200px',
    },
    {
      data: 'type',
      readOnly: true,
      header: $t('Type'),
      renderer: typeRenderer,
    },
    {
      data: 'permission',
      type: 'dropdown',
      validator: 'default-validator',
      header: $t('Access level'),
      source: [
        $t('Viewer'),
        $t('Manager'),
        $t('Admin'),
      ],
      config: {
        required: true,
        field_type: 'dropdown',
      },
    },
    {
      data: 'cost_type',
      type: 'dropdown',
      validator: 'default-validator',
      header: $t('Cost type'),
      source: [
        $t('Per hour'),
        $t('Per item'),
        $t('Fixed'),
      ],
      config: {
        required: true,
        field_type: 'dropdown',
      },
    },
    {
      data: 'cost',
      type: 'numeric',
      prefix: active_schedule.value.currency.symbol,
      validator: 'default-validator',
      header: $t('Cost'),
      renderer: 'suffixPrefixRenderer',
      config: {
        required: true,
        field_type: 'numeric',
        min: 0,
      },
    },
  ];

  return columns;
});

const hot_table_height = computed(() => {
  let calculated_height = (state.data.length + 1) * 30 + 7;
  if (calculated_height > 500)
    calculated_height = 500;
  if (state.data.length < 3)
    calculated_height += 100;
  return `${calculated_height}px`;
});

const is_save_disabled = computed(() => {
  return state.invalid_data.is_table_empty
    || state.invalid_data.has_duplicate_names
    || state.invalid_data.has_existing_names
    || state.is_save_disabled
    || state.invalid_data.is_comment_cell_present
    || state.is_translucent_loading;
});

function typeRenderer(instance, td, row, _col, _prop, _value, _cellProperties) {
  td.classList.add('read-only-cell', 'htMiddle');

  const hot_row_data = instance.getData()[row];
  if (hot_row_data[1]) {
    const member = valid_member_details.value.find((member) => {
      return member.uid === hot_row_data[1];
    });
    if (member)
      td.textContent = $t('Member');
    else
      td.textContent = $t('Custom');
  }
  else {
    td.textContent = '';
  }

  return td;
}

function checkEmptyResource(resource) {
  const copied_resource = cloneDeep(resource);
  if (copied_resource.uid)
    delete copied_resource.uid;
  return Object.values(resource).every((value) => {
    return value === null || value === '' || value === undefined || value?.length === 0;
  });
}

function preprocessData(data) {
  return data.map((resource) => {
    return {
      uid: resource[0],
      name: resource[1],
      type: resource[2],
      permission: resource[3],
      cost_type: resource[4],
      cost: resource[5],
    };
  });
}

async function onSave() {
  const indices_to_be_removed = [];
  preprocessData(state.hands_on_table_instance.getData()).forEach((resource, index) => {
    const copied_resource = {
      name: resource.name,
      type: resource.type,
      permission: resource.permission,
      cost_type: resource.cost_type,
      cost: resource.cost,
    };
    if (resource.type === $t('Custom'))
      delete copied_resource.permission;
    if (resource.cost_type === $t('Fixed'))
      delete copied_resource.cost;
    const is_empty_task = checkEmptyResource(copied_resource);
    if (is_empty_task)
      indices_to_be_removed.push(index - indices_to_be_removed.length);
  });

  indices_to_be_removed.forEach((index) => {
    if (state.data.length === 1) {
      state.invalid_data.is_table_empty = true;
      return;
    }
    state.hands_on_table_instance.alter('remove_row', index);
  });
  if (state.data.length === 1 && state.invalid_data.is_table_empty) {
    await nextTick();
    state.is_saving = false;
    setTimeout(() => {
      state.invalid_data.is_table_empty = true;
    }, 0);
    return;
  }

  if (props.mode === 'create') {
    const names = state.data.map(resource => resource.name);
    if (names.length !== new Set(names).size) {
      await nextTick();
      state.is_saving = false;
      state.invalid_data.has_duplicate_names = true;
    }

    for (const resource_name of names) {
      if (taken_names.value.includes(resource_name)) {
        await nextTick();
        state.is_saving = false;
        state.invalid_data.has_existing_names = true;
      }
    }
  }

  if (state.invalid_data.has_duplicate_names || state.invalid_data.has_existing_names)
    return;

  const preprocessed_data = preprocessData(state.hands_on_table_instance.getData()).map((resource) => {
    const permission_map = {
      [`${$t('Viewer')}`]: 'read',
      [`${$t('Manager')}`]: 'manage',
      [`${$t('Admin')}`]: 'write',
    };
    const cost_type_map = {
      [`${$t('Per hour')}`]: 'per_hour',
      [`${$t('Per item')}`]: 'per_item',
      [`${$t('Fixed')}`]: 'fixed',
    };
    const member = valid_member_details.value.find((member) => {
      return member.uid === resource.name;
    });
    return {
      ...(props.mode === 'edit' ? { uid: resource.uid } : {}),
      ...(member ? { external_id: resource.name } : { name: resource.name }),
      type: member ? 'member' : 'custom',
      permission: permission_map[resource.permission],
      cost_type: cost_type_map[resource.cost_type],
      cost: resource.cost,
    };
  });
  if (props.mode === 'create')
    await save_resources(preprocessed_data, [], []);
  else
    await save_resources([], preprocessed_data, []);
  emit('save');
  emit('close');
}

function onHandsOnTableReady(event) {
  state.is_loading = false;
  state.hands_on_table_instance = event;

  state.hands_on_table_instance.updateSettings({
    cells(row, col) {
      const row_data = state.hands_on_table_instance.getData()[row];
      const cells_object = {};
      if (row_data?.[1]) {
        if (col === hot_columns.value.findIndex(column => column.data === 'name')) {
          const member = valid_member_details.value.find((member) => {
            return member.uid === row_data[1];
          });

          if (member) {
            cells_object.renderer = hot$.value.getCustomFunctions().membersRenderer;
            if (props.mode === 'edit')
              cells_object.readOnly = true;
          }
          else {
            cells_object.renderer = textRenderer;
          }
        }
      }
      if (col === 3) {
        if (row_data?.[1]) {
          const member = valid_member_details.value.find((member) => {
            return member.uid === row_data[1];
          });
          if (member) {
            cells_object.readOnly = false;
            cells_object.validator = 'default-validator';
          }
          else {
            cells_object.readOnly = true;
            cells_object.validator = undefined;
          }
        }
      }
      if (col === 4) {
        if (row_data?.[1]) {
          const member = valid_member_details.value.find((member) => {
            return member.uid === row_data[1];
          });
          if (member) {
            cells_object.source = [$t('Per hour'), $t('Fixed')];
          }
          else {
            cells_object.source = [$t('Per hour'), $t('Per item'), $t('Fixed')];
          }
        }
      }
      if (col === 5) {
        if (row_data?.[4] === $t('Fixed')) {
          cells_object.readOnly = true;
          cells_object.validator = undefined;
        }
        else {
          cells_object.readOnly = false;
          cells_object.validator = 'default-validator';
        }
      }
      return cells_object;
    },
  });

  state.hands_on_table_instance.render();
}

function isEmptyRow(row_data) {
  if (!row_data)
    return true;
  return row_data.every((value) => {
    return value === null || value === '' || value === undefined || value?.length === 0;
  });
}

function textRenderer(instance, td, row, col, _prop, _value, cellProperties) {
  const hot_row_data = instance.getData()[row];
  td.textContent = hot_row_data[col];
  td.classList.add('htMiddle');

  if (cellProperties.valid === false) {
    td.className += ' htInvalid';
  }

  return td;
}

function beforeValidate(_val, row, _prop) {
  const hot_row_data = state.hands_on_table_instance.getData()[row];
  if (isEmptyRow(hot_row_data?.slice?.(1))) {
    return 0;
  }
}

function beforeChange(_changes, _source) {
  // For some reason, the members selection is failing if this empty beforeChange function is not called
}

function beforeCellPasteInterceptor(data, pastedValue, dataRowIndex, dataColIndex, cellMeta, cellData, row, _col) {
  const value = data[dataRowIndex % data.length][dataColIndex % data[0].length];
  if (cellMeta.prop === 'name') {
    if (props.mode === 'edit') {
      const member = valid_member_details.value.find((member) => {
        return member.uid === value || member.uid === value?.[0];
      });
      if (member) {
        data[dataRowIndex % data.length][dataColIndex % data[0].length] = member.name;
        return;
      }
    }
    if (Array.isArray(value)) {
      data[dataRowIndex % data.length][dataColIndex % data[0].length] = value[0];
    }
    else if (data[dataRowIndex % data.length][dataColIndex % data[0].length] !== pastedValue) {
      data[dataRowIndex % data.length][dataColIndex % data[0].length] = pastedValue;
    }
  }
  else if (cellMeta.prop === 'permission') {
    const pasting_name = data[dataRowIndex % data.length][(dataColIndex - 2) % data[0].length];
    if (pasting_name && dataRowIndex >= 0 && (dataColIndex - 2) >= 0) {
      const member = valid_member_details.value.find((member) => {
        return member.uid === pasting_name;
      });
      if (member)
        data[dataRowIndex % data.length][dataColIndex % data[0].length] = value;
      else
        data[dataRowIndex % data.length][dataColIndex % data[0].length] = cellData;
    }
    else {
      const current_name = state.hands_on_table_instance.getDataAtCell(row, hot_columns.value.findIndex(column => column.data === 'name'));
      if (current_name) {
        const member = valid_member_details.value.find((member) => {
          return member.uid === current_name;
        });
        if (member)
          data[dataRowIndex % data.length][dataColIndex % data[0].length] = value;
        else
          data[dataRowIndex % data.length][dataColIndex % data[0].length] = cellData;
      }
    }
  }
  else if (cellMeta.prop === 'cost_type') {
    const pasting_name = data[dataRowIndex % data.length][(dataColIndex - 3) % data[0].length];
    if (pasting_name && dataRowIndex >= 0 && (dataColIndex - 3) >= 0) {
      const member = valid_member_details.value.find((member) => {
        return member.uid === pasting_name;
      });
      if (member && value === $t('Per item'))
        data[dataRowIndex % data.length][dataColIndex % data[0].length] = cellData;
    }
    else {
      const current_name = state.hands_on_table_instance.getDataAtCell(row, hot_columns.value.findIndex(column => column.data === 'name'));
      if (current_name) {
        const member = valid_member_details.value.find((member) => {
          return member.uid === current_name;
        });
        if (member && value === $t('Per item'))
          data[dataRowIndex % data.length][dataColIndex % data[0].length] = cellData;
      }
    }
  }
  else if (cellMeta.prop === 'cost') {
    const pasting_cost_type = data[dataRowIndex % data.length][dataColIndex - 1 % data[0].length];
    if (pasting_cost_type && dataRowIndex >= 0 && (dataColIndex - 1) >= 0) {
      if (pasting_cost_type === $t('Fixed')) {
        data[dataRowIndex % data.length][dataColIndex % data[0].length] = cellData;
      }
      else if (pastedValue) {
        const parsedValue = Number.parseFloat(pastedValue.replace(/[^\d.-]/g, ''));
        if (!Number.isNaN(parsedValue)) {
          data[dataRowIndex % data.length][dataColIndex % data[0].length] = parsedValue;
        }
      }
    }
    else {
      if (state.hands_on_table_instance.getDataAtCell(row, hot_columns.value.findIndex(column => column.data === 'cost_type')) === $t('Fixed')) {
        data[dataRowIndex % data.length][dataColIndex % data[0].length] = cellData;
      }
      else if (pastedValue) {
        const parsedValue = Number.parseFloat(pastedValue.replace(/[^\d.-]/g, ''));
        if (!Number.isNaN(parsedValue)) {
          data[dataRowIndex % data.length][dataColIndex % data[0].length] = parsedValue;
        }
      }
    }
  }
}

function afterBeginEditing(row, column) {
  const current_value = state.hands_on_table_instance.getDataAtCell(row, column);
  const member_found = valid_member_details.value.find((member) => {
    return member.uid === current_value;
  });
  if (member_found)
    state.hands_on_table_instance.getActiveEditor()?.setValue(member_found.name);
}

function checkForDuplicates() {
  const column = state.hands_on_table_instance?.getDataAtCol?.(1);
  column?.forEach?.((value, row) => {
    const data = Object.assign([], column);

    const index = data.indexOf(value);
    data.splice(index, 1);
    const second_index = data.indexOf(value);
    const cell = state.hands_on_table_instance.getCellMeta(row, 1);
    if (index > -1 && second_index > -1 && !(value == null || value === '')) {
      cell.valid = false;
      cell.comment = { value: $t('Duplicate value') };
    }
    else if (taken_names.value.includes(value)) {
      cell.valid = false;
      cell.comment = { value: $t('Resource name already exists in schedule') };
    }
    else {
      cell.valid = true;
      cell.comment = '';
    }
  });
  state.hands_on_table_instance?.render?.();
  state.invalid_data.is_comment_cell_present = !!Array.from(document.getElementsByClassName('htCommentCell')).length;
}

function afterRemoveRow() {
  checkForDuplicates();
}

function afterChange(changes, operation) {
  checkForDuplicates();

  if (operation === 'CopyPaste.paste') {
    changes?.forEach?.((change) => {
      if (change[1] === 'name') {
        const value = change[3];
        const is_the_value_already_uid = valid_member_details.value.find((member) => {
          return member.uid === value || member.uid === value?.[0];
        });

        if (is_the_value_already_uid) {
          if (!state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'permission')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'permission'), $t('Viewer'));
        }
        if (state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'name'))) {
          if (!state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type'), $t('Per hour'));
          if (state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type')) !== $t('Fixed') && !state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost'), 0);
        }
      }
    });
    state.hands_on_table_instance?.render?.();
    setTimeout(() => {
      state.is_translucent_loading = false;
      state.invalid_data.is_comment_cell_present = !!Array.from(document.getElementsByClassName('htCommentCell')).length;
    }, 1000);
  }

  if (operation !== 'edit')
    return;
  changes?.forEach?.((change) => {
    if (change[1] === 'cost_type') {
      if (change[3] === $t('Fixed')) {
        state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost'), null);
      }
    }
    if (change[1] === 'name') {
      const value = change[3];
      let member = valid_member_details.value.find((member) => {
        return member.name === value || member.name === value?.[0];
      });
      if (props.mode === 'edit')
        member = null;

      const is_the_value_already_uid = valid_member_details.value.find((member) => {
        return member.uid === value || member.uid === value?.[0];
      });
      if (is_the_value_already_uid) {
        // Eat 5 star, do nothing
      }
      else {
        if (member) {
          state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'name'), member.uid);
          if (state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type')) === $t('Per item'))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type'), null);
          if (!state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'permission')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'permission'), $t('Viewer'));
        }
        else {
          if (state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'name')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'permission'), null);
        }
        if (state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'name')) && !Array.isArray(value)) {
          if (!state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type'), $t('Per hour'));
          if (state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost_type')) !== $t('Fixed') && !state.hands_on_table_instance.getDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost')))
            state.hands_on_table_instance.setDataAtCell(change[0], hot_columns.value.findIndex(column => column.data === 'cost'), 0);
        }
      }
    }
  });
  state.hands_on_table_instance?.render?.();
}

function afterCreateRow(...args) {
  state.hands_on_table_instance.setDataAtCell(args[0], 0, `hot-${crypto.randomUUID().substring(0, 8)}`);
}

watch(() => state.data.length, () => {
  let calculated_height = (state.data.length + 1) * 30 + 7;
  if (calculated_height > 500)
    calculated_height = 500;

  state.hands_on_table_instance?.updateSettings({
    height: `${calculated_height}px`,
  });
}, { immediate: true });

watch(() => state.data, () => {
  if (state.is_saving)
    return;
  state.invalid_data.is_table_empty = false;
  state.invalid_data.has_duplicate_names = false;
  state.invalid_data.has_existing_names = false;
  state.hands_on_table_instance?.validateCells?.((is_valid) => {
    state.is_save_disabled = !is_valid;
  });
  setTimeout(() => {
    state.hands_on_table_instance?.render?.();
  }, 0);
}, { deep: true });

onMounted(() => {
  state.is_loading = true;
  if (props.mode === 'create') {
    const empty_item = { uid: null, name: null, type: null, permission: null, cost_type: null, cost: null };
    while (state.data.length < 15) {
      const new_item_uid = `hot-${crypto.randomUUID().substring(0, 8)}`;
      empty_item.uid = new_item_uid;
      state.data.push(cloneDeep(empty_item));
    }
  }
  else if (props.mode === 'edit') {
    state.data = cloneDeep(props.prefillData);
  }

  document.addEventListener('Enter', (event) => {
    const el = document.querySelector('.pika-single');
    if (el) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  });
});
</script>

<template>
  <HawkModalContainer
    id="pm-resource-modal-container"
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body', escToClose: false }"
    content_class="w-[80vw]"
  >
    <Vueform
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              <template v-if="props.mode === 'create'">
                {{ $t('Add resources') }}
              </template>
              <template v-else-if="props.mode === 'edit'">
                {{ $t('Update resources') }}
              </template>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent class="min-h-[550px]">
          <HawkLoader v-if="state.is_loading" class="h-[300px]" />
          <HawkHandsontable
            ref="hot$"
            :hot-settings="{
              datePickerConfig: {
                disableDayFn(date) {
                  return !$g?.isWorkTime?.(date);
                },
              },
              rowHeights: '30px',
              className: 'htMiddle',
              manualColumnResize: true,
              allowRemoveRow: props.mode === 'create',
              hiddenColumns: {
                columns: [0],
                copyPasteEnabled: false,
                indicators: true,
              },
              comments: true,
              // afterColumnResize,
              beforeValidate,
              beforeChange,
              afterChange,
              afterRemoveRow,
              afterCreateRow,
              afterBeginEditing,
            }"
            :row-sorting="false"
            :data="state.data"
            :columns="hot_columns"
            :col-headers="hot_columns.map(column => column.header)"
            :columns-menu="{ items: {} }"
            :height="hot_table_height"
            :add-new-row-on-enter="props.mode === 'create'"
            :before-cell-paste-interceptor="beforeCellPasteInterceptor"
            :members-renderer-classes="{ container: '!mb-0 mt-[3px]', avatar: '!text-xs !w-4 !h-4 flex justify-center' }"
            class="pm-excel-modal min-h-[120px]"
            @ready="onHandsOnTableReady"
            @before-paste="state.is_translucent_loading = true"
          />
          <div v-if="state.is_translucent_loading" class="absolute top-[20vh] left-[10vw] z-10 flex items-center justify-center w-[80vw] h-[550px]">
            <HawkLoader class="z-10" />
            <div class="absolute top-0 left-0 w-full h-full bg-white opacity-70" />
          </div>
          <div v-if="state.invalid_data.is_table_empty" class="text-sm text-error-600 mt-3">
            {{ $t('The table cannot be empty.') }}
          </div>
          <div v-if="state.invalid_data.has_duplicate_names" class="text-sm text-error-600 mt-3">
            {{ $t('The table cannot have duplicate resource names.') }}
          </div>
          <div v-if="state.invalid_data.has_existing_names" class="text-sm text-error-600 mt-3">
            {{ $t('Some of the entered resource names already exist in the schedule.') }}
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <HawkButton
                type="text"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement
                name="save"
                :loading="state.is_saving"
                :disabled="is_save_disabled"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>

<style lang="scss">
.pm-excel-modal {
  .changeType {
    @apply hidden;
  }

  .handsontableInput {
    line-height: 29px;
  }

  th.afterHiddenColumn::before{
    content: '' !important;
  }

  .htCommentCell {
    @apply bg-[#FFBEBA];
  }
}
</style>
