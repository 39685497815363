import {
  CORE_API_HOST,
  DMS_API_HOST,
  FORMS_API_HOST,
  PLANS_API_HOST,
  SM_API_HOST,
  TERRA_API_HOST,
  THERM_API_HOST,
} from '~/common/utils/api-hosts.js';

const element_stage_hosts_map = {
  core: CORE_API_HOST,
  therm: THERM_API_HOST,
  terra: TERRA_API_HOST,
  forms: FORMS_API_HOST,
  dms: DMS_API_HOST,
  system_model: SM_API_HOST,
  work: PLANS_API_HOST,
};
export default {
  sm_templates: {
    url: 'templates',
    baseURL: SM_API_HOST,
  },
  sm_categories: {
    url: 'categories',
    baseURL: SM_API_HOST,
  },
  sm_components: {
    url: 'components',
    baseURL: SM_API_HOST,
    methods: {
      import_csv: (req) => {
        return {
          url: `csv/plan/${req.plan_id}/component/${req.component_id}/import-csv/`,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'post',
        };
      },
      add_layout: (req) => {
        return {
          url: `components/${req.uid}/add/layout`,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'post',
        };
      },
      add_alignment: (req) => {
        return {
          url: `components/${req.uid}/add/alignment`,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'post',
        };
      },
      get_children: (req) => {
        return {
          url: `components/${req.parent_id}/get-children/`,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'get',
        };
      },
    },
  },
  sm_fieldvalues: {
    url: 'fieldvalues',
    baseURL: SM_API_HOST,
  },
  sm_fields: {
    url: 'fields',
    baseURL: SM_API_HOST,
  },
  sm_instances: {
    url: 'instances',
    baseURL: SM_API_HOST,
    methods: {
      post_aliases: req => ({
        url: `csv/template/${req.template}/alias`,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'post',
      }),
      get_asset_instances: req => ({
        url: `instances/asset/${req.asset_id}`,
        method: 'GET',
      }),
      get_element_hierarchy: req => ({
        url: `/element/${req.id}/hierarchy`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        toast: req?.toast,
        baseURL: element_stage_hosts_map[req.stage.toLowerCase()],
      }),
      get_element_for_instance: req => ({
        url: `/elements/instance/${req.instance_uid}`,
        method: 'GET',
        baseURL: element_stage_hosts_map[req.stage.toLowerCase()],
      }),
      generate_instances: (req) => {
        return {
          url: '/instances/materials/',
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'POST',
        };
      },
    },
  },
};
