<script setup>
import DOMPurify from 'dompurify';

const props = defineProps({
  activityName: {
    type: String,
    required: true,
  },
  userName: {
    type: String,
    required: true,
  },
  commentBy: {
    type: String,
    required: true,
  },
  commentText: {
    type: String,
    required: true,
  },
  translate: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['close-toast']);
</script>

<template>
  <div class="bg-white p-4 text-gray-900 cursor-pointer pm-mentions-toast">
    <div class="flex flex-col gap-3">
      <div class="flex justify-between gap-2">
        <div class="flex gap-2">
          <HawkMembers size="xs" :members="props.commentBy" />
          <span>
            <span class="text-sm !font-semibold text-gray-600 mr-1">
              {{ props.userName }}
            </span>
            <span class="text-sm font-normal text-gray-600">
              {{ props.translate('added a comment in') }}
            </span>
            <span class="text-sm !font-semibold text-gray-600">
              "{{ props.activityName }}"
            </span>
          </span>
        </div>
        <IconHawkXClose class="cursor-pointer text-gray-400 hover:text-gray-600 mt-0.5" @click.stop="emit('close-toast')" />
      </div>
      <span class="text-sm font-normal text-gray-700">
        {{ DOMPurify.sanitize(props.commentText, { ALLOWED_TAGS: [] }) }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.Vue-Toastification__container.hawk-toast-container:has(.pm-mentions-toast) {
  .Vue-Toastification__toast {
    background-color: white;
    padding: 0;
  }
  .Vue-Toastification__progress-bar {
    background-color: #2E90FA;
    border-bottom-color: red;
  }
}
</style>
