import {
  CNC_API_HOST,
  CORE_API_HOST,
  GIS_API_HOST,
  ONM_API_HOST,
  PM_API_HOST,
  TASKS_API_HOST,
  TASKS_API_HOST_V2,
  THERM_API_HOST,
} from '~/common/utils/api-hosts.js';
import AuthService from './auth.service';
import CommonService from './common.service';
import CoreService from './core.service';
import DMSService from './dms.service';
import FormsService from './forms.service';
import InventoryServices from './inventory.service';
import PlansService from './plans.service';
import SMServices from './sm.service';
import TerraService from './terra.service';

export default {
  ...AuthService,
  ...CommonService,
  ...InventoryServices,
  ...SMServices,
  ...DMSService,
  ...FormsService,
  ...PlansService,
  ...TerraService,
  ...CoreService,
  // ONM
  onm: {
    url: 'onm',
    baseURL: ONM_API_HOST,
  },
  // Tasks
  tasks: {
    url: 'tasks',
    baseURL: TASKS_API_HOST,
    methods: {
      get: req => ({
        url: `tasks/${req.id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        baseURL: TASKS_API_HOST_V2,
      }),
      get_map_snapshot: (req = {}) => {
        return {
          url: `misc/map-snapshot`,
          data: req.body,
          ...(req.query && { params: req.query }),
          method: 'POST',
        };
      },
      load_from_template: req => ({
        url: 'tasks/action/load-template/',
        method: 'POST',
        data: req.body,
      }),
      save_template: req => ({
        url: 'tasks/action/save-template/',
        method: 'POST',
        data: req.body,
      }),
      duplicate: req => ({
        url: 'tasks/action/duplicate',
        method: 'POST',
        data: req.body,
      }),
      get_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/`,
        method: 'GET',
        data: req.body,
      }),

      set_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/`,
        method: 'POST',
        data: req.body,
      }),
      update_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/`,
        method: 'PATCH',
        data: req.body,
      }),
      delete_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/${req.attachment_id}/`,
        method: 'DELETE',
      }),
      set_checklist_attachments: req => ({
        url: `tasks/${req.id}/checklists/${req.checklist_id}/items/${req.item_id}/attachments`,
        method: 'POST',
        data: req.body,
      }),
      delete_checklist_attachments: req => ({
        url: `tasks/${req.id}/checklists/${req.checklist_id}/items/${req.item_id}/attachments/${req.attachment_id}/`,
        method: 'DELETE',
      }),
      set_comment_attachments: req => ({
        url: `tasks/${req.id}/comments/${req.comment_id}/attachments/`,
        method: 'POST',
        data: req.body,
      }),
      delete_comment_attachments: req => ({
        url: `tasks/${req.id}/comments/${req.comment_id}/attachments/${req.attachment_id}/`,
        method: 'DELETE',
      }),
      // Checklist
      create_checklist: req => ({
        url: `tasks/${req.id}/checklists/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      update_checklists: req => ({
        url: `tasks/${req.id}/checklists/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'PATCH',
      }),
      update_checklists_template: req => ({
        url: `tasks/${req.id}/checklists/template/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      load_multiple_checklists: req => ({
        url: 'checklists/template',
        data: req.body,
        method: 'POST',
      }),
      delete_checklist: req => ({
        url: `tasks/${req.id}/checklists/${req.checklist_id}`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'DELETE',
      }),
      generate_csv: req => ({
        url: 'tasks/generate/csv',
        data: req.body,
        method: 'POST',
      }),
      get_graph: req => ({
        url: 'reports/new',
        data: req.body,
        method: 'POST',
      }),
      get_tasks_by_status: req => ({
        url: 'tasks/status',
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'GET',
      }),
      get_custom_report: req => ({
        url: 'reports/export-pdf',
        data: req.body,
        method: 'POST',
        toast: false,
        baseURL: TASKS_API_HOST_V2,
      }),
      // Reminders
      get_reminders: req => ({
        url: `tasks/${req.id}/reminders/`,
        ...(req.query && { params: req.query }),
        data: req.body,
      }),
      create_reminder: req => ({
        url: `tasks/${req.id}/reminders/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      update_reminder: req => ({
        url: `tasks/${req.id}/reminders/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_reminder: req => ({
        url: `tasks/${req.id}/reminders/${req.reminder_id}`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'DELETE',
      }),
    },
  },
  checklists: {
    url: 'checklists',
    baseURL: TASKS_API_HOST,
    methods: {
      get_attachments: req => ({
        url: `checklists/${req.checklist_id}/attachments/`,
        method: 'GET',
      }),
      add_attachments: req => ({
        url: `checklists/${req.checklist_id}/items/${req.item_id}/attachments/`,
        data: req.body,
        method: 'POST',
      }),
      delete_attachments: req => ({
        url: `checklists/${req.checklist_id}/items/${req.item_id}/attachments/${req.attachment_id}`,
        data: req.body,
        method: 'DELETE',
      }),
    },
  },
  // PM
  project_management: {
    url: '',
    baseURL: PM_API_HOST,
    methods: {
      get_graph: req => ({
        url: '/reports/new',
        data: req.body,
        method: 'POST',
        toast: false,
      }),
    },
  },
  // Dashboard
  dashboard: {
    url: 'reports',
    baseURL: TASKS_API_HOST,
    methods: {
      get_reports: req => ({
        baseURL: CORE_API_HOST,
        url: 'assets/reports/',
        data: req.body,
        method: 'POST',
      }),
      custom_print: req => ({
        baseURL: import.meta.env.VITE_APP_API_HOST,
        url: 'dashboard-exporter/v1/resource/custom-export',
        data: req.body,
        method: 'POST',
      }),
    },
  },
  // Therm
  therm: {
    url: '',
    baseURL: THERM_API_HOST,
    methods: {
      get_elements: req => ({
        url: 'elements/feature/',
        method: 'post',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      get_project_elements: req => ({
        url: `elements/projects/${req.project_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      get_graph: req => ({
        url: 'metrics/reports',
        data: req.body,
        method: 'POST',
      }),

    },
  },
  // GIS
  gis_service: {
    baseURL: GIS_API_HOST,
    methods: {
      volume_base_height: (req) => {
        return ({
          url: 'volume/base-height/',
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      volume_triangulated: (req) => {
        return ({
          url: 'volume/triangulated/',
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      volume_flat_minimum: (req) => {
        return ({
          url: 'volume/flat-minimum/',
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      volume_surface_to_surface: (req) => {
        return ({
          url: 'volume/surface-to-surface/',
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      // Elevation
      elevation_line: (req) => {
        return ({
          url: 'elevation/line/',
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      elevation_point: (req) => {
        return ({
          url: 'elevation/point/',
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
    },
  },
  // CNC
  cnc_template: {
    url: 'templates',
    baseURL: CNC_API_HOST,
  },
  cnc_reports: {
    url: 'reports',
    baseURL: CNC_API_HOST,
  },
};
