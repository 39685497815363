<script setup>
import { computed, defineProps, onBeforeUnmount, onMounted, reactive } from 'vue';

const props = defineProps({
  textFieldOptions: {
    type: Array,
    default: () => [],
  },
  inputData: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['select', 'close']);
const state = reactive({
  hovered_option: null,
  input_data: '',
  filtered_text_field_options: [],
});

const filtered_text_field_options = computed(() => {
  const something = props.textFieldOptions.filter((option) => {
    return option.toLowerCase().includes(props.inputData.toLowerCase()) && option !== props.inputData;
  });
  return something;
});

function handleArrowDownKey() {
  if (state.hovered_option === '') {
    state.hovered_option = filtered_text_field_options.value[0];
  }
  else {
    const index = filtered_text_field_options.value.indexOf(state.hovered_option);
    if (index < filtered_text_field_options.value.length - 1)
      state.hovered_option = filtered_text_field_options.value[index + 1];
  }
  document.getElementById(state.hovered_option)?.scrollIntoView?.({ block: 'nearest' });
}

function handleArrowUpKey() {
  if (state.hovered_option === '') {
    state.hovered_option = filtered_text_field_options.value[filtered_text_field_options.value.length - 1];
  }
  else {
    const index = filtered_text_field_options.value.indexOf(state.hovered_option);
    if (index > 0)
      state.hovered_option = filtered_text_field_options.value[index - 1];
  }
  document.getElementById(state.hovered_option)?.scrollIntoView?.({ block: 'nearest' });
}

function handleEnterKey() {
  if (state.hovered_option) {
    emit('select', state.hovered_option);
  }
  else if (!props.inputData) {
    emit('close');
  }
}

function onKeyUp(e) {
  if (!filtered_text_field_options.value.length) {
    if (['Enter', 'Escape'].includes(e.key)) {
      emit('close');
    }
    return;
  }
  if (e.key === 'ArrowDown') {
    handleArrowDownKey();
  }
  else if (e.key === 'ArrowUp') {
    handleArrowUpKey();
  }
  else if (e.key === 'Enter') {
    handleEnterKey();
  }
  else if (e.key === 'Escape') {
    emit('close');
  }
}

onMounted(() => {
  window.addEventListener('keyup', onKeyUp, true);
});

onBeforeUnmount(() => {
  window.removeEventListener('keyup', onKeyUp, true);
});
</script>

<template>
  <div
    v-if="filtered_text_field_options.length"
    id="custom-text-editor" :key="filtered_text_field_options"
    class="min-w-[65px]"
  >
    <div
      :id="`custom-text-editor${state.hovered_option ? '++' : ''}`"
      class="dropdown relative z-[1003] bg-white border border-gray-300 rounded-lg max-h-[250px] scrollbar py-1"
    >
      <template
        v-for="option in filtered_text_field_options"
        :key="option"
      >
        <div
          :id="option"
          class="text-xs font-medium text-gray-600 cursor-pointer hover:text-gray-900 hover:bg-gray-50 rounded-lg px-3 py-1.5 border-b border-gray-50"
          :class="{
            'bg-gray-50 text-gray-900': state.hovered_option === option,
          }"
          @click="emit('select', option)"
        >
          {{ option }}
        </div>
      </template>
    </div>
  </div>
</template>
