<script setup>
const props = defineProps({
  save: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['close', 'discard']);

const $t = inject('$t');

const state = reactive({
  is_saving: false,
});

async function onSave() {
  state.is_saving = true;
  await props.save();
  state.is_saving = false;
  emit('close');
}
</script>

<template>
  <HawkModalContainer>
    <div class="col-span-12">
      <HawkModalHeader @close="emit('close')">
        <template #title>
          <div class="font-semibold text-lg">
            {{ $t('Unsaved changes') }}
          </div>
        </template>
      </HawkModalHeader>
      <HawkModalContent>
        <div class="text-gray-700 text-sm">
          {{ $t('Do you want to save your changes before navigating away?') }}
        </div>
      </HawkModalContent>
      <HawkModalFooter>
        <template #right>
          <div class="flex justify-between w-full col-span-full">
            <HawkButton type="plain" color="error" @click="emit('discard')">
              {{ $t('Discard') }}
            </HawkButton>
            <div>
              <HawkButton
                name="cancel"
                class="mr-3"
                type="outlined"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </HawkButton>
              <HawkButton
                name="exit"
                :loading="state.is_saving"
                @click="onSave"
              >
                {{ $t('Save') }}
              </HawkButton>
            </div>
          </div>
        </template>
      </HawkModalFooter>
    </div>
  </HawkModalContainer>
</template>
