<script setup>
import { computed } from 'vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import SmNoResultsIllustration from '~/system-model/atoms/sm-no-results-illustration.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { formatFields } from '~/system-model/utils/helper';

const $t = inject('$t');
const system_model_store = useSystemModelStore();

const table_columns = [
  {
    accessorKey: 'name',
    header: $t('Property'),
    id: 'name',
  },
  {
    accessorKey: 'value',
    header: $t('Value'),
    id: 'value',
  },
];

const active_instance = computed(() => system_model_store.active_instance);
const active_component_details = computed(() => system_model_store.active_component_details);
const field_values = computed(() => {
  if (!active_instance.value)
    return active_component_details.value?.fieldvalues;
  else
    return system_model_store.active_instance_details?.fieldvalues;
});

const fields = computed(() => formatFields(system_model_store.fields, field_values.value));

function openLinkInNewPage(url) {
  window.open(url, '_blank');
}

function downloadAttachment(file) {
  if (file?.pre_signed_url) {
    window.open(file.pre_signed_url, '_blank');
  }
  else {
    $toast({
      text: 'File not available, please try again later',
      type: 'warning',
      position: 'bottom-right',
    });
  }
}
</script>

<template>
  <SmNoResultsIllustration v-if="!fields.length" title="No #Properties found" />
  <TableWrapperVue v-else class="border-0">
    <HawkTable
      class="!px-0"
      :data="fields"
      :pagination_config="{ totalRows: fields.length, pageSize: 25 }"
      :columns="table_columns"
      :show_menu_header="false"
      :enable_infinite_scroll="true"
      :non_sortable_columns="['context_menu', 'value']"
    >
      <template #name="{ data }">
        <div class="text-gray-900 font-medium break-all">
          {{ data.row.original.name }}
        </div>
      </template>
      <template #value="{ data: { row: { original } } }">
        <div class="text-gray-600 flex items-center justify-between flex-auto">
          <div v-if="original.type === 'file'" class="w-full">
            <HawkAttachmentsList
              :items="original.value"
              :can_delete="false"
              :show_file_size="false"
              :badge-options="{
                tooltip_text: $t('Latest version synced'),
              }"
              @download="downloadAttachment"
            />
          </div>
          <div v-else-if="original.type === 'url'">
            <span class="hover:underline underline-offset-1 cursor-pointer" @click="openLinkInNewPage(original.value)">
              {{ original?.value }}
            </span>
          </div>
          <div v-else>
            {{ original?.value }}
          </div>
        </div>
      </template>
    </HawkTable>
  </TableWrapperVue>
</template>

<style lang="scss">
.file-fields {
  img {
    aspect-ratio: 1;
  }
}
</style>
