const API_HOST = import.meta.env.VITE_APP_API_HOST;
const STORAGE_API_HOST = import.meta.env.VITE_APP_STORAGE_API_HOST;

const APPROVAL_API_HOST = `${API_HOST}/approvals/v1/resource`;
const CORE_API_HOST = `${API_HOST}/core/v1/resource`;
const THERM_API_HOST = `${API_HOST}/therm/v1/resource`;
const TERRA_API_HOST = `${API_HOST}/terra/v1/resource`;
const DMS_API_HOST = `${API_HOST}/dms/v1/resource`;
const SM_API_HOST = `${API_HOST}/system-model/v1/resource`;
const FORMS_API_HOST = `${API_HOST}/forms/v1/resource`;
const GIS_API_HOST = import.meta.env.VITE_APP_GIS_API_HOST;
const CNC_API_HOST = `${API_HOST}/cnc/v1/resource`;
const PM_API_HOST = `${API_HOST}/pm/v1/resource`;
const TASKS_API_HOST = `${API_HOST}/tasks/v1/resource`;
const TASKS_API_HOST_V2 = `${API_HOST}/tasks/v2/resource`;
const PLANS_API_HOST = `${API_HOST}/work/v1/resource`;
const ANNOTATIONS_API_HOST = `${API_HOST}/annotations/v1/resource`;
const ONM_API_HOST = `${API_HOST}/onm/v1/resource`;
const INVENTORY_API_HOST = `${API_HOST}/inventory/v1/resource`;
const INTEGRATIONS_API_HOST = `${API_HOST}/integrations/v1/resource`;

export {
  ANNOTATIONS_API_HOST,
  APPROVAL_API_HOST,
  CNC_API_HOST,
  CORE_API_HOST,
  DMS_API_HOST,
  FORMS_API_HOST,
  GIS_API_HOST,
  INTEGRATIONS_API_HOST,
  INVENTORY_API_HOST,
  ONM_API_HOST,
  PLANS_API_HOST,
  PM_API_HOST,
  SM_API_HOST,
  STORAGE_API_HOST,
  TASKS_API_HOST,
  TASKS_API_HOST_V2,
  TERRA_API_HOST,
  THERM_API_HOST,
};
