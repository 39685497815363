<script setup>
import FormActionsReusable from '~/forms/components/form-actions-reusable.vue';
import TaskBulkActionsMenu from '~/tasks/components/organisms/tasks-bulk-actions-menu.vue';
import ToolButton from './tool-button.vue';

const props = defineProps({
  tool: {
    type: Object,
    required: true,
  },
  taskStore: {
    type: Object,
    default: null,
  },
  formsStore: {
    type: Object,
    default: null,
  },
  tasksForms: {
    type: Object,
    default: () => ({}),
  },
  isMenuChild: {
    type: Boolean,
    default: false,
  },
  activeTool: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['changeFeatureType']);

const tasks_list = computed(() => {
  return props.tasksForms.task?.data || [];
});

const forms_list = computed(() => {
  return props.tasksForms.form?.data || [];
});

function getTooltip() {
  let content = '';
  if (props.tool.disabled)
    content = props.tool.disabled_reason;
  else if (!props.isMenuChild && props.tool.tooltip)
    content = props.tool.tooltip;
  if (content)
    return { content, placement: 'top' };
  return null;
}

function getAdditionalTriggerClasses() {
  return ['!ring-0 !border-0', props.isMenuChild ? 'w-[200px]' : ''];
}
</script>

<template>
  <div v-tippy="getTooltip()">
    <HawkMenu
      v-if="tool.uid === 'class'"
      position="fixed"
      :additional_trigger_classes="getAdditionalTriggerClasses()"
      :items="tool.sub_tools"
      :disabled="tool.disabled"
    >
      <template #trigger="{ open }">
        <ToolButton :tool="tool" :active-tool="open ? tool.uid : ''" :is-menu-child="isMenuChild" />
      </template>
      <template #content="{ close }">
        <FeatureTypes
          class="overflow-auto max-w-[400px] max-h-[400px] min-w-[240px] scrollbar"
          type="dropdown"
          @select="emit('changeFeatureType', $event), close()"
        />
      </template>
    </HawkMenu>

    <TaskBulkActionsMenu
      v-else-if="tool.uid === 'tasks'"
      :task_store="taskStore"
      :selected_tasks="tasks_list"
      :options="{
        additional_trigger_classes: getAdditionalTriggerClasses(),
        disabled: tool.disabled,
      }"
      :bulk_actions_config="{
        prepend: true,
        include: tasksForms.task?.data.length > 0 ? ['bulk-update', 'archive', 'export-as-csv', 'export-as-pdf', 'delete'] : [],
        additional_options: tool.sub_tools,
      }"
    >
      <template #trigger="{ open }">
        <ToolButton :tool="tool" :active-tool="open ? tool.uid : ''" :is-menu-child="isMenuChild" />
      </template>
    </TaskBulkActionsMenu>

    <FormActionsReusable
      v-else-if="tool.uid === 'forms'"
      :forms_store="formsStore"
      :selected_forms="forms_list"
      :options="{
        additional_trigger_classes: getAdditionalTriggerClasses(),
        disabled: tool.disabled,
      }"
      :bulk_actions_config="{
        prepend: true,
        include: (forms_list).length > 0 ? ['bulk-update', 'export-as-pdf', 'delete'] : [],
        additional_options: tool.sub_tools,
      }"
    >
      <template #trigger="{ open }">
        <ToolButton :tool="tool" :active-tool="open ? tool.uid : ''" :is-menu-child="isMenuChild" />
      </template>
    </FormActionsReusable>

    <div v-else>
      <ToolButton :tool="tool" :is-menu-child="isMenuChild" :active-tool="activeTool" />
    </div>
  </div>
</template>
