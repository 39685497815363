<script setup>
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const props = defineProps({
  mode: {
    type: String,
    default: 'add',
    validator: value => ['add', 'edit'].includes(value),
  },
  initialData: {
    type: Object,
    default: () => ({
      type: 'FS',
      lag: 0,
    }),
  },
  dependencies: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const $toast = inject('$toast');

const project_management_store = useProjectManagementStore();
const { $g, active_task, active_schedule, flags, is_fullscreen } = storeToRefs(project_management_store);

const all_activities = computed(() => {
  const result = [];
  let existing_dependencies_task_uids = props.dependencies.map(dependency => dependency.id);
  if (props.mode === 'edit')
    existing_dependencies_task_uids = [];
  $g.value.eachTask((task) => {
    if (
      task.id !== active_task.value.id
      && (!existing_dependencies_task_uids.includes(task.id))
      && ![
        $g.value.config.types.surrogate,
        $g.value.config.types.placeholder,
        $g.value.config.types.virtual,
        $g.value.config.types.project,
      ].includes(task.type)) {
      result.push({
        ...task,
        label: task.text,
        search_property: `${$g.value.getWBSCode(task)} - ${task.text} (${task.id})`,
        value: task.id,
      });
    }
  });
  return result;
});

const state = reactive({
  form_data: cloneDeep(props.initialData),
  type: cloneDeep(props.initialData.type),
});

async function onSave() {
  try {
    state.form_data.activities.forEach((activity) => {
      const new_link_obj = {
        lag: Number.parseInt(state.form_data.lag, 10),
        source: state.form_data.source_or_target === 'source' ? active_task.value?.id : activity,
        target: state.form_data.source_or_target === 'source' ? activity : active_task.value?.id,
        type: state.type,
      };

      if (props.mode === 'add') {
        new_link_obj.id = crypto.randomUUID();
        const link_id = $g.value.addLink(new_link_obj);
        if (link_id)
          active_schedule.value.relations.push(new_link_obj);
        else
          throw new Error('Failed to add new link');
      }
      else {
        $g.value.getLink(props.initialData.link_id).lag = new_link_obj.lag;
        $g.value.getLink(props.initialData.link_id).source = new_link_obj.source;
        $g.value.getLink(props.initialData.link_id).target = new_link_obj.target;
        $g.value.getLink(props.initialData.link_id).type = new_link_obj.type;
        $g.value.updateLink(props.initialData.link_id);

        active_schedule.value.relations = $g.value.getLinks();
      }
    });
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
    });
  }
  finally {
    flags.value.activities_updated_count++;
    emit('close');
  }
}
</script>

<template>
  <HawkModalContainer id="pm-new-dependency-modal-container" :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      v-model="state.form_data"
      size="sm"
      sync
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: {
          container: 12,
          label: 3,
          wrapper: 9,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="text-lg font-semibold text-gray-900">
              <span v-if="props.mode === 'add'">
                {{ $t('Add dependency') }}
              </span>
              <span v-else>
                {{ $t('Edit dependency') }}
              </span>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <SelectElement
            name="source_or_target"
            :label="$t('Relation')"
            :items="[
              { label: $t('Predecessor'), value: 'source' },
              { label: $t('Successor'), value: 'target' },
            ]"
            default="source"
            :native="false"
            :rules="[
              'required',
            ]"
            :can-clear="false"
            :can-deselect="false"
            class="mb-6"
          >
            <template #description>
              {{ $t('The link will be created with') }}
              <span class="font-semibold break-all">"{{ active_task.text }}"</span> {{ $t('as the') }}
              <template v-if="state.form_data.source_or_target === 'source'">
                {{ $t('predecessor') }}
              </template>
              <template v-else>
                {{ $t('successor') }}
              </template>
            </template>
          </SelectElement>
          <div class="grid grid-cols-12 mb-6">
            <div class="col-span-4 text-sm font-medium text-gray-700">
              {{ $t('Type') }}<span class="text-amber-600">*</span>
            </div>
            <div class="col-span-8 flex flex-col gap-3">
              <div class="flex gap-6">
                <div @click="state.type = 'FS'">
                  <div
                    class="ring-2 ring-transparent hover:ring-primary-200 rounded p-0.5"
                    :class="{
                      '!ring-primary-600': state.type === 'FS',
                    }"
                  >
                    <IconHawkPmFinishToStartSource
                      v-if="state.form_data.source_or_target === 'source'"
                      class="w-12 h-8"
                    />
                    <IconHawkPmFinishToStartTarget
                      v-else
                      class="w-12 h-8"
                    />
                  </div>
                  <div class="text-sm text-center font-medium text-gray-900 mt-0.5">
                    FS
                  </div>
                </div>
                <div @click="state.type = 'SS'">
                  <div
                    class="ring-2 ring-transparent hover:ring-primary-200 rounded p-0.5"
                    :class="{
                      '!ring-primary-600': state.type === 'SS',
                    }"
                  >
                    <IconHawkPmStartToStartSource
                      v-if="state.form_data.source_or_target === 'source'"
                      class="w-12 h-8"
                    />
                    <IconHawkPmStartToStartTarget
                      v-else
                      class="w-12 h-8"
                    />
                  </div>
                  <div class="text-sm text-center font-medium text-gray-900 mt-0.5">
                    SS
                  </div>
                </div>
                <div @click="state.type = 'FF'">
                  <div
                    class="ring-2 ring-transparent hover:ring-primary-200 rounded p-0.5"
                    :class="{
                      '!ring-primary-600': state.type === 'FF',
                    }"
                  >
                    <IconHawkPmFinishToFinishSource
                      v-if="state.form_data.source_or_target === 'source'"
                      class="w-12 h-8"
                    />
                    <IconHawkPmFinishToFinishTarget
                      v-else
                      class="w-12 h-8"
                    />
                  </div>
                  <div class="text-sm text-center font-medium text-gray-900 mt-0.5">
                    FF
                  </div>
                </div>
                <div @click="state.type = 'SF'">
                  <div
                    class="ring-2 ring-transparent hover:ring-primary-200 rounded p-0.5"
                    :class="{
                      '!ring-primary-600': state.type === 'SF',
                    }"
                  >
                    <IconHawkPmStartToFinishSource
                      v-if="state.form_data.source_or_target === 'source'"
                      class="w-12 h-8"
                    />
                    <IconHawkPmStartToFinishTarget
                      v-else
                      class="w-12 h-8"
                    />
                  </div>
                  <div class="text-sm text-center font-medium text-gray-900 mt-0.5">
                    SF
                  </div>
                </div>
              </div>
              <div class="text-sm font-normal text-gray-600">
                <template v-if="state.type === 'FS'">
                  <span class="font-medium">
                    {{ $t('Finish to Start') }}:
                  </span>
                  {{ $t(`The target task can't start before the source task ends (but it may start later).`) }}
                </template>
                <template v-else-if="state.type === 'SS'">
                  <span class="font-medium">
                    {{ $t('Start to Start') }}:
                  </span>
                  {{ $t(`The target task can't start until the source task starts (but it may start later).`) }}
                </template>
                <template v-else-if="state.type === 'FF'">
                  <span class="font-medium">
                    {{ $t('Finish to Finish') }}:
                  </span>
                  {{ $t(`The target task can't end before the source task ends (but it may end later).`) }}
                </template>
                <template v-else-if="state.type === 'SF'">
                  <span class="font-medium">
                    {{ $t('Start to Finish') }}:
                  </span>
                  {{ $t(`The target task can't end before the source task starts (but it may end later).`) }}
                </template>
              </div>
            </div>
          </div>
          <TagsElement
            name="activities"
            :label="$t('Activities')"
            :placeholder="$t('Search activities')"
            :items="all_activities"
            :native="false"
            :search="true"
            :close-on-select="false"
            track-by="search_property"
            append-to="#pm-new-dependency-modal-container"
            :disabled="props.mode === 'edit'"
            rules="required"
            class="mb-6"
          >
            <template #option="{ option }">
              <div class="flex w-full">
                <div class="text-sm font-medium text-gray-900 w-fit flex-shrink-0">
                  {{ $g.getWBSCode(option) }} -&nbsp;
                </div>
                <div class="text-sm text-gray-600 font-regular flex-grow break-all">
                  {{ option.label }} ({{ option?.id }})
                </div>
              </div>
            </template>
            <template #single-label="{ value }">
              <div class="flex items-center w-full px-2 py-1">
                <div class="text-sm font-medium text-gray-900 w-fit flex-shrink-0">
                  {{ $g.getWBSCode(value) }} -&nbsp;
                </div>
                <div class="text-sm text-gray-600 font-regular flex-grow break-all">
                  {{ value.label }} ({{ value?.id }})
                </div>
              </div>
            </template>
          </TagsElement>
          <TextElement
            name="lag"
            input-type="number"
            :label="$t('Lag')"
            :default="0"
            :placeholder="$t('In days')"
            :rules="[
              'integer',
            ]"
          >
            <template #addon-after>
              {{ $t('days') }}
            </template>
            <template #after>
              <div class="text-sm font-regular text-gray-600">
                {{ $t('Number of days to wait after finishing the activity. Use -ve for setting lead.') }}
              </div>
            </template>
          </TextElement>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
