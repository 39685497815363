<script setup>
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);
const $t = inject('$t');
const $toast = inject('$toast');

dayjs.extend(utc);
dayjs.extend(timezone);

const auth_store = useAuthStore();
const project_management_store = useProjectManagementStore();

const { logged_in_user_details } = storeToRefs(auth_store);
const { active_schedule } = storeToRefs(project_management_store);
const { sync_activity_progress } = project_management_store;

const state = reactive({
  task_data: {
    data_date: new Date(),
  },
  min_data_date: active_schedule.value?.data_date,
  max_data_date: new Date(),
  is_syncing: false,
  user_timezone: logged_in_user_details.value?.timezone || dayjs.tz.guess(),
});

async function syncProgress() {
  try {
    state.is_syncing = true;

    let final_data_date;
    if (dayjs(state.task_data.data_date).isSame(dayjs(), 'day'))
      final_data_date = null;
    else
      final_data_date = dayjs(state.task_data.data_date).format('YYYY-MM-DD');

    await sync_activity_progress({
      schedule_uid: active_schedule.value.uid,
      body: {
        ...(final_data_date ? { data_date: final_data_date } : {}),
      },
    });
    emit('close');
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
    });
  }
  finally {
    state.is_syncing = false;
  }
}
</script>

<template>
  <HawkModalContainer :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      v-model="state.task_data"
      size="sm"
      sync
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: {
          container: 12,
          label: 3,
          wrapper: 9,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="text-lg text-gray-900 font-semibold">
              {{ $t('Sync now') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <DateTimeElement
            name="data_date"
            :rules="['required']"
            :label="$t('Data date')"
            :description="$t('Select the date for which the progress should be recorded.')"
            :columns="{ container: 12, label: 3, wrapper: 9 }"
            :class="{ 'mb-6': is_start_date_field_visible || is_finish_date_field_visible }"
            :options="{
              'min-date': state.min_data_date,
              'max-date': state.max_data_date,
              'timezone': state.user_timezone,
            }"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <Vueform size="sm">
              <div class="flex justify-end w-full col-span-full">
                <ButtonElement
                  name="cancel"
                  class="mr-4"
                  :secondary="true"
                  @click="emit('close')"
                >
                  {{ $t('Cancel') }}
                </ButtonElement>
                <ButtonElement
                  name="sync"
                  :loading="state.is_syncing"
                  @click="syncProgress"
                >
                  {{ $t('Sync') }}
                </ButtonElement>
              </div>
            </vueform>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
