<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, route } = useCommonImports();
const sidebar_top_items = computed(() => [
  {
    uid: 'forms',
    label: $t('Forms'),
    to: { name: 'forms-overview-list-view' },
    icon: IconHawkFileFive,
  },
  {
    uid: 'templates',
    label: $t('Templates'),
    to: { name: 'forms-templates', params: { asset_id: route.params.asset_id }, query: { active: 'published' } },
    icon: IconHawkFileLockTwo,
  },
  {
    uid: 'submissions',
    label: $t('Submissions'),
    to: { name: 'forms-submission-overview', params: { asset_id: route.params.asset_id } },
    icon: IconHawkCheckCircle,
  },
  {
    uid: 'requests',
    label: $t('Requests'),
    to: { name: 'forms-requests', params: { asset_id: route.params.asset_id }, query: { active: 'pending' } },
    icon: IconHawkYesNo,
  },
  {
    uid: 'archived',
    label: $t('Archived'),
    to: { name: 'forms-archived' },
    icon: IconHawkTrashFour,
  },
]);
</script>

<template>
  <hawk-sidebar-navigation :sidebar_top_items="sidebar_top_items" />
</template>
