<script setup>
import { nextTick, ref } from 'vue';
import { load_js_css_file } from '~/common/utils/load-script.util';

const props = defineProps({
  options: {
    type: Object,
  },
  editable: {
    type: Boolean,
  },
});

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');

onMounted(async () => {
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@15.1.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@15.1.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );

  nextTick(makeDataTable);
});

const data_table = ref(null);
const table_container = ref(null);
const is_table_data_ready = ref(false);

async function makeDataTable() {
  let table_settings = { ...(props.options?.table_settings || {}) };
  if (props.options?.format_load && table_settings.data)
    table_settings = await props.options?.format_load(table_settings);
  is_table_data_ready.value = true;
  data_table.value = new Handsontable(table_container.value, table_settings);
  data_table.value.render();
}

function clearData() {
  data_table.value.clear();
}

function save() {
  const data = data_table.value.getData();
  if (props.options?.format_data)
    emit('save', props.options?.format_data(data));
  else
    emit('save', data);
}
</script>

<template>
  <hawk-modal-container :width="800" content_class="rounded-lg w-[800px]">
    <hawk-modal-header @close="emit('close')">
      <template #title>
        <div class="">
          <p>{{ props.options?.heading }}</p>
          <p class="text-sm text-gray-700 font-normal">
            {{ $t(`Add or copy data into table`) }}
          </p>
        </div>
      </template>
    </hawk-modal-header>
    <hawk-modal-content class="h-[70vh]">
      <hawk-loader v-if="!is_table_data_ready" />
      <div ref="table_container" />
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #left>
        <div v-if="editable" class="flex">
          <hawk-button type="plain" color="error" @click="clearData">
            {{ $t('Clear all') }}
          </hawk-button>
        </div>
      </template>
      <template #right>
        <div class="flex justify-end items-center">
          <hawk-button type="outlined" @click="emit('close')">
            {{ `${editable ? $t('Cancel') : $t('Close')}` }}
          </hawk-button>
          <hawk-button v-if="editable" class="ml-5" @click="save">
            {{ $t('Save') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>

<style scoped lang="scss">
.custom-httable {
    border-radius: 8px;
    z-index: 1;
    border:  1px solid #ccc ;
    :deep(.handsontable) {
      input {
        appearance: auto;
      }
      tr td {
        padding: 4px 8px;
        vertical-align: middle;
        border-color:#ccc !important;
      }
      th{
        border: none !important;
        padding: 6px 8px;
      }
      thead tr:last-child th {
        text-align: left;
      }
      thead th .relative {
        padding: 0;
      }
      td{
        border-right: none;
      }
    }

:deep(.htCore) {
th {
  padding: 6px 8px;
  font-weight: 500;
  vertical-align: middle;
  background-color: rgba(249, 250, 251, 1);
}
td {
  cursor: pointer;

}
}

:deep(.ht_clone_left){
  tbody{
    tr:first-child th{
      border-top:1px solid #ccc !important;
    }
    th {
     padding: inherit !important;
     vertical-align: middle;
     border-bottom:1px solid #ccc !important;
     box-sizing:border-box;
    }
  }
}

  }
</style>
