<script setup>
import { debounce } from 'lodash-es';
import { VueFinalModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { usePusherStore } from '~/common/stores/pusher.store.js';

import { sleep } from '~/common/utils/common.utils.js';

const $t = inject('$t');
const $services = inject('$services');
const auth_store = useAuthStore();
const pusher_store = usePusherStore();

const hawk_activities = ref(null);

const state = reactive({
  open_notification_popup: false,
  is_loading: false,
  notification_count: 0,
});

const user_id = computed(() => auth_store?.logged_in_user_details?.user_id);

function getNotificationQuery(unread = false) {
  return {
    type: 'notification',
    sort: '-createdAt',
    ...(unread && { unread: true }),
  };
}

async function getNotificationCount() {
  try {
    const { data } = await $services.common.get_notifications({
      user: user_id.value,
      query: getNotificationQuery(true),
    });
    state.notification_count = data?.unread_count || 0;
  }
  catch (error) {
    logger.log(error);
  }
}

async function togglePopup() {
  state.open_notification_popup = !state.open_notification_popup;
  await sleep();
  if (state.open_notification_popup) {
    await hawk_activities.value?.getData();
    state.notification_count = 0;
  }
}

async function clearAll() {
  try {
    const response = await $services.common.clear_notifications({
      user: user_id.value,
      body: {
        clearedAt: new Date().toISOString(),
      },
    });
    if (response) {
      await getNotificationCount();
      await hawk_activities.value.getData(true);
    }
  }
  catch (error) {
    logger.log(`error: ${error}`);
  }
}

watchEffect(() => {
  if (pusher_store.is_initialized) {
    pusher_store.user_feed_channel = pusher_store.PUSHER.subscribe(
      `private-notifications_${user_id.value}`,
    );

    pusher_store.user_feed_channel.bind('NEW_ACTIVITIES', debounce(() => {
      getNotificationCount();
    }, 1000));
  }
}, { flush: 'post' });

onMounted(async () => {
  await getNotificationCount();
});
</script>

<template>
  <div>
    <div class="relative" @click="togglePopup">
      <hawk-button
        v-tippy="{ content: $t('Notifications'), placement: 'bottom' }"
        icon
        type="text"
        size="md"
        class="hover:bg-gray-900"
      >
        <IconHawkBellOne class="!text-white" />
      </hawk-button>
      <div v-if="state?.notification_count" :class="{ 'px-1': state.notification_count > 9 }" class="absolute left-5 top-0 text-xs bg-red-500 text-white border h-4 min-w-[16px] grid place-items-center border-white rounded-full font-medium">
        {{ (state.notification_count > 9) ? '9+' : state.notification_count }}
      </div>
    </div>

    <VueFinalModal
      v-model="state.open_notification_popup"
      class="flex justify-center items-center"
      content-class="bg-white border space-t-2 rounded-xl min-w-[14rem] sm:!min-w-[800px] max-w-4xl border-0"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
    >
      <hawk-modal-header @close="state.open_notification_popup = !state.open_notification_popup;">
        <template #title>
          {{ $t('Notifications') }}
        </template>
        <template #right>
          <div class="flex gap-3">
            <HawkButton v-if="hawk_activities?.has_activities" type="text" @click="clearAll()">
              <div>{{ $t('Clear all') }}</div>
            </HawkButton>
            <HawkButton icon type="text" @click="$router.replace({ name: 'profile-settings-notifications' });state.open_notification_popup = !state.open_notification_popup;">
              <IconHawkSettingsOne />
            </HawkButton>
          </div>
        </template>
      </hawk-modal-header>
      <hawk-modal-content class="px-6 !py-0 max-h-[400px]" :is_scroll="false">
        <HawkActivities
          ref="hawk_activities"
          is_notifications
          :can_initialize="false"
          :lazy_load="true"
          @close="state.open_notification_popup = !state.open_notification_popup;"
        />
      </hawk-modal-content>
    </VueFinalModal>
  </div>
</template>
