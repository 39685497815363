<script setup>
import { cloneDeep, groupBy, keyBy, orderBy } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useTerraStore } from '~/terra/store/terra.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  selectedFeatureTypeUid: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { $services, $t, $toast } = useCommonImports();
const terra_store = useTerraStore();
const { getStyles } = useTerraHelperComposable();
const is_loading = ref(false);
const features_list = ref(cloneDeep(terra_store.selected_features));

function checkCanChangeFeatureType(current_feature_type, workflow_uid, feature) {
  const workflow = terra_store.terra_workflows[workflow_uid];
  const progress_type = workflow.data[Object.keys(workflow.data)[0]]?.progress_type || 'units';

  if (progress_type === 'cumulative') {
    return { status: 'not allowed', message: $t('Cannot change type for features associated with a percentage workflow') };
  }
  else {
    const result = { status: true, message: '' };
    let current_class_step_index = workflow.ordered_list.findIndex(item => item.possibleClass === current_feature_type);
    const updated_class_step_index = workflow.ordered_list.findIndex(item => item.possibleClass === props.selectedFeatureTypeUid);
    if (current_class_step_index === -1 || updated_class_step_index === -1) {
      result.status = 'not allowed';
      result.message = $t('Cannot change the type for features as selected class in not associated with the feature');
    }
    else if (updated_class_step_index < current_class_step_index) {
      result.status = 'not allowed';
      result.message = $t('Cannot change type for features to previous stage of workflow.');
    }
    else {
      while (current_class_step_index <= updated_class_step_index) {
        const step_data = workflow.ordered_list[current_class_step_index];
        if (current_class_step_index === updated_class_step_index) {
          result.status = 'allowed';
          result.updated_feature_type = step_data.possibleClass;
          break;
        }
        else {
          const hasRequiredInspectionForm = step_data.fields.some((field) => {
            if (field.type !== 'INTG_101' || !field.config?.required) {
              return false;
            }

            const fieldProgress = feature.properties.workflowProgress?.[field.uid];
            // Only block if form is not completed
            return !(fieldProgress?.current >= (fieldProgress?.total || 1));
          });

          if (hasRequiredInspectionForm) {
            result.status = 'partially allowed';
            result.message = 'Can not change class for the features associated with a workflow with incomplete inspection forms';
            result.updated_feature_type = step_data.possibleClass;
            break;
          }
        }
        current_class_step_index++;
      }
    }
    if (current_feature_type === props.selectedFeatureTypeUid) {
      result.status = 'allowed';
      result.updated_feature_type = props.selectedFeatureTypeUid;
    }
    return result;
  }
}

function getGroupedFeatures() {
  const grouped_features = features_list.value.reduce((acc, f) => {
    const feature_type = f.properties.featureType || 'na';
    const workflow = f.properties.workflow || 'na';

    if (!acc[feature_type]) {
      acc[feature_type] = {
        updated_class: terra_store.feature_types_by_uid[props.selectedFeatureTypeUid],
        class: terra_store.feature_types_by_uid[feature_type],
        count: 0,
        allowed_features: [],
        not_allowed_features: [],
        partially_allow_features: [],
      };
    }

    if (workflow === 'na') {
      acc[feature_type].allowed_features.push({
        feature: f,
        updated_class: terra_store.feature_types_by_uid[props.selectedFeatureTypeUid],
      });
    }
    else {
      const result = checkCanChangeFeatureType(feature_type, workflow, f);
      if (result.status === 'allowed') {
        acc[feature_type].allowed_features.push({
          feature: f,
          updated_class: terra_store.feature_types_by_uid[result.updated_feature_type],
        });
      }
      else if (result.status === 'partially allowed') {
        acc[feature_type].partially_allow_features.push({
          feature: f,
          updated_class: terra_store.feature_types_by_uid[result.updated_feature_type],
        });
      }
      else {
        acc[feature_type].not_allowed_features.push({
          feature: f,
          message: result.message,
        });
      }
    }

    acc[feature_type].count = acc[feature_type].count + 1;
    return acc;
  }, {});
  const filtered_grouped_features = orderBy(Object.entries(grouped_features)
    .filter(([_, group]) => group.count > 0), ([, value]) => value.class?.name?.toLowerCase() || '', ['asc'])
    .reduce((filtered, [key, value]) => {
      filtered[key] = value;
      return filtered;
    }, {});
  return filtered_grouped_features;
}

function getFeaturesGroupedByClass(value) {
  const classes = [];

  if (value.allowed_features.length) {
    classes.push({
      class: value.updated_class,
      features: value.allowed_features.map(item => item.feature),
    });
  }
  if (value.partially_allow_features.length) {
    const grouped_by_updated_class = groupBy(value.partially_allow_features, item => item.updated_class.uid);
    for (const [key, data] of Object.entries(grouped_by_updated_class)) {
      const class_value = terra_store.feature_types_by_uid[key];
      classes.push({
        class: class_value,
        features: data.map(item => item.feature),
        message: `${$t('Cannot change to')} ${terra_store.feature_types_by_uid[props.selectedFeatureTypeUid].name} ${$t('due to mandatory inspection check in')} ${class_value.name} ${$t('step')}`,
      });
    }
  }
  if (value.not_allowed_features.length) {
    const grouped_by_updated_class = groupBy(value.not_allowed_features, item => item.message);
    for (const [key, data] of Object.entries(grouped_by_updated_class)) {
      classes.push({
        message: key,
        features: data.map(item => item.feature),
      });
    }
  }

  return classes;
}

function deselectFeatures(features) {
  const feature_uids = features.map(item => item.properties.uid);
  terra_store.selected_features = terra_store.selected_features.filter(item => !feature_uids.includes(item.properties.uid));
}

async function handleWorkflowProgression(feature, newFeatureType, currentFeatureType, workflowUid) {
  logger.log('🚀 ~ handleWorkflowProgression ~ newFeatureType:', newFeatureType, terra_store.feature_types_by_uid[newFeatureType]?.name);
  logger.log('🚀 ~ handleWorkflowProgression ~ currentFeatureType:', currentFeatureType, terra_store.feature_types_by_uid[currentFeatureType]?.name);
  // If the feature is already of the target type, no need to process
  if (currentFeatureType === newFeatureType) {
    return {
      stopAtClass: newFeatureType,
      stoppedEarly: false,
      skippedFeatures: [],
      progressData: {}, // Return empty progress data
    };
  }
  const workflow = terra_store.terra_workflows[workflowUid];
  const classChain = terra_store.getWorkflowClassChain(workflow);

  // If there's no current feature type, allow moving forward to the new type
  if (!currentFeatureType) {
    return {
      stopAtClass: newFeatureType,
      stoppedEarly: false,
      skippedFeatures: [],
      progressData: {}, // Return empty progress data
    };
  }

  // Find current and target positions in the chain
  const currentIndex = classChain.findIndex(item => item.possibleClass === currentFeatureType);
  const targetIndex = classChain.findIndex(item => item.possibleClass === newFeatureType);

  // Initialize skipped features array
  const skippedFeatures = [];

  // Check if feature type exists in the workflow
  if (currentIndex === -1 || targetIndex === -1) {
    // If current index is not found but target is valid, allow the change
    if (currentIndex === -1 && targetIndex !== -1) {
      return {
        stopAtClass: newFeatureType,
        stoppedEarly: false,
        skippedFeatures: [],
        progressData: {}, // Return empty progress data
      };
    }
  }

  // If trying to move backward in the workflow (target comes before current)
  if (targetIndex < currentIndex) {
    skippedFeatures.push({
      featureUid: feature.properties.uid,
      featureName: feature.properties.name,
      currentClass: currentFeatureType,
      currentClassName: terra_store.feature_types_by_uid[currentFeatureType]?.name || currentFeatureType,
      targetClass: newFeatureType,
      targetClassName: terra_store.feature_types_by_uid[newFeatureType]?.name || newFeatureType,
      reason: 'Cannot move backward in workflow',
    });

    return {
      stopAtClass: currentFeatureType, // Don't change the class
      stoppedEarly: true,
      skippedFeatures,
      progressData: {}, // Return empty progress data
    };
  }
  // Find the first class with required integration form between current and target
  let stopAtIndex = targetIndex;

  // Check all steps from current to target (inclusive of current)
  for (let i = currentIndex; i < targetIndex; i++) {
    const stepData = workflow.data[classChain[i].possibleClass];
    const integrationField = stepData.fields?.find(f =>
      f.type === 'INTG_101'
      && f.config?.required === true,
    );

    if (integrationField) {
      const fieldProgress = feature.properties.workflowProgress?.[integrationField.uid];
      // Only stop if form is not completed
      if (!(fieldProgress?.current >= (fieldProgress?.total || 1))) {
        stopAtIndex = i;
        break;
      }
    }
  }

  // Calculate progress for each step until stopAtIndex
  const progressData = {};
  const featureUid = feature.properties.uid;

  // Create a single progress entry that accounts for all transitions
  // This is the key change - we'll accumulate all field progress in one entry
  const finalStepProgressData = {
    progress: {},
    status_changed: true,
    new_feature_type: classChain[stopAtIndex].possibleClass,
    old_feature_type: classChain[currentIndex].possibleClass,
    attachments: [],
    notes: '',
    category: '',
    timestamp: null,
  };

  for (let i = currentIndex; i < stopAtIndex; i++) {
    const currentStepClass = classChain[i].possibleClass;
    const stepData = workflow.data[currentStepClass];
    if (stepData?.required) {
      Object.entries(stepData.required).forEach(([fieldUid, requiredPercentage]) => {
        const total = feature.properties.workflowProgress?.[fieldUid]?.total;
        const current = feature.properties.workflowProgress?.[fieldUid]?.current || 0;
        const requiredValue = Math.round(total * requiredPercentage);
        // <= because if all fields are completed, we should just allow changing
        if (current <= requiredValue) {
          const valueToAdd = requiredValue - current;
          finalStepProgressData.progress[fieldUid] = {
            current: current + valueToAdd,
            total,
            value: valueToAdd,
          };
        }
      });
    }
  }
  // Add the final progress data
  progressData[featureUid] = finalStepProgressData;

  logger.log('Generated progress data for feature:', featureUid, progressData);

  // Return the class where we stopped, whether we stopped early, and the progress data
  return {
    stopAtClass: classChain[stopAtIndex].possibleClass,
    stoppedEarly: stopAtIndex < targetIndex,
    skippedFeatures,
    progressData,
  };
}

async function updateFeatureType() {
  is_loading.value = true;

  try {
    const features = terra_store.selected_features;
    const allProgressUpdates = {}; // Collect all progress updates here
    const allNonWorkflowUpdates = {};

    for (const feature of features) {
      const workflowUid = feature.properties.workflow;
      const newFeatureType = props.selectedFeatureTypeUid;
      const currentFeatureType = terra_store.feature_types[feature.properties.featureTypeId]?.uid;
      // Skip features that are already at the target class
      if (currentFeatureType === newFeatureType) {
        continue; // Skip this feature entirely
      }

      if (workflowUid) {
        const { stopAtClass, _stoppedEarly, _skippedFeatures, progressData } = await handleWorkflowProgression(
          feature,
          newFeatureType,
          currentFeatureType,
          workflowUid,
        );

        // Properly merge progress data into allProgressUpdates
        // Each feature's progress data is keyed by its UID
        if (progressData && Object.keys(progressData).length > 0) {
          for (const [featureUid, progressInfo] of Object.entries(progressData)) {
            if (Object.keys(progressInfo?.progress || {}).length)
              allProgressUpdates[featureUid] = progressInfo;
          }
        }

        // Update the feature type to the class where we stopped
        feature.properties.featureType = stopAtClass;
        feature.properties.oldfeatureTypeId = feature.properties.featureTypeId;
      }
      else {
        // For features without workflow, directly update to the new feature type
        feature.properties.featureType = newFeatureType;
        feature.properties.oldfeatureTypeId = feature.properties.featureTypeId;
        feature.properties.featureTypeId = terra_store.feature_types_by_uid[newFeatureType]?.id;
        allNonWorkflowUpdates[feature.properties.uid] = feature;
      }
    }

    logger.log('All progress updates:', allProgressUpdates);

    // Make a single API call with all updates if there are any
    if (Object.keys(allProgressUpdates).length > 0) {
      const response = await $services.features.update_workflow_progress({
        container_id: terra_store.container.uid,
        body: allProgressUpdates,
      });
      const workflow_features_map = keyBy(response?.data?.features || [], f => f.properties.uid);
      terra_store.selected_features = terra_store.selected_features.map(f => workflow_features_map[f.properties.uid] || f);

      await terra_store.create_or_update_selected_features({
        reselectFeatures: true,
        updateFeatureRequest: false,
      });
    }
    if (Object.keys(allNonWorkflowUpdates).length > 0) {
      const non_workflow_features_map = allNonWorkflowUpdates;
      terra_store.selected_features = Object.values(non_workflow_features_map);
      await terra_store.create_or_update_selected_features({
        reselectFeatures: true,
        updateFeatureRequest: true,
      });
      terra_store.selected_features = features_list.value.map(f => terra_store.features_hash[f.properties.uid]);
    }
    terra_store.update_map_features_and_polygon();
  }
  catch (err) {
    console.error('Update feature type failed:', err);
    $toast({
      text: err.message || 'Failed to update feature class',
      type: 'error',
      position: 'top-center',
      has_dismiss_button: true,
      timeout: 5000,
    });
  }
  is_loading.value = false;
  emit('close');
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <hawk-modal-header>
      <template #header>
        <div class="p-6 border-b border-b-gray-200">
          <div class="flex items-start justify-between text-lg font-semibold text-gray-800">
            <HawkFeaturedIcon
              theme="light-circle-outline"
              color="primary"
              size="lg"
            >
              <IconHawkGridOne />
            </HawkFeaturedIcon>

            <div class="flex items-center justify-center">
              <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2" @click="emit('close')">
                <IconHawkXClose class="w-6 h-6" />
              </div>
            </div>
          </div>
          <div class="mt-2">
            <p class="font-semibold text-lg mb-1">
              {{ $t('Are you sure to change classes?') }}
            </p>
            <p class="text-gray-600 text-sm  mt-2 mb-1">
              {{ $t('The following classes will get changed once saved') }}
            </p>
          </div>
        </div>
      </template>
    </hawk-modal-header>

    <hawk-modal-content class="max-h-80 scrollbar" :is_scroll="false">
      <div class="flex flex-col">
        <div v-for="(val, key) in getGroupedFeatures()" :key="key">
          <div v-for="(data, i) in getFeaturesGroupedByClass(val)" :key="i" class="flex justify-between items-start gap-x-6 py-2">
            <div class="flex items-center w-44 flex-auto">
              <div class="w-4 h-4 rounded-full" :style="getStyles(val.class)" />
              <HawkText class="text-sm font-medium ml-2" :content="(val.class?.name || 'Unassociated')" :length="16" />
              <HawkBadge class="ml-2">
                {{ data.features.length }}
              </HawkBadge>
            </div>
            <div class="flex items-center flex-none w-6">
              <IconHawkArrowRight />
            </div>
            <div class="flex items-center justify-between w-[300px] flex-auto">
              <div class="flex flex-col w-full">
                <div class="flex items-start justify-between">
                  <div class="flex flex-col gap-y-2">
                    <div v-if="data.class" class="flex items-center">
                      <div class="w-4 h-4 rounded-full" :style="getStyles(data.class)" />
                      <span class="text-sm font-medium ml-2">
                        <HawkText :content="(data.class?.name)" :length="25" />
                      </span>
                    </div>
                    <div v-if="data.message" class="text-xs text-gray-600">
                      {{ data.message }}
                    </div>
                  </div>
                  <div class="cursor-pointer" @click="deselectFeatures(data.features)">
                    <IconHawkXClose class="w-4.5 h-4.5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end w-full">
          <HawkButton type="outlined" class="mr-3" @click="emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton :loading="is_loading" @click="updateFeatureType">
            {{ $t('Yes, Change classes') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>

<style scoped lang="scss">

</style>
